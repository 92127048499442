import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404'
    },
    { path: 'suppliers-list', component: DashboardComponent },
    { path: 'xero-invoices', component: DashboardComponent },
    { path: 'supplier-details', component: DashboardComponent },
    { path: 'invoices-list', component: DashboardComponent },
    { path: 'received-invoices', component: DashboardComponent },
    { path: 'debt-collection', component: DashboardComponent },
    { path: 'credit-notes', component: DashboardComponent },
    {
        path: '',
        redirectTo: 'suppliers-list',
        pathMatch: 'full'
    },];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinanceRoutingModule { }
