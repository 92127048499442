import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, LOCALE_ID, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
//import { LoaderService } from '../../common/loading/LoaderService';
import { CourseType, errorMessages } from '../../common/validation/messages';
import { CoursesService } from '../../common/_services/courses-services';
import { AddCourseComponent } from './add-course/add-course.component';
import { CourseClickableParentComponent } from './course-clickable-parent/course-clickable-parent.component';
import * as $ from "jquery";
import { formatDate, KeyValue } from '@angular/common';
import { AuthenticationService } from '../../common/guards/AuthenticationService';
import { CommonServiceService } from '../../common/_services/common-service.service';
import jsPDF from 'jspdf';
import { LoaderService } from '../../common/loading/LoaderService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-courses',
    templateUrl: './courses.component.html',
    styleUrls: ['./courses.component.css']
})

export class CoursesComponent implements OnInit
//, AfterViewInit
{


    //ngAfterViewInit() {
    //    this.loaderService.httpProgress().subscribe((status: boolean) => {
    //
    //        if (status) {
    //            this.renderer.addClass(document.body, 'cursor-loader');
    //        } else {
    //            this.renderer.removeClass(document.body, 'cursor-loader');
    //        }
    //    });
    //}

    _date = new Date();
    _methodToload = "";
    listTitle = "";
    coursesList: any;
    courseReportList: any = [];
    displayCourse: any = {};
    gridOptions: GridOptions;
    domLayout;
    private gridApi;
    tabIndex: any = 0;
    paginationPageSize: any = 50;

    @Output() gotoMainMenu = new EventEmitter<void>();
    @Output() gotoCourseTitles = new EventEmitter<void>();
    @Output() gotoMimeo = new EventEmitter<void>();


    @ViewChild('modalContentInsert', { static: true }) modalContentInsert: TemplateRef<any>;
    @ViewChild('modalContentEdit', { static: true }) modalContentEdit: TemplateRef<any>;

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        //{ field: 'courseId', width: 150 },
        { field: 'dates.courseStartDate', headerName: 'Start Date', valueFormatter: this.convertStringToDate, width: 150 },
        { field: 'title', width: 350, valueFormatter: this.getDayNumber },
        { field: 'speakerName', width: 170 },
        { field: 'location', width: 125 },
        { field: 'hotel.name', width: 150 },
        //{ field: 'hotel.capacity', headerName: 'Capacity', width: 125 },
        { field: 'boxSent', headerName: 'Material Sent', valueFormatter: this.boolFormatter, width: 150 },
        { field: 'hotelBooked', valueFormatter: this.boolFormatter, width: 150 },
        { field: 'courseBookings', headerName: 'Bookings', width: 150 },
        {
            field: 'Action', cellRendererFramework: CourseClickableParentComponent,
            sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    getDayNumber(params) {
        //debugger;
        if (params.data.dayNumber != null)
            return params.data.title + params.data.dayNumber;
        else return params.value;
    }

    dateFormatter(params) {

        //return params ? (new Date(params.value)).toLocaleDateString() : '';
        return params ? this.convertStringToDate(params.value).toString() : '';

    }

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);

            // getting yesterday date, because we set the start date one day ahead for calendar view
            //date = new Date(date.setDate(date.getDate() - 1));

            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return new Date().toString();
    }

    public style: any = {
        width: '100%',
        height: '100%',
        //flex: '1 1 auto'
    };


    constructor(//private loaderService: LoaderService, 
        private spinner: NgxSpinnerService,
//        private renderer: Renderer2,
        //@Inject(LOCALE_ID) public locale: string,
        private _authService: AuthenticationService,
        private modal: NgbModal,
        private _coursesService: CoursesService,
        private _route: Router,
        private _common: CommonServiceService) {

        _common.setBackNavigation();

        this.gridOptions = {
            context: {
                componentParent: this
            },
            rowClass: 'grid-row',
            suppressCellSelection: true,
            rowClassRules: {
                "isCancelled": params => params.api.getValue("dates.d1Cancelled", params.node) == true
            },
        };

        this.domLayout = "autoHeight";
    }

    rowClassRules = {
        'isCancelled': function (params) {
            return params.data.dates.d1Cancelled === true;
        },
        'is-inhouse': (params) => {
            return params.data.isInhouse == true;
        },

    };

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#myGrid").style.height = "";
        var elm = document.querySelector("#myGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#myGrid").style.height = "400px";
        var elm = document.querySelector("#myGrid") as HTMLElement;
        elm.style.height = '535px'; // previous was 535px with pagination
    }

    //@Output() refreshGrid = new EventEmitter();

    openModal(param) {
        const modalRef = this.modal.open(AddCourseComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        if (this._methodToload == this.methodName.all)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.allCourses(false));
        else if (this._methodToload == this.methodName.archive)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.allCourses(true));
        else if (this._methodToload == this.methodName.upcoming)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.upcomingCourses());
        //modalRef.componentInstance.refreshGrid.subscribe(event => this.getUpcomingCoursesList(this.paginationPageSize, 1));
    }

    refreshGridMethod() {

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        if (this._methodToload == this.methodName.all)
            this.allCourses(false);
        else if (this._methodToload == this.methodName.archive)
            this.allCourses(true);
        else if (this._methodToload == this.methodName.upcoming)
            this.upcomingCourses();
    }

    boolFormatter(params) {

        if (params.value == null)
            return '-';
        else if (params.value)
            return 'Yes';
        else if (!params.value)
            return 'No';
        else return '-';
    }

    public rowData = [
        {}
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
        resizable: true
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        //    //this.rowData$ = this.http
        //    //    .get<any[]>('http://www.ag-grid.com/example-assets/row-data.json');

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);

        if (e.colDef.field != "Action")
            this.gotoMainMenu.emit(e.data);
    }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }





    ngOnInit(): void {



        localStorage.setItem('projectPageSize', '50');

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'));

        //this.getUpcomingCoursesList(this.paginationPageSize, 1);
        this.upcomingCourses();


    }



    //gotoMainMenu(tab) {
    //    if (tab.index == 1) {
    //        this._route.navigateByUrl("/delegates");
    //    }
    //    else if (tab.index == 2) {
    //        this._route.navigateByUrl("/schedule");
    //    }
    //}

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);

        //this.getUpcomingCoursesList(Number(elm.value), this.gridApi.paginationGetCurrentPage() + 1);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }

    onBtFirst() {
        //this.gridApi.paginationGoToFirstPage();
        this.pageNumber = 1;
        //this.getUpcomingCoursesList(this.paginationPageSize, this.pageNumber);
    }

    onBtLast() {
        //this.gridApi.paginationGoToLastPage();
        this.pageNumber = this.totalPages;
        //this.getUpcomingCoursesList(this.paginationPageSize, this.pageNumber);
    }

    onBtNext() {
        //this.gridApi.paginationGoToNextPage();
        this.pageNumber = this.pageNumber + 1;


        if (this.pageNumber > this.totalPages)
            this.pageNumber = this.totalPages;

        //this.getUpcomingCoursesList(this.paginationPageSize, this.pageNumber);
    }

    onBtPrevious() {
        //   this.gridApi.paginationGoToPreviousPage();
        this.pageNumber = this.pageNumber - 1;

        if (this.pageNumber < 1)
            this.pageNumber = 1;
        //this.getUpcomingCoursesList(this.paginationPageSize, this.pageNumber);
    }

    onBtPageFive() {
        // we say page 4, as the first page is zero
        //this.gridApi.paginationGoToPage(4);

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        //this.getUpcomingCoursesList(this.paginationPageSize, 4);
    }

    pageNumber: any = 1;
    totalPages: any = 0;
    totalRecords: any = 0;

    onPaginationChanged() {
        //console.log('onPaginationPageLoaded');
        // Workaround for bug in events order
        if (this.gridApi!) {
            //this.setText('#lbLastPageFound', this.gridApi.paginationIsLastPageFound());
            //this.setText('#lbPageSize', this.gridApi.paginationGetPageSize());
            // we +1 to current page, as pages are zero based

            //this.setText('#lbCurrentPage', this.gridApi.paginationGetCurrentPage() + 1);
            //this.setText('#lbTotalPages', this.gridApi.paginationGetTotalPages());
            //this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());



            //this.setText('#lbFromRecords', (Number(this.pageNumber) * Number(this.paginationPageSize) - (this.paginationPageSize - 1)));

            //if (Number(this.pageNumber) == Number(this.totalPages))
            //    this.setText('#lbToRecords', this.totalRecords);
            //else
            //    this.setText('#lbToRecords', Number(this.pageNumber) * Number(this.paginationPageSize));
            //this.setText('#lbCurrentPage', this.pageNumber);

            //this.getUpcomingCoursesList(this.gridApi.paginationGetPageSize(), this.gridApi.paginationGetCurrentPage() + 1);
        }
    }

    setText(selector: string, text: any) {
        (document.querySelector(selector) as any).innerHTML = text;
    }
    setLastButtonDisabled(disabled: boolean) {
        (document.querySelector('#btLast') as any).disabled = disabled;
    }

    methodName = {
        all: "allCourses",
        upcoming: "upcomingCourses",
        archive: "archivedCourses"
    }

    allCourses(isArch) {
        this._methodToload = this.methodName.all;

        if (isArch == false)
            this.listTitle = "Courses";
        else
            this.listTitle = "Archived Courses";

        var req = {
            pageSize: 0,
            currentPage: 0,
            isArchived: isArch
        };

        this._coursesService.getAllCoursesList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.coursesList = result.data;
                
                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    //archivedCourses() {
    //    this.listTitle = "Archived Courses";
    //    this._methodToload = this.methodName.archive;


    //    this._coursesService.getArchivedCoursesList().subscribe((result: any) => {

    //        if (result.success == true) {

    //            this.coursesList = result.data;

    //            if (localStorage.getItem('projectPageSize') != null)
    //                this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

    //            $("#page-size").val(this.paginationPageSize);

    //        } else {

    //            //this._commonService.error(result.data.desc);
    //        }

    //    }, (_error: any) => {
    //        //this.loading = false;
    //    })
    //}



    courseTitlesList() {

        this.gotoCourseTitles.emit();

    }

    changeTab(tabIndex: number) {
        this.tabIndex = tabIndex;
    }





    upcomingCourses() {
        //getUpcomingCoursesList() {

        this._methodToload = this.methodName.upcoming;
        this.listTitle = "Upcoming Courses";
        var req = {
            pageSize: 0,
            currentPage: 0
        };
        this._coursesService.getUpcomingCoursesList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.coursesList = result.data
                
                this.totalRecords = Number(result.message);
                //this.setText('#lbTotalRecords', this.totalRecords);

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                this.totalPages = this.calculatePageNumber(Number(this.paginationPageSize), Number(result.message));
                //this.setText('#lbTotalPages', this.totalPages);
                $("#page-size").val(this.paginationPageSize);

                if (this.pageNumber > this.totalPages)
                    this.pageNumber = this.totalPages;
                //this.paginationPageSize = Number(localStorage.getItem('projectPageSize'));

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    calculatePageNumber = (pageSize, itemIndex) => {
        return Math.ceil(++itemIndex / pageSize);
    };

    addNew() {
        //
        //this._title = "Add new course";
        const modalRef = this.modal.open(AddCourseComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {

            this.refreshGridMethod();
        }, (reason) => {

            //console.log('Dismissed action: ' + reason);
        });
        //modalRef.componentInstance.data = {};
        //this.modal.open(this.modalContentInsert, {

        //    size: 'lg'
        //});

    }

    editCourse() {
        //this._title = "Edit course";
        this.modal.open(this.modalContentEdit, { size: 'lg' });
    }

    close() {

        this.modal.dismissAll();
    }

    onClose(): void {

        this.modal.dismissAll();
    }

    onRowClicked() {
        //

        //this.gotoMainMenu.emit();

        //this._route.navigateByUrl("courses/course-detail");
    }

    gotoMimeoTab() {
        this.gotoMimeo.emit();
    }

    sortDatesFunction(a, b) {
        var dateA = new Date(a.date).getTime();
        var dateB = new Date(b.date).getTime();
        return dateA > dateB ? 1 : -1;
    };

    sortFunction(a, b) {
        var dateA = new Date(a.startDate).getTime();
        var dateB = new Date(b.startDate).getTime();
        return dateA > dateB ? 1 : -1;
    };

    // Preserve original property order
    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    upcomingCoursesReport() {
        
        this.spinner.show();

        this._coursesService.getUpcomingCoursesReport().subscribe((result: any) => {

            if (result.success == true) {

                this.courseReportList = result.data;

                this.displayCourse = [];

               
                // Step 1: Sort the array by date
                //this.courseReportList.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
                this.courseReportList.sort(this.sortFunction);


                // Step 2: Split the array by month
                
                this.courseReportList.forEach((item) => {
                    const monthKey = `${new Date(item.startDate).getMonth() + 1}-${new Date(item.startDate).getFullYear()}`;

                    if (!this.displayCourse[monthKey]) {
                        this.displayCourse[monthKey] = [];
                    }

                    this.displayCourse[monthKey].push(item);
                });

                this.displayCourse.sort(this.sortFunction);
                // Now displayCourse contains separate arrays for each month
                //console.log(displayCourse);
                

                this.downloadCourseReport();
               
                this.spinner.hide();
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    @ViewChild('printSection', { static: false }) el!: ElementRef

    
    downloadCourseReport() {
        
        setTimeout(() => {
            this.spinner.show();

            $("#section-to-print").css("display", "block");

            let pdf = new jsPDF('p', 'px', 'a4', true);


            pdf.setFontSize(8);


            pdf.html(this.el.nativeElement, {

                callback: (pdf) => {
                    pdf.save( 'course.pdf');
                },
                margin: [15, 15, 15, 15],
                autoPaging: 'text',
                x: 25,
                y: 25,
                width: 350, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            })

            setTimeout(() => {
                $("#section-to-print").css("display", "none");
                this.spinner.hide();
            }, 4000);
        }, 1000);

        
    }

    

    getCourseType(type) {
        return CourseType[type];
    }

}
