import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from '../../../common/guards/AuthenticationService';
import { userRoles } from '../../../common/model/request';
import { errorMessages, regExpressions } from '../../../common/validation/messages';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { AccountService } from '../../account.service';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit, AfterViewInit {

    formModal: any;
    verificationForm: any;
    loading = false;
    errors: any = errorMessages;
    baseurl: string;
    public url = environment.apiURL;
    public verificationData: any;


    constructor(private http: HttpClient, //@Inject('BASE_URL') base: string,
        private formBuilder: FormBuilder,
        private _accountService: AccountService,
        private _route: Router,
        private renderer: Renderer2,
        private _commonService: CommonServiceService,
        private _auth: AuthenticationService) {
        //this.baseurl = base;
        this.getGoogleVerificationData();
    }

    ngOnInit(): void {
        this.verificationForm = this.formBuilder.group({
            inputCode: ['', [Validators.required, Validators.pattern(regExpressions.inputCode)]]
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {

            var elem = this.renderer.selectRootElement('#inputCode');

            this.renderer.listen(elem, "focus", () => { console.log('focus') });

            this.renderer.listen(elem, "blur", () => { console.log('blur') });

            elem.focus();

        }, 200);
    }

    getGoogleVerificationData() {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': 'Bearer ' + localStorage.getItem('Token')

        });


        this.http.get<any>(this.url + 'api/Account/v1/Verification', { headers }).subscribe(result => {
            localStorage.setItem("Token", result.verification.token);
            this.verificationData = result.verification;
        }, error => console.log(error));

        
    }

    get f() { return this.verificationForm.controls; }

    onSubmit() {


        this.loading = true;
        if (this.verificationForm.invalid) {
            return;
        }

        var code = { inputCode: this.verificationForm.value.inputCode }

        this._accountService.authorize(code).subscribe((result: any) => {


            this.loading = false;



            if (result.data.status == true) {

                localStorage.setItem("Token", result.data.token);
                
                if (this._auth.userRole() == userRoles.TVScreen.name)
                    this._route.navigateByUrl("tv");
                else
                    this._route.navigateByUrl("home/dashboard");

                //this._route.navigateByUrl(result.data.redirectPath);
            } else {
                this.clearForm();
                this._commonService.error("Invalid code");
            }

        }, (_error: any) => {
            this.loading = false;
        })
    }

    clearForm() {
        this.loading = false;
        this.verificationForm.reset();
    }

    
}
