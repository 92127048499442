import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InhouseRoutingModule } from './inhouse-routing.module';
import { InhouseListComponent } from './inhouse-list/inhouse-list.component';
import { InhouseDetailComponent } from './inhouse-detail/inhouse-detail.component';
import { AgGridModule } from 'ag-grid-angular';
import { AddEditInhouseComponent } from './add-edit-inhouse/add-edit-inhouse.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OrganisationsComponent } from './organisations/organisations.component';
import { AddEditOrganisationComponent } from './organisations/add-edit-organisation/add-edit-organisation.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        InhouseListComponent,
        InhouseDetailComponent,
        AddEditInhouseComponent,
        OrganisationsComponent,
        AddEditOrganisationComponent
    ],
    imports: [
        CommonModule,
        InhouseRoutingModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [
        InhouseListComponent,
        InhouseDetailComponent,
        AddEditInhouseComponent,
        OrganisationsComponent
    ]
})
export class InhouseModule { }
