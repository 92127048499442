import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DelegateService } from '../../common/_services/delegate-services';

@Component({
    selector: 'app-products-booking',
    templateUrl: './products-booking.component.html',
    styleUrls: ['./products-booking.component.css']
})
export class ProductsBookingComponent implements OnInit {

    bookingForm: FormGroup;
    acceptedTC: any = false;
    gdprList: any = [];
    foiList: any = [];
    rmList: any = [];

    selectedGDPR: any;
    gdprId: any;
    gdprDate: any;

    selectedFOI: any;
    foiId: any;
    foiDate: any;

    selectedRM: any;
    rmId: any;
    rmDate: any;

    constructor(private formBuilder: FormBuilder,
        private _route: Router,
        private _delegateService: DelegateService) { }

    ngOnInit(): void {

        $('body').addClass("booking-bg");

        this.bookingForm = this.formBuilder.group({

            name: ['', Validators.required],
            organization: ['', Validators.required],
            department: [''],
            address: ['', Validators.required],
            city: ['', Validators.required],
            postcode: ['', Validators.required],
            telephone: ['', Validators.required],
            email: ['', Validators.email],

            invoice_name: ['', Validators.required],
            invoice_organization: ['', Validators.required],
            invoice_department: [''],
            invoice_address: ['', Validators.required],
            invoice_city: ['', Validators.required],
            invoice_postcode: ['', Validators.required],
            invoice_telephone: ['', Validators.required],
            invoice_email: ['', Validators.email],
            invoice_purchaseOrder: [''],

            dd_GDPR: ['', Validators.required],
            dd_FOI: ['', Validators.required],
            dd_RM: ['', Validators.required],

            subscription: ['', Validators.required],
            leaflet: ['', Validators.required],

            invoice_details: [false],
            invoice_tc: [false, Validators.required],

        });


        this.getFoundationCertDDLists();
    }

    get f() { return this.bookingForm.controls; }

    updateInvoiceDetails(val) {

        if (val == true) {

            this.bookingForm.controls.invoice_name.setValue(this.bookingForm.controls.name.value);
            this.bookingForm.controls.invoice_organization.setValue(this.bookingForm.controls.organization.value);
            this.bookingForm.controls.invoice_department.setValue(this.bookingForm.controls.department.value);
            this.bookingForm.controls.invoice_address.setValue(this.bookingForm.controls.address.value);
            this.bookingForm.controls.invoice_city.setValue(this.bookingForm.controls.city.value);
            this.bookingForm.controls.invoice_postcode.setValue(this.bookingForm.controls.postcode.value);
            this.bookingForm.controls.invoice_telephone.setValue(this.bookingForm.controls.telephone.value);
            this.bookingForm.controls.invoice_email.setValue(this.bookingForm.controls.email.value);

        } else {
            this.bookingForm.controls.invoice_name.setValue('');
            this.bookingForm.controls.invoice_organization.setValue('');
            this.bookingForm.controls.invoice_department.setValue('');
            this.bookingForm.controls.invoice_address.setValue('');
            this.bookingForm.controls.invoice_city.setValue('');
            this.bookingForm.controls.invoice_postcode.setValue('');
            this.bookingForm.controls.invoice_telephone.setValue('');
            this.bookingForm.controls.invoice_email.setValue('');

        }

    }

    termConditions(val) {

        this.acceptedTC = val;
    }

    onBookingSubmit() {

       
        var req = {


            name: this.bookingForm.controls.name.value,
            organization: this.bookingForm.controls.organization.value,
            department: this.bookingForm.controls.department.value,
            address: this.bookingForm.controls.address.value,
            city: this.bookingForm.controls.city.value,
            postcode: this.bookingForm.controls.postcode.value,
            telephone: this.bookingForm.controls.telephone.value,
            email: this.bookingForm.controls.email.value,

            invoice_name: this.bookingForm.controls.invoice_name.value,
            invoice_organization: this.bookingForm.controls.invoice_organization.value,
            invoice_department: this.bookingForm.controls.invoice_department.value,
            invoice_address: this.bookingForm.controls.invoice_address.value,
            invoice_city: this.bookingForm.controls.invoice_city.value,
            invoice_postcode: this.bookingForm.controls.invoice_postcode.value,
            invoice_telephone: this.bookingForm.controls.invoice_telephone.value,
            invoice_email: this.bookingForm.controls.invoice_email.value,
            invoice_purchaseOrder: this.bookingForm.controls.invoice_purchaseOrder.value,

            webinar_GDPR_date: this.gdprDate,
            webinar_GDPR: this.gdprId,  // "" + this.bookingForm.controls.dd_GDPR.value,


            webinar_FOI_date: this.foiDate,
            webinar_FOI: this.foiId, // "" + this.bookingForm.controls.dd_FOI.value,

            webinar_RM_date: this.rmDate,
            webinar_RM: this.rmId, // "" + this.bookingForm.controls.dd_RM.value,

            subscription: this.bookingForm.controls.subscription.value,
            leaflet: this.bookingForm.controls.leaflet.value



        };
       
        this._delegateService.insertProductBooking(req).subscribe((result: any) => {


            if (result.success == true) {

                this.bookingForm.reset(); 
                this._route.navigateByUrl("/booking-confirmation");

            } else {
                //show errror popup

            }

            //}, (_error: any) => {
            //    //this.loading = false;
        })

    }

    getFoundationCertDDLists() {


        this._delegateService.getFoundationCertDDLists().subscribe((result: any) => {

            if (result.success == true) {

                this.gdprList = result.data[0];
                this.foiList = result.data[1];
                this.rmList = result.data[2];

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    onGDPRSelected(event) {
        this.selectedGDPR = event.value.split(','); // event.source.selected._element.nativeElement.innerText.split(',');

        this.gdprId = this.selectedGDPR[0];
        this.gdprDate = this.selectedGDPR[1];
    }

    onFOISelected(event) {
        this.selectedFOI = event.value.split(','); // event.source.selected._element.nativeElement.innerText.split(',');

        this.foiId = this.selectedFOI[0];
        this.foiDate = this.selectedFOI[1];
    }

    onRMSelected(event) {
        this.selectedRM = event.value.split(','); //event.source.selected._element.nativeElement.innerText.split(',');

        this.rmId = this.selectedRM[0];
        this.rmDate = this.selectedRM[1];
    }
}
