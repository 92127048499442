import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  //selector: 'app-actions',
  //templateUrl: './actions.component.html',
  //styleUrls: ['./actions.component.css']

    selector: 'app-actions',
    template: `<a href=javascript:void() style="height: 21px" (click)="editClick()" class="grid-btn"><i class="material-icons">edit</i></a>
    <a href=javascript:void() style="height: 21px" (click)="deleteClick()" class="grid-btn"><i class="material-icons">delete</i></a>
    <a href=javascript:void() *ngIf="showDownload == true" style="height: 21px" (click)="download()" class="grid-btn"><i class="material-icons">download</i></a>`,
    styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {

    showDownload: boolean = false;
    @Input() cell: any;
    @Input() type: any;
    @Input() module: any;
    @Output() onClicked = new EventEmitter<any>();

    deleteClick(): void {
        debugger;
        this.onClicked.emit({ Cell: this.cell, Type: 'delete', Comp: 'course' });
    }

    viewClick(): void {
        this.onClicked.emit({ Cell: this.cell, Type: 'view' });
    }

    editClick(): void {
        
        this.onClicked.emit({ Cell: this.cell, Type: 'edit' });
    }

    download(): void {

        this.onClicked.emit({ Cell: this.cell, Type: 'download' });
    }

    constructor() { }

    ngOnInit(): void {
        if (this.module == "credit note")
            this.showDownload = true;
        else
            this.showDownload = false;
    }

}
