import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoursesRoutingModule } from './courses-routing.module';
import { CoursesComponent } from './courses.component';
import { SharedModule } from '../../shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { ViewCourseComponent } from './view-course/view-course.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
//import { AnHeaderComponent } from '../../shared/_components/layouts/an-header/an-header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CourseActionsComponent } from './course-actions/course-actions.component';
import { CourseClickableParentComponent } from './course-clickable-parent/course-clickable-parent.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddCourseComponent } from './add-course/add-course.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckboxRendererComponent } from './course-actions/checkbox-renderer/checkbox-renderer.component';
import { CourseTitlesListComponent } from './course-titles/course-titles-list/course-titles-list.component';
import { AddEditCourseTitlesComponent } from './course-titles/add-edit-course-titles/add-edit-course-titles.component';
import { CourseTitleClickableParentComponent } from './course-titles/course-title-clickable-parent/course-title-clickable-parent.component';
import { CourseTitleActionsComponent } from './course-titles/course-title-actions/course-title-actions.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ButtonRendererComponent } from './course-actions/button-renderer/button-renderer.component';
import { MimeoComponent } from './mimeo/mimeo.component';

@NgModule({
    declarations: [
        CoursesComponent,
        ViewCourseComponent,
        CourseDetailsComponent,
        CourseActionsComponent,
        CourseClickableParentComponent,
        AddCourseComponent,
        CheckboxRendererComponent,
        ButtonRendererComponent,
        CourseTitlesListComponent,
        AddEditCourseTitlesComponent,
        CourseTitleClickableParentComponent,
        CourseTitleActionsComponent,
        MimeoComponent,
        
    ],
    imports: [
        CommonModule,
        CoursesRoutingModule,
        //SharedModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatMenuModule,
        MatIconModule
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],

    exports: [CoursesComponent,
        CourseDetailsComponent,
        AddCourseComponent,
        CourseActionsComponent,
        CourseClickableParentComponent,
        CourseTitlesListComponent,
        MimeoComponent
    ]
})
export class CoursesModule { }
