<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="speakerEditForm" (ngSubmit)="onSubmit()" name="speakerEditForm" autocomplete="off">

        <div class="row">

            <div class="col-md-6">

                <label class="text-second">Speaker name:</label>
                <input type="text" class="form-control no-radius" placeholder="Speaker name"
                       [ngClass]="{  'is-invalid': f.speakerName.errors}"
                       required formControlName="speakerName" maxlength="250" />
                <div *ngIf="f.speakerName.errors" class="invalid-feedback">
                    <div *ngIf="f.speakerName.errors.pattern">{{errors.name}}</div>
                </div>

            </div>

            <div class="col-md-6">
                <label class="text-second">Subject:</label>
                <mat-select formControlName="subjectIds" #select multiple
                            class="form-control no-radius">
                    <mat-option *ngFor="let sub of subjectsList" [value]="sub.subjectId">{{sub.title}}</mat-option>
                </mat-select>
            </div>

            <div class="col-12">
                <label class="text-second">Address Line 1:</label>
                <input type="text" class="form-control no-radius" placeholder="Address Line 1"
                       [ngClass]="{  'is-invalid': f.address1.errors}"
                       formControlName="address1" maxlength="250" />
            </div>

            <div class="col-12">
                <label class="text-second">Initials:</label>
                <input type="text" class="form-control no-radius" placeholder="Initials"
                       [ngClass]="{  'is-invalid': f.initials.errors}"
                       formControlName="initials" maxlength="5" />
            </div>

            <div class="col-6">
                <label class="text-second">Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Telephone"
                       formControlName="telephone" maxlength="50" />
            </div>

            <div class="col-6">
                <label class="text-second">Mobile:</label>
                <input type="text" class="form-control no-radius" placeholder="Mobile"
                       formControlName="mobile" maxlength="50" />
            </div>

            <div class="col-6">

                <label class="text-second">Email 1:</label>
                <input type="text" class="form-control no-radius" placeholder="Email 1"
                       [ngClass]="{  'is-invalid': f.email.errors && f.email.errors.pattern}"
                       required formControlName="email" maxlength="250" />
                <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.pattern">{{errors.email}}</div>
                </div>
            </div>

            <div class="col-6">
                <label class="text-second">Email 2:</label>
                <input type="text" class="form-control no-radius" placeholder="Email 2"
                       [ngClass]="{  'is-invalid': f.email1.errors && f.email1.errors.pattern}"
                       formControlName="email1" maxlength="250" />
                <div *ngIf="f.email1.errors" class="invalid-feedback">
                    <div *ngIf="f.email1.errors.pattern">{{errors.email}}</div>
                </div>
            </div>

            <div class="col-4">
                <label class="text-second">Fees:</label>
                <input type="text" class="form-control no-radius" placeholder="Course Fees"
                       [ngClass]="{  'is-invalid': f.fees.errors}"
                       formControlName="fees" maxlength="250" />
                <div *ngIf="f.fees.errors" class="invalid-feedback">
                    <div *ngIf="f.fees.errors.pattern">{{errors.courseTitle}}</div>
                </div>

            </div>
        </div>

        <div class="modal-footer">

            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>
            <div class="">
                <button [disabled]="!speakerEditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>
        </div>

    </form>


</div>

