import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinanceRoutingModule } from './finance-routing.module';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddEditSupplierComponent } from './add-edit-supplier/add-edit-supplier.component';
import { SupplierActionsComponent } from './suppliers/supplier-actions/supplier-actions.component';
import { SupplierClickableParentComponent } from './suppliers/supplier-clickable-parent/supplier-clickable-parent.component';
import { InvoicesReceivedComponent } from './invoices-received/invoices-received.component';
import { SupplierDetailsComponent } from './suppliers/supplier-details/supplier-details.component';
import { AddEditInvoiceComponent } from './add-edit-invoice/add-edit-invoice.component';
import { InvoiceReceivedActionsComponent } from './invoices-received/invoice-received-actions/invoice-received-actions.component';
import { InvoiceReceivedClickableParentComponent } from './invoices-received/invoice-received-clickable-parent/invoice-received-clickable-parent.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { XeroInvoicesComponent } from './xero-invoices/xero-invoices.component';
import { CreditNotesComponent } from './credit-notes/credit-notes.component';
import { AddEditCreditNoteComponent } from './add-edit-credit-note/add-edit-credit-note.component';

@NgModule({
    declarations: [
        SuppliersComponent,
        AddEditSupplierComponent,
        SupplierActionsComponent,
        SupplierClickableParentComponent,
        InvoicesReceivedComponent,
        SupplierDetailsComponent,
        AddEditInvoiceComponent,
        InvoiceReceivedActionsComponent,
        InvoiceReceivedClickableParentComponent,
        //XeroComponent,
        XeroInvoicesComponent,
        CreditNotesComponent,
        AddEditCreditNoteComponent,
        
    ],
    imports: [
        CommonModule,
        FinanceRoutingModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatMenuModule,
        MatIconModule,
        MatButtonToggleModule,
        MatRadioModule
    ],
    exports: [
        SuppliersComponent,
        InvoicesReceivedComponent,
        SupplierDetailsComponent,
        XeroInvoicesComponent,
        CreditNotesComponent
    ]
})
export class FinanceModule { }
