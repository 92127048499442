import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-supplier-actions',
    template: `<a href=javascript:void() style="height: 21px" (click)="editClick()" class="grid-btn"><i class="material-icons">edit</i></a>
    <a href=javascript:void() style="height: 21px" (click)="deleteClick()" class="grid-btn"><i class="material-icons">delete</i></a>`,
   styleUrls: ['./supplier-actions.component.css']
})
export class SupplierActionsComponent implements OnInit {

    @Input() cell: any;
    @Input() type: any;
    @Output() onClicked = new EventEmitter<any>();

    deleteClick(): void {

        this.onClicked.emit({ Cell: this.cell, Type: 'delete', Comp: 'course' });
    }

    viewClick(): void {
        this.onClicked.emit({ Cell: this.cell, Type: 'view' });
    }

    editClick(): void {
        this.onClicked.emit({ Cell: this.cell, Type: 'edit' });
    }

    constructor() { }

    ngOnInit(): void {
    }

}
