import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-an-footer',
    templateUrl: './an-footer.component.html',
    styleUrls: ['./an-footer.component.css']
})
export class AnFooterComponent implements OnInit {

    currentYear: number = new Date().getFullYear();
    constructor() { }

    ngOnInit(): void {
    }

}
