import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { AppComponent } from './app.component';
import { SideBarLayoutComponent } from './_modules/shared/_components/layouts/side-bar-layout/side-bar-layout.component';
import { BlankLayoutComponent } from './_modules/shared/_components/layouts/blank-layout/blank-layout.component';
import { SharedModule } from './_modules/shared/shared.module';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { JwtInterceptor } from './_modules/common/_interceptor/jwt.interceptor';
//import { AuthGuard } from './_modules/common/guards';
import { JwtModule } from "@auth0/angular-jwt";
import { ErrorInterceptor } from './_modules/common/_interceptor/error.interceptor';
import { VerificationComponent } from './_modules/account/_components/verification/verification.component';
//import { CoursesComponent } from './_modules/core/courses/courses.component';
//import { UploadCoursesComponent } from './_modules/core/upload-courses/upload-courses.component';

//import { MatSelectModule } from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { ViewScheduleComponent } from './_modules/core/Schedule/view-schedule/view-schedule.component';

//import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { YearlyScheduleComponent } from './_modules/core/Schedule/yearly-schedule/yearly-schedule.component';
//import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ViewDelegateComponent } from './_modules/core/delegates/view-delegate/view-delegate.component';
import { AddEditDelegateComponent } from './_modules/core/delegates/add-edit-delegate/add-edit-delegate.component';
import { AgGridModule } from 'ag-grid-angular';
import { DelgateActionsComponent } from './_modules/core/delegates/view-delegate/delgate-actions/delgate-actions.component';
import { ClickableParentComponent } from './_modules/core/delegates/view-delegate/clickable-parent/clickable-parent.component';
import { BookingComponent } from './_modules/core/booking/booking.component';
//import { CourseActionsComponent } from './_modules/core/courses/course-actions/course-actions.component';
//import { CourseClickableParentComponent } from './_modules/core/courses/course-clickable-parent/course-clickable-parent.component';
//import { CourseDetailsComponent } from './_modules/core/courses/course-details/course-details.component';
//import { CourseSpeakerDetailsComponent } from './_modules/core/courses/course-speaker-details/course-speaker-details.component';
//import { CourseDelegateDetailsComponent } from './_modules/core/courses/course-delegate-details/course-delegate-details.component';
import { CourseDatesDetailsComponent } from './_modules/core/courses/course-dates-details/course-dates-details.component';
//import { ViewCourseComponent } from './_modules/core/courses/view-course/view-course.component';
import { DelegateDetailsComponent } from './_modules/core/delegates/delegate-details/delegate-details.component';
import { AnFooterComponent } from './_modules/shared/_components/layouts/an-footer/an-footer.component';
//import { AnHeaderComponent } from './_modules/shared/_components/layouts/an-header/an-header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthGuard } from './_modules/common/guards/auth.guard';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon'
import { UnauthoriseComponent } from './_modules/shared/_components/unauthorise/unauthorise.component';
import { TvScreenComponent } from './_modules/shared/_components/tv-screen/tv-screen.component';
import { ProductsBookingComponent } from './_modules/core/products-booking/products-booking.component';
import { RipaBookingComponent } from './_modules/core/booking/ripa-booking/ripa-booking.component';
import { BookingConfirmationComponent } from './_modules/core/booking/booking-confirmation/booking-confirmation.component';

export function tokenGetter() {
    return localStorage.getItem("Token");
}

//@Injectable({
//    providedIn: 'root'
//})

//export class AuthGaurd implements CanActivate {
//    constructor(/*public authService: AuthService,*/ public router: Router, private spinner: NgxSpinnerService) { }
//    async canActivate(
//        route: ActivatedRouteSnapshot,
//        state: RouterStateSnapshot
//    ): Promise<boolean | UrlTree> {

//        this.spinner.show();
//        let result = true;// localStorage.getItem('token'); //true; //await this.authService.chechAuthenticated();
//        if (!result) {
//            this.router.navigateByUrl('/account/login');
//        }
//        setTimeout(() => {
//            this.spinner.hide()
//        }, 1000);

//        return true;
//    }
//}

//export class AuthGaurd implements CanActivate {

//    constructor(
//        private router: Router,
//        //private auth: AuthService
//    ) { }

//    canActivate() {
//        // if (this.auth.authenticated) {
//        if (false) {
//            return true;
//        } else {
//            this.router.navigateByUrl('/account/login');
//        }
//        return true;
//    } 
//}

const adminRoutes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./_modules/core/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'courses',
        //canActivateChild: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('./_modules/core/courses/courses.module').then(m => m.CoursesModule)
    },
    {
        path: 'delegates',
        loadChildren: () => import('./_modules/core/delegates/delegates.module').then(m => m.DelegatesModule)
    },
    {
        path: 'invoices',
        //canActivateChild: [AuthGuard],
        loadChildren: () => import('./_modules/core/invoices/invoices.module').then(m => m.InvoicesModule)
    },
    {
        path: 'inhouse',
        loadChildren: () => import('./_modules/core/inhouse/inhouse.module').then(m => m.InhouseModule)
    },
    {
        path: 'product',
        loadChildren: () => import('./_modules/core/product/product.module').then(m => m.ProductModule)
    },
    
    //{
    //    path: 'admin',
    //    loadChildren: () => import('./_modules/core/speakers/speakers.module').then(m => m.SpeakersModule)
    //},
    //{
    //    path: 'admin',
    //    loadChildren: () => import('./_modules/core/reports/reports.module').then(m => m.ReportsModule)
    //},
    {
        path: 'finance',
        loadChildren: () => import('./_modules/core/finance/finance.module').then(m => m.FinanceModule)
    },
    {
        path: 'schedules',
        canActivate: [AuthGuard],
        loadChildren: () => import('./_modules/core/Schedule/schedules.module').then(m => m.SchedulesModule)
    },
    //{
    //    path: 'admin',
    //    loadChildren: () => import('./_modules/core/upload-courses/uploads.module').then(m => m.UploadsModule)
    //}

    {
        path: 'admin',
        loadChildren: () => import('./_modules/core/admin/admin.module').then(m => m.AdminModule)
    }
];

const routes: Routes = [
    //{
    //    path: '',
    //    redirectTo: 'home/dashboard',
    //    pathMatch: 'full'
    //},
    {
        path: '',
        component: BlankLayoutComponent,
        children: [
            {
                path: 'account',
                loadChildren: () => import('./_modules/account/account.module').then(m => m.AccountModule)
            },
            {
                path: 'tv', component: TvScreenComponent
            },
            {
                path: 'website',
                loadChildren: () => import('./_modules/core/temp-website/temp-website.module').then(m => m.TempWebsiteModule)
            },
        ]
    },
    {
        path: 'verification',
        component: VerificationComponent,
        children: [
            {
                path: 'verification',
                loadChildren: () => import('./_modules/account/account.module').then(m => m.AccountModule)
            }
        ]
    },

    {
        path: '',
        component: SideBarLayoutComponent,
        canActivate: [AuthGuard],
        children: adminRoutes
    },
    {
        path: '**',
        redirectTo: 'others/404',
        canActivate: [AuthGuard]
    },
    // { path: 'courses', component: CoursesComponent },
    //{ path: 'course', component: ViewCourseComponent },
    //{ path: 'upload-courses', component: UploadCoursesComponent },
    //{ path: 'schedule', component: ViewScheduleComponent },
    //{ path: 'yearly-schedule', component: YearlyScheduleComponent },
    //{ path: 'new-delegate', component: AddEditDelegateComponent },
    //{ path: 'delegates', component: ViewDelegateComponent },
    //{ path: 'delegate-details', component: DelegateDetailsComponent },
    { path: 'booking', component: BookingComponent },
    { path: 'booking-confirmation', component: BookingConfirmationComponent },
    { path: 'booking-product', component: ProductsBookingComponent },
    { path: 'booking-ripa', component: RipaBookingComponent },
    { path: 'skin', component: CourseDatesDetailsComponent },
    { path: 'unauthorise', component: UnauthoriseComponent },
    
    
];

@NgModule({
    declarations: [
        AppComponent,
        //CoursesComponent,

        //UploadCoursesComponent,
        //ViewScheduleComponent,
        //YearlyScheduleComponent,
        //ViewDelegateComponent,
        //AddEditDelegateComponent,
        //DelgateActionsComponent,
        //ClickableParentComponent,
        BookingComponent,
        //CourseActionsComponent,
        //CourseClickableParentComponent,
        //CourseDetailsComponent,
        //CourseSpeakerDetailsComponent,
        //CourseDelegateDetailsComponent,
        CourseDatesDetailsComponent,
        //ViewCourseComponent,
        //DelegateDetailsComponent,
        AnFooterComponent,
        ProductsBookingComponent,
        RipaBookingComponent,
        BookingConfirmationComponent,
        //AnHeaderComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        NgxSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatCardModule,
        HighchartsChartModule,
        MatFormFieldModule,
        MatInputModule,
        AgGridModule,
        MatTabsModule,
        MatExpansionModule,
        MatIconModule,
        //FlatpickrModule.forRoot(),
        //CalendarModule.forRoot({
        //    provide: DateAdapter,
        //    useFactory: adapterFactory,
        //}),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ["localhost:44384"],
                //disallowedRoutesRoutes: []
            }
        })

    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


