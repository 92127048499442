<div class="row">
    <div class="col-lg-10">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Import Courses</span> Schedule

        </h2>

    </div>

    <div class="col-lg-2 pt-1">

        
        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

                <button mat-menu-item (click)="gotoAdmin('speakerList')">Speaker List</button>
                <!--<button mat-menu-item (click)="gotoAdmin('debtCollection')">Debt Collection</button>-->
                <button mat-menu-item (click)="gotoAdmin('speakerCourseNumbers')">Speaker Course Numbers</button>
                <!--<button mat-menu-item (click)="gotoAdmin('uploadCourse')">Upload Course Schedule</button>-->
            </mat-menu>
        </div>




    </div>
</div>

<div class="row mt-10- bg-white ml-0 pb-4">
    
        <div class="container">

            <div class="row justify-content-center">

                    <div class="col-md-12">

                       
                        <p class="pl-0">Download the template provided below and fill in the information of multiple courses in a single spreadsheer. This filled spreadsheet can be uploaded at once to populate data about multiple courses in one go.</p>
                        <hr  class="pl-1" />

                        <div class="row mt-3">
                            <div class="col-md-2 mt-1">
                                Download Excel Template:
                            </div>
                            <div class="col-md-4">
                                <div (click)="downloadProjectFile()" style="cursor:pointer">
                                    <mat-card class="download-card bc-gray">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <img src="assets/images/excel.png" class="download-icon" />
                                            </div>
                                            <div class="col-md-10 download-card-inner">
                                                <strong class="pl-10">ANT Course Template</strong>

                                                <p class="download-btn mb-0"><strong>Download Now</strong></p>
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-2 mt-1">
                                Import Course:

                            </div>
                            <div class="col-md-5">
                                <input id="uploadprofile" class="btn btn-success" style="margin-top:2px;" type="file" name="myfile" (change)="onFileSelect($event)" accept=".xlsx" />

                                <label for="uploadprofile" class="md-button md-raised md-primary  text-second">Choose Files</label>
                                <p class="mb-0">Only .xlsx files are allowed.</p>
                                <p class="">Courses will be import automatically once choosed the file.</p>

                            </div>
                        </div>
                        <br /><br />



                    </div>

            </div>
        </div>
    
</div>