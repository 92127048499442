import { error } from "@angular/compiler/src/util";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { CreatePinComponent } from "../../shared/_components/create-pin/create-pin.component";
import { ErrorComponent } from "../../shared/_components/error/error.component";
import { OtpVerificationComponent } from "../../shared/_components/otp-verification/otp-verification.component";
import { SuccessSummaryComponent } from "../../shared/_components/success-summary/success-summary.component";
import { SuccessComponent } from "../../shared/_components/success/success.component";
import { ValidatePinComponent } from "../../shared/_components/validate-pin/validate-pin.component";
import { BaseRequest, ClientDeviceInfo, DeviceLocation, Users } from "../model/request";
import { CodeStatus, ResponseCode } from "../validation/errors-code";
import { AuthUserService } from "./auth-user.service";


@Injectable({
    providedIn: 'root'
})
export class CommonServiceService {

    public OtpClear = new Subject<any>();
    //OtpClearObservable$ = this.OtpClear.asObservable();

    constructor(private _route: Router,

        private route: ActivatedRoute,
        private _dialog: MatDialog,
        private _authUserService: AuthUserService) { }

    public optVerification(otpLen: number, resendWait: number) {
        return this._dialog.open(OtpVerificationComponent, { disableClose: true, data: { maxLength: otpLen, otpResendWait: resendWait } });
    }

    public createPin() {
        return this._dialog.open(CreatePinComponent, { disableClose: true, minWidth: '25%' });
    }

    public validatePin() {
        return this._dialog.open(ValidatePinComponent, { disableClose: true, minWidth: '20%' });
    }

    public success(desc: string) {
        return this._dialog.open(SuccessComponent, { panelClass: 'modal-sm', disableClose: true, minWidth: '20%', data: { desc: desc } });
    }

    public successSummary(summaryList: any) {
        return this._dialog.open(SuccessSummaryComponent, { disableClose: true, minWidth: '35%', data: { data: summaryList } });
    }

    public error(desc: string) {
        return this._dialog.open(ErrorComponent, { panelClass: 'modal-sm', disableClose: true, minWidth: '20%', data: { desc: desc } });
    }

    public getBaseReq(baseReq: BaseRequest) {

        const deviceLocation = new DeviceLocation()
        deviceLocation.Lat = "0.0";
        deviceLocation.Long = "0.0";
        deviceLocation.BtsId = "0";
        deviceLocation.BtsLoc = "";
        deviceLocation.Src = "GPS";

        const deviceInfo = new ClientDeviceInfo()
        deviceInfo.Chl = 2;
        deviceInfo.App = 6;
        deviceInfo.Ver = "1";
        deviceInfo.Imei1 = "12234234242";
        deviceInfo.Imei2 = "32424242342";
        deviceInfo.SrNo = "45454";
        deviceInfo.Net = 1;
        deviceInfo.OsVer = "5.0";
        deviceInfo.Model = "BM5500";
        deviceInfo.Make = "Aratek";

        const user = new Users();

        var loginUser = this._authUserService.getUserDetail();
        if (loginUser) {
            //user.ABit = "1";
            //user.Act = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            //user.Bid = "AKSA";
            //user.Lslt = null;
            //user.Luat = null;
            //user.Rid = null;
            user.Sid = loginUser.Sid;//"ead4288c-c985-4836-ba16-d8fc22fab6e5";
            //user.SidN = "9WE5jB+x85qqwU4k3ks7Fw9Bx0M7XgX5T7Qz+KvHGaI=";
            if (loginUser.Uid) {
                user.Uid = parseInt(loginUser.Uid);
            }
            user.Unm = loginUser.Unm;
            //var UserData = JSON.parse(localStorage.getItem("currentUser"));
            //user.Unm = this.Remove92FromMobile(UserData.mobileNumber);  // this._appService.currentUserValue["mobileNumber"];//this.currentUserSubject.value["mobileNumber"];//this.currentUser["mobileNumber"];// localStorage.getItem('currentUser');//this._appService.currentUserValue["mobileNumber"];
            //user.Sid = UserData.sid;
        }

        baseReq.dev = deviceInfo;
        baseReq.loc = deviceLocation;
        baseReq.user = user;

        return baseReq;
    }

    public OtpClearField(data: any) {

        if (data) {
            this.OtpClear.next(data);
        }
    }

    checkForNull(params: any) {
        return params === 'null' ? '-' : params;
    }


    setBackNavigation() {
        //debugger;

        if (localStorage.getItem("CURRENT_PATH") != window.location.pathname + window.location.search) {
            var previousPath = localStorage.getItem("CURRENT_PATH");

            if (previousPath != null && previousPath != "" && previousPath != undefined)
                localStorage.setItem("PREVIOUS_PATH", previousPath);
            else previousPath = "";


            localStorage.setItem("CURRENT_PATH", window.location.pathname + window.location.search);
        }
    }
}
