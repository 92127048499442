import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { environment } from '../../../../../../environments/environment';
import { DelegateService } from '../../../../common/_services/delegate-services';
import { DeleteComponent } from '../../../../shared/_components/delete/delete.component';

@Component({
  selector: 'app-clickable-parent',
  //templateUrl: './clickable-parent.component.html',
  //styleUrls: ['./clickable-parent.component.css']
    template: `
        <app-delgate-actions (onClicked)="clicked($event)" [cell]="cell"></app-delgate-actions>
    `
})
export class ClickableParentComponent implements ICellRendererAngularComp {

    private params: any;
    public cell: any;
    public _refDialog: any;
    public url = environment.apiURL;

    agInit(params: any): void {
        
        this.params = params;
        this.cell = { row: params.value, col: params.colDef.headerName, id: params.data.id };
    }

    constructor(private _router: Router,
        private _dialog: MatDialog,
        private _delegateService: DelegateService,
        private http: HttpClient) {
        
    }

    public clicked(data: any): void {
        
        if (data.Type == 'view')
            location.href = "view-project/" + data.Cell.id;
        //this._router.navigateByUrl("view-project/" + data.Cell.pid);

        if (data.Type == 'edit')
            this.params.context.componentParent.openModal(this.params.data);
            //location.href = "new-delegate?delegateId=" + data.Cell.id;
        //this._router.navigateByUrl("edit-project/" + data.Cell.pid);

        if (data.Type == 'delete')
            this.deleteProject(data.Cell.id);

    }


    deleteProject(delegateId: any) {

        //
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {


            if (delegateId > 0) {
                this._delegateService.deleteDelegate(delegateId).subscribe((result: any) => {

                    if (result.success == true) {


                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }

            
            //var req = {
            //    ProjectId: Number(projectId),
            //    UpdatedBy: this.appService.currentUserValue.value.userId
            //}

            //let headers = new HttpHeaders({
            //    'Content-Type': 'application/json',
            //});


            //this.http.post<any>(this.url + '/Project/DeleteProject', JSON.stringify(req), { headers }).subscribe(result => {
            //    if (result.success) {
            //        //this.getProjectAttachments();
            //        
            //        this.genericMessage(result.message, "Success");
            //    } else {

            //    }

            //}, error => console.log(error));

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });

    }

    genericMessage(Desc: any, Status: string) {

        //this._refDialog = this._dialog.open(ShowMessageComponent, {
        //    disableClose: true,
        //    autoFocus: false,
        //    data: { message: Desc, status: Status }
        //});

        //this._refDialog.componentInstance.closeGenericMessasge.subscribe((data) => {
        //    this._refDialog.close();
        //    
        //    if (Status == "Success")
        //        location.href = 'projects';
        //    //else if (Status == "Completed")
        //    //  this._router.navigateByUrl('projects');
        //});

    }

    ngOnInit() {
    }

    refresh(): boolean {
        return false;
    }
}
