import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import previousDay from 'date-fns/esm/previousDay/index';
import jsPDF from 'jspdf';
import { regExpressions } from '../../../../common/validation/messages';
import { CommonServiceService } from '../../../../common/_services/common-service.service';
import { CoursesService } from '../../../../common/_services/courses-services';
import { DelegateService } from '../../../../common/_services/delegate-services';
import { FinanceService } from '../../../../common/_services/finance-services';
import { DeleteComponent } from '../../../../shared/_components/delete/delete.component';
import { ButtonRendererComponent } from '../../../courses/course-actions/button-renderer/button-renderer.component';
import { AddEditInvoiceComponent } from '../../add-edit-invoice/add-edit-invoice.component';
import { AddEditSupplierComponent } from '../../add-edit-supplier/add-edit-supplier.component';
import { InvoiceReceivedClickableParentComponent } from '../../invoices-received/invoice-received-clickable-parent/invoice-received-clickable-parent.component';

@Component({
    selector: 'app-supplier-details',
    templateUrl: './supplier-details.component.html',
    styleUrls: ['./supplier-details.component.css']
})
export class SupplierDetailsComponent implements OnInit {

    loading = false;
    @Input() supplierId: any;

    domLayout;
    private gridApi;
    gridOptions: GridOptions;
    paginationPageSize: any = 10;
    public _refDialog: any;
    frameworkComponents: any;
    currentInvoice: any = [];
    _params: any;
    _supplierId: any = 0;
    currentSupplier: any;
    invoices: any;
    _totalValue: any;

    _paramOut = {
        showReceivedInvoices: false,
        showSuppliers: false,
        id: 0
    }

    @Output() backNavigation = new EventEmitter<void>();
    @Output() gotoSuppliersList = new EventEmitter<void>();
    @Output() gotoInvoicesReceived = new EventEmitter<void>();


    constructor(private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private _financeService: FinanceService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private _dialog: MatDialog,
        private _route: Router,
        private _commonService: CommonServiceService,
        private formBuilder: FormBuilder) {

        _commonService.setBackNavigation();

        this.gridOptions = {
            suppressCellSelection: true,
            rowClass: 'grid-row',
            context: {
                componentParent: this
            }
        };

        this._route.routeReuseStrategy.shouldReuseRoute = () => false;


        this.frameworkComponents = {
            buttonRenderer: ButtonRendererComponent,
        }
    }

    ngOnInit(): void {

        if (this.supplierId) {
            this._supplierId = this.supplierId;

            if (Number(this._supplierId) > 0) {
                this.getSupplierData();
            }
        }

    }


    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'invoiceDate', headerName: 'Issue Date', valueFormatter: this.convertStringToDate },
        { field: 'invoiceNumber', headerName: 'Invoice Number' },
        { field: 'supplierName', headerName: 'From' },
        {
            field: 'grossTotal', headerName: 'Total Amount',
            cellRenderer: params => this.currencyFormatter(params.value),
        },
        {
            field: 'paymentMethod', headerName: 'Transaction Type',
            cellRenderer: params => params.value == null ? "-" : params.value,
        },
        { field: 'datePaid', headerName: 'Paid On', cellRenderer: this.reconcileCellRendererFunc, valueFormatter: this.convertStringToDate },
        {
            field: 'Action', cellRendererFramework: InvoiceReceivedClickableParentComponent,
            sortable: false, filter: false, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        },
        //{
        //    headerName: '',
        //    cellRenderer: 'buttonRenderer',
        //    cellRendererParams: {
        //        onClick: this.openModal.bind(this),
        //        label: 'edit'
        //    },
        //    width: 10

        //},
        //{
        //    headerName: '',
        //    cellRenderer: 'buttonRenderer',
        //    cellRendererParams: {
        //        onClick: this.deleteSupplierInvoice.bind(this),
        //        label: 'delete'
        //    },
        //    width: 10

        //},        
        //{
        //    headerName: '',
        //    cellRenderer: 'buttonRenderer',
        //    cellRendererParams: {
        //        onClick: this.sendInvoice.bind(this),
        //        label: 'download'
        //    },
        //    width: 10

        //}

    ];

    // DATA FORMATTING
    currencyFormatter(currency) {

        if (currency != null) {

            var sansDec = currency.toFixed(0);
            var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '<span>&#163;</span>' + `${formatted}`;
        }
        else return '-';
    }

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: '', model: '', price: 0 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        var elm = document.querySelector("#invoicesReceivedGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");

        var elm = document.querySelector("#invoicesReceivedGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: '', model: '', price: 0 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);

        //this.gotoSupplierDetails.emit(e.data);
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }
    getSupplierData() {

        if (this._supplierId != null) {
            this._financeService.getSupplierData(Number(this._supplierId)).subscribe((result: any) => {

                if (result.success == true) {

                    if (result.data != null) {
                        this.currentSupplier = result.data.supplier;
                        this.invoices = result.data.invoices;

                        this._totalValue = this.invoices.filter(item => item.grossTotal != null)
                            .reduce((sum, current) => sum + current.grossTotal, 0);


                        var sansDec = this._totalValue.toFixed(0);
                        var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        //return `${formatted}`;

                        this._totalValue = formatted;
                    }
                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    gotoSuppliers() {
        //this._paramOut.showSuppliers = true;
        this.gotoSuppliersList.emit();
    }

    gotoInvoicedReceived() {
        //this._paramOut.showReceivedInvoices = true;
        //this.gotoSuppliersList.emit(this._paramOut);
        this.gotoInvoicesReceived.emit();
    }

    addInvoice() {
        const modalRef = this.modal.open(AddEditInvoiceComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = this._supplierId;

        modalRef.result.then((result) => {

            this.getSupplierData();
        }, (reason) => {

            //console.log('Dismissed action: ' + reason);
        });

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getSupplierData());
    }

    openModal(param) {
        
        const modalRef = this.modal.open(AddEditInvoiceComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;


        modalRef.componentInstance.refreshGrid.subscribe(event => this.getSupplierData());

    }
    
    deleteSupplierInvoice(param) {
        
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {
            //debugger;
            if (param.invoiceId > 0) {
                this._financeService.deleteSupplierInvoice(param.invoiceId).subscribe((result: any) => {

                    if (result.success == true) {
                        this.getSupplierData();

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }



        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    @ViewChild('printSection', { static: false }) el!: ElementRef

    sendInvoice(param) {
        
        this.currentInvoice = param;
        setTimeout(() => {


            $("#section-to-print").css("display", "block");

            let pdf = new jsPDF('p', 'px', 'a4', true);


            pdf.setFontSize(8);


            pdf.html(this.el.nativeElement, {

                callback: (pdf) => {
                    pdf.save(param.invoiceNumber + '.pdf');
                },
                margin: [15, 15, 15, 15],
                autoPaging: 'text',
                x: 25,
                y: 25,
                width: 350, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            })

            setTimeout(() => {
                $("#section-to-print").css("display", "none");
            }, 4000);
        }, 1000);
    }

    goBack() {
        //this.gotoSuppliers();

        var previousPath = localStorage.getItem("PREVIOUS_PATH");

        if (previousPath != null && previousPath != "" && previousPath != undefined) {
            if (previousPath == "/finance/suppliers-list")
                this.gotoSuppliers();
            else if (previousPath == "/finance/received-invoices")
                this.gotoInvoicedReceived();
        }

        this.backNavigation.emit();
    }

    editDetials(param) {

        const modalRef = this.modal.open(AddEditSupplierComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getSupplierData());
    }
}
