<div id="section-to-print" #printSection class="full-width" style="display:none; top:0; position: absolute;">

    <div class="row">
        <div class="col-5 pull-left">
            <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
        </div>
        <div class="col-7 text-right">

            <h2 class="mb-3 text-second font-ag-light" style="font-size: 3rem;">
                <span class="text-main font-ag-bold">Debt Collection</span>
            </h2>

            <span class="mb-3 pull-right" style="font-weight: bold;">{{ currentDate | date:'longDate' }}</span>
        </div>

        <!--<table class="full-width">
            <tr style="border-bottom: 1pt solid black; font-weight: bold;">
                <td class="v-align-top w15">Date</td>
                <td class="v-align-top w70">Course</td>
                <td class="v-align-top w15">Delegate numbers</td>
            </tr>
        </table>-->
        <!--<table class="full-width" *ngFor="let monthData of displayCourse">

        <tr>
            <td colspan="3" class="pt-4">
                {{monthData.date | date : 'MMMM yyyy' }}-->
        <!--<label class="text-second p-1 pull-right- p-rl-10px bg-yellow-gradient mb-2">{{monthData.date | date : 'MMMM yyyy' }}</label>-->
        <!--</td>
        </tr>

        <tr *ngFor="let course of monthData.courses" style="border-bottom:1pt solid black;">

            <td class="v-align-top w15">
                {{course.date | date : 'dd-MM-yy'}}
            </td>

            <td class="v-align-top w70">
                <span *ngIf="course.title.includes('Certificate')">{{course.day}} - </span>
                {{course.title}}
            </td>
            <td class="v-align-top w15">
                <span *ngIf="course.isInhouse == true">N/A</span>
                <span *ngIf="course.isInhouse != true">{{course.totalDelegates}}</span>
            </td>
        </tr>-->
        <!--<div class="page-break"></div>-->
        <!--</table>-->
        <!--<div>
            <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />

            <div class="col-lg-7 pull-right">

                <h2 class="mb-3 text-second font-ag-light" style="font-size: 3rem;">
                    <span class="text-main font-ag-bold">Debt Collection</span>
                </h2>

                <span class="mb-3 pull-right" style="font-weight: bold;">{{ currentDate | date:'longDate' }}</span>
            </div>


        </div>-->
        <table class="full-width">
            <tr style="border-bottom: 1pt solid black; font-weight: bold;">
                <td></td>
                <td class="w10">Days since invoice</td>
                <td class="w15-">Delegate</td>
                <td class="w15">Debt</td>
                <td class="w10">Overdue</td>
            </tr>
        </table>
        <table>
            <tr *ngFor="let debt of debtCollectionList" style="border-bottom:1pt solid black;">
                <td class="v-align-top">
                    <span [ngClass]="getColor(getDaysCount(debt.dateAdded).replace(' Days', ''))"
                          class='status-circles mt-1'></span>
                </td>

                <td class="v-align-top w10">
                    {{getDaysCount(debt.dateAdded)}}
                </td>

                <td class="v-align-top">
                    {{debt.delegate?.name ?? '-'}}
                    <br />
                    {{truncateString(debt.delegate?.organization) ?? 'N/A'}}
                    <br />
                    Course: &nbsp;{{truncateString(debt.courseTitle)}}
                    <br />
                    Invoice number: &nbsp;{{debt.invoiceNumber ?? 'Not yet added'}}
                    <br />
                    Finance Details: &nbsp;{{debt.delegate?.invoiceName ?? 'N/A'}}
                    &nbsp; | &nbsp; {{debt.delegate?.invoiceTelephone ?? 'N/A'}}
                    
                </td>

                <td class="v-align-top w15">
                    {{addVAT(debt.amount,20) | currency: 'GBP' : 'symbol' : '1.2-2'}}
                </td>

                <td class="v-align-top w10">
                    {{daysOverdueBy(debt.dateAdded)}}
                </td>


            </tr>

        </table>
    </div>
</div>


<div class="row">
    <div class="col-lg-5" id="print-heading">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Debt Collection</span>
        </h2>

    </div>
    <div class="col-lg-7 pt-1">


        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

                <!--<button mat-menu-item (click)="gotoAdmin('speakerList')">Speaker List</button>
                <button mat-menu-item (click)="gotoAdmin('speakerCourseNumbers')">Speaker Course Numbers</button>
                <button mat-menu-item (click)="gotoAdmin('uploadCourse')">Upload Course Schedule</button>-->

                <button mat-menu-item (click)="gotoSuppliers()">Suppliers List</button>
                <button mat-menu-item (click)="gotoInvoicesReceivedList()">Received Invoices</button>
                <button mat-menu-item (click)="gotoInvoicesIssuedList()">Issued Invoices</button>
                <button mat-menu-item (click)="gotoCreditNotesList()">Credit Notes</button>
                <button mat-menu-item (click)="gotoXeroInvoicesList()">Xero Invoices</button>
                <button mat-menu-item (click)="printing()">Print</button>
            </mat-menu>
        </div>



        <!--<button class="book-btn pull-right filter-apply mr-1" (click)="printing()">
            Print
        </button>-->


        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="mb-2 mr-2 pull-right">
            <!--<mat-button-toggle value="<28 Days" (change)="getFilterToggleValue('<28 Days')" [checked]="filterToggleAll" class="toggle-color"><28 Days</mat-button-toggle>
            <mat-button-toggle value=">28<49 Days" (change)="getFilterToggleValue('>28<49 Days')" class="toggle-color">>28<49 Days</mat-button-toggle>
            <mat-button-toggle value=">49 Days" (change)="getFilterToggleValue('>49 Days')" class="toggle-color">>49 Days</mat-button-toggle>
            <mat-button-toggle value=">63 Days" (change)="getFilterToggleValue('>63 Days')" class="toggle-color">>63 Days</mat-button-toggle>-->


            <div class="row pull-right debt-btn-min-width">
                <div class="col-12">
                    <mat-button-toggle value="<28 Days" (change)="getFilterToggleValue('<28 Days')" [checked]="filterToggleAll" class="debt-toggle-button pull-right filter-apply mr-1 toggle-color">
                        <span class="debt-toggle-button-span">
                            <28 Days
                        </span>
                    </mat-button-toggle>

                </div>
                <div class="col-12 text-right">
                    <div class="mr-1 mt-2 mb-2 p-1 income-box green f-15px">
                        &pound;{{debtCollectionCount.total_28 | number}} | {{debtCollectionCount.invoices_count_28}}
                    </div>
                </div>
            </div>


            <div class="row pull-right debt-btn-min-width">
                <div class="col-12">
                    <mat-button-toggle value=">28<49 Days" (change)="getFilterToggleValue('>28<49 Days')" class="debt-toggle-button pull-right filter-apply mr-1 p-rl-10px- toggle-color">
                        <span class="debt-toggle-button-span">>28<49 Days</span>
                    </mat-button-toggle>
                </div>
                <div class="col-12 text-right">
                    <div class="mr-1 mt-2 mb-2 p-1 income-box amber f-15px">
                        &pound;{{debtCollectionCount.total_Minus49 | number}} | {{debtCollectionCount.invoices_count_Minus49}}
                    </div>
                </div>
            </div>

            <div class="row pull-right debt-btn-min-width">
                <div class="col-12">
                    <mat-button-toggle value=">49 Days" (change)="getFilterToggleValue('>49 Days')" class="debt-toggle-button pull-right filter-apply mr-1 toggle-color">
                        <span class="debt-toggle-button-span">
                            >49 Days
                        </span>
                    </mat-button-toggle>
                </div>

                <div class="col-12 text-right">
                    <div class="mr-1 mt-2 mb-2 p-1 income-box text-second- bg-white- redborder-simple- f-15px">
                        &pound;{{debtCollectionCount.total_49 | number}} | {{debtCollectionCount.invoices_count_49}}
                    </div>
                </div>
            </div>

            <div class="row pull-right debt-btn-min-width">
                <div class="col-12">
                    <mat-button-toggle value=">63 Days" (change)="getFilterToggleValue('>63 Days')" class="debt-toggle-button pull-right filter-apply mr-1 toggle-color">
                        <span class="debt-toggle-button-span">
                            >63 Days
                        </span>
                    </mat-button-toggle>
                </div>
                <div class="col-12 text-right ">
                    <div class="mr-1 mt-2 mb-2 p-1 debt-red f-15px">
                        &pound;{{debtCollectionCount.total_63 | number}} | {{debtCollectionCount.invoices_count_63}}
                    </div>
                </div>
            </div>

        </mat-button-toggle-group>


        <!--<div class="row pull-right debt-btn-min-width">
            <div class="col-12">
                <button class="book-btn pull-right filter-apply mr-1" (click)="getListGreaterThan63Days()">
                    >63 Days
                </button>
            </div>
            <div class="col-12 text-right ">
                <div class="mr-1 mt-2 mb-2 p-1 debt-red f-15px">
                    &pound;{{debtCollectionCount.total_63 | number}} | {{debtCollectionCount.invoices_count_63}}
                </div>
            </div>
        </div>


        <div class="row pull-right debt-btn-min-width">
            <div class="col-12">
                <button class="book-btn pull-right filter-apply mr-1" (click)="getListGreaterThan49Days()">
                    >49 Days
                </button>
            </div>

            <div class="col-12 text-right">
                <div class="mr-1 mt-2 mb-2 p-1 income-box text-second- bg-white- redborder-simple- f-15px">
                    &pound;{{debtCollectionCount.total_49 | number}} | {{debtCollectionCount.invoices_count_49}}
                </div>

            </div>
        </div>

        <div class="row pull-right debt-btn-min-width">
            <div class="col-12">
                <button class="book-btn filter-apply mr-1 p-rl-10px" (click)="getListGreaterThan28LessThan49Days()">
                    >28<49 Days
                </button>

            </div>
            <div class="col-12 text-right">
                <div class="mr-1 mt-2 mb-2 p-1 income-box amber f-15px">
                    &pound;{{debtCollectionCount.total_Minus49 | number}} | {{debtCollectionCount.invoices_count_Minus49}}
                </div>
            </div>
        </div>


        <div class="row pull-right debt-btn-min-width">
            <div class="col-12">
                <button class="book-btn pull-right filter-apply mr-1 " (click)="getListLessThan28Days()">
                    <28 Days
                </button>

            </div>
            <div class="col-12 text-right">
                <div class="mr-1 mt-2 mb-2 p-1 income-box green f-15px">
                    &pound;{{debtCollectionCount.total_28 | number}} | {{debtCollectionCount.invoices_count_28}}
                </div>

            </div>
        </div>-->




    </div>

</div>



<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>


<ag-grid-angular #agGrid
                 id="debtCollectionGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="debtCollectionList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">

</ag-grid-angular>



