import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import jsPDF from 'jspdf';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DelegateService } from '../../../common/_services/delegate-services';
import { FinanceService } from '../../../common/_services/finance-services';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { AddEditInvoiceComponent } from '../add-edit-invoice/add-edit-invoice.component';
import { InvoiceReceivedClickableParentComponent } from './invoice-received-clickable-parent/invoice-received-clickable-parent.component';

@Component({
    selector: 'app-invoices-received',
    templateUrl: './invoices-received.component.html',
    styleUrls: ['./invoices-received.component.css']
})
export class InvoicesReceivedComponent implements OnInit {

    accountantReportsForm: FormGroup;
    _date = new Date();
    currentInvoice: any = [];
    invoicesList: any;
    unpaidInvoicesList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;
    public _refDialog: any;
    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 10;
    _paramOut = {
        showReceivedInvoices: false,
        showSuppliers: false,
        id: 0
    }

    @ViewChild('modalAccountantReports', { static: true }) modalAccountantReports: TemplateRef<any>;

    @Output() gotoSupplierDetails = new EventEmitter<void>();
    @Output() gotoSuppliersList = new EventEmitter<void>();
    @Output() gotoInvoicesIssued = new EventEmitter<void>();
    @Output() gotoDebtCollection = new EventEmitter<void>();
    @Output() gotoXeroInvoices = new EventEmitter<void>();
    @Output() gotoCreditNotes = new EventEmitter<void>();

    @ViewChild('printSection_accountantReport', { static: false }) printAccountantReport!: ElementRef

    @ViewChild('printSection_notPaidInvoices', { static: false }) notPaidInvoices!: ElementRef


    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'invoiceDate', headerName: 'Issue Date', width: 325, valueFormatter: this.convertStringToDate },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 225 },
        { field: 'supplierName', headerName: 'From', width: 225 },
        {
            field: 'grossTotal', headerName: 'Total Amount', width: 225,
            cellRenderer: params => this.currencyFormatter(params.value),
        },
        {
            field: 'paymentMethod', headerName: 'Transaction Type', width: 225,
            cellRenderer: params => params.value == null ? "-" : params.value,
        },
        { field: 'datePaid', headerName: 'Paid On', width: 250, cellRenderer: this.reconcileCellRendererFunc, valueFormatter: this.convertStringToDate },
        {
            field: 'Action', cellRendererFramework: InvoiceReceivedClickableParentComponent,
            sortable: false, filter: false, width: 125, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    // DATA FORMATTING
    currencyFormatter(currency) {

        if (currency != null) {

            var sansDec = currency.toFixed(0);
            var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '<span>&#163;</span>' + `${formatted}`;
        }
        else return '-';
    }

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: '', model: '', price: 0 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        private formBuilder: FormBuilder,
        private _financeService: FinanceService,
        private _commonService: CommonServiceService,
        private _dialog: MatDialog,
    ) {

        _commonService.setBackNavigation();

        this.gridOptions = {
            suppressCellSelection: true,
            rowClass: 'grid-row',
            context: {
                componentParent: this
            }
        };

        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        this.domLayout = "autoHeight";
    }

    ngOnInit(): void {
        localStorage.setItem('projectPageSize', '50');
        this.getReceivedInvoicesList();
        this.getUnpaidReceivedInvoicesList();

        this.accountantReportsForm = this.formBuilder.group({
            year: ['', Validators.required]

        });
    }

    get f() { return this.accountantReportsForm.controls; }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        var elm = document.querySelector("#invoicesReceivedGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");

        var elm = document.querySelector("#invoicesReceivedGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: '', model: '', price: 0 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        if (e.colDef.field != "Action") {
            this.gotoSupplierDetails.emit(e.data);
        }

    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }


    openModal(param) {
        const modalRef = this.modal.open(AddEditInvoiceComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getReceivedInvoicesList());

    }

    deleteSupplierInvoice(param) {

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            if (param.invoiceId > 0) {
                this._financeService.deleteSupplierInvoice(param.invoiceId).subscribe((result: any) => {

                    if (result.success == true) {
                        this.getReceivedInvoicesList();

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }



        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    @ViewChild('printSection', { static: false }) el!: ElementRef


    sendInvoice(param) {
        //debugger;

        this.currentInvoice = param;
        setTimeout(() => {


            $("#section-to-print").css("display", "block");

            let pdf = new jsPDF('p', 'px', 'a4', true);


            pdf.setFontSize(8);


            pdf.html(this.el.nativeElement, {

                callback: (pdf) => {
                    pdf.save(param.invoiceNumber + '.pdf');
                },
                margin: [15, 15, 15, 15],
                autoPaging: 'text',
                x: 25,
                y: 25,
                width: 350, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            })

            setTimeout(() => {
                $("#section-to-print").css("display", "none");
            }, 4000);
        }, 1000);
    }


    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getReceivedInvoicesList() {

        //if (isArch == true) {
        //    this.listTitle = "Archived Delegates";
        //    this._methodToload = this.methodName.archive;
        //} else {
        //    this.listTitle = "Delegates";
        //    this._methodToload = this.methodName.nonArchive;
        //}

        var req = {
            type: ""
        };
        this._financeService.getReceivedInvoicesList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.invoicesList = result.data;

                //this.unpaidInvoicesList = this.invoicesList.filter(i => i.datePaid === null);
                //debugger;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    displayUnpaidInvoice: any = [];

    getUnpaidReceivedInvoicesList() {


        this._financeService.getUnpaidReceivedInvoicesList().subscribe((result: any) => {

            if (result.success == true) {

                this.unpaidInvoicesList = result.data;
                debugger;

                let data = new Set(this.unpaidInvoicesList.map(item => item.name))
                data.forEach((name) => {
                    this.displayUnpaidInvoice.push({
                        name: this.unpaidInvoicesList.filter(i => i.name === name)[0].name,
                        invoices: this.unpaidInvoicesList.filter(i => i.name === name)
                    })
                })

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    gotoSuppliers() {

        this.gotoSuppliersList.emit();
    }

    getTotal(data) {
        return data.reduce((acc, obj) => acc + Number(obj.grossTotal), 0);
    }



    addNewInvoice() {
        const modalRef = this.modal.open(AddEditInvoiceComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {

            this.getReceivedInvoicesList();
        }, (reason) => {

            //console.log('Dismissed action: ' + reason);
        });
    }

    gotoInvoicesIssuedList() {
        this.gotoInvoicesIssued.emit();
    }

    gotoXeroInvoicesList() {

        this.gotoXeroInvoices.emit();
    }

    gotoDebtCollectionList() {
        this.gotoDebtCollection.emit();
    }

    gotoCreditNotesList() {
        this.gotoCreditNotes.emit();
    }

    
    printUnpaid() {
        setTimeout(() => {


            $("#printSection_notPaidInvoices").css("display", "block");

            let pdf = new jsPDF('p', 'px', 'a4', true);


            pdf.setFontSize(8);


            pdf.html(this.notPaidInvoices.nativeElement, {

                callback: (pdf) => {
                    pdf.save('Unpaid_invoices.pdf');
                },
                margin: [15, 15, 15, 15],
                autoPaging: 'text',
                x: 25,
                y: 25,
                width: 350, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            })

            setTimeout(() => {
                $("#printSection_notPaidInvoices").css("display", "none");
            }, 4000);
        }, 1000);
    }


    markAllPaid() {

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to mark all unpaid invoices as Paid?", title: "Payment" }
        });
        debugger;
        this._refDialog.componentInstance.redirect.subscribe((data) => {

            this._financeService.markUnpaidReceivedInvoices().subscribe((result: any) => {

                if (result.success == true) {
                    this.getReceivedInvoicesList();
                    this.getUnpaidReceivedInvoicesList();

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })


        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    //START //ACCOUNTANT REPORTS

    toggleFilterValue: any = "";
    accountantReportsFormValidate: boolean = false;
    reportName: any = "";
    displayAccountantReport: any = [];
    invoicesListForReport: any = [];
    

    viewAccountantReportsPopup() {
        this.modal.open(this.modalAccountantReports, { size: 'lg' });
    }

    updateReportName(_reportName) {
        this.reportName = _reportName;
        this.validateAccountantReportsForm();
    }

    onAccountantReportsFormSubmit() {

        var req = {
            reportName: this.reportName,
            quarterNumber: this.toggleFilterValue,
            year: Number(this.accountantReportsForm.controls.year.value)
        };
        this._financeService.getAccountantReport(req).subscribe((result: any) => {

            if (result.success == true) {

                this.invoicesListForReport = result.data;

                this.displayAccountantReport = [];


                this.invoicesListForReport.sort(this.sortFunction);

                let data = new Set(this.invoicesListForReport.map(item => item.supplierName))
                data.forEach((supplierName) => {

                    this.displayAccountantReport.push({
                        supplier: this.invoicesListForReport.filter(i => i.supplierName === supplierName)[0].supplierName,
                        invoices: this.invoicesListForReport.filter(i => i.supplierName === supplierName)
                    })
                })

                this.displayAccountantReport.forEach((value) => {
                    value.invoices.sort(this.sortDatesFunction);
                });
                debugger;

                
                

                //DOWNLOADING REPORT

                setTimeout(() => {
                    $("#printSection_accountantReport").css("display", "block");
                    window.print();
                    $("#printSection_accountantReport").css("display", "none");
                   
                //    $("#printSection_accountantReport").css("display", "block");

                //    let pdf = new jsPDF('p', 'px', 'a4', true);

                //    pdf.setFontSize(8);

                //    pdf.html(this.printAccountantReport.nativeElement, {

                //        callback: (pdf) => {
                //            pdf.save(this.reportName + '.pdf');
                //        },
                //        margin: [15, 15, 15, 15],
                //        autoPaging: 'text',
                //        x: 25,
                //        y: 25,
                //        width: 350, //target width in the PDF document
                //        windowWidth: 675 //window width in CSS pixels
                //    })

                //    setTimeout(() => {
                //        $("#printSection_accountantReport").css("display", "none");
                //    }, 4000);
                }, 6000);


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })



        this.modal.dismissAll();

        this.resetAccountantReportsForm();
    }

    sortDatesFunction(a, b) {
        var dateA = new Date(a.invoiceDate).getTime();
        var dateB = new Date(b.invoiceDate).getTime();
        return dateA > dateB ? 1 : -1;
    };

    sortFunction(a, b) {
        var dataA = a.supplierName; // new Date(a.month).getTime();
        var dataB = b.supplierName; // new Date(b.month).getTime();
        return dataA > dataB ? 1 : -1;
    };

    resetAccountantReportsForm() {
        this.toggleFilterValue == "";
        this.reportName == "";
    }

    getFilterToggleValue(val: any) {
        this.toggleFilterValue = val;
        this.validateAccountantReportsForm();
    }

    validateAccountantReportsForm() {

        if (this.toggleFilterValue == ""
            || this.reportName == "")
            this.accountantReportsFormValidate = false;
        else
            this.accountantReportsFormValidate = true;

    }
    //END //ACCOUNTANT REPORTS
}
