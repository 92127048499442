import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private spinner: NgxSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        
        request = request.clone({
            setHeaders: {
                'AuthBasic':'Basic YWdlbnRhcHA6ZGlnaUNAQGw=',
                'Authorization': 'Bearer ' + localStorage.getItem('Token')
            }
        });
        
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    
                    this.spinner.hide();
                }
            })
        );
    }
}


