import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef } from '@angular/core';
import { Activity, ChildActivity, MainActivity, SubActivity } from '../../../../../common/model/activities';
import { ActivitiesList } from '../../../../../common/model/request';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  loginUser: any;
  userType: any;
  model: any = {};

  mobileQuery: MediaQueryList;

  decodedToken: any;
  changePassword = "/home/user-management/changepassword";
  activities: MainActivity[] = [];



  private _mobileQueryListener: () => void;


  constructor(
    //public authService: AuthService,
    private router: Router,
    //private _cookieService: CookieService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    //private alertify: AlertifyService,

  ) {

    this.mobileQuery = media.matchMedia('(max-width: 400px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    
    this.makeActivities("test");
    //this.decodedToken = this.authService.decodedToken;
    //this.activities = JSON.parse('[{"id": 105, "parentId": -1, "name": "Home", "url": "home/dashboard","icon":"/assets/images/menu-icons/home.svg"}]');
    //this.activities = ;
    debugger
    this.loginUser = "Admin"; //localStorage.getItem('userName'); //this.decodedToken?.unique_name;
    //this.loginUser = this._cookieService.get('userName');
    this.userType = "Checker";// this.decodedToken?.userType === '1' ? 'Maker' : 'Checker';
  }

  ngOnInit(): void {
  }

  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  loggedIn() {
    return true; //this.authService.loggedIn();
  }

  logout() {
    // //this._cookieService.deleteAll('userName');
    // //this._cookieService.deleteAll('token');
    // localStorage.removeItem('userName');
    // localStorage.removeItem('token');
    // localStorage.removeItem('activities');
    // localStorage.removeItem('flatactivity');
    // this.authService.logOutReason = 'explicit';
    // window.location.reload();
    // this.router.navigate(['/']);
    //this.alertify.logoutConfirm(() => {
    //    this.authService.logout();
    //});

    this.router.navigateByUrl("/account/login");
  }

  makeActivities(activity: any) {
    
    activity = [1, 5, 2, 3, 4, 17, 18];
    if (activity != null) {
      //var mainActivity = new MainActivity();
      //mainActivity.url = "home/dashboard";
      //mainActivity.icon = "assets/images/menu-icons/home.svg";
      //mainActivity.name = "Home";
      //this.activities.push(mainActivity);

      for (let i = 0; i < activity.length; i++) {
        mainActivity = new MainActivity();
        switch (activity[i]) {
          case 5:
            var mainActivity = new MainActivity();
            mainActivity.id = 5;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/transfer.svg";
            mainActivity.name = "App Config";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;

          case 1:
            var mainActivity = new MainActivity();
            mainActivity.id = 1;
            mainActivity.url = "home/dashboard";
            mainActivity.icon = "assets/images/menu-icons/home.svg";
            mainActivity.name = "Home";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 2:
            var mainActivity = new MainActivity();
            mainActivity.id = 2;
            mainActivity.url = "ias/users";
            mainActivity.icon = "assets/images/menu-icons/Retailer.svg";
            mainActivity.name = "Users";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 3:
            var mainActivity = new MainActivity();
            mainActivity.id = 3;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/reports.svg";
            mainActivity.name = "Calls History";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 4:
            var mainActivity = new MainActivity();
            mainActivity.id = 4;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/verify.svg";
            mainActivity.name = "Recordings";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 5:
            var mainActivity = new MainActivity();
            mainActivity.id = 5;
            mainActivity.url = "";
            mainActivity.icon = "assets/images/menu-icons/moneytransfer.svg";
            mainActivity.name = "Money Transfer";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 6:
            var mainActivity = new MainActivity();
            mainActivity.id = 6;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/transfer.svg";
            mainActivity.name = "Transfer to Agent";
            mainActivity.parentId = 5
            this.activities.push(mainActivity);
            break;
          case 7:
            var mainActivity = new MainActivity();
            mainActivity.id = 7;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/transfer.svg";
            mainActivity.name = "Transfer to Franchise";
            mainActivity.parentId = 5
            this.activities.push(mainActivity);
            break;
          case 8:
            var mainActivity = new MainActivity();
            mainActivity.id = 8;
            mainActivity.url = "";
            mainActivity.icon = "assets/images/menu-icons/OTC.svg";
            mainActivity.name = "OTC";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 9:
            var mainActivity = new MainActivity();
            mainActivity.id = 9;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/send.svg";
            mainActivity.name = "Send Money";
            mainActivity.parentId = 8
            this.activities.push(mainActivity);
            break;
          case 10:
            var mainActivity = new MainActivity();
            mainActivity.id = 10;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/receive.svg";
            mainActivity.name = "TRN Receive Money";
            mainActivity.parentId = 8
            this.activities.push(mainActivity);
            break;
          case 11:
            var mainActivity = new MainActivity();
            mainActivity.id = 11;
            mainActivity.url = "payments/cash-in";
            mainActivity.icon = "assets/images/menu-icons/cash-in.svg";
            mainActivity.name = "Cash In";
            mainActivity.parentId = 8
            this.activities.push(mainActivity);
            break;
          case 12:
            var mainActivity = new MainActivity();
            mainActivity.id = 12;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/cash-out.svg";
            mainActivity.name = "Cash Out";
            mainActivity.parentId = 8
            this.activities.push(mainActivity);
            break;
          case 13:
            var mainActivity = new MainActivity();
            mainActivity.id = 13;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/cash-out-voucher.svg";
            mainActivity.name = "Cash Out Voucher";
            mainActivity.parentId = 8
            this.activities.push(mainActivity);
            break;
          case 14:
            var mainActivity = new MainActivity();
            mainActivity.id = 14;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/update.svg";
            mainActivity.name = "Update to Max";
            mainActivity.parentId = 8
            this.activities.push(mainActivity);
            break;
          case 15:
            var mainActivity = new MainActivity();
            mainActivity.id = 15;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/utility-bill.svg";
            mainActivity.name = "Utility Bill";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 16:
            var mainActivity = new MainActivity();
            mainActivity.id = 16;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/user-mangement.svg";
            mainActivity.name = "User Management";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 17:
            var mainActivity = new MainActivity();
            mainActivity.id = 17;
            mainActivity.url = "";
            mainActivity.icon = "assets/images/menu-icons/user.svg";
            mainActivity.name = "My Account";
            mainActivity.parentId = 0
            this.activities.push(mainActivity);
            break;
          case 18:
            var mainActivity = new MainActivity();
            mainActivity.id = 18;
            mainActivity.url = "home/coming-soon";
            mainActivity.icon = "assets/images/menu-icons/key.svg";
            mainActivity.name = "Change Pin";
            mainActivity.parentId = 17
            this.activities.push(mainActivity);
            break;

          default:
            break;
        }

      }
    }
    else {

    }

    var distinctParent = this.activities.filter(x => x.parentId == 0);

    for (var i = 0; i < distinctParent.length; i++) {
      var listOfChild = this.activities.filter(x => x.parentId == distinctParent[i].id);
      var parent = distinctParent[i];
      parent.subActivities = listOfChild;

      listOfChild.forEach((element, index) => {
        var indexvalue = this.activities.findIndex(x => x.id == element.id);
        this.activities.splice(indexvalue, 1);
      });

    }

  }












}



