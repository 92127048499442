import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { DelegateService } from '../../../common/_services/delegate-services';
import { AddEditInhouseComponent } from '../add-edit-inhouse/add-edit-inhouse.component';

@Component({
    selector: 'app-inhouse-list',
    templateUrl: './inhouse-list.component.html',
    styleUrls: ['./inhouse-list.component.css']
})
export class InhouseListComponent implements OnInit {

    inhouseList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 10;



    @Output() gotoInhouseDetails = new EventEmitter<void>();

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'trainingDate', headerName: 'Training Date', width: 200, valueFormatter: this.convertStringToDate },
        { field: 'organisation', headerName: 'Organisation', width: 325 },
        { field: 'contactName', width: 200 },
        { field: 'email', width: 350 },
        { field: 'telephone', width: 225 },
        { field: 'paid', headerName: 'Paid', width: 100, cellRenderer: this.reconcileCellRendererFunc },
        
    ];

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (params.value == true)
            return "<div class='paid status-circles mt-10px'></div>";
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        private http: HttpClient,
        private _delegateService: DelegateService,
        private _route: Router) {

        this.gridOptions = {
            suppressCellSelection: true,
            context: {
                componentParent: this
            }
        };

        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        this.domLayout = "autoHeight";

        
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#inhouseGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#inhouseGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        
        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        if (e.colDef.field != "Action")
            this.gotoInhouseDetails.emit(e.data);
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }




    ngOnInit(): void {
        
        localStorage.setItem('projectPageSize', '10');
        this.getInhouseList(true);
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getInhouseList(isUpcoming) {

        if (isUpcoming == true) {
            this.listTitle = "Upcoming Inhouse";
            this._methodToload = this.methodName.archive;
        } else {
            this.listTitle = "All Inhouse";
            this._methodToload = this.methodName.nonArchive;
        }

        var req = {
            isUpcoming: isUpcoming
        };
        this._delegateService.getInhouseList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.inhouseList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditInhouseComponent, {
            size: 'lg'
        });
    }

}
