import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';
import { FinanceService } from '../../../common/_services/finance-services';

@Component({
    selector: 'app-add-edit-supplier',
    templateUrl: './add-edit-supplier.component.html',
    styleUrls: ['./add-edit-supplier.component.css']
})
export class AddEditSupplierComponent implements OnInit {

    currentDate: any = new FormControl(new Date());
    submitted = false;
    public _title = "Add Supplier";
    public locationsList: any;
    supplierEditForm: FormGroup;
    _params: any;
    _supplierId: any = 0;

    selectedCityName: any;

    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService,
        private _financeService: FinanceService,
        private _route: Router) {

    }

    ngOnInit(): void {

        
        this.loading = true;

        this.supplierEditForm = this.formBuilder.group({
            name: ['', Validators.required],
            address1: [''],
            address2: [''],
            city: [''],
            postcode: [''],
            bankName: [''],
            sortCode: [''],
            accountNumber: [''],
            bankAddress: [''],
            VATNumber: [''],
            companyNumber: [''],
            telephone: [''],
            email: ['', [Validators.pattern(regExpressions.email)]]

        });


        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this.populateForm(this.data);
        }

        this.getLocationsList();

        this.loading = false;
    }

    getLocationsList() {

        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {
                
                this.locationsList = result.data
                debugger;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    onCitySelected(event) {
        // get select option text
        this.selectedCityName = event.source.selected._element.nativeElement.innerText;
        
    }

    get f() { return this.supplierEditForm.controls; }

    populateForm(__data) {
        
        this._title = "Edit Supplier";

        this._supplierId = __data.supplierId;
        
        this.supplierEditForm.controls.name.setValue(__data.name);
        this.supplierEditForm.controls.address1.setValue(__data.address1);
        this.supplierEditForm.controls.address2.setValue(__data.address2);
        this.supplierEditForm.controls.city.setValue(__data.city);
        this.supplierEditForm.controls.postcode.setValue(__data.postCode);
        this.supplierEditForm.controls.bankName.setValue(__data.bankName);
        this.supplierEditForm.controls.sortCode.setValue(__data.sortCode);
        this.supplierEditForm.controls.accountNumber.setValue(__data.accountNumber);
        this.supplierEditForm.controls.bankAddress.setValue(__data.bankAddress);
        this.supplierEditForm.controls.VATNumber.setValue(__data.vatnumber);
        this.supplierEditForm.controls.companyNumber.setValue(__data.companyNumber);
        this.supplierEditForm.controls.telephone.setValue(__data.telephone);
        this.supplierEditForm.controls.email.setValue(__data.email);


    }


    onSubmit() {

        var req = {
            request: {
                SupplierId: Number(this._supplierId),
                Name: this.supplierEditForm.controls.name.value,
                Address1: this.supplierEditForm.controls.address1.value,
                Address2: this.supplierEditForm.controls.address2.value,
                City: this.supplierEditForm.controls.city.value, //this.selectedCityName,
                PostCode: this.supplierEditForm.controls.postcode.value,
                BankName: this.supplierEditForm.controls.bankName.value,
                SortCode: this.supplierEditForm.controls.sortCode.value,
                AccountNumber: this.supplierEditForm.controls.accountNumber.value,
                BankAddress: this.supplierEditForm.controls.bankAddress.value,
                Vatnumber: this.supplierEditForm.controls.VATNumber.value,
                CompanyNumber: this.supplierEditForm.controls.companyNumber.value,
                Email: this.supplierEditForm.controls.email.value,
                CityId: 0, //this.supplierEditForm.controls.city.value,
                Telephone: this.supplierEditForm.controls.telephone.value

            }
        };


        this._financeService.insertSupplier(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();
                
            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    close() { }

    //delete(id) {

    //    if (id > 0) {
    //        this._delegateService.deleteDelegate(id).subscribe((result: any) => {

    //            if (result.success == true) {


    //            } else {

    //                //this._commonService.error(result.data.desc);
    //            }

    //        }, (_error: any) => {
    //            //this.loading = false;
    //        })
    //    }
    //}

    gotoMainMenu(tab) {
        if (tab.index == 0) {
            this._route.navigateByUrl("/courses/all-courses");
        }
        else if (tab.index == 2) {
            this._route.navigateByUrl("/schedule");
        }
    }

}
