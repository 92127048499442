<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="inhouseEditForm" (ngSubmit)="onSubmit()" name="inhouseEditForm" autocomplete="off">

        <div class="row">

            <div class="col-6">
                <label class="text-second">Contact Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Delegate name"
                       [ngClass]="{  'is-invalid': f.name.errors}"
                       required formControlName="name" maxlength="250" />
                <div *ngIf="f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.pattern">{{errors.name}}</div>
                </div>
            </div>


            <div class="col-6">
                <label class="text-second">Email:</label>
                <input type="text" class="form-control no-radius" placeholder="Email"
                       [ngClass]="{  'is-invalid': f.email.errors && f.email.errors.pattern}"
                       required formControlName="email" maxlength="250" />
                <!--<div *ngIf="f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.pattern">{{errors.email}}</div>
        </div>-->

            </div>

            <div class="col-md-12">

                <!--<label class="text-second">Organisation:</label>
        <input type="text" class="form-control no-radius" placeholder="Organisation"
               formControlName="organization" maxlength="250" />-->


                <label class="text-second">Address:</label>
                <input type="text" class="form-control no-radius" placeholder="Address"
                       [ngClass]="{  'is-invalid': f.address1.errors}"
                       required formControlName="address1" maxlength="250" />
            </div>

            <div class="col-6">

                <label class="text-second">Postcode:</label>
                <input type="text" class="form-control no-radius" placeholder="Postcode"
                       pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                       [ngClass]="{  'is-invalid': f.postcode.errors}"
                       required formControlName="postcode" maxlength="20" />
                <div *ngIf="f.postcode.errors" class="invalid-feedback">
                    <div *ngIf="f.postcode.errors.pattern">{{errors.postcode}}</div>
                </div>
            </div>

            <div class="col-6">
                <label class="text-second">Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Telephone"
                       formControlName="telephone" maxlength="50" />

            </div>






            <!--INVOICE SECTION-->

            <hr class="u-line  mt-4 mb-2" />
            <div class="col-12">
                <h4 class="font-ag-light text-second mt-2 mb-0">Invoice Details</h4>
            </div>
            <div class="col-6">
                <label class="text-second">Invoice Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Invoice name"
                       [ngClass]="{  'is-invalid': f.invoiceName.errors}"
                       required formControlName="invoiceName" maxlength="250" />
                <div *ngIf="f.invoiceName.errors" class="invalid-feedback">
                    <div *ngIf="f.invoiceName.errors.pattern">{{errors.name}}</div>
                </div>
            </div>

            <div class="col-6">
                <label class="text-second">Invoice Email:</label>
                <input type="text" class="form-control no-radius" placeholder="Invoice Email"
                       [ngClass]="{  'is-invalid': f.invoiceEmail.errors && f.invoiceEmail.errors.pattern}"
                       required formControlName="invoiceEmail" maxlength="250" />
                <div *ngIf="f.invoiceEmail.errors" class="invalid-feedback">
                    <div *ngIf="f.invoiceEmail.errors.pattern">{{errors.email}}</div>
                </div>
            </div>

            <div class="col-md-12">
                <label class="text-second">Invoice Address:</label>
                <input type="text" class="form-control no-radius" placeholder="Invoice Address"
                       [ngClass]="{  'is-invalid': f.invoiceAddress.errors}"
                       required formControlName="invoiceAddress" maxlength="250" />
            </div>

            <div class="col-4">
                <label class="text-second">Invoice Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Invoice Telephone"
                       formControlName="invoiceTelephone" maxlength="50" />
            </div>

            <div class="col-4">
                <label class="text-second">Purchase Order:</label>
                <input type="text" class="form-control no-radius" placeholder="Purchase order number"
                       formControlName="purchaseOrder" maxlength="50" />
            </div>

            <div class="col-4 mt-3">
                <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                    <mat-label>Invoice Date</mat-label>
                    <input matInput [matDatepicker]="picker_invoice_date" formControlName="invoice_date" placeholder=""
                           [ngModel]="start_d1"  (dateChange)="addEvent($event)"
                           [ngClass]="{'is-invalid':  f.invoice_date.errors}">
                    <mat-datepicker-toggle matSuffix [for]="picker_invoice_date"></mat-datepicker-toggle>
                    <mat-datepicker #picker_invoice_date></mat-datepicker>
                </mat-form-field>
            </div>
        </div>


        <div class="modal-footer">
            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>

            <div class="">
                <button [disabled]="!inhouseEditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>
        </div>

    </form>

    <!--<button class="book-btn" (click)="delete(1)">
        Delete
    </button>-->
</div>

