import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { CoursesService } from '../../../common/_services/courses-services';

@Component({
    selector: 'app-mimeo',
    templateUrl: './mimeo.component.html',
    styleUrls: ['./mimeo.component.css']
})
export class MimeoComponent implements OnInit {

    @Output() gotoAllCourses = new EventEmitter<void>();
    @Output() gotoCourseTitles = new EventEmitter<void>();

    usersList: any;

    constructor(private _coursesService: CoursesService,
        private _commonService: CommonServiceService) { }

    ngOnInit(): void {

        this.getUsersList();
    }

    allCourses() {
        this.gotoAllCourses.emit();
    }

    courseTitlesList() {
        this.gotoCourseTitles.emit();
    }

    getUsersList() {

        this._coursesService.getMimeoUsersList().subscribe((result: any) => {

            if (result.success == true) {

                this.usersList = result.data

                //

            } else {

                this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

}
