<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>





<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Inhouse</span> DETAILS

        </h2>

    </div>
    <div class="col-lg-3 pt-1">

        <!--<button class="book-btn pull-right filter-apply" (click)="allInhouse()">
            Back
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="addNewTraining()">
            Add Training
        </button>-->



        <div class="pull-right">

            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNewTraining()">Add Training</button>
                <button mat-menu-item (click)="upcomingCourses(false)">Organisations</button>
                <button mat-menu-item (click)="upcomingCourses(true)">Upcoming Courses</button>
            </mat-menu>
        </div>

        <button class="mt-4 mr-2  book-btn pull-right filter-apply" (click)="goBack()">
            Back
        </button>
    </div>

</div>


<div class="row no-bg pb-0 p-3 ml-0 mb-0 m-2 pt-0 mt-0">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <label class="text-second-- top-dd">{{currentInhouse.organisationName}}</label>

            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="row no-bg">

            <div class="col-md-4">
            </div>

            <div class="col-md-4">
            </div>

            <div class="col-md-4">
                <label class="text-second fw-normal pull-right">
                    Lifetime Value:
                    <span class="income-box">
                        <!--&#163;{{printNo(currentInhouse.lifetimeValue)}}-->
                        {{lifeTimeValue | currency: 'GBP' : 'symbol' : '1.0-0' }}
                    </span>
                </label>
            </div>
        </div>
    </div>

</div>


<div class="row outer-div bg-lg ml-0 mb-0 mt-0 m-2 pt-0 p-3">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Contact Name:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentInhouse.contactName}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Department:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentInhouse.department}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Postcode:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentInhouse.postcode}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Telephone:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentInhouse.telephone}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Email:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentInhouse.contactEmail}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Address:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3">

                        <label class="text-second lbl-notes" *ngIf="currentInhouse.address != null">
                            {{currentInhouse.address}}
                        </label>
                        <label class="text-second lbl-notes" *ngIf="currentInhouse.address ==null">
                            N/A
                        </label>

                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Notes:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3">

                        <label class="text-second lbl-notes" *ngIf="currentInhouse.notes != null">
                            {{currentInhouse.notes}}
                        </label>
                        <label class="text-second lbl-notes" *ngIf="currentInhouse.notes ==null">
                            N/A
                        </label>
                    </div>
                </div>

                <div class="btn-holder mb-3">
                    <button class="book-btn pull-right filter-apply" (click)="updateOrganisation()">
                        Update
                    </button>
                </div>

                <!--<div class="row bg-white h-crs-detail m-0 mb-3px h-auto pb-3-">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Postal Address:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3 lbl-address">
                        <label class="text-second w-inherit" *ngIf="currentInhouse.postalName!=null">
                            {{currentInhouse.postalName}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouse.postalAddress!=null">
                            {{currentInhouse.postalAddress}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouse.postalTelephone!=null">
                            {{currentInhouse.postalTelephone}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouse.postalCity!=null">
                            {{currentInhouse.postalCity}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouse.postalPostcode!=null">
                            {{currentInhouse.postalPostcode}}
                        </label>

                        <label class="text-second lbl-notes-" *ngIf="(currentInhouse.postalPostcode == null) &&
                               (currentInhouse.postalName==null) &&
                               (currentInhouse.postalAddress==null) &&
                               (currentInhouse.postalTelephone==null) &&
                               (currentInhouse.postalCity==null)">
                            N/A
                        </label>
                        <div class="btn-holder">
                            <button class="book-btn pull-right filter-apply" (click)="updatePostalAddress()">
                                Update
                            </button>
                        </div>

                    </div>
                </div>-->












                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto pb-3-">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Invoice Details:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3 lbl-address">
                        <label class="text-second w-inherit" *ngIf="currentInhouseInvoiceDetails.invoiceName!=null">
                            {{currentInhouseInvoiceDetails.invoiceName}}
                        </label>
                        <label class="text-second w-inherit-" *ngIf="currentInhouseInvoiceDetails.invoiceAddress!=null">
                            {{currentInhouseInvoiceDetails.invoiceAddress}}
                        </label>
                        <label class="text-second w-inherit-" *ngIf="currentInhouseInvoiceDetails.postcode!=null">
                            {{currentInhouseInvoiceDetails.postcode}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouseInvoiceDetails.invoiceEmail!=null">
                            {{currentInhouseInvoiceDetails.invoiceEmail}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouseInvoiceDetails.invoiceDepartment!=null">
                            {{currentInhouseInvoiceDetails.invoiceDepartment}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouseInvoiceDetails.invoiceOrganisation!=null">
                            {{currentInhouseInvoiceDetails.invoiceOrganisation}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouseInvoiceDetails.invoiceTelephone!=null">
                            {{currentInhouseInvoiceDetails.invoiceTelephone}}
                        </label>
                        <!--<label class="text-second w-inherit" *ngIf="currentInhouse.invoicePaidBy!=null">
                            {{currentInhouse.invoicePaidBy}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentInhouse.invoicePaidOn!=null">
                            Paid on: {{currentInhouse.invoicePaidOn |  date:'dd-MM-yy'}}
                        </label>-->


                        <label class="text-second lbl-notes-" *ngIf="(currentInhouseInvoiceDetails.invoiceName == null) &&
                               (currentInhouseInvoiceDetails.invoiceAddress==null) &&
                               (currentInhouseInvoiceDetails.invoiceEmail==null) &&
                               (currentInhouseInvoiceDetails.invoiceDepartment==null) &&
                               (currentInhouseInvoiceDetails.invoiceOrganisation==null) &&
                               (currentInhouseInvoiceDetails.invoiceTelephone==null)">
                            N/A
                        </label>

                        <!--<div class="btn-holder">
                            <button class="book-btn pull-right filter-apply" (click)="updateInvoiceAddress()">
                                Update
                            </button>
                        </div>-->

                    </div>
                </div>





            </div>
        </div>
    </div>


    <div class="col-md-8">
        <div *ngFor="let inhouse of __inhouse">
            {{setInhouse(inhouse[0])}}
            {{setCurrentInhouseTrainingDates(inhouse[1])}}
            {{setCurrentInhouseItems(inhouse[2])}}
            <div class="row">
                <div class="col-md-12">

                    <label *ngIf="currentInhouseTrainingDates.length > 0" class="text-second p-1">Dates / Trainers</label>


                    <!--<label class="text-second p-1 pull-right">{{currentInhouseTrainingDates.length}}</label>-->
                    <!--<label *ngIf="currentInhouseTrainingDates.length > 0" class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{currentInhouseTrainingDates.length}}</label>-->

                    <hr *ngIf="currentInhouseTrainingDates.length > 0" class="u-line" />



                    <div *ngIf="currentInhouseTrainingDates.length > 0" class="row">
                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Training Date:</label>
                        </div>

                        <!--<div class="col-md-3 pr-0">
                            <label class="text-second fw-normal">Subject:</label>
                        </div>-->

                        <div class="col-md-5 pr-0">
                            <label class="text-second fw-normal">Trainer:</label>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Trainer Fee:</label>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Expense:</label>
                        </div>
                        <div class="col-md-1 pr-0">

                        </div>
                    </div>

                    <div *ngFor="let training of currentInhouseTrainingDates">

                        <div class="row">
                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{training.trainingDate |  date:'dd-MM-yy'}}</label>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="col-md-3 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{training.subject}}</label>
                                    </div>
                                </div>
                            </div>-->

                            <div class="col-md-5 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{training.trainerName}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{training.trainerFee | currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{training.trainerExpense| currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1 mt-1">
                                        <a href=javascript:void() style="height: 21px" (click)="editTrainingDatesClick(training)" class="grid-btn"><i class="material-icons">edit</i></a>
                                        <a href=javascript:void() style="height: 21px" (click)="deleteTrainingDatesClick(training)" class="grid-btn"><i class="material-icons">delete</i></a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <!--<div class="col-12 mb-1">
                        <button class="book-btn pull-right filter-apply" (click)="addTrainer(inhouse[0].inHouseId)">
                            Add new Trainer/Date
                        </button>
                    </div>-->
                <!--<hr class="u-line" />-->
            </div>



            <div class="row">
                <div class="col-md-12">

                    <label *ngIf="inhouseCourseFeeItems.length > 0" class="text-second p-1">Dates / Fees</label>

                    <!--<label *ngIf="inhouseCourseFeeItems.length > 0" class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{inhouseCourseFeeItems.length}}</label>-->
                    <!--<label class="text-second p-1 pull-right">{{currentInhouseItems.length}}</label>-->


                    <hr *ngIf="inhouseCourseFeeItems.length > 0" class="u-line" />

                    <div *ngIf="inhouseCourseFeeItems.length > 0" class="row">
                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Course Date:</label>
                        </div>

                        <!--<div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Type:</label>
                        </div>-->

                        <div class="col-md-4 pr-0">
                            <label class="text-second fw-normal">Description:</label>
                        </div>

                        <div class="col-md-1 pr-0">
                            <label class="text-second fw-normal">Qty:</label>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Amount:</label>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Total:</label>
                        </div>

                        <div class="col-md-1 pr-0">

                        </div>
                    </div>

                    <div *ngFor="let item of inhouseCourseFeeItems">

                        <div class="row">
                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.dateAdded |  date:'dd-MM-yy'}}</label>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.itemType}}</label>
                                    </div>
                                </div>
                            </div>-->

                            <div class="col-md-4 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.itemDescription}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.quantity}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.amount | currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{(item.quantity * item.amount) | currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1 mt-1">
                                        <a href=javascript:void() style="height: 21px" (click)="editInhouseItemClick(item)" class="grid-btn"><i class="material-icons">edit</i></a>
                                        <a href=javascript:void() style="height: 21px" (click)="deleteInhouseItemClick(item)" class="grid-btn"><i class="material-icons">delete</i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row" *ngIf="inhouseCourseFeeItems.length > 0">
                        <div class="col-md-8 pr-0">

                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Training Fee:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{courseTotalFee | currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label class="text-second fw-normal">Inc VAT:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{courseCost | currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>



                    <label *ngIf="inhouseCourseCostItems.length > 0" class="text-second p-1">Expenses</label>

                    <!--<label *ngIf="inhouseCourseCostItems.length > 0" class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{inhouseCourseCostItems.length}}</label>-->

                    <hr *ngIf="inhouseCourseCostItems.length > 0" class="u-line" />

                    <div *ngIf="inhouseCourseCostItems.length > 0" class="row">
                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Date:</label>
                        </div>

                        <!--<div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Type:</label>
                        </div>-->

                        <div class="col-md-4 pr-0">
                            <label class="text-second fw-normal">Description:</label>
                        </div>

                        <div class="col-md-1 pr-0">
                            <label class="text-second fw-normal">Qty:</label>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Amount:</label>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Total:</label>
                        </div>

                        <div class="col-md-1 pr-0">

                        </div>
                    </div>
                    <div *ngFor="let item of inhouseCourseCostItems">

                        <div class="row">
                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.dateAdded |  date:'dd-MM-yy'}}</label>
                                    </div>
                                </div>
                            </div>

                            <!--<div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.itemType}}</label>
                                    </div>
                                </div>
                            </div>-->

                            <div class="col-md-4 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.itemDescription}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.quantity}}</label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="item.amount < 1" class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.amount| currency: 'GBP'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="item.amount >= 1" class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{item.amount| currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">&nbsp; {{(item.quantity * item.amount) | currency: 'GBP'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 ml-1">
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 p-1 pl-1 mt-1">
                                        <a href=javascript:void() style="height: 21px" (click)="editInhouseItemClick(item)" class="grid-btn"><i class="material-icons">edit</i></a>
                                        <a href=javascript:void() style="height: 21px" (click)="deleteInhouseItemClick(item)" class="grid-btn"><i class="material-icons">delete</i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row" *ngIf="inhouseCourseCostItems.length > 0">
                        <div class="col-md-10 pr-0">

                        </div>
                        <div class="col-md-2">
                            <label class="text-second fw-normal">Course Expense:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{courseExpense | currency: 'GBP' : 'symbol' : '1.2-2'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <!--<div class="col-12 mb-1">
                    <button class="book-btn pull-right filter-apply" (click)="addInhouseItems(inhouse[0].inHouseId)">
                        Add new Fees/Expense
                    </button>
                </div>-->

            </div>



            <div class="row">
                <div class="col-md-12">

                    <label class="text-second p-1">Training Specifics</label>

                    <hr class="u-line" />

                    <div class="row">
                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Contact Name:</label><br>
                            <div class="row bg-white h-crs-detail m-0 mb-2">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp;{{tainingInhouse.contactName}}</label>
                                </div>
                            </div>
                        </div>

                        <!--<div class="col-md-3 pr-0">
        <label class="text-second fw-normal">Course Fee:</label><br />
        <div class="row bg-white h-crs-detail m-0 mb-3">
            <div class="col-md-12 p-1 pl-1">
                <label class="text-second dt-box-f mt-1">&nbsp; &#163;{{courseTotalFee}}</label>
            </div>
        </div>
    </div>

    <div class="col-md-3 pr-0">
        <label class="text-second fw-normal">Course Cost:</label><br />
        <div class="row bg-white h-crs-detail m-0 mb-3">
            <div class="col-md-12 p-1 pl-1">
                <label class="text-second dt-box-f mt-1">&nbsp; &#163;{{courseCost}}</label>
            </div>
        </div>
    </div>

    <div class="col-md-3 pr-0">
        <label class="text-second fw-normal">Course Expense:</label><br />
        <div class="row bg-white h-crs-detail m-0 mb-3">
            <div class="col-md-12 p-1 pl-1">
                <label class="text-second dt-box-f mt-1">&nbsp; &#163;{{courseExpense}}</label>
            </div>
        </div>
    </div>-->
                        <!--</div>

    <div class="row">-->

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Invoice Date:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{tainingInhouse.invoiceDate |  date:'dd-MM-yy'}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 pr-0">
                            <label class="text-second fw-normal">Paid by:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{tainingInhouse.invoicePaidBy}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Payment Date:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{tainingInhouse.invoicePaidOn |  date:'dd-MM-yy' }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">PO Number:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{tainingInhouse.purchaseOrder}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 pr-0">
                            <label class="text-second fw-normal">Amount:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">&nbsp; {{courseCost + courseExpense  | currency: 'GBP' : 'symbol' : '1.0-0'}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 pr-0">
                            <label class="text-second fw-normal">Paid: </label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                <div class="col-md-12">
                                    <label class="text-second fw-normal pr-2-">&nbsp; </label>
                                    <input type="checkbox"
                                           id="{{tainingInhouse.inHouseId}}_chk"
                                           (click)="onPaidClick(inhouse[0])"
                                           [checked]="tainingInhouse.paid" />
                                </div>
                            </div>
                        </div>


                        <div class="col-md-1">
                            <label class="text-second fw-normal">&nbsp;</label><br>

                            <div class="row bg-green- h-crs-detail m-0 mb-2" [ngClass]="getStatus(tainingInhouse)">
                                <div class="col-md-12 p-1 pl-1 text-center">
                                    <label class="dt-box-f mt-1" [ngClass]="getStatus(tainingInhouse) == 'redborder-simple' ? 'text-second' : 'text-white'">
                                        {{tainingInhouse.invoiceNumber}}
                                    </label>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>

                <!--<hr class="u-line" />-->
            </div>

            <div class="row">
                <!--<div class="col-12 mb-1">

                </div>-->

                <div class="col-12 mb-1">
                    <button class="book-btn pull-right filter-apply" (click)="addTrainer(inhouse[0].inHouseId)">
                        Add new Trainer/Date
                    </button>

                    <button class="book-btn pull-right filter-apply mr-1" (click)="addInhouseItems(inhouse[0].inHouseId)">
                        Add new Fees/Expense
                    </button>

                    <button class="book-btn pull-right filter-apply mr-1" (click)="editTraining(inhouse[0])">
                        Edit Training
                    </button>

                    <button class="book-btn pull-right filter-apply mr-1" (click)="deleteTraining(inhouse[0])">
                        Delete Training
                    </button>
                </div>
            </div>

            <hr class="u-line-" />
        </div>


    </div>


</div>











<ng-template #modalEditInvoice let-close="close">

    <div class="modal-header primary">

        <h5 class="modal-title primary">Invoice Details</h5>

    </div>

    <div class="p-4">

        <form class="form-horizontal" [formGroup]="invoiceEditForm" (ngSubmit)="onInvoiceEditSubmit()" name="invoiceEditForm" autocomplete="off">

            <div class="row">

                <div class="col-md-12">

                    <label class="text-second">Name:</label>
                    <input type="text" class="form-control no-radius" placeholder="Contact name"
                           [ngClass]="{  'is-invalid': f.invoiceName.errors}"
                           required formControlName="invoiceName" maxlength="250" />

                    <label class="text-second">Address:</label>
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': f.invoiceAddress.errors}"
                           required formControlName="invoiceAddress" maxlength="250" />

                    <div class="row">
                        <div class="col-6">
                            <label class="text-second">Email:</label>
                            <input type="text" class="form-control no-radius" placeholder="Email"
                                   [ngClass]="{  'is-invalid': f.invoiceEmail.errors && f.invoiceEmail.errors.pattern}"
                                   required formControlName="invoiceEmail" maxlength="250" />
                            <!--<div *ngIf="f.invoiceEmail.errors" class="invalid-feedback">
                                <div *ngIf="f.invoiceEmail.errors.pattern">{{errors.invoiceEmail}}</div>
                            </div>-->
                        </div>
                        <div class="col-6">
                            <label class="text-second">Telephone:</label>
                            <input type="text" class="form-control no-radius" placeholder="Telephone"
                                   formControlName="invoiceTelephone" maxlength="50" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <label class="text-second">Organisation:</label>
                            <input type="text" class="form-control no-radius" placeholder="Organisation"
                                   formControlName="invoiceOrganization" maxlength="250" />
                        </div>
                        <div class="col-6">
                            <label class="text-second">Department:</label>
                            <input type="text" class="form-control no-radius" placeholder="Department"
                                   formControlName="invoiceDepartment" maxlength="250" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <label class="text-second">Paid by:</label>
                            <mat-select *ngIf="true" formControlName="source" #select [(ngModel)]="selectedPaymentMethod" id="paymentMethod"
                                        class="form-control no-radius text-second mt-3-">

                                <mat-option selected value="none" class=" text-second">Select source</mat-option>

                                <mat-option value="BACS">
                                    BACS
                                </mat-option>

                                <mat-option value="Cash">
                                    Cash
                                </mat-option>

                                <mat-option value="Cheque">
                                    Cheque
                                </mat-option>

                                <mat-option value="Credit Note">
                                    Credit Note
                                </mat-option>

                                <mat-option value="Direct Debit">
                                    Direct Debit
                                </mat-option>

                                <mat-option value="AMEX">
                                    AMEX
                                </mat-option>

                                <mat-option value="Paypal">
                                    Paypal
                                </mat-option>

                                <mat-option value="Phone">
                                    Phone
                                </mat-option>

                                <mat-option value="Shopify">
                                    Shopify
                                </mat-option>

                                <mat-option value="Written Off">
                                    Written Off
                                </mat-option>

                                <mat-option value="Other">
                                    Other
                                </mat-option>

                                <mat-option value="Cancelled">
                                    Cancelled
                                </mat-option>

                            </mat-select>
                        </div>
                        <div class="col-6">
                            <mat-form-field appearance="fill" ngDefaultControl class="w-100 mt-10px">
                                <mat-label>Invoice date:</mat-label>
                                <input matInput [matDatepicker]="picker_invoiceDate" formControlName="invoiceDate" placeholder=""
                                       [ngModel]="currentDate" (dateChange)="dateEvent($event)"
                                       [ngClass]="{'is-invalid':  submitted && f.invoiceDate.errors}">
                                <mat-datepicker-toggle matSuffix [for]="picker_invoiceDate"></mat-datepicker-toggle>
                                <mat-datepicker [startAt]="" #picker_invoiceDate></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>

                </div>



            </div>



            <div class="modal-footer">

                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>
                <div class="">
                    <button [disabled]="!invoiceEditForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>
            </div>

        </form>
    </div>

</ng-template>



<!--<ng-template #modalEditInhouse let-close="close">

    <div class="modal-header primary">

        <h5 class="modal-title primary">Organisation Details</h5>

    </div>

    <div class="p-4">

        <form class="form-horizontal" [formGroup]="organisationForm" (ngSubmit)="onIOrganisationEditSubmit()" name="organisationForm" autocomplete="off">

            <div class="row">

                <div class="col-md-12">

                    <label class="text-second">Organization:</label>
                    <input type="text" class="form-control no-radius" placeholder="Organisation"
                           formControlName="contactOrganisation" maxlength="250" />

                    <label class="text-second">Contact Name:</label>
                    <input type="text" class="form-control no-radius" placeholder="Contact name"
                           [ngClass]="{  'is-invalid': p.contactName.errors}"
                           required formControlName="contactName" maxlength="250" />

                    <label class="text-second">Department:</label>
                    <input type="text" class="form-control no-radius" placeholder="Department"
                           formControlName="contactDepartment" maxlength="250" />

                    <label class="text-second">Address:</label>
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': p.contactAddress.errors}"
                           required formControlName="contactAddress" maxlength="250" />

                    <div class="row">
                        <div class="col-6">
                            <label class="text-second">Telephone:</label>
                            <input type="text" class="form-control no-radius" placeholder="Telephone"
                                   formControlName="contactTelephone" maxlength="50" />
                        </div>
                        <div class="col-6">
                            <label class="text-second">Postcode:</label>
                            <input type="text" class="form-control no-radius" placeholder="Postcode eg: AA123AA" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                   [ngClass]="{  'is-invalid': p.contactPostcode.errors}"
                                   required formControlName="contactPostcode" maxlength="250" />
                            <div *ngIf="p.contactPostcode.errors" class="invalid-feedback mt-0">
                                <div *ngIf="p.contactPostcode.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>
                    </div>

                    <label class="text-second">Email:</label>
                    <input type="text" class="form-control no-radius" placeholder="Email"
                           [ngClass]="{  'is-invalid': p.contactEmail.errors && p.contactEmail.errors.pattern}"
                           required formControlName="contactEmail" maxlength="250" />
                    <div *ngIf="p.contactEmail.errors" class="invalid-feedback">
                        <div *ngIf="p.contactEmail.errors.pattern">{{errors.contactEmail}}</div>
                    </div>


                    <div class="row">
                        <div class="col-md-2">
                            <label class="text-second">Notes:</label>
                        </div>
                        <div class="col-md-10 mt-2">
                            <textarea class="form-control  no-radius" formControlName="notes" placeholder="10,000 Characters Limit" maxlength="10000" rows="5"></textarea>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">

                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>
                <div class="">
                    <button [disabled]="!organisationForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>

</ng-template>-->


<ng-template #modalAddEditTrainer let-close="close">

    <div class="modal-header primary">
        <h5 class="modal-title primary">{{_addEditTrainer}}</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="trainerForm" (ngSubmit)="onTrainerFormSubmit()" name="trainerForm" autocomplete="off">


            <div class="row">
                <div class="col-6">

                    <label class="text-second">Trainer:</label>
                    <mat-select formControlName="trainer" #select
                                class="form-control no-radius" (selectionChange)='onTrainerSelected($event)'>
                        <mat-option selected value="none" class=" text-second">Select Trainer</mat-option>

                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-6">
                    <label class="text-second">Subject:</label>
                    <mat-select formControlName="subject" #select (selectionChange)='onSubjectSelected($event)'
                                class="form-control no-radius">
                        <mat-option selected value="none" class=" text-second">Select Subject</mat-option>

                        <mat-option *ngFor="let sub of subjectsList" [value]="sub.subjectId">{{sub.title}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-4">
                    <label class="text-second">Fees:</label>
                    <input type="text" class="form-control no-radius" placeholder="Fees"
                           [ngClass]="{  'is-invalid': t.fees.errors}"
                           required formControlName="fees" maxlength="25" />
                    <div *ngIf="t.fees.errors" class="invalid-feedback">
                        <div *ngIf="t.fees.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">Expense:</label>
                    <input type="text" class="form-control no-radius" placeholder="Expense"
                           [ngClass]="{  'is-invalid': t.expense.errors}"
                           formControlName="expense" maxlength="250" />
                    <div *ngIf="t.expense.errors" class="invalid-feedback">
                        <div *ngIf="t.expense.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>


                <div class="col-4">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Training date</mat-label>
                            <input matInput [matDatepicker]="picker_trainingDate" formControlName="trainingDate" placeholder=""
                                   [ngModel]="currentDate" (dateChange)="dateEvent($event)"
                                   [ngClass]="{'is-invalid':  submitted && t.trainingDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_trainingDate"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="_date" #picker_trainingDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>

            </div>

            <div class="modal-footer">

                <button type="button" class="book-btn" (click)="close(); close2($event);">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!trainerForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>

            </div>

        </form>

    </div>

</ng-template>


<ng-template #modalAddEditInhouseItem let-close="close">

    <div class="modal-header primary">
        <h5 class="modal-title primary">{{_addEditInhouseItemTitle}}</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="itemForm" (ngSubmit)="onItemFormSubmit()" name="itemForm" autocomplete="off">


            <div class="row">

                <div class="col-8">
                    <label class="text-second">Description:</label>
                    <input type="text" class="form-control no-radius" placeholder="Description"
                           [ngClass]="{  'is-invalid': i.description.errors}"
                           required formControlName="description" maxlength="250" />
                    <div *ngIf="i.description.errors" class="invalid-feedback">
                        <div *ngIf="i.description.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">Fee/Expense:</label>
                    <mat-select *ngIf="true" formControlName="itemType" #select id="itemType"
                                class="form-control no-radius text-second mt-3-">

                        <mat-option selected value="none" class=" text-second">Select option</mat-option>

                        <mat-option value="Fee">
                            Fee
                        </mat-option>

                        <mat-option value="Expense">
                            Expense
                        </mat-option>

                        <mat-option value="Other">
                            Other
                        </mat-option>

                    </mat-select>
                </div>

                <div class="col-4">
                    <label class="text-second">Quantity:</label>
                    <input type="text" class="form-control no-radius" placeholder="Quantity"
                           [ngClass]="{  'is-invalid': i.quantity.errors}"
                           required formControlName="quantity" maxlength="25" />
                    <div *ngIf="i.quantity.errors" class="invalid-feedback">
                        <div *ngIf="i.quantity.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">Amount:</label>
                    <input type="text" class="form-control no-radius" placeholder="Amount"
                           [ngClass]="{  'is-invalid': i.amount.errors}"
                           required formControlName="amount" maxlength="25" />
                    <div *ngIf="i.amount.errors" class="invalid-feedback">
                        <div *ngIf="i.amount.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Date</mat-label>
                            <input matInput [matDatepicker]="picker_itemDate" formControlName="itemDate" placeholder=""
                                   [ngModel]="currentDate" (dateChange)="dateEvent($event)"
                                   [ngClass]="{'is-invalid':  submitted && i.itemDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_itemDate"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="_date" #picker_itemDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>

            </div>

            <div class="modal-footer">



                <button type="button" class="book-btn" (click)="close(); close2($event);">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!itemForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>

            </div>

        </form>

    </div>

</ng-template>




<ng-template #modalPaymentDetails let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Payment Details</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()" name="cuForm" autocomplete="off">


            <div class="row">
                <div class="col-6">

                    <label class="text-second">Invoice Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Invoice Number"
                           [ngClass]="{  'is-invalid': pd.invoiceNumber.errors}"
                           required formControlName="invoiceNumber" maxlength="250" />
                    <div *ngIf="pd.invoiceNumber.errors" class="invalid-feedback">
                        <div *ngIf="pd.invoiceNumber.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-6">
                    <label class="text-second">Paid By:</label>

                    <mat-select *ngIf="true" formControlName="source" #select [(ngModel)]="selectedPaymentMethod" id="paymentMethod"
                                class="form-control no-radius text-second mt-3-">

                        <mat-option selected value="none" class=" text-second">Select source</mat-option>

                        <mat-option value="BACS">
                            BACS
                        </mat-option>

                        <mat-option value="Cash">
                            Cash
                        </mat-option>

                        <mat-option value="Cheque">
                            Cheque
                        </mat-option>

                        <mat-option value="Credit Note">
                            Credit Note
                        </mat-option>

                        <mat-option value="Direct Debit">
                            Direct Debit
                        </mat-option>

                        <mat-option value="AMEX">
                            AMEX
                        </mat-option>

                        <mat-option value="Paypal">
                            Paypal
                        </mat-option>

                        <mat-option value="Phone">
                            Phone
                        </mat-option>

                        <mat-option value="Shopify">
                            Shopify
                        </mat-option>

                        <mat-option value="Written Off">
                            Written Off
                        </mat-option>

                        <mat-option value="Other">
                            Other
                        </mat-option>

                        <mat-option value="Cancelled">
                            Cancelled
                        </mat-option>

                    </mat-select>

                </div>

                <div class="col-4">
                    <label class="text-second">Subtotal:</label>
                    <input type="text" class="form-control no-radius" placeholder="Subtotal"
                           [ngClass]="{  'is-invalid': pd.subtotal.errors}"
                           required formControlName="subtotal" maxlength="250" (input)="applyTax($event.target.value)" />
                    <div *ngIf="pd.subtotal.errors" class="invalid-feedback">
                        <div *ngIf="pd.subtotal.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="text-second">Total Tax:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total Tax"
                           [ngClass]="{  'is-invalid': pd.totalTax.errors}" (input)="changeTax($event.target.value)"
                           required formControlName="totalTax" maxlength="250" />
                    <div *ngIf="pd.totalTax.errors" class="invalid-feedback">
                        <div *ngIf="pd.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="text-second">Total:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total"
                           [ngClass]="{  'is-invalid': pd.total.errors}"
                           required formControlName="total" maxlength="250" />
                    <div *ngIf="pd.total.errors" class="invalid-feedback">
                        <div *ngIf="pd.total.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Payment date</mat-label>
                            <input matInput [matDatepicker]="picker_paymentDate" formControlName="p_paymentDate" placeholder=""
                                   [ngModel]="paymentDate_" (dateChange)="addEvent($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_paymentDate"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="_date" #picker_paymentDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <!--[ngClass]="{'is-invalid':  submitted && i.p_paymentDate.errors}"-->
                </div>

                <div class="col-4">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Reconcile date</mat-label>
                            <input matInput [matDatepicker]="picker_reconcileDate" formControlName="p_reconcileDate" placeholder=""
                                   [ngModel]="reconcileDate" (dateChange)="reconcileDateEvent($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_reconcileDate"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="_date" #picker_reconcileDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>


                <div class="col-4 pt-2">
                    <mat-checkbox formControlName="chkReconcile" [ngModel]="_reconcile">Reconcile</mat-checkbox>
                </div>

            </div>


            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close(); close2($event);">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!paymentForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>
            </div>

        </form>

    </div>

</ng-template>