
<div class="vertical-menu">

    <div data-simplebar class="h-100">

        <!--- Sidemenu -->
        <div id="sidebar-menu" style="height:100%; overflow:hidden scroll;">
            <ul class="metismenu list-unstyled" id="side-menu">
                <metis-menu>

                    <li class="menu-title">Menu</li>

                    <!--<li *ngFor="let act of activities">
                        <a href="javascript: void(0);" routerLink="{{ act.url }}" class="text-black-50 waves-effect active">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="{{act.icon}}" alt="menu-icon">
                            </div>
                            <span>{{act.name}}</span>
                        </a>
                    </li>-->

                    <li *ngFor="let act of activities; let i = index">
                        <a href="javascript: void(0);" routerLink="{{ act.url }}" [ngClass]="{'text-black-50': act.subActivities.length <= 0 , 'has-arrow' : act.subActivities.length > 0 }" class=" waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="{{act.icon}}" alt="menu-icon">
                            </div>
                            <span>{{act.name}}</span>
                        </a>
                        <ul *ngIf="act.subActivities.length > 0" class="sub-menu" aria-expanded="false">
                            <li *ngFor="let item of act.subActivities">
                                <a href="javascript: void(0);" routerLink="{{ item.url }}">
                                    <img class="mr-2  sub-icon" src="{{item.icon}}" alt="menu-icon">
                                    {{item.name}}
                                </a>
                            </li>
                        </ul>
                    </li>

                    <!--<li>
                        <a href="retailer.html" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/Retailer.svg" alt="menu-icon">
                            </div>
                            <span>Retailer</span>
                        </a>
                    </li>
                    <li>
                        <a href="report.html" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/reports.svg" alt="menu-icon">
                            </div>
                            <span>Reports</span>
                        </a>
                    </li>

                    <li>
                        <a href="kyc.html" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/verify.svg" alt="menu-icon">
                            </div>
                            <span>KYC Verification</span>
                        </a>
                    </li>-->
                    <!--<li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/moneytransfer.svg" alt="menu-icon">
                            </div>
                            <span>Money Transfer</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/transfer.svg" alt="menu-icon">
                                    Transfer to Agent
                                </a>
                            </li>
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/transfer.svg" alt="menu-icon">

                                    Transfer to Franchise
                                </a>
                            </li>
                        </ul>
                    </li>-->
                    <!--<li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/OTC.svg" alt="menu-icon">
                            </div>
                            <span>OTC</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/send.svg" alt="menu-icon">

                                    Send Money
                                </a>

                            </li>
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/receive.svg" alt="menu-icon">
                                    TRN Receive Money

                                </a>
                            </li>
                            <li>
                                <a href="javascript: void(0);" [routerLink]="['/payments/cash-in']">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/cash-in.svg" alt="menu-icon">
                                    Cash In
                                </a>
                            </li>
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/cash-out.svg" alt="menu-icon">

                                    Cash Out
                                </a>
                            </li>
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/cash-out-voucher.svg" alt="menu-icon">

                                    Cash Out Voucher

                                </a>
                            </li>
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/update.svg" alt="menu-icon">

                                    Update to Max
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <a href="javascript: void(0);" routerLink="home/coming-soon" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/utility-bill.svg" alt="menu-icon">
                            </div>
                            <span>Utility Bill</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript: void(0);" routerLink="home/coming-soon" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/user-mangement.svg" alt="menu-icon">
                            </div>
                            <span>User Management</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/user.svg" alt="menu-icon">
                            </div>
                            <span>My Account</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li>
                                <a href="javascript: void(0);" routerLink="home/coming-soon">
                                    <img class="mr-2  sub-icon" src="assets/images/menu-icons/key.svg" alt="menu-icon">

                                    Change Pin
                                </a>
                            </li>
                        </ul>
                    </li>-->
                    <!--<li class="menu-title">Menu</li>

                    <li>
                        <a href="javascript: void(0);" class="text-black-50 waves-effect active">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/home.png" alt="menu-icon">
                            </div>
                            <span>Home</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript: void(0);" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/d-load.png" alt="menu-icon">
                            </div>
                            <span>Retailer</span>
                        </a>
                    </li>
                    <li>
                        <a href="report.html" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/cash-out.png" alt="menu-icon">
                            </div>
                            <span>Reports</span>
                        </a>
                    </li>

                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/money-transfer.png" alt="menu-icon">
                            </div>
                            <span>Money Transfer</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a href="#">Transfer to Agent</a></li>
                            <li><a href="#">Transfer to Franchise</a></li>
                        </ul>
                    </li>

                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/cash-in.png" alt="menu-icon">
                            </div>
                            <span>OTC</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a href="#">OTC - Send Money</a></li>
                            <li><a href="#">OTC - TRN Receive Money</a></li>
                            <li><a href="#">OTC - Cash In</a></li>
                            <li><a href="#">OTC - Cash Out</a></li>
                            <li><a href="#">OTC - Cash Out Voucher</a></li>
                            <li><a href="#">OTC - Update to Max</a></li>

                        </ul>
                    </li>
                    <li>
                        <a href="utility-bill.html" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/bill-payments.png" alt="menu-icon">
                            </div>
                            <span>Utility Bill</span>
                        </a>
                    </li>
                    <li>
                        <a href="user-management.html" class="waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/user.png" alt="menu-icon">
                            </div>
                            <span>User Management</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript: void(0);" class="has-arrow waves-effect">
                            <div class="d-inline-block icons-sm mr-2">
                                <img src="assets/images/menu-icons/user.png" alt="menu-icon">
                            </div>
                            <span>My Account</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a href="#">Change Pin</a></li>
                        </ul>
                    </li>-->
                </metis-menu>
            </ul>
        </div>
        <!-- Sidebar -->
    </div>
</div>