import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { LoginUserDetail } from '../model/request';

@Injectable({
    providedIn: 'root'
})
export class AuthUserService {

    baseurl: string;

    constructor(private _router: Router, private http: HttpClient, @Inject('BASE_URL') base: string) {
        this.baseurl = base;
    }


    getUserDetail() {

        var activites = localStorage.getItem("act")?.toString();
        if (activites != undefined)
            activites = JSON.parse(activites);

        var user = new LoginUserDetail();
        user.name = localStorage.getItem("name");
        user.balance = localStorage.getItem("balance");
        user.Sid = localStorage.getItem("sid");
        user.Uid = localStorage.getItem("uid");
        user.Unm = localStorage.getItem("unm");
        user.Act = activites;

        return user;

    }

    logout() {
        localStorage.removeItem("Token");
        localStorage.removeItem("userName");
        localStorage.removeItem("projectPageSize");
    }

    login(req: any) {
        return this.http.post<any>(this.baseurl + '/api/account/login', req)
            .pipe(map(resp => {
                return resp;
            }));
    }




}
