import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { DelegatesRoutingModule } from './delegates-routing.module';
import { ViewDelegateComponent } from './view-delegate/view-delegate.component';
import { AddEditDelegateComponent } from './add-edit-delegate/add-edit-delegate.component';
import { DelegateDetailsComponent } from './delegate-details/delegate-details.component';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DelgateActionsComponent } from './view-delegate/delgate-actions/delgate-actions.component';
import { ClickableParentComponent } from './view-delegate/clickable-parent/clickable-parent.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [ViewDelegateComponent,
        AddEditDelegateComponent,
        DelegateDetailsComponent,
        DelgateActionsComponent,
        ClickableParentComponent,
    ],
    imports: [
        CommonModule,
        DelegatesRoutingModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatDatepickerModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [ViewDelegateComponent,
        AddEditDelegateComponent,
        DelegateDetailsComponent
    ]
})
export class DelegatesModule { }
