import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { CoursesComponent } from './courses.component';
import { ViewCourseComponent } from './view-course/view-course.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404'
    },
    //{ path: 'all-courses-old', component: CoursesComponent },
    //{ path: 'course-detail-old', component: ViewCourseComponent },
    
    { path: 'course-detail', component: DashboardComponent },
    { path: 'all-courses', component: DashboardComponent },
    { path: 'course-titles', component: DashboardComponent },
    { path: 'mimeo', component: DashboardComponent },
    {
        path: '',
        redirectTo: 'account/login',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CoursesRoutingModule { }
