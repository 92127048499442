import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, FirstDataRenderedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { DelegateService } from '../../../common/_services/delegate-services';
import { FinanceService } from '../../../common/_services/finance-services';
import * as $ from "jquery";
import { CommonServiceService } from '../../../common/_services/common-service.service';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-debt-collection',
    templateUrl: './debt-collection.component.html',
    styleUrls: ['./debt-collection.component.css']
})
export class DebtCollectionComponent implements OnInit {

    currentDate: Date = new Date();
    debtCollectionList: any = [];
    debtCollectionCount: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    filterToggleAll: boolean = true;

    _methodToload: any = "";
    //listTitle: any = "Products";
    paginationPageSize: any = 10;

    _paramOut = {
        allBookings: false, id: 0
    }

    _param = {
        showReceivedInvoices: false,
        showSuppliers: false,
        id: 0
    }

    @Output() gotoProductBookings = new EventEmitter<object>();
    @Output() gotoDelegateDetails = new EventEmitter<void>();

    @Output() gotoSuppliersList = new EventEmitter<void>();
    @Output() gotoSupplierDetails = new EventEmitter<void>();
    @Output() gotoInvoicesReceived = new EventEmitter<void>();
    @Output() gotoInvoicesIssued = new EventEmitter<void>();
    @Output() gotoDebtCollection = new EventEmitter<void>();
    @Output() gotoXeroInvoices = new EventEmitter<void>();
    @Output() gotoCreditNotes = new EventEmitter<void>();

    _paramAdmin = {
        gotoAdmin: ""
    }

    @Output() gotoAdminTab = new EventEmitter<object>();



    constructor(@Inject(DOCUMENT) private document: Document,
        private _commonService: CommonServiceService,
        private _financeService: FinanceService) {

        _commonService.setBackNavigation();

        this.gridOptions = {
            suppressCellSelection: true,
            //animateRows: true,
            //groupDisplayType: 'groupRows',
            //onFirstDataRendered: this.onFirstDataRendered,
        };
        this.domLayout = "autoHeight";
    }

    //onFirstDataRendered(params: FirstDataRenderedEvent) {
    //    params.api.expandAll();
    //}

    gotoAdmin(param) {
        this._paramAdmin.gotoAdmin = param;
        this.gotoAdminTab.emit(this._paramAdmin);
    }

    public columnDefs: ColDef[] = [
        { field: 'dateAdded', headerName: 'Status', width: 100, cellRenderer: params => this.getStatus(params.value) },
        { field: 'dateAdded', headerName: 'Date', width: 90, valueFormatter: this.convertStringToDate },
        { field: 'dateAdded', headerName: 'Days since invoice', width: 90, cellRenderer: params => this.getDaysCount(params.value) },
        { field: 'dateAdded', headerName: 'Overdue by', width: 90, cellRenderer: params => this.daysOverdueBy(params.value) },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 90 },
        { field: 'amount', width: 65, headerName: 'Amount', cellRenderer: params => this.currencyFormatter(params.value), },
        { field: 'delegate.name', headerName: 'Issued To', width: 150 },
        { field: 'delegate.email', headerName: 'Email', width: 150, valueFormatter: this.noDataFound },
        { field: 'delegate.telephone', headerName: 'Telephone', width: 150, valueFormatter: this.noDataFound },
        { field: 'delegate.organization', headerName: 'Organisation', width: 150, valueFormatter: this.noDataFound },
        { field: 'courseCode', headerName: 'Course Code', width: 90, },
        { field: 'courseTitle', headerName: 'Course Title', width: 300 },



        //{ field: 'title', headerName: 'Product Title', width: 325 },
        //{
        //    field: 'cost', headerName: 'Cost', width: 200,
        //    cellRenderer: params => params.value == null ? "-" : params.value,
        //},
        //{
        //    field: 'Action', cellRendererFramework: ProductClickableParentComponent,
        //    sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        //}
    ];

    noDataFound(param) {
        if (param.value == null)
            return "-";
        else return param.value;
    }

    //STATUS COLORS
    getStatus(dateString) {
        const givenDate = new Date(dateString);
        const today = new Date();
        const timeDiff = today.getTime() - givenDate.getTime();
        const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24)));

        const status = this.getColor(daysDiff);

        if (status == 'green')
            return "<div class='green status-circles mt-10px'></div>";
        else if (status == 'amber')
            return "<div class='amber status-circles mt-10px'></div>";
        else if (status == 'red')
            return "<div class='red status-circles mt-10px'></div>";
        else if (status == 'debt-red')
            return "<div class='debt-red status-circles mt-10px'></div>";
        else "";
        //return daysDiff.toString() + " Days";
    }

    //DAYS COUNT
    getDaysCount(dateString): string {

        const givenDate = new Date(dateString);
        const today = new Date();
        const timeDiff = today.getTime() - givenDate.getTime();
        const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24)));
        return daysDiff.toString() + " Days";
    }

    daysOverdueBy(dateString): string {
        const givenDate = new Date(dateString);
        const today = new Date();
        const timeDiff = today.getTime() - givenDate.getTime();
        const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24))) - 28;
        return daysDiff.toString() + " Days";
    }

    // VAT
    addVAT(amount, vatRate) {
        var vat = amount * vatRate / 100;
        var totalAmount = amount + vat;
        return totalAmount;
    }

    // DATA FORMATTING
    currencyFormatter(currency) {

        if (currency != null) {
            currency = this.addVAT(currency, 20);
            var sansDec = currency.toFixed(1);
            var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '<span>&#163;</span>' + `${formatted}`;
        }
        else return '-';
    }

    truncateString(str) {
        if (str != null && str != "")
            if (str.length <= 40) {
                return str;
            } else {
                return str.slice(0, 40) + '...';
            }
        else {
            return "-";
        }
    }

    getColor(value) {

        if (value < 28) {
            return 'green';
        } else if (value > 28 && value <= 49) {
            return 'amber';
        } else if (value > 63) {
            return 'debt-red';
        } else if (value > 49) {
            //return 'redborder';
            return 'red'
        }


    }

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (params.value == true)
            return "<div class='paid status-circles mt-10px'></div>";
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
        resizable: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;



    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#debtCollectionGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#debtCollectionGrid") as HTMLElement;
        elm.style.height = '535px';
    }


    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();

    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        //if (e.colDef.field != "Action")
        //if (e.colDef.field != "Action") {
        //    this._paramOut.allBookings = false;
        //    this._paramOut.id = e.data.id
        //    this.gotoProductBookings.emit(this._paramOut);
        //}

        this.gotoDelegateDetails.emit(e.data);
    }


    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }

    ngOnInit(): void {

        localStorage.setItem('projectPageSize', '50');
        this.getDebtCollectionList(-28);
        this.getDebtCollectionCount();
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }


    getListLessThan28Days() {
        this.getDebtCollectionList(-28);
    }

    getListGreaterThan28LessThan49Days() {
        this.getDebtCollectionList(-49);
    }

    getListGreaterThan49Days() {
        this.getDebtCollectionList(49);
    }

    getListGreaterThan63Days() {
        this.getDebtCollectionList(63);
    }

    printing1() {


        //const api = this.gridOptions.api!;

        //this.setPrinterFriendly(api);

        //setTimeout(() => {
        //    print();
        //    this.setNormal(api);
        //}, 500);

        const section = $("#section-to-print");
        section.css("display", "block");

        // Allow the browser to render the section before printing
        setTimeout(() => {
            window.print();
        }, 250); // Adjust the delay if necessary



        //$("#section-to-print").css("display", "block");
        //window.print();
        //$("#section-to-print").css("display", "none");

        ////Copy the element you want to print to the print-me div.
        ////$("#printarea").clone().appendTo("#print-me");
        ////Apply some styles to hide everything else while printing.
        ////$("#section-to-print").show();
        //$("body").addClass("print");
        ////$("#section-to-print").hide();
        ////Print the window.


        //window.print();
        ////Restore the styles.

        //$("body").removeClass("print");
        ////Clear up the div.
        ////$("#print-me").empty();

        //$("#print-me").printThis({

        //    //debug: true
        //});

        //(<any>$("#print-me")).printThis({

        //    //debug: true
        //});

        //const eGridDiv = document.querySelector<HTMLElement>('#debtCollectionGrid')! as any;


        //eGridDiv.style.width = '100%';
        //eGridDiv.style.height = '1000px';
        //this.gridOptions.api!.setDomLayout('print');

        ////print();
        //setTimeout(function () {
        //    print();
        //    //setNormal(api);
        //}, 2000);


        //var printContents = this.document.getElementById('#debtCollectionGrid').innerHTML;
        //const originalContents = document.body.innerHTML;
        //document.body.innerHTML = printContents;
        //window.print();
        //document.body.innerHTML = originalContents;


        ////const eGridDiv = document.querySelector<HTMLElement>('#debtCollectionGrid')! as any;

        //var eGridDiv = document.querySelector("#debtCollectionGrid") as HTMLElement;
        ////elm.style.height = '535px';

        ////eGridDiv.style.width = '';
        ////eGridDiv.style.height = '';
        ////this.gridApi.setDomLayout('print');



        //var printContents = eGridDiv.innerHTML;
        //const originalContents = document.body.innerHTML;
        //document.body.innerHTML = printContents;
        //window.print();
        //document.body.innerHTML = originalContents;

        ////print();


        //var eGridDiv = document.querySelector("#debtCollectionGrid") as HTMLElement;

        //var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        //window.document.write('<html><head><title>' + document.title + '</title>');
        //window.document.write('</head><body >');
        //window.document.write('<h1>' + document.title + '</h1>');
        //window.document.write(eGridDiv.innerHTML);
        //window.document.write('</body></html>');

        //window.document.close(); // necessary for IE >= 10
        //window.focus(); // necessary for IE >= 10*/

        //window.print();
        //window.close();



    }

    @ViewChild('printSection', { static: false }) el!: ElementRef

    printing() {
        $("#section-to-print").css("display", "block");

        let pdf = new jsPDF('p', 'px', 'a4', true);

        pdf.setFontSize(8);

        pdf.html(this.el.nativeElement, {

            callback: (pdf) => {
                pdf.save('DebtCollection.pdf');
            },
            margin: [15, 15, 15, 15],
            autoPaging: 'text',
            x: 25,
            y: 25,
            width: 350, //target width in the PDF document
            windowWidth: 675 //window width in CSS pixels
        })

        setTimeout(() => {
            $("#section-to-print").css("display", "none");
        }, 5000);


    }



    setPrinterFriendly(api: GridApi) {



        //api.paginationGetRowCount()
        this.gridApi.paginationSetPageSize(100);


        const eGridDiv = document.querySelector<HTMLElement>('#debtCollectionGrid')! as any;

        eGridDiv.style.width = '';
        eGridDiv.style.height = '';

        //this.gridOptions.rowClass = "no-border";

        //this.gridOptions.columnApi?.setColumnVisible("Reopen", true);
        api.sizeColumnsToFit();


        api.setDomLayout('print');


    }

    setNormal(api: GridApi) {

        api.setDomLayout("normal");

        this.setFixedHeight();
        this.onPageSizeChanged();

        const eGridDiv = document.querySelector<HTMLElement>('#debtCollectionGrid')! as any;
        eGridDiv.style.width = '100%';
        api.sizeColumnsToFit();

        //api.setDomLayout();
    }





    getDebtCollectionList(n) {

        var req = {
            days: n
        }

        this._financeService.getDebtCollectionList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.debtCollectionList = result.data;
                //this.debtCollectionList = result.data.filter(obj => obj.id != null); ;
                debugger;
                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getDebtCollectionCount() {



        this._financeService.getDebtCollectionCount().subscribe((result: any) => {

            if (result.success == true) {

                this.debtCollectionCount = result.data;


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }



    //gotoBookingsList(isAllBookings) {
    //    this._paramOut.allBookings = isAllBookings;
    //    this._paramOut.id = 0
    //    this.gotoProductBookings.emit(this._paramOut);
    //}


    gotoSuppliers() {
        this.gotoSuppliersList.emit();
    }

    gotoInvoicesReceivedList() {
        //this._param.showReceivedInvoices = true;
        //this._param.id = 0
        //this._param.showSuppliers = false;
        //this.gotoInvoicesReceived.emit(this._param);

        this.gotoInvoicesReceived.emit();
    }


    gotoXeroInvoicesList() {

        this.gotoXeroInvoices.emit();
    }

    gotoInvoicesIssuedList() {
        this.gotoInvoicesIssued.emit();
    }

    gotoCreditNotesList() {
        this.gotoCreditNotes.emit();
    }

    getFilterToggleValue(val: any) {

        this.filterToggleAll = false;
        //this.toggleFilterValue = val;

        if (val == "<28 Days")
            this.getListLessThan28Days();
        else if (val == ">28<49 Days")
            this.getListGreaterThan28LessThan49Days();
        else if (val == ">49 Days")
            this.getListGreaterThan49Days();
        else if (val == ">63 Days")
            this.getListGreaterThan63Days();

    }



}
