<div class="modal-body modal-sm text-center">
    <!--<img src="assets/images/menu-icons/error.png" class="img-responsive mb-4">-->
    <h4 class="text-colr">Error</h4>
    <p>{{Text}}</p>

    

        <button type="button" (click)="close()" mat-dialog-close class="btn btn-primary w-100">
            Ok
        </button>
   
</div>