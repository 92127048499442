import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { FinanceService } from '../../../common/_services/finance-services';
//import { ProductClickableParentComponent } from '../../product/product-clickable-parent/product-clickable-parent.component';
import { AddEditSupplierComponent } from '../add-edit-supplier/add-edit-supplier.component';
import { SupplierClickableParentComponent } from './supplier-clickable-parent/supplier-clickable-parent.component';

@Component({
    selector: 'app-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {

    suppliersList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;
    _params: any;
    _code: any;
    _state: any;


    _methodToload: any = "";
    listTitle: any = "Suppliers";
    paginationPageSize: any = 10;

    _paramOut = {
        showReceivedInvoices: false, id: 0
    }

    //@Output() gotoInvoicesReceived = new EventEmitter<object>();
    @Output() gotoInvoicesReceived = new EventEmitter<void>();
    @Output() gotoSupplierDetails = new EventEmitter<void>();
    @Output() gotoInvoicesIssued = new EventEmitter<void>();
    @Output() gotoDebtCollection = new EventEmitter<void>();
    @Output() gotoCreditNotes = new EventEmitter<void>();
    @Output() gotoXeroInvoices = new EventEmitter<void>();

    public columnDefs: ColDef[] = [
        //{ field: 'dateAdded', headerName: 'Added Date', width: 200, valueFormatter: this.convertStringToDate },
        { field: 'name', headerName: 'Supplier Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 225, cellRenderer: this.emptyReplacement },
        { field: 'city', headerName: 'City', cellRenderer: this.emptyReplacement },
        { field: 'postCode', headerName: 'Postcode', cellRenderer: this.emptyReplacement },
        { field: 'bankName', headerName: 'Bank Name', cellRenderer: this.emptyReplacement },
        {
            field: 'Action', cellRendererFramework: SupplierClickableParentComponent,
            sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    emptyReplacement(params) {

        if (params.value != null && params.value != "") {
            return params.value;
        }
        else return "-";
    }


    public rowData = [
        { make: '', model: '', price: 0 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };


    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        private route: ActivatedRoute,
        private _commonService: CommonServiceService,
        private _financeService: FinanceService
    ) {

        _commonService.setBackNavigation();

        this.gridOptions = {
            suppressCellSelection: true,
            rowClass: 'grid-row',
            context: {
                componentParent: this
            }
        };

        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#supplierGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#supplierGrid") as HTMLElement;
        elm.style.height = '535px';
    }


    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: '', model: '', price: 0 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        if (e.colDef.field != "Action") {
            this.gotoSupplierDetails.emit(e.data);
        }
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }

    ngOnInit(): void {

        


        localStorage.setItem('projectPageSize', '50');
        this.getSuppliersList();
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }



    getSuppliersList() {

        this._financeService.getSuppliersList().subscribe((result: any) => {

            if (result.success == true) {

                this.suppliersList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNewSupplier() {
        const modalRef = this.modal.open(AddEditSupplierComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {
            
            this.getSuppliersList();
        }, (reason) => {
            
            //console.log('Dismissed action: ' + reason);
        });
    }

    openModal(param) {

        const modalRef = this.modal.open(AddEditSupplierComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getSuppliersList());
    }

    gotoInvoicesReceivedList() {
        //this._paramOut.showReceivedInvoices = true;
        //this._paramOut.id = 0
        //this.gotoInvoicesReceived.emit(this._paramOut);

        this.gotoInvoicesReceived.emit();
    }

    gotoXeroInvoicesList() {
        
        this.gotoXeroInvoices.emit();
    }

    gotoInvoicesIssuedList() {
        this.gotoInvoicesIssued.emit();
    }

    gotoDebtCollectionList() {
        this.gotoDebtCollection.emit();
    }

    gotoCreditNotesList() {
        this.gotoCreditNotes.emit();
    }

}
