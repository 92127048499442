<div class="row page-header">
    <div class="col-lg-6">
        Welcome, <strong>{{user.name}}</strong>
        <br />
        <strong class="user-role">
            <span>{{user.role}}</span>
        </strong>
        <span (click)="logout()" class="cursor-pointer material-icons">
            logout
        </span>
    </div>
    <div class="col-lg-6">
        <div class="card no-border no-radius">
            <div class="card-body text-right an-logo-parent">
                <img class="img-responsive an-logo" src="assets\images\loginlogo.png" />

            </div>
        </div>
    </div>

</div>
<div class="page-header-second"></div>

<div class="unauth text-center">
    <div class="unauth_title" data-content="404">
        403 - ACCESS DENIED
    </div>

    <div class="unauth_subtitle">
        You don't have permission to access this page.
    </div>
</div>

