import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SchedulesRoutingModule } from './schedules-routing.module';
import { ViewScheduleComponent } from './view-schedule/view-schedule.component';
import { YearlyScheduleComponent } from './yearly-schedule/yearly-schedule.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
//import { FlatpickrModule } from 'angularx-flatpickr';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
//import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CourseSchedulerComponent } from './course-scheduler/course-scheduler.component';

@NgModule({
    declarations: [
        ViewScheduleComponent,
        YearlyScheduleComponent,
        CourseSchedulerComponent,
    ],
  imports: [
    CommonModule,
      SchedulesRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatCardModule,
      HighchartsChartModule,
      MatFormFieldModule,
      MatInputModule,
      MatMenuModule,
      MatIconModule,
      //FlatpickrModule.forRoot(),
      CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
      }),
    ],
    exports: [
        ViewScheduleComponent,
        YearlyScheduleComponent,
        CourseSchedulerComponent
    ],
    providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class SchedulesModule { }
