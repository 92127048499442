import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { CoursesService } from '../../common/_services/courses-services';

@Component({
    selector: 'app-upload-courses',
    templateUrl: './upload-courses.component.html',
    styleUrls: ['./upload-courses.component.css']
})
export class UploadCoursesComponent implements OnInit {

    public progress: number;
    public message: string;
    public fileNameProfile: string;
    originalFile: any;
    public fileInfo: any;

    public url = environment.apiURL;
        
    _paramAdmin = {
        gotoAdmin: ""
    }

    @Output() gotoAdminTab = new EventEmitter<object>();
    @Output() public onUploadFinished = new EventEmitter();


    constructor(private spinner: NgxSpinnerService,
        private http: HttpClient,
        private _coursesService: CoursesService) {
        
    }

    ngOnInit(): void {
    }

    gotoAdmin(param) {
        this._paramAdmin.gotoAdmin = param;
        this.gotoAdminTab.emit(this._paramAdmin);
    }

    downloadProjectFile() {
        let link = document.createElement("a");
        link.download = "Template.xlsx";
        link.href = "assets/Resources/Template.xlsx";
        link.click();
    }

    public uploadFile = (files: any) => {
        if (files.length === 0) {
            return;
        }
        let filesToUpload: File[] = files;
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
            return formData.append('file' + index, file, file.name);
        });

        this.spinner.show();

        this._coursesService.upload(formData)
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    if (event.total) {
                        const total: number = event.total;
                        this.progress = Math.round(100 * event.loaded / total);
                    }
                    else {
                        //handle illegal state  
                    }
                }
                else if (event.type === HttpEventType.Response) {
                    this.message = 'Upload success.';
                    this.onUploadFinished.emit(event.body);

                    
                }
            });

        this.spinner.hide();
    }



    _handleReaderLoaded(readerEvt: any) {

        const file = readerEvt.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.fileInfo.file = reader.result;

            this.onSubmit();
        };

    }


    onSubmit() {


        //var req = {
        //    UpdatedBy: this.appService.currentUserValue.value.userId,
        //    ProjectFile: this.imageInfo,
        //    fileData: this.originalFile
        //}

        const formData = new FormData();

        //formData.append('UpdatedBy', this._coursesService.currentUserValue.value.userId);
        formData.append('fileData', this.originalFile);


        let headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data',
        });

        this.http.post<any>(this.url + 'Project/UploadProject', formData).subscribe(result => {
            if (result.success) {
                //this.genericMessage(result.message, "Success");
            } else {
                //this.genericMessage(result.message, "Error");
            }

        }, error => console.log(error));

    }

    onFileSelect(event: any) {

        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.originalFile = file;
            this.fileNameProfile = file.name;

            var Name = file.name.split('.').pop();
            if (Name != undefined) {
                if (Name.toLowerCase() == "xlsx" || Name.toLowerCase() == "xls") {
                    this.fileInfo = {};
                    this._handleReaderLoaded(event);
                    this.fileInfo.fileName = file.name;
                    this.fileInfo.fileType = file.type;
                    this.fileInfo.fileSize = file.size;
                    this.fileInfo.file = file;
                    //this.registrationForm.controls['resume'].setValue(file);
                }

            }
            else {
                //this.genericMessage("Only .xlsx files are allowed", "Error");
                event.srcElement.value = null;
            }
        }
    }

}
