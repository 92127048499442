<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="courseTitleEditForm" (ngSubmit)="onSubmit()" name="courseTitleEditForm" autocomplete="off">

        <div class="row">

            <div class="col-md-12">


                <label class="text-second">Course Title:</label>
                <input type="text" class="form-control no-radius" placeholder="Course title"
                       [ngClass]="{  'is-invalid': f.p_title.errors}"
                       required formControlName="p_title" maxlength="250" />
                <div *ngIf="f.p_title.errors" class="invalid-feedback">
                    <div *ngIf="f.p_title.errors.pattern">{{errors.name}}</div>
                </div>

            </div>

            <div class="col-3">
                <label class="text-second">Cost:</label>
                <input type="text" class="form-control no-radius" placeholder="Cost"
                       formControlName="cost" maxlength="250" />
            </div>

            <div class="col-3">
                <label class="text-second">Duration:</label>
                <input type="text" class="form-control no-radius" placeholder="Duration"
                       formControlName="duration" maxlength="5" />
            </div>

            <div class="col-3">
                <label class="text-second">Course Code:</label>
                <input type="text" class="form-control no-radius" placeholder="Course Code"
                       formControlName="courseCode" />
            </div>

            <div class="col-3">
                <label class="text-second">Subject:</label>
                <mat-select formControlName="subject" #select
                            class="form-control no-radius">
                    <mat-option *ngFor="let sub of subjectsList" [value]="sub.subjectId">{{sub.title}}</mat-option>
                </mat-select>

            </div>



            <div class="col-4">
                <label class="text-second">Course Type:</label>

                <mat-select formControlName="courseType" #select [(ngModel)]="_courseTypeId"
                            class="form-control no-radius text-second">

                    <mat-option selected value="0" class=" text-second">Course type</mat-option>

                    <mat-option value="1">
                        Full day
                    </mat-option>

                    <mat-option value="2">
                        Certificate
                    </mat-option>

                    <mat-option value="3">
                        eLearning
                    </mat-option>

                    <mat-option value="4">
                        Product
                    </mat-option>

                    <mat-option value="5">
                        Inhouse
                    </mat-option>

                    <mat-option value="6">
                        Webinar
                    </mat-option>

                </mat-select>
            </div>


            <div class="col-4">
                <label class="text-second">Start Time:</label>
                <input type="text" class="form-control no-radius" placeholder="09:45"
                       formControlName="startTime" maxlength="8" />
            </div>

            <div class="col-4">
                <label class="text-second">Finish Time:</label>
                <input type="text" class="form-control no-radius" placeholder="10:45"
                       formControlName="endTime" maxlength="8" />
            </div>

        </div>

        <div class="modal-footer">

            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>
            <div class="">
                <button [disabled]="!courseTitleEditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>
        </div>

    </form>


</div>

