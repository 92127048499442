<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-theme-default@latest/dist/all.css" />


<style>
    .cdk-overlay-container, .cdk-overlay-pane {
        z-index: 9999 !important;
    }
</style>

<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Courses Schedule</span> MONTHLY
            <!--REVERT-->
        </h2>

    </div>
    <div class="col-lg-3 pt-1">


        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNew()">Add New Course</button>
                <button mat-menu-item (click)="yearlySchedule()">Yearly Schedule</button>
                <button mat-menu-item (click)="courseScheduler()">Course Scheduler</button>

            </mat-menu>
        </div>



        <!--<button class="book-btn pull-right filter-apply" (click)="addNew()">
            Add new course
        </button>-->
    </div>

</div>

<div class="row mt-10- bg-white ml-0 pb-4">
    <div class="col-md-3 pl-0">


        <form [formGroup]="coursesForm" name="coursesForm">
            <mat-select formControlName="current_courses" #select id="main_crs"
                        (selectionChange)="getCurrentCourseList($event)"
                        class="form-control no-radius top-dd">
                <mat-option [value]="0">Select Course</mat-option>
                <mat-option *ngFor="let course of coursesList" [value]="course.id">{{course.title}}</mat-option>
            </mat-select>
        </form>

        <!--<mat-select *ngIf="coursesList != null" #select multiple
                    (selectionChange)="getScheduleData($event)"
                    class="form-control">
            <mat-option *ngFor="let course of currentCoursesList" [value]="course.courseId">{{course.courseTitle}}</mat-option>
        </mat-select>-->
        <!--<ul>
            LIST 1
            <li *ngFor="let item of currentCoursesList"
                [ngClass]="item.selected == true ? 'item-selected' : 'not_selected'"
                [class.item-selected]="item.selected"
                (click)="toggleSelection(item)">{{item.courseTitle}}</li>
        </ul>-->


        <div class="boxes">
            <span *ngFor="let item of currentCoursesList">
                <input type="checkbox" id="{{item.courseId}}" (click)="toggleSelection(item)" checked>
                <label for="{{item.courseId}}">{{item.courseTitle}} ({{item.dates.courseStartDate | date:'dd-MM-yyyy'}})</label>

                <!--<input type="checkbox" id="box-2" checked>
                <label for="box-2">Gentrify pickled kale chips </label>

                <input type="checkbox" id="box-3">
                <label for="box-3">Gastropub butcher</label>

                <input type="checkbox" id="box-4">
                <label for="box-4">Gutcher</label>-->
            </span>
        </div>

    </div>

    <div class="col-md-9">


        <div class="row text-center">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary"
                         mwlCalendarPreviousView
                         [view]="view"
                         [(viewDate)]="viewDate"
                         (viewDateChange)="closeOpenMonthViewDay()">
                        Previous
                    </div>
                    <div class="btn btn-outline-secondary"
                         mwlCalendarToday
                         [(viewDate)]="viewDate">
                        Today
                    </div>
                    <div class="btn btn-primary"
                         mwlCalendarNextView
                         [view]="view"
                         [(viewDate)]="viewDate"
                         (viewDateChange)="closeOpenMonthViewDay()">
                        Next
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary"
                         (click)="setView(CalendarView.Month)"
                         [class.active]="view === CalendarView.Month">
                        Month
                    </div>
                    <div class="btn btn-primary"
                         (click)="setView(CalendarView.Week)"
                         [class.active]="view === CalendarView.Week">
                        Week
                    </div>
                    <div class="btn btn-primary"
                         (click)="setView(CalendarView.Day)"
                         [class.active]="view === CalendarView.Day">
                        Day
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                     [viewDate]="viewDate"
                                     [events]="events"
                                     [refresh]="refresh"
                                     [activeDayIsOpen]="activeDayIsOpen"
                                     (dayClicked)="dayClicked($event.day)"
                                     (eventClicked)="handleEvent('Clicked', $event.event)"
                                     (eventTimesChanged)="eventTimesChanged($event)"
                                     (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                    [viewDate]="viewDate"
                                    [events]="events"
                                    [refresh]="refresh"
                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                    (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                   [viewDate]="viewDate"
                                   [events]="events"
                                   [refresh]="refresh"
                                   (eventClicked)="handleEvent('Clicked', $event.event)"
                                   (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
        </div>

    </div>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->
<!--<br />
<br />
<br />

<h3>
    Edit events
    <button class="btn btn-primary float-right" (click)="addEvent()">
        Add new
    </button>
    <div class="clearfix"></div>
</h3>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Title</th>
                <th>Primary color</th>
                <th>Secondary color</th>
                <th>Starts at</th>
                <th>Ends at</th>
                <th>Remove</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let event of events">
                <td>
                    <input type="text"
                           class="form-control"
                           [(ngModel)]="event.title"
                           (keyup)="refresh.next()" />
                </td>
                <td>
                    <input type="color"
                           [(ngModel)]="event.color.primary"
                           (change)="refresh.next()" />
                </td>
                <td>
                    <input type="color"
                           [(ngModel)]="event.color.secondary"
                           (change)="refresh.next()" />
                </td>
                <td>
                    <input class="form-control"
                           type="text"
                           mwlFlatpickr
                           [(ngModel)]="event.start"
                           (ngModelChange)="refresh.next()"
                           [altInput]="true"
                           [convertModelValue]="true"
                           [enableTime]="true"
                           dateFormat="Y-m-dTH:i"
                           altFormat="F j, Y H:i"
                           placeholder="Not set" />
                </td>
                <td>
                    <input class="form-control"
                           type="text"
                           mwlFlatpickr
                           [(ngModel)]="event.end"
                           (ngModelChange)="refresh.next()"
                           [altInput]="true"
                           [convertModelValue]="true"
                           [enableTime]="true"
                           dateFormat="Y-m-dTH:i"
                           altFormat="F j, Y H:i"
                           placeholder="Not set" />
                </td>
                <td>
                    <button class="btn btn-danger" (click)="deleteEvent(event)">
                        Delete
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>-->

<ng-template #modalContentDetails let-close="close">
    <div class="modal-header primary">

        <h5 class="modal-title primary">{{_title}}</h5>
        <!--<button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body- p-4">

        <!--<div class="row">
            <div class="col-md-2">

            </div>

            <div class="col-md-10">

            </div>
        </div>-->

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    Speaker:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{_speaker}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    Subject:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{_subjectTitle}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    Title:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{modalData?.event.title}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    Start Date:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{modalData?.event.start|date}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    End Date:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{modalData?.event.end|date}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    Location:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{_location}}
                    </span>
                </div>
            </div>
        </h6>





    </div>
    <div class="modal-footer">
        <button type="button" class="book-btn" (click)="close()">
            OK
        </button>
    </div>
</ng-template>






<ng-template #modalContentEdit let-close="close">
    <div class="modal-header primary">

        <h5 class="modal-title primary">{{_title}}</h5>
        <!--<button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body- p-4">

        <form class="form-horizontal" [formGroup]="cuForm" (ngSubmit)="onUpdateSubmit(modalData?.event)" name="cuForm" autocomplete="off">

            <div class="row">

                <div class="col-md-12">
                    <label class="text-second">Title:</label>
                    <input type="text" class="form-control no-radius" placeholder="Course title"
                           [ngClass]="{  'is-invalid': f.courseTitle.errors}"
                           required formControlName="courseTitle" maxlength="250" />
                    <div *ngIf="f.courseTitle.errors" class="invalid-feedback">
                        <div *ngIf="f.courseTitle.errors.pattern">{{errors.courseTitle}}</div>
                    </div>


                    <label class="text-second">Speaker:</label>
                    <mat-select formControlName="speaker" #select
                                class="form-control no-radius">
                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerName">{{spkr.speakerName}}</mat-option>
                    </mat-select>

                    <label class="text-second">Location:</label>
                    <mat-select formControlName="location" #select
                                class="form-control no-radius">
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                    </mat-select>

                    <label class="text-second">Subject:</label>
                    <mat-select formControlName="subject" #select
                                class="form-control no-radius">
                        <mat-option *ngFor="let sub of subjectsList" [value]="sub.title">{{sub.title}}</mat-option>
                    </mat-select>

                    <mat-form-field class="mt-4">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="StartDate" placeholder=""
                               [ngModel]="start_date"
                               [ngClass]="{'is-invalid': submitted && f.StartDate.errors}">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>

                        <!--<mat-error *ngIf="cuForm.get('StartDate').hasError('required')">
                            Start Date is required.
                        </mat-error>-->


                    </mat-form-field>

                    <!--<mat-form-field appearance="fill-" ngDefaultControl>
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="StartDate" placeholder=""
                               [ngModel]="start_date"
                               [ngClass]="{'is-invalid': submitted && f.StartDate.errors}">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>




                    </mat-form-field>-->
                    <!--<mat-form-field appearance="fill" ngDefaultControl >
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="courseDate">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>-->

                </div>
            </div>

            <div class="modal-footer">
                <div class="">
                    <button [disabled]="!cuForm.valid"
                            class="book-btn">
                        Update course
                    </button>
                </div>
                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>
            </div>

        </form>
    </div>

</ng-template>

<!--<button type="button" class="btn btn-outline-secondary" (click)="addNew()">
    Add new
</button>-->


<ng-template #modalContentInsert let-close="close">
    <div class="modal-header primary">

        <h5 class="modal-title primary">{{_title}}</h5>

    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="insertForm" (ngSubmit)="onInsertSubmit()" name="cuForm" autocomplete="off">


            <div class="row">

                <div class="col-md-12">
                    <label class="text-second">Title:</label>
                    <input type="text" class="form-control no-radius" placeholder="Course title"
                           [ngClass]="{  'is-invalid': f.courseTitle.errors}"
                           required formControlName="courseTitle" maxlength="250" />
                    <div *ngIf="f.courseTitle.errors" class="invalid-feedback">
                        <div *ngIf="f.courseTitle.errors.pattern">{{errors.courseTitle}}</div>
                    </div>

                    <label class="text-second">Speaker:</label>
                    <mat-select formControlName="speaker" #select
                                class="form-control no-radius">
                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerName">{{spkr.speakerName}}</mat-option>
                    </mat-select>

                    <label class="text-second">Location:</label>
                    <mat-select formControlName="location" #select
                                class="form-control no-radius">
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                    </mat-select>

                    <label class="text-second">Subject:</label>
                    <mat-select formControlName="subject" #select
                                class="form-control no-radius">
                        <mat-option *ngFor="let sub of subjectsList" [value]="sub.title">{{sub.title}}</mat-option>
                    </mat-select>


                    <div class="row mt-2">
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                                <mat-label>Day 1</mat-label>
                                <input matInput [matDatepicker]="picker_day1" formControlName="startDate_day1" placeholder=""
                                       [ngModel]="start_date"
                                       [ngClass]="{'is-invalid':  submitted && i.startDate.errors}">
                                <mat-datepicker-toggle matSuffix [for]="picker_day1"></mat-datepicker-toggle>
                                <mat-datepicker #picker_day1></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3">
                            <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                                <mat-label>Day 2</mat-label>
                                <input matInput [matDatepicker]="picker_day2" formControlName="startDate_day2" placeholder=""
                                       [ngModel]=""
                                       [ngClass]="{'is-invalid': submitted &&  i.startDate.errors}">
                                <mat-datepicker-toggle matSuffix [for]="picker_day2"></mat-datepicker-toggle>
                                <mat-datepicker #picker_day2></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3">
                            <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                                <mat-label>Day 3</mat-label>
                                <input matInput [matDatepicker]="picker_day3" formControlName="startDate_day3" placeholder=""
                                       [ngModel]=""
                                       [ngClass]="{'is-invalid': submitted &&  i.startDate.errors}">
                                <mat-datepicker-toggle matSuffix [for]="picker_day3"></mat-datepicker-toggle>
                                <mat-datepicker #picker_day3></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3">
                            <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                                <mat-label>Day 4</mat-label>
                                <input matInput [matDatepicker]="picker_day4" formControlName="startDate_day4" placeholder=""
                                       [ngModel]=""
                                       [ngClass]="{'is-invalid': submitted &&  i.startDate.errors}">
                                <mat-datepicker-toggle matSuffix [for]="picker_day4"></mat-datepicker-toggle>
                                <mat-datepicker #picker_day4></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                </div>

            </div>


            <div class="row">




            </div>

            <div class="modal-footer">
                <div class="">
                    <button [disabled]="!insertForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>
                <button type="button" class="book-btn" data-dismiss="modal" (click)="close()">
                    Cancel
                </button>
            </div>


           


        </form>
    </div>
    
</ng-template>
