
<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>





<div class="my-5">
    <!-- Overview -->
    <div class="mb-5" id="overview">
        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;"><span class="text-main font-ag-bold">Course Booking</span> form</h2>

        <h3 class="mb-4 font-ag-light text-gray  fw-300">Book a course with us.</h3>

        <p class="font-ag text-gray">We respect your privacy and will only use your personal data in accordance with the General Data Protection Regulation (GDPR). Click <a class="a-underline" href="https://actnow.org.uk/content/11">here</a> to read our full privacy policy.</p>

    </div>

    <div style="margin: 10px 0;text-align:center;font: bold 14px/21px Arial, Tahoma sans-serif;">
        Required fields are highlighted in <span class="req">red</span>
    </div>

</div>

<form [formGroup]="bookingForm" id="bookingForm" name="bookingForm" (ngSubmit)="onBookingSubmit()">
    <!--<mat-select formControlName="bookingDelegate" #select
                class="form-control no-radius">
        <mat-option *ngFor="let dlegate of delegatesList" [value]="dlegate.id">{{dlegate.name}}</mat-option>
    </mat-select>

    <mat-select formControlName="bookingCourse" #select
                (selectionChange)="getCurrentCourseList($event)"
                class="form-control no-radius">
        <mat-option *ngFor="let course of coursesList" [value]="course.title">{{course.title}}</mat-option>
    </mat-select>

    <mat-select formControlName="bookingCurrentCourses" #select
                class="form-control no-radius">
        <mat-option *ngFor="let c_course of currentCoursesList" [value]="c_course.courseId">{{c_course.courseTitle}} - {{c_course.location}} - Start date: {{c_course.dates.courseStartDate | date: 'dd/MM/yyyy'}}</mat-option>
    </mat-select>

    <div class="modal-footer">
        <div class="">
            <button [disabled]="!bookingForm.valid"
                    class="book-btn">
                Save
            </button>
        </div>

    </div>-->



    <mat-accordion class="example-headers-align">
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    COURSE DETAILS
                </mat-panel-title>
                <!--<mat-panel-description>
                    Type your name and age
                    <mat-icon>account_circle</mat-icon>
                </mat-panel-description>-->
            </mat-expansion-panel-header>

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-3">
                                <label class="text-second fw-normal">Course title:</label>
                            </div>
                            <div class="col-8">
                                <label class="text-second">{{courseName}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-3">
                                <label class="text-second fw-normal">Cost:</label>
                            </div>
                            <div class="col-8">
                                <label class="text-second">&pound;{{courseCost}}&nbsp;</label>
                                <label class="text-second" *ngIf="courseLocation == 'Online'">
                                    per delegate
                                </label>

                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-3">
                                <label class="text-second fw-normal">Date:</label>
                            </div>
                            <div class="col-8">
                                <label *ngIf="courseName.includes('BCS') || courseName.includes('GDPR Practitioner') || courseName.includes('Practitioner Certificate in FOISA 2002')">
                                    Starting on&nbsp;
                                </label>
                                <label class="text-second">{{courseDate | date:'dd-MM-yyyy'}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-3">
                                <label class="text-second fw-normal">Venu:</label>
                            </div>
                            <div class="col-8">
                                <label class="text-second">{{courseLocation}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-3">
                                <label class="text-second fw-normal">Where did you hear of this course?:</label>
                            </div>
                            <div class="col-md-8">



                                <mat-select formControlName="source" #select [(ngModel)]="selectedSource" id="hearing"
                                            (selectionChange)="onSourceChanged($event)"
                                            class="form-control no-radius text-second">

                                    <mat-option selected value="Select source" class=" text-second">Select source</mat-option>

                                    <mat-option value="IRMS Conference Stand">
                                        IRMS Conference Stand
                                    </mat-option>

                                    <mat-option value="Act Now Blog">
                                        Act Now Blog
                                    </mat-option>

                                    <mat-option value="Business Scotland">
                                        Business Scotland
                                    </mat-option>

                                    <mat-option value="Business Comment for the Edinburgh Chamber of Commerce">
                                        Business Comment for the Edinburgh Chamber of Commerce
                                    </mat-option>

                                    <mat-option value="Internet Search">
                                        Internet Search
                                    </mat-option>

                                    <mat-option value="Local Government Lawyer">
                                        Local Government Lawyer
                                    </mat-option>

                                    <mat-option value="Received Email">
                                        Received Email
                                    </mat-option>

                                    <mat-option value="Received Flyer">
                                        Received Flyer
                                    </mat-option>

                                    <mat-option value="Attended a Course">
                                        Attended a Course
                                    </mat-option>

                                    <mat-option value="Attended a Course">
                                        Recommended/Colleague
                                    </mat-option>

                                    <mat-option value="Other">
                                        Other
                                    </mat-option>

                                </mat-select>

                                <input [hidden]="!other_source" type="text" class="form-control no-radius" placeholder="Please specify"
                                       formControlName="txt_other" maxlength="250" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <mat-action-row>
                <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    OFFERS
                </mat-panel-title>
            </mat-expansion-panel-header>

            <table width="100%" class="form_field" style="border-style: none;border-collapse: separate;border-spacing: 3px;">
                <tr>
                    <td align="left" valign="top" colspan="2" align="center" style="font: normal 14px/20px arial, sans-serif;">

                        <div class="accord-inner-heading">THE ACT NOW UK GDPR HANDBOOK</div>

                        <p>
                            <img src="https://www.actnow.org.uk/themes/custom2010/handbooks/img/UK_GDPR_Handbook_Cover.jpg" style="width: 125px;margin:0 10px 10px 0;border:solid 1px #888;" align="left" />
                        </p>

                        <div style="text-align:left; margin-bottom:10px;">
                            <p>The <a href="https://www.actnow.org.uk/gdprhandbook" target="_blank">Act Now UK GDPR handbook</a> is designed for data protection practitioners and legal advisers who require a single printed resource cross-referencing the UK GDPR with the supplementary provisions set out in the DPA 2018. The handbook includes:</p>

                            <ul style="padding-left:155px;">
                                <li>Full text of the UK GDPR</li>
                                <li>Part 1 and 2 of the DPA 2018 together with schedules 1 - 4 (inclusive). </li>
                            </ul>

                        </div>

                        <div class="offer-parent bg-purple-gradient text-white">

                            <span class="offer-price">OFFER PRICE</span>

                            <ng-template [ngIf]="(courseCost == 'FREE') || (courseLocation == 'Online')" [ngIfElse]="loggedOut">
                                - Purchase the handbook for &pound;44.95
                            </ng-template>

                            <ng-template #loggedOut>
                                <br>Purchase the handbook for &pound;44.95 when ordering together with a course booking (RRP &pound;54.95)
                            </ng-template>

                        </div>

                        <div *ngIf="courseName.includes('GDPR Practitioner Certificate')" style="text-align:center; margin-bottom:10px;">
                            Delegates booking the GDPR Practitioner Certificate will receive one <span style="color:red;">free</span> copy.<br>Please indicate below if you would like <u>additional copies</u>.
                        </div>

                        <div style="text-align:center; margin-bottom:10px;">

                            <span style="margin-right: 25px;">Would you like to purchase a copy?</span>

                            <input type="radio" name="manual_purchase" value="Yes" (click)="orderHandbook('handbook-show');"> Yes,

                            Quantity
                            <input formControlName="manual_purchase_qty" type="text"
                                   class="form_field"
                                   style="width: 50px; margin-right: 25px;" />

                            <input type="radio" name="manual_purchase" value="No" (click)="orderHandbook('handbook-hide');"> No

                        </div>



                        <div id="handbook-show" class="hanbook-show-style">

                            Please note, a separate invoice will be issued for the Handbook. Orders will not be despatched until payment has been received.

                        </div>

                        <!--<div class="accord-inner-heading">THE ACT NOW DP POLICY PACK</div>

                        <p><img src="https://www.actnow.org.uk/themes/custom2010/policy_pack/comp.jpg" style="width: 125px;margin:0 10px 10px 0;" align="left" /></p>

                        <div style="text-align:left; margin-bottom:10px;">

                            <p>Act Now has applied its information governance knowledge and experience to create a <a href="/gdprpolicypack">policy pack</a> containing essential documentation templates to help you meet the requirements of GDPR and the Data Protection Act 2018.</p>

                        </div>

                        <div class="offer-parent">

                            <span class="offer-price">OFFER PRICE</span>

                            - Purchase the Policy Pack for &pound;199 <input name="policypack_cost" value="199" type="hidden" />

                        </div>

                        <div style="text-align:center; margin-bottom:10px;">

                            <span style="margin-right: 25px;">Would you like to purchase a copy?</span>
                            
                            <input type="radio" name="policypack" value="Yes"> Yes,

                            <input type="radio" name="policypack" value="No"> No

                        </div>-->


                    </td>
                </tr>


            </table>

            <mat-action-row>
                <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    DELEGATE DETAILS
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div class="col-md-2">
                    <label class="text-second">Name:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Delegate name" (blur)="validateEmails()"
                           [ngClass]="{  'is-invalid': !validNames || f.name.errors}"
                           required formControlName="name" maxlength="250" />

                    <div id="_validNames" class="invalid-feedback mt-0">
                        Number of emails not matched with number of delegate names
                    </div>

                    <div *ngIf="f.name.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.name.errors.pattern">{{errors.required}}</div>
                    </div>


                </div>

                <div class="col-md-2">
                    <label class="text-second">Organisation:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Organisation"
                           [ngClass]="{  'is-invalid': f.organization.errors}"
                           required formControlName="organization" maxlength="250" />
                    <div *ngIf="f.organization.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.organization.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Sector:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <mat-select formControlName="sector" #select [(ngModel)]="selectedSector"
                                (selectionChange)="onSourceChanged($event)"
                                class="form-control no-radius text-second">

                        <mat-option value="Select sector" class=" text-second">Select sector</mat-option>
                        <mat-option value="Central Government">Central Government</mat-option>
                        <mat-option value="Civil Service">Civil Service</mat-option>
                        <mat-option value="Education">Education</mat-option>
                        <mat-option value="Emergency Services">Emergency Services</mat-option>
                        <mat-option value="Health Sector">Health Sector</mat-option>
                        <mat-option value="Higher Education">Higher Education</mat-option>
                        <mat-option value="Local Government">Local Government</mat-option>
                        <mat-option value="Private Sector">Private Sector</mat-option>

                        <mat-option value="Third Sector">Third Sector (Charities etc)</mat-option>
                    </mat-select>

                </div>

                <div class="col-md-2">
                    <label class="text-second">Department:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Department"
                           formControlName="department" maxlength="250" />

                </div>

                <div class="col-md-2">
                    <label class="text-second">Address:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': f.address.errors}"
                           required formControlName="address" maxlength="250" />
                    <div *ngIf="f.address.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.address.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Town/City:</label>
                </div>
                <!--<div class="col-md-10 mb-1">
                    <mat-select formControlName="city" #select
                                class="form-control no-radius">
                        <mat-option selected value="Select city" class=" text-second">Select City</mat-option>
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                    </mat-select>
                </div>-->
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Town/City"
                           [ngClass]="{  'is-invalid': f.city.errors}"
                           required formControlName="city" maxlength="250" />
                    <div *ngIf="f.city.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.city.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-2">
                    <label class="text-second">Postcode:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Postcode" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                           [ngClass]="{  'is-invalid': f.postcode.errors}"
                           required formControlName="postcode" maxlength="250" />
                    <div *ngIf="f.postcode.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.postcode.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Telephone:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="+441234567890" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                           [ngClass]="{  'is-invalid': f.telephone.errors}"
                           required formControlName="telephone" maxlength="250" />
                    <div *ngIf="f.telephone.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.telephone.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Email:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <!--^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$
        pattern="^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
        -->
                    <input type="text" class="form-control no-radius" placeholder="Email"
                           [ngClass]="{  'is-invalid': !validEmail}"
                           required formControlName="email" maxlength="250" (blur)="validateEmails()" />
                    <div *ngIf="f.email.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.email.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

            </div>

            <div *ngIf="(courseLocation =='Online') && (courseDuration == 1) && (!courseName.includes('certificate'))">


                <!--<div class="row">

                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10 mb-1">
                        <span style="font-size:14px; font-weight: bold;">
                            If you would like to receive an Act Now bag and pen, please confirm your address in box below.
                        </span>
                        <div class="col-md-12 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Address"
                                   formControlName="bagAddress" maxlength="250" />

                        </div>
                    </div>
                </div>-->

                <div class="footprint-style">

                    <!-- ///////////// CONTENT CHANGED BY: ACT NOW TRAINING //////////// -->
                    <!--<div style="font-weight :bold;margin-bottom: 10px;">We are Reducing our Carbon Footprint</div>-->
                    An electronic copy of your course material will be emailed to you prior to the course.
                    Please ensure the email you have entered is correct.

                    <!--Would you like an electronic copy of the course materials emailed to you instead of a printed hard copy?<br>
                    We will donate &pound;1 to our supported charity.-->
                    <!--<br><br>-->
                    <!--<mat-radio-group aria-label="Select an option" formControlName="rdGogreen">
                        <mat-radio-button value="Yes" style="color: #080;"> <strong>Yes,</strong> it is correct.</mat-radio-button>
                    </mat-radio-group>-->
                    <!--<input type="radio" name="gogreen" value="Yes" onclick="goGreen('green-hide');"> <strong>Yes,</strong> it is correct.-->
                    <!--<input type="radio" name="gogreen" value="No" onclick="goGreen('green-show');"> <strong>No,</strong> Please post the materials to the address below-->

                </div>

                <div id="green-show" style="display:none;
	                                        clear:both;
	                                        font-weight: normal;">

                    Please provide the address where the course materials should be posted. If an incorrect address is provided there will be a charge for additional materials/postage.

                    <table width="100%" class="form_field" style="border-style: none;border-collapse: separate;border-spacing: 5px;">
                        <tr>
                            <td class="form_label" valign="top">
                                <div class="req">Postal Address for Materials</div>
                            </td>
                            <td>
                                <textarea name="postal_address"
                                          class="form_field"
                                          rows="5"
                                          style="width:100%;"></textarea>
                            </td>
                        </tr>
                    </table>

                </div>
            </div>

            <mat-action-row>
                <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="((courseDuration > 1) || courseName.includes('certificate'))"
                             [expanded]="step === 6" (opened)="setStep(6)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    POSTAL ADDRESS DETAILS
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                <!--<input type="checkbox" name="invoice_details" (click)="fillInvoiceDel()"> Same as <span style="font-weight:bold;">DELEGATE DETAILS</span>-->
                <mat-checkbox formControlName="invoice_details" class="example-margin"
                              (change)="updatePostalDetails($event.checked)">Same as <span style="font-weight:bold;">DELEGATE DETAILS</span></mat-checkbox>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <label class="text-second">Name:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Delegate name"
                           formControlName="postal_name" maxlength="250" />
                </div>


                <div class="col-md-2">
                    <label class="text-second">Address:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           formControlName="postal_address" maxlength="250" />
                </div>

                <div class="col-md-2">
                    <label class="text-second">Town/City:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Town/City"
                           formControlName="postal_city" maxlength="250" />
                </div>
                <!--<div class="col-md-10 mb-1">
                    <mat-select formControlName="postal_city" #select
                                class="form-control no-radius">
                        <mat-option selected value="Select city" class=" text-second">Select City</mat-option>
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                    </mat-select>

                </div>-->

                <div class="col-md-2">
                    <label class="text-second">Postcode:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Postcode" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                           formControlName="postal_postcode" maxlength="250" />
                </div>

                <div class="col-md-2">
                    <label class="text-second">Telephone:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="+441234567890" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                           required formControlName="postal_telephone" maxlength="250" />
                </div>


            </div>


            <!-- ******** this is certificate ********* -->

            <div style="
                        text-align: center;
                        margin: 10px 0;
                        padding: 10px;
                        /*background: #cfc;
                        color: #080;*/
                        border: solid 1px #000;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        font: normal 14px/21px arial,sans-serif;
                    ">

                <div style="font-weight :bold;margin-bottom: 10px;">Course Material</div>

                Please provide us with your preferred postal address for your course material. All course material is ordered 14 days prior to the course. If there are any changes to the postal address prior to the course, please update us immediately. If the materials have already been shipped, or a failure to update the address within the timeframe will result in a &pound;85 plus vat charge, to reprint and ship the course material.


            </div>


            <mat-action-row>
                <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    INVOICE DETAILS
                </mat-panel-title>
            </mat-expansion-panel-header>


            <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                Please give details of the individual/department responsible for payment of the invoice.
            </div>


            <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                <!--<input type="checkbox" name="invoice_details" (click)="fillInvoiceDel()"> Same as <span style="font-weight:bold;">DELEGATE DETAILS</span>-->
                <mat-checkbox formControlName="invoice_details" class="example-margin"
                              (change)="updateInvoiceDetails($event.checked)">Same as <span style="font-weight:bold;">DELEGATE DETAILS</span></mat-checkbox>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <label class="text-second">Name:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Delegate name"
                           [ngClass]="{  'is-invalid': f.invoice_name.errors}"
                           required formControlName="invoice_name" maxlength="250" />
                    <div *ngIf="f.invoice_name.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_name.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Organisation:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Organisation"
                           [ngClass]="{  'is-invalid': f.invoice_organization.errors}"
                           required formControlName="invoice_organization" maxlength="250" />
                    <div *ngIf="f.invoice_organization.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_organization.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-2">
                    <label class="text-second">Department:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Department"
                           [ngClass]="{  'is-invalid': f.invoice_department.errors}"
                           formControlName="invoice_department" maxlength="250" />
                    <div *ngIf="f.invoice_department.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_department.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Address:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': f.name.errors}"
                           required formControlName="invoice_address" maxlength="250" />
                    <div *ngIf="f.invoice_address.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_address.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Town/City:</label>
                </div>

                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Town/City"
                           [ngClass]="{  'is-invalid': f.invoice_city.errors}"
                           required formControlName="invoice_city" maxlength="250" />
                    <div *ngIf="f.invoice_city.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_city.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <!--<div class="col-md-10 mb-1">
                    <mat-select formControlName="invoice_city" #select
                                class="form-control no-radius">
                        <mat-option selected value="Select city" class=" text-second">Select city</mat-option>
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                    </mat-select>
                </div>-->

                <div class="col-md-2">
                    <label class="text-second">Postcode:</label>
                </div>

                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Postcode"
                           [ngClass]="{  'is-invalid': f.invoice_postcode.errors}"
                           required formControlName="invoice_postcode" maxlength="250" />
                    <div *ngIf="f.invoice_postcode.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_postcode.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Telephone:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="+441234567890" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                           [ngClass]="{  'is-invalid': f.invoice_telephone.errors}"
                           required formControlName="invoice_telephone" maxlength="250" />
                    <div *ngIf="f.invoice_telephone.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_telephone.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Email:</label>
                </div>
                <div class="col-md-10 mb-1">
                    
                    <input type="text" class="form-control no-radius" placeholder="Email" pattern="^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
                           [ngClass]="{  'is-invalid': f.invoice_email.errors}"
                           required formControlName="invoice_email" maxlength="250" />
                    <div *ngIf="f.invoice_email.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoice_email.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Purchase Order:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Purchase Order"
                           formControlName="invoice_purchaseOrder" maxlength="250" />

                </div>

                <div class="col-md-2">
                    <label class="text-second">Promo Code:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Promo Code"
                           formControlName="invoice_promoCode" maxlength="250" />

                </div>
            </div>

            <mat-action-row>
                <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    BOOKER DETAILS (If different)
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">

                <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                    <!--<input type="radio" value="delegate" name="booker_details" (click)="updateBookerDetails('delegate')">
                    Same as <span style="padding-right:15px;font-weight:bold;">DELEGATE DETAILS</span>
                    <input type="radio" value="invoice" name="booker_details" (click)="updateBookerDetails('invoice')">
                    Same as <span style="padding-right:15px;font-weight:bold;">INVOICE DETAILS</span>
                    <input type="radio" value="none" name="booker_details" (click)="updateBookerDetails('none')" checked>
                    <span style="font-weight:bold;">Other</span>-->


                    <mat-radio-group aria-label="Select an option" class="smallRadio">
                        <mat-radio-button value="Yes" (click)="updateBookerDetails('delegate')">
                            Same as <span style="padding-right:15px;font-weight:bold;">DELEGATE DETAILS</span>
                        </mat-radio-button>
                        <mat-radio-button value="No" (click)="updateBookerDetails('invoice')">
                            Same as <span style="padding-right:15px;font-weight:bold;">INVOICE DETAILS</span>
                        </mat-radio-button>
                        <mat-radio-button value="Already" (click)="updateBookerDetails('none')">
                            <span style="font-weight:bold;">Other</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Name:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Delegate name"
                           [ngClass]="{  'is-invalid': f.booker_name.errors}"
                           required formControlName="booker_name" maxlength="250" />
                    <div *ngIf="f.booker_name.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.booker_name.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Telephone:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="+441234567890" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                           [ngClass]="{  'is-invalid': f.booker_telephone.errors}"
                           required formControlName="booker_telephone" maxlength="250" />
                    <div *ngIf="f.booker_telephone.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.booker_telephone.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-2">
                    <label class="text-second">Email:</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Email" pattern="^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
                           [ngClass]="{  'is-invalid': f.booker_email.errors}"
                           required formControlName="booker_email" maxlength="250" />
                    <div *ngIf="f.booker_email.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.booker_email.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


            </div>
            <mat-action-row>
                <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
            </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" (closed)="setStep_closed()" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    TERMS AND CONDITIONS
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="<?php if ( isset ( $_POST['submit'] ) && ( $_POST['blog']=='' ) ) echo 'e'; ?> subscribe-style">

                Would you like to subscribe to our regular <strong>email</strong> which will keep you up to date with our latest blog posts and training courses on information and surveillance law? (<span style="font-weight: bold;color: red;">required</span>)

                <div style="margin-top: 10px;">

                    <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                        <!--<input type="radio" value="Yes" name="blog_details"> Yes
                <input type="radio" value="No" name="blog_details"> No
                <input type="radio" value="Already" name="blog_details" checked> Already Subscribed-->

                        <mat-radio-group aria-label="Select an option" class="smallRadio" formControlName="blog_details">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Already">Already Subscribed</mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>

            </div>

            <div class="<?php if ( isset ( $_POST['submit'] ) && ( $_POST['booknow']=='' ) )  echo 'e'; ?> subscribe-style">

                From time to time we send out leaflets by <strong>post</strong> which detail our new and upcoming training courses and products. Would you like to receive these? (<span style="font-weight: bold;color: red;">required</span>)

                <div style="margin-top: 10px;">
                    <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                        <!--<input type="radio" value="Yes" name="booknow"> Yes
                <input type="radio" value="No" name="booknow"> No
                <input type="radio" value="Already" name="booknow" checked> Already Receive-->

                        <mat-radio-group aria-label="Select an option" class="smallRadio" formControlName="booknow">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Already">Already Subscribed</mat-radio-button>
                        </mat-radio-group>
                    </div>


                </div>

            </div>

            <h2 class="mb-4 text-second font-ag-light" style="font-size: 2rem;"><span class="text-main font-ag-bold">Bookings & Cancellation</span> Policy</h2>


            <ul class="font-ag text-gray" style="margin: 0 0 10px 30px;">

                <li class="list-inside">Act Now Training will invoice your organisation upon receipt of the booking. Receipt of a booking form by us implies that you have the necessary internal approvals.</li>

                <li class="list-inside">The invoice is payable within 30 days of being issued regardless of whether the event has taken place.</li>

                <li class="list-inside">If you book within 10 days of the course date please check by phone or email that your place is confirmed.</li>

                <li class="list-inside">Confirmation and directions to the venue will be sent to you a minimum of 7 days before the course date.</li>

                <li class="list-inside">It is your responsibility to ensure we have your correct details especially email address. Failure to do so could lead to you not receiving joining instructions. In such cases you are still liable to pay the invoice.</li>

                <li class="list-inside"><strong>The full fee will remain payable even if the delegate does not attend.</strong></li>

                <li class="list-inside">We reserve the right to cancel the event or change the venue or speakers due to circumstances beyond our control. In such cases we will not be liable for refunding any expenses incurred.</li>

                <li class="list-inside">For the latest info on courses, venues etc please check the front page of our website or <a href="https://twitter.com/@ActNowTraining" target="_blank">follow us on twitter</a>.</li>

                <li class="list-inside">We reserve the right to refuse any bookings (and cancel any bookings) especially from consultants, those in the business of information rights training/consultancy and those whose business directly or indirectly competes with ours.</li>

                <div style="margin:10px 0;font-weight:bold;">CERTIFICATE COURSES</div>

                <li class="list-inside">If the invoice remains unpaid, we reserve the right not to release your exam marks and/or issue your certificate until the invoice has been paid in full.</li>

                <div style="margin:10px 0;font-weight:bold;">REFUND/CANCELLATION POLICY</div>

                <li class="list-inside">You can cancel your booking up to 30 days before the event without charge. After this you cannot cancel your booking nor transfer it to another course. This is irrespective of when booked.</li>

                <li class="list-inside">Substitute delegates are welcome at any time without charge.</li>

                <li class="list-inside">Transfers to another date for the same course are considered as another course.</li>

                <div style="margin:10px 0;font-weight:bold;">INCLEMENTAL WEATHER/TRANSPORT PROBLEMS</div>

                <li class="list-inside">We do not cancel courses due to inclemental weather or transport problems. If you cannot attend due to these reasons the invoice remains payable.</li>

            </ul>



            <div class="accept-style">
                <mat-checkbox formControlName="invoice_tc" class="example-margin"
                              (change)="termConditions($event.checked)">Please tick to accept the terms and conditions.</mat-checkbox>
                <!--<input type="checkbox" name="tandc" value="yes" /> Please tick to accept the terms and conditions.-->

            </div>

            
            <mat-action-row>
                <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                <button mat-button color="primary" class="book-btn" [disabled]="(validEmail == false) || !bookingForm.valid || (acceptedTC==false)">Submit</button>

            </mat-action-row>
        </mat-expansion-panel>

    </mat-accordion>



</form>
