
<div class="row">
    <div class="col-lg-7">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Issued Invoices</span>
        </h2>

    </div>
    <div class="col-lg-5 pt-1">

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="gotoSuppliers()">Suppliers List</button>
                <button mat-menu-item (click)="gotoInvoicesReceivedList()">Received Invoices</button>
                <button mat-menu-item (click)="gotoDebtCollectionList()">Debt Collection</button>
                <button mat-menu-item (click)="gotoCreditNotesList()">Credit Notes</button>
                <button mat-menu-item (click)="gotoXeroInvoicesList()">Xero Invoices</button>
            </mat-menu>
        </div>




        <!--<button class="book-btn pull-right filter-apply mr-1" (click)="gotoSuppliersList()">
            Suppliers List
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="gotoInvoicesReceivedList()">
            Invoices Received
        </button>-->


    </div>

</div>


<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>

<ag-grid-angular #agGrid
                 id="iGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="invoicesList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">
</ag-grid-angular>
