import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../common/guards/AuthenticationService';
import { AuthUserService } from '../../../common/_services/auth-user.service';

@Component({
    selector: 'app-unauthorise',
    templateUrl: './unauthorise.component.html',
    styleUrls: ['./unauthorise.component.css']
})
export class UnauthoriseComponent implements OnInit {

    user: any;

    constructor(private _router: Router,
        private _authService: AuthUserService,
        private _auth: AuthenticationService) { }

    ngOnInit(): void {
        this.user = {
            role: this._auth.userRole(),
            name: localStorage.getItem("userName")

        }
    }

    logout() {
        this._authService.logout();
        this._router.navigateByUrl("/account/login");
    }

}
