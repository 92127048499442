import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import add from 'date-fns/esm/fp/add/index.js';
import { errorMessages } from '../../../common/validation/messages';
import { CoursesService } from '../../../common/_services/courses-services';

@Component({
    selector: 'app-add-course',
    templateUrl: './add-course.component.html',
    styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit, OnChanges {

    public _title = "Add Course";
    insertForm: FormGroup;
    start_d1: any = new FormControl(new Date());
    start_d2: any;
    start_d3: any;
    start_d4: any;
    public speakersList: any;
    public locationsList: any;
    public subjectsList: any;
    public titlesList: any;
    errors: any = errorMessages;
    submitted = false;
    _courseTypeId: any = 0;
    _courseTitleId: any = 0;

    isNew: any = true;

    loading = false;

    @ViewChild('select') select: MatSelect;
    @Output() close = new EventEmitter<any>();

    @Output() refreshGrid = new EventEmitter();
    @Output() refreshGridMethod = new EventEmitter<any>();

    @Input() data;

    constructor(//@Inject(MAT_DIALOG_DATA) public data: any = {},
        public activeModal: NgbActiveModal,
        private _coursesService: CoursesService,
        private formBuilder: FormBuilder) {
        //
    }
    ngOnChanges(changes: SimpleChanges): void {
        throw new Error('Method not implemented.');
    }

    ngOnInit(): void {

        this.loading = true;

        this.insertForm = this.formBuilder.group({
            courseTitle: ['', Validators.required],
            speaker: ['', Validators.required],
            subject: ['', Validators.required],
            courseType: ['', Validators.required],
            location: ['', Validators.required],
            startDate_day1: [this.start_d1.value, Validators.required],
            fees: ['0', Validators.required],
            startDate_day2: [''],
            startDate_day3: [''],
            startDate_day4: [''],
            startTime: [''],
            endTime: [''],
        });

        this.getTitlesList();
        this.getLocationsList();
        this.getSpeakersList();
        this.getSubjectsList();

        //
        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this.populateForm(this.data);
        }

        this.loading = false;
    }

    populateForm(__data: any) {
        
        this._title = "Edit Course";

        //var obj = this.titlesList.filter(obj => obj.id === __data.courseTitleId);
        //this.insertForm.controls.courseTitle.setValue(obj);
        
        this._courseTitleId = __data.courseTitleId;
        //this.insertForm.controls.courseTitle.setValue(__data.title);
        this.insertForm.controls.speaker.patchValue(__data.speakerName);
        this.insertForm.controls.location.patchValue(__data.location);
        this.insertForm.controls.subject.patchValue(__data.subjectTitle);
        //this.insertForm.controls.courseType.patchValue(__data.courseTypeId);
        this._courseTypeId = __data.courseTypeId + "";
        this.insertForm.controls.fees.setValue(__data.courseCost);
        this.insertForm.controls.startTime.setValue(__data.dates.startTime);
        this.insertForm.controls.endTime.setValue(__data.dates.finishTime);
        this.start_d1 = __data.dates.day1Date;
        this.start_d2 = __data.dates.day2Date;
        this.start_d3 = __data.dates.day3Date;
        this.start_d4 = __data.dates.day4Date;


    }

    addEvent(day:string, event: MatDatepickerInputEvent<Date>) {
        
        if (event.value != null) {
            if (day == 'd1') {
                //this.start_d1 = new Date(event.value);
                //this.start_d1.setDate(event.value.getDate());
                //this.start_d1.setMonth(event.value.getMonth());
                //this.start_d1.setFullYear(event.value.getFullYear());

                this.start_d1 = this.adjustDateForTimeOffset(event.value);
            }
            else if (day == 'd2') {
                //this.start_d2 = new Date(event.value);
                //this.start_d2.setDate(event.value.getDate());
                //this.start_d2.setMonth(event.value.getMonth());
                //this.start_d2.setFullYear(event.value.getFullYear());

                this.start_d2 = this.adjustDateForTimeOffset(event.value);
            }
            else if (day == 'd3') {
                //this.start_d3 = new Date(event.value);
                //this.start_d3.setDate(event.value.getDate());
                //this.start_d3.setMonth(event.value.getMonth());
                //this.start_d3.setFullYear(event.value.getFullYear());

                this.start_d3 = this.adjustDateForTimeOffset(event.value);
            }
            else if (day == 'd4') {
                //this.start_d4 = new Date(event.value);
                //this.start_d4.setDate(event.value.getDate());
                //this.start_d4.setMonth(event.value.getMonth());
                //this.start_d4.setFullYear(event.value.getFullYear());

                this.start_d4 = this.adjustDateForTimeOffset(event.value);
            }
            
            // console.log(event.value)
        }

    }

    adjustDateForTimeOffset(dateToAdjust) {
        var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
        return new Date(dateToAdjust.getTime() - offsetMs);
    }

    getSpeakersList() {
        this.loading = true;
        this._coursesService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data;

                this.loading = false;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    populateTitleData(data: any) {
        
        //this.selectedSubject = event.source.selected._element.nativeElement.innerText;
        this.insertForm.controls.subject.patchValue(data.value.subject);
        this._courseTypeId = data.value.courseTypeId + "";
        this.insertForm.controls.fees.setValue(data.value.courseCost);
        this.insertForm.controls.startTime.setValue(data.value.startTime);
        this.insertForm.controls.endTime.setValue(data.value.endTime);
    }

    getLocationsList() {
        this.loading = true;
        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data;
                this.loading = false;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {
                this.subjectsList = result.data;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getTitlesList() {

        this._coursesService.getCourseTitlesList().subscribe((result: any) => {

            if (result.success == true) {
                this.titlesList = result.data;
                

                if (this._title == "Edit Course") {
                    var obj = this.titlesList.filter(obj => obj.id === this._courseTitleId);
                    this.insertForm.controls.courseTitle.setValue(obj[0]);
                }
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    get i() { return this.insertForm.controls; }

    insertNewCourse() {
        var d2 = this.insertForm.controls.startDate_day2.value ? this.insertForm.controls.startDate_day2.value : null;
        var d3 = this.insertForm.controls.startDate_day3.value ? this.insertForm.controls.startDate_day3.value : null;
        var d4 = this.insertForm.controls.startDate_day4.value ? this.insertForm.controls.startDate_day4.value : null;

       
        var req = {
            subjectTitle: this.insertForm.controls.subject.value,
            courseTitle: this.insertForm.controls.courseTitle.value.title,
            courseTitleId: this.insertForm.controls.courseTitle.value.id,
            location: this.insertForm.controls.location.value,
            dates: {
                day1Date: this.insertForm.controls.startDate_day1.value,
                day2Date: d2,
                day3Date: d3,
                day4Date: d4,
                StartTime: this.insertForm.controls.startTime.value,
                FinishTime: this.insertForm.controls.endTime.value
            },
            courseTypeId: parseInt(this.insertForm.controls.courseType.value),
            speakerName: this.insertForm.controls.speaker.value,
            courseCost: parseInt(this.insertForm.controls.fees.value)


        };

       
        this._coursesService.insertCourseData(req).subscribe((result: any) => {


            if (result.success == true) {
                this.activeModal.close('Close click');
                //this.close.emit();
                this.refreshGridMethod.emit();
               
            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    updateExistingCourse() {
        
        
        var req = {
            dates: {
                courseDateId: this.data.dates.courseDateId,
                day1Date: this.insertForm.controls.startDate_day1.value,
                day2Date: this.insertForm.controls.startDate_day2.value,
                day3Date: this.insertForm.controls.startDate_day3.value,
                day4Date: this.insertForm.controls.startDate_day4.value,
                StartTime: this.insertForm.controls.startTime.value,
                FinishTime: this.insertForm.controls.endTime.value
            },
            //startDate: this.insertForm.controls.StartDate.value,
            courseTitle: this.insertForm.controls.courseTitle.value.title,
            courseTitleId: this.insertForm.controls.courseTitle.value.id,
            
            courseId: this.data.courseId,
            subject: this.insertForm.controls.subject.value,
            speakerName: this.insertForm.controls.speaker.value,
            courseCost: parseInt(this.insertForm.controls.fees.value),
            courseTypeId: parseInt(this.insertForm.controls.courseType.value),
            location: this.insertForm.controls.location.value,
            toBeUpdate: this.data.dates.courseDateId + "|all"
        };

        this._coursesService.updateCourseData(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();
                //this.close.emit();

                //this.modal.dismissAll();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    onInsertSubmit() {

        if (this.isNew == true) {

            this.insertNewCourse();
        }
        else {
            this.updateExistingCourse();
        }



    }

    onModalClose(): void {
        this.close.emit();
    }

}
