<div class="modal-body text-center">
    <mat-icon (click)="CloseOTP()" class="close">close</mat-icon>
    <img src="assets/images/accept.svg" class="img-responsive mb-4">
    <h4 class="text-colr">PIN CONFIRMATION</h4>
    <p>{{text}}</p>
    <input type="text" appNumberOnly class="form-control" placeholder="Enter Pin" required="" [(ngModel)]="mPin" [maxLength]="maxLength">

    <button type="button" (click)="send()" [disabled]="mPin == null || mPin == undefined || mPin == '' " class="mt-3  btn btn-primary w-100">
        Send
    </button>
</div>