import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FinanceService } from '../../../common/_services/finance-services';

@Component({
    selector: 'app-add-edit-invoice',
    templateUrl: './add-edit-invoice.component.html',
    styleUrls: ['./add-edit-invoice.component.css']
})
export class AddEditInvoiceComponent implements OnInit {

    invoiceForm: FormGroup;
    paymentDate: any = new Date();
    _invoiceDate: any = new Date();
    _paymentDate: any = '';// new Date();
    _date: any = new Date();

    suppliersList: any;
    _invoiceId: any = 0;
    @Input() data;
    isNew: any = true;
    public _title = "Add Invoice";
    @Output() refreshGrid = new EventEmitter();

    constructor(private formBuilder: FormBuilder,
        private modal: NgbModal,
        private _financeService: FinanceService
    ) { }

    ngOnInit(): void {
        this.invoiceForm = this.formBuilder.group({

            supplier: ['none'],
            invoiceNumber: ['', Validators.required],
            source: ['none'],
            subtotal: ['', Validators.required],
            totalTax: ['', Validators.required],
            total: ['', Validators.required],
            expense: [''],
            serviceDetails: [''],
            paymentRef: [''],
            p_paymentDate: [''], //[this.paymentDate.value],
            invoiceDate: [this.paymentDate.value],
        });

       
        this.getSuppliersList();

        if (Object.keys(this.data).length === 0) {
            // this is add case
                        
            this.invoiceForm.controls.supplier.setValue(this.data);
        }
        else {
            this.isNew = false;
            this.populateForm(this.data);
        }

        
    }

    populateForm(__data) {

        this._title = "Edit Invoice";

        this._invoiceId = __data.invoiceId;
        //debugger;
        this.invoiceForm.controls.invoiceNumber.setValue(__data.invoiceNumber);
        this.invoiceForm.controls.invoiceDate.setValue(__data.invoiceDate);
        this.invoiceForm.controls.p_paymentDate.setValue(__data.datePaid);
        this.invoiceForm.controls.source.patchValue(__data.paymentMethod);
        this.invoiceForm.controls.subtotal.setValue(__data.netTotal);
        this.invoiceForm.controls.totalTax.setValue(__data.vat);
        this.invoiceForm.controls.total.setValue(__data.grossTotal);
        this.invoiceForm.controls.expense.setValue(__data.expenses);
        this.invoiceForm.controls.supplier.setValue(__data.supplierId);
        this.invoiceForm.controls.paymentRef.setValue(__data.paymentReference);
        this.invoiceForm.controls.serviceDetails.setValue(__data.servicesDetails);

        this._invoiceDate = __data.invoiceDate;
        this._paymentDate = __data.datePaid;

    }

    getSuppliersList() {

        this._financeService.getSuppliersList().subscribe((result: any) => {

            if (result.success == true) {

                this.suppliersList = result.data;
                
            } else {
                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    get i() { return this.invoiceForm.controls; }

    applyTax(d: any) {
        var tax = (d * 20) / 100;
        this.invoiceForm.controls.totalTax.setValue(tax.toFixed(2));
        this.invoiceForm.controls.total.setValue((Number(d) + Number(tax)).toFixed(2));
    }

    changeTax(d: number) {
        //var tax = (d * 20) / 100;
        debugger;
        //this.invoiceForm.controls.totalTax.setValue(Number(d).toFixed(2));
        this.invoiceForm.controls.total.setValue((Number(this.invoiceForm.controls.subtotal.value) + Number(d)).toFixed(2));
    }

    onPaymentSubmit() {

        var paymentDate = this.invoiceForm.controls.p_paymentDate.value == '' ? null : this.invoiceForm.controls.p_paymentDate.value;
        var req = {
            request : {
                invoiceId: this._invoiceId,
                invoiceNumber: this.invoiceForm.controls.invoiceNumber.value,
                invoiceDate: this.invoiceForm.controls.invoiceDate.value,
                datePaid: paymentDate, //this.invoiceForm.controls.p_paymentDate.value,
                paymentMethod: this.invoiceForm.controls.source.value,
                netTotal: "" + this.invoiceForm.controls.subtotal.value,
                vat: "" + this.invoiceForm.controls.totalTax.value,
                grossTotal: Number(this.invoiceForm.controls.total.value),
                expenses: "" + this.invoiceForm.controls.expense.value,
                supplierId: this.invoiceForm.controls.supplier.value,
                paymentReference: this.invoiceForm.controls.paymentRef.value,
                servicesDetails: this.invoiceForm.controls.serviceDetails.value,
            }
        };
       
        debugger;

        this._financeService.insertInvoiceReceived(req).subscribe((result: any) => {


            if (result.success == true) {

                this.invoiceForm.reset();

                this.invoiceForm.controls.source.patchValue("none");
                this.invoiceForm.controls.supplier.patchValue("none");

                this.modal.dismissAll();
                this.refreshGrid.emit();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }


    close() {
        this.modal.dismissAll();
    }

    addEvent(date: string, event: MatDatepickerInputEvent<Date>) {

        if (event.value != null) {
            if (date == '_invoiceDate') {
                this._invoiceDate = this.adjustDateForTimeOffset(event.value);
            }
            else if (date == '_paymentDate') {
                this._paymentDate = this.adjustDateForTimeOffset(event.value);
            }
        }

    }

    adjustDateForTimeOffset(dateToAdjust) {
        var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
        return new Date(dateToAdjust.getTime() - offsetMs);
    }
}
