


<!--FOUNDATION CERTIFICATE-->



<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>

<form [formGroup]="bookingForm" id="bookingForm" name="bookingForm" (ngSubmit)="onBookingSubmit()">



    <div class="row m-2">
        <div class="col-6 panel">

            <h2 class="mb-2 mt-2 text-second font-ag-bold text-center" style="font-size: 1.5rem;">
                DELEGATE DETAILS
            </h2>

            <div class="row">
                <div class="col-md-3">
                    <label class="text-second">Name:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Delegate name"
                           [ngClass]="{  'is-invalid': f.name.errors}"
                           required formControlName="name" maxlength="250" />
                    <div *ngIf="f.name.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.name.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-3">
                    <label class="text-second">Organisation:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Organisation"
                           [ngClass]="{  'is-invalid': f.organization.errors}"
                           required formControlName="organization" maxlength="250" />
                    <div *ngIf="f.organization.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.organization.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-3">
                    <label class="text-second">Department:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Department"
                           formControlName="department" maxlength="250" />
                </div>


                <div class="col-md-3">
                    <label class="text-second">Address:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': f.address.errors}"
                           required formControlName="address" maxlength="250" />
                    <div *ngIf="f.address.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.address.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-3">
                    <label class="text-second">Town/City:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Town/City"
                           [ngClass]="{  'is-invalid': f.city.errors}"
                           required formControlName="city" maxlength="250" />
                    <div *ngIf="f.city.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.city.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-3">
                    <label class="text-second">Postcode:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="Postcode" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                           [ngClass]="{  'is-invalid': f.postcode.errors}"
                           required formControlName="postcode" maxlength="250" />
                    <div *ngIf="f.postcode.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.postcode.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>


                <div class="col-md-3">
                    <label class="text-second">Telephone:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <input type="text" class="form-control no-radius" placeholder="+441234567890" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                           [ngClass]="{  'is-invalid': f.telephone.errors}"
                           required formControlName="telephone" maxlength="250" />
                    <div *ngIf="f.telephone.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.telephone.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-md-3">
                    <label class="text-second">Email:</label>
                </div>
                <div class="col-md-9 mb-1">
                    <!--^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$-->
                    <input type="text" class="form-control no-radius" placeholder="Email"
                           [ngClass]="{  'is-invalid': f.email.errors}"
                           required formControlName="email" maxlength="250" />
                    <div *ngIf="f.email.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.email.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-6 pr-0">
            <div class="panel pr-0">
                <h2 class="mb-2 mt-2 text-second font-ag-bold text-center" style="font-size: 1.5rem;">
                    INVOICE DETAILS
                </h2>

                <div class="row">
                    <div align="center" style="font: normal 14px/21px Arial, Tahoma sans-serif; margin: 10px 0;">
                        <mat-checkbox formControlName="invoice_details" class="example-margin"
                                      (change)="updateInvoiceDetails($event.checked)">Same as <span style="font-weight:bold;">DELEGATE DETAILS</span></mat-checkbox>
                    </div>

                    <div class="row pr-0">
                        <div class="col-md-3">
                            <label class="text-second">Name:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Delegate name"
                                   [ngClass]="{  'is-invalid': f.invoice_name.errors}"
                                   required formControlName="invoice_name" maxlength="250" />
                            <div *ngIf="f.invoice_name.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_name.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label class="text-second">Organisation:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Organisation"
                                   [ngClass]="{  'is-invalid': f.invoice_organization.errors}"
                                   required formControlName="invoice_organization" maxlength="250" />
                            <div *ngIf="f.invoice_organization.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_organization.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>


                        <div class="col-md-3">
                            <label class="text-second">Department:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Department"
                                   [ngClass]="{  'is-invalid': f.invoice_department.errors}"
                                   formControlName="invoice_department" maxlength="250" />
                            <div *ngIf="f.invoice_department.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_department.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label class="text-second">Address:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Address"
                                   [ngClass]="{  'is-invalid': f.name.errors}"
                                   required formControlName="invoice_address" maxlength="250" />
                            <div *ngIf="f.invoice_address.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_address.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label class="text-second">Town/City:</label>
                        </div>

                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Town/City"
                                   [ngClass]="{  'is-invalid': f.invoice_city.errors}"
                                   required formControlName="invoice_city" maxlength="250" />
                            <div *ngIf="f.invoice_city.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_city.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <!--<div class="col-md-9 mb-1">
                            <mat-select formControlName="invoice_city" #select
                                        class="form-control no-radius">
                                <mat-option selected value="Select city" class=" text-second">Select city</mat-option>
                                <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                            </mat-select>
                        </div>-->

                        <div class="col-md-3">
                            <label class="text-second">Postcode:</label>
                        </div>

                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Postcode"
                                   [ngClass]="{  'is-invalid': f.invoice_postcode.errors}"
                                   required formControlName="invoice_postcode" maxlength="250" />
                            <div *ngIf="f.invoice_postcode.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_postcode.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label class="text-second">Telephone:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="+441234567890" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                                   [ngClass]="{  'is-invalid': f.invoice_telephone.errors}"
                                   required formControlName="invoice_telephone" maxlength="250" />
                            <div *ngIf="f.invoice_telephone.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_telephone.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label class="text-second">Email:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Email" pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                   [ngClass]="{  'is-invalid': f.invoice_email.errors}"
                                   required formControlName="invoice_email" maxlength="250" />
                            <div *ngIf="f.invoice_email.errors" class="invalid-feedback mt-0">
                                <div *ngIf="f.invoice_email.errors.pattern">{{errors.required}}</div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label class="text-second">Purchase Order:</label>
                        </div>
                        <div class="col-md-9 mb-1">
                            <input type="text" class="form-control no-radius" placeholder="Purchase Order"
                                   formControlName="invoice_purchaseOrder" maxlength="250" />

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row panel mt-2 subscribe-style bg-white">
        <div class="fw-bold text-center">LIVE WEBINARS</div>

        <div class="green_text">

            Please choose a date to participate in each of the live webinars below.

        </div>

        <div class="col-4">

            <label class="text-second">Introduction to GDPR</label>
            <mat-select formControlName="dd_GDPR" #select 
                        (selectionChange)='onGDPRSelected($event)'
                        class="text-left form-control no-radius">
                <mat-option selected value="" class=" text-second">Select date</mat-option>
                <mat-option *ngFor="let gdpr of gdprList" [value]="gdpr.courseId + ',' + gdpr.courseDate">{{gdpr.courseDate | date:'dd-MM-yy'}}</mat-option>
            </mat-select>
            
        </div>

        <div class="col-4">
            <label class="text-second">Introduction to FOI</label>
            <mat-select formControlName="dd_FOI" #select
                        (selectionChange)='onFOISelected($event)'
                        class="text-left form-control no-radius">
                <mat-option selected value="" class=" text-second">Select date</mat-option>
                <mat-option *ngFor="let foi of foiList" [value]="foi.courseId + ',' + foi.courseDate">{{foi.courseDate | date:'dd-MM-yy'}}</mat-option>
            </mat-select>
        </div>

        <div class="col-4">
            <label class="text-second">Introduction to RM</label>
            <mat-select formControlName="dd_RM" #select
                        (selectionChange)='onRMSelected($event)'
                        class="text-left form-control no-radius">
                <mat-option selected value="" class=" text-second">Select date</mat-option>
                <mat-option *ngFor="let rm of rmList" [value]="rm.courseId + ',' + rm.courseDate">{{rm.courseDate | date:'dd-MM-yy'}}</mat-option>
            </mat-select>
        </div>
    </div>


    <div class="panel mt-2 subscribe-style bg-white" align="center">
        <div class="fw-bold">MODULES</div>

        <div class="green_text">

            You will receive a link to the modules and online assessments with your booking confirmation.

        </div>
    </div>

    <div class="panel mt-2 subscribe-style">

        <!-- /////////////////////////////////////////////////////////////////////////// -->
        <!-- ////////////////////// PRIVACY //////////////////////////////////////////// -->
        <!-- /////////////////////////////////////////////////////////////////////////// -->

        <div class="fw-bold">YOUR PRIVACY</div>

        We respect your privacy and will only use your personal data in accordance with the General Data Protection Regulation (GDPR). Click <a href="actnow.org.uk/content/11">here</a> to read our full privacy policy.

    </div>


    <div class="subscribe-style">

        <p class="mb-0">
            Would you like to subscribe to our regular email and blog which will keep you up to date with the latest news, views and developments in the world of information and surveillance law?
            (<span style="font-weight: bold;color: red;">required</span>)</p>

    <mat-radio-group aria-label="Select an option" class="smallRadio" formControlName="subscription">
        <mat-radio-button value="Yes">Yes</mat-radio-button>
        <mat-radio-button value="No">No</mat-radio-button>
        <mat-radio-button value="Already">Already Subscribed</mat-radio-button>
    </mat-radio-group>



    <div style="height:3px;border-top:solid 1px #888;margin: 0 30px;"></div>

<p class="mb-0">From time to time we post out leaflets detailing our new training courses and e learning products. Would you like to receive these? (<span style="font-weight: bold;color: red;">required</span>)</p>

        <mat-radio-group aria-label="Select an option" class="smallRadio" formControlName="leaflet">
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No">No</mat-radio-button>
            <mat-radio-button value="Already">Already Subscribed</mat-radio-button>
        </mat-radio-group>

    </div>


    <div class="panel mt-2 subscribe-style bg-white">
        <div class="text-center fw-bold">TERMS AND CONDITIONS</div>

        <ol style="text-align:justify;">

            <li>Act Now Training will invoice your organisation for this booking. Receipt of a booking form by us implies that you have the necessary internal approvals.</li>

            <li>The invoice is payable within 30 days of being issued, not after the course has started or after you have completed it.</li>

            <li>You will have a choice of dates to participate in the live webinars. You have to state your choice at the time of booking (above). You can change your choice of date on one occasion 7 days prior to a webinar. After that time an additional fee of &pound;69 plus vat will be payable.</li>

            <li>If you do not attend a webinar then there will be an additional charge of &pound;69 plus vat if you wish to attend on another date.</li>

            <li>You can cancel your booking within 14 days of us receiving it provided that you have not participated in any live webinars and have not accessed the recorded modules or the assessments. If you have done any of the above or cancel after 14 days then the full fee is payable.</li>

            <li>You can transfer your booking to another colleague in your organisation only if you have not participated in any live webinars and have not accessed the recorded modules or the assessments.</li>

            <li>For the avoidance of doubt, you cannot transfer your booking to a colleague or cancel the booking after you have started the course.</li>

            <li>The full fee remains payable even if the course is not completed.</li>

            <li>Our courses are tailored towards an audience of people who are employed by organisations who deal with personal data and FOI requests. We reserve the right to refuse any bookings especially from consultants and those in the business of information rights training.</li>

        </ol>
        <div class="accept-style">
            <mat-checkbox formControlName="invoice_tc" class="example-margin"
                          (change)="termConditions($event.checked)">Please tick to accept the terms and conditions.</mat-checkbox>
        </div>
    </div>
    <div class="text-center">
        <button mat-button color="primary" class="book-btn text-center" [disabled]="!bookingForm.valid || (acceptedTC==false)">Submit</button>
    </div>

</form>
