import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { StatsComponent } from './stats/stats.component';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [DashboardComponent, ComingSoonComponent, StatsComponent],
  imports: [
    CommonModule,
      DashboardRoutingModule,
      MatExpansionModule
    ],
    exports: [
        StatsComponent
    ],
})
export class DashboardModule { }
