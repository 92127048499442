<!--<header id="page-topbar" style="    background-color: #702c91 !important;">
    <div class="navbar-header">
        <div class="d-flex">-->
<!-- LOGO -->
<!--<div class="navbar-brand-box">
        <a href="#" class="logo logo-dark">
            <span class="logo-sm">
                <img src="assets/images/logo-sm.png" height="20px" alt="logo">
            </span>
            <span class="logo-lg">
                <img src="assets/images/loginlogo.png" alt="logo" class="dashboard-logo">
            </span>
        </a>

        <a href="#" class="logo logo-light">
            <span class="logo-sm">
                <img src="assets/images/logo-sm-light.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="20">
            </span>
        </a>
    </div>

    <button (click)="menuChange()" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
        <i class="mdi mdi-backburger text-white"></i>
    </button>


</div>

<div class="d-flex">

    <div class="dropdown d-inline-block d-lg-none ml-2">
        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
             aria-labelledby="page-header-search-dropdown">
        </div>
    </div>



    <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="rounded-circle header-profile-user mr-2" src="assets/images/logo-sm.png" alt="Header Avatar">
            <span class="d-none d-sm-inline-block ml-1 text-white">{{user.name}}</span>
            <i class="mdi mdi-chevron-down d-none d-sm-inline-block text-white"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">-->
<!-- item-->
<!--<a class="dropdown-item" href="javascript: void(0);"><i class="mdi mdi-face-profile font-size-16 align-middle mr-1"></i> My Account</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()" ><i class="mdi mdi-logout font-size-16 align-middle mr-1"></i> Logout</a>
                </div>
            </div>

        </div>
    </div>

</header>-->
<!--<mat-tab-group (selectedTabChange)="yourFn($event)" mat-align-tabs="start">
    <mat-tab label="Courses">
        <ng-template matTabContent>-->
<!--<app-course-details></app-course-details>-->
<!--</ng-template>
</mat-tab>

<mat-tab label="Delegates">
    <ng-template matTabContent>-->
<!--<app-view-delegate></app-view-delegate>-->
<!--</ng-template>
</mat-tab>
<mat-tab label="Schedule">-->
<!--<app-course-details></app-course-details>-->
<!--</mat-tab>
</mat-tab-group>-->



<div class="row page-header hide-for-print">
    <div class="col-lg-6">
        Welcome, <strong>{{user.name}}</strong>
        <br />
        <strong class="user-role">
            <span>{{user.role}}</span>
        </strong>
        <span (click)="logoutConfirmation()" class="cursor-pointer material-icons">
            logout
        </span>
    </div>
    <div class="col-lg-6">
        <div class="card no-border no-radius">
            <div class="card-body text-right an-logo-parent">
                <img id="logoDiv" class="img-responsive an-logo" src="assets\images\loginlogo.png" />

            </div>
        </div>
    </div>

</div>
<div class="page-header-second hide-for-print"></div>



<!--search-->
<!--<div class="row mt-minus-5  page-header">
    <div class="col-lg-6">

    </div>
    <div class="col-lg-6">
        <div class="card no-border no-radius search-bg">
            <div class="card-body text-right an-filter-parent">

                <form class="example-form">
                    <div class="row">

                        <div class="col-lg-4 an-search">
                            <mat-form-field class="example-full-width">
                                <mat-label>Search</mat-label>

                                <input type="search" matInput placeholder="">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-1">
                            <button class="book-btn pull-left filter-apply">
                                Apply
                            </button>
                        </div>

                        <div class="col-lg-7 tog-buttons">

                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                <mat-button-toggle value="all" (change)="getFilterToggleValue('all')" [checked]="filterToggleAll" class="toggle-color">All</mat-button-toggle>
                                <mat-button-toggle value="7 Days" (change)="getFilterToggleValue('7 Days')">7 Days</mat-button-toggle>
                                <mat-button-toggle value="14 Days" (change)="getFilterToggleValue('14 Days')">14 Days</mat-button-toggle>
                                <mat-button-toggle value="28 Days" (change)="getFilterToggleValue('28 Days')" [checked]="filterToggleDraft">28 Days</mat-button-toggle>
                            </mat-button-toggle-group>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>
<div class="page-header-second2"></div>-->


<mat-tab-group (selectedTabChange)="gotoMainMenu($event)" [selectedIndex]="tabIndex" mat-align-tabs="start" *ngIf="isShowMenu == true">



    <mat-tab class="text-second strong" label="Dashboard">
        <ng-template matTabContent>
            <div class="pt-4 p-5">
                <app-stats (gotoCourseDetails)="gotoCourseDetails($event)"></app-stats>
            </div>
            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>


    <mat-tab class="text-second strong" label="Courses">
        <ng-template matTabContent>

            <div *ngIf="course_titles==true" class="pt-4 p-5">
                <app-course-titles-list (gotoMimeo)="gotoMimeo()" (gotoAllCourses)="allCourses()"></app-course-titles-list>
            </div>

            <div *ngIf="all_courses == true" class="pt-4 p-5">
                <app-courses (gotoMimeo)="gotoMimeo()" (gotoMainMenu)="gotoCourseDetails($event)" (gotoCourseTitles)="gotoCourseTitles()"></app-courses>
            </div>

            <div *ngIf="course_titles==false && course_detail==true" class="pt-3 p-5">
                <app-course-details [detailData]="outCourse" (gotoAllCourses)="allCourses()"></app-course-details>
            </div>

            <div *ngIf="mimeo==true" class="pt-3 p-5">
                <app-mimeo (gotoAllCourses)="allCourses()" (gotoCourseTitles)="gotoCourseTitles()"></app-mimeo>
            </div>
            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>


    <mat-tab class="text-second strong" label="Delegates">
        <ng-template matTabContent>

            <div *ngIf="delegate_detail==false" class="pt-4 p-5">
                <app-view-delegate (gotoMainMenu)="gotoDelegateDetails($event)"></app-view-delegate>
            </div>

            <div *ngIf="delegate_detail==true" class="pt-4 p-5">
                <app-delegate-details [delegateDetail]="outDelegateID" (gotoAllDelegates)="allDelegates()" (backNavigation)="backNavigation()"></app-delegate-details>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>


    <mat-tab class="text-second strong" label="Inhouse">
        <ng-template matTabContent>

            <div *ngIf="inhouse_detail==false" class="pt-4 p-5">
                <app-organisations (gotoInhouseDetails)="gotoInhouseDetails($event)" [showUpcomingCourses]="outShowUpcomingCourses"></app-organisations>
            </div>

            <!--<div *ngIf="inhouse_detail==false" class="pt-4 p-5">
                <app-inhouse-list (gotoInhouseDetails)="gotoInhouseDetails($event)" [inhouseDetailById]="outInhouseID"></app-inhouse-list>
            </div>-->

            <div *ngIf="inhouse_detail==true" class="pt-4 p-5">
                <app-inhouse-detail [inhouseDetail]="outInhouseID" (gotoAllInhouse)="gotoAllInhouse($event)" (backNavigation)="backNavigation()"></app-inhouse-detail>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>



    <mat-tab class="text-second strong" label="Finance">
        <ng-template matTabContent>

            <div *ngIf="invoiceList == true" class="pt-4 p-5">
                <app-invoices (gotoDelegateDetails)="gotoDelegateDetailsFromInvoice($event)"
                              (gotoSuppliersList)="gotoSuppliersList()"
                              (gotoInvoicesReceived)="gotoInvoicesReceived()"
                              (gotoDebtCollection)="gotoDebtCollection()"
                              (gotoCreditNotes)="gotoCreditNotes()"
                              (gotoXeroInvoices)="gotoXeroInvoices()"></app-invoices>
            </div>

            <div *ngIf="suppliersList == true" class="pt-4 p-5">
                <app-suppliers (gotoSupplierDetails)="gotoSupplierDetails($event)"
                               (gotoInvoicesReceived)="gotoInvoicesReceived()"
                               (gotoInvoicesIssued)="gotoInvoicesIssued()"
                               (gotoDebtCollection)="gotoDebtCollection()"
                               (gotoCreditNotes)="gotoCreditNotes()"
                               (gotoXeroInvoices)="gotoXeroInvoices()"></app-suppliers>
            </div>

            <div *ngIf="received_invoices == true" class="pt-4 p-5">
                <app-invoices-received (gotoSupplierDetails)="gotoSupplierDetails($event)"
                                       (gotoSuppliersList)="gotoSuppliersList()"
                                       (gotoInvoicesReceived)="gotoInvoicesReceived()"
                                       (gotoInvoicesIssued)="gotoInvoicesIssued()"
                                       (gotoDebtCollection)="gotoDebtCollection()"
                                       (gotoCreditNotes)="gotoCreditNotes()"
                                       (gotoXeroInvoices)="gotoXeroInvoices()"></app-invoices-received>
            </div>

            <div *ngIf="supplierDetails == true" class="pt-4 p-5">
                <app-supplier-details [supplierId]="outSupplierId"
                                      (gotoSuppliersList)="gotoSuppliersList()"
                                      (gotoInvoicesReceived)="gotoInvoicesReceived()"
                                      (backNavigation)="backNavigation()"></app-supplier-details>
            </div>

            <div *ngIf="xero_invoices == true" class="pt-4 p-5">
                <app-xero-invoices (gotoSuppliersList)="gotoSuppliersList()"
                                   (gotoInvoicesReceived)="gotoInvoicesReceived()"
                                   (gotoCreditNotes)="gotoCreditNotes()"
                                   (gotoInvoicesIssued)="gotoInvoicesIssued()"></app-xero-invoices>
            </div>

            <div *ngIf="debtCollection == true" class="pt-4 p-5">
                <app-debt-collection (gotoDelegateDetails)="gotoDelegateDetailsFromInvoice($event)"
                                     (gotoSupplierDetails)="gotoSupplierDetails($event)"
                                     (gotoSuppliersList)="gotoSuppliersList()"
                                     (gotoInvoicesReceived)="gotoInvoicesReceived()"
                                     (gotoInvoicesIssued)="gotoInvoicesIssued()"
                                     (gotoCreditNotes)="gotoCreditNotes()"
                                     (gotoXeroInvoices)="gotoXeroInvoices()"></app-debt-collection>
            </div>

            <div *ngIf="creditNotes == true" class="pt-4 p-5">
                <app-credit-notes (gotoSuppliersList)="gotoSuppliersList()"
                                     (gotoInvoicesReceived)="gotoInvoicesReceived()"
                                     (gotoInvoicesIssued)="gotoInvoicesIssued()"
                                  (gotoDebtCollection)="gotoDebtCollection()"
                                     (gotoXeroInvoices)="gotoXeroInvoices()"></app-credit-notes>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>



    <mat-tab class="text-second strong" label="Schedule">
        <ng-template matTabContent>

            <div class="pt-4 p-5" *ngIf="monthlySchedule == true">
                <app-view-schedule (gotoSchedule)="gotoSchedule($event)"></app-view-schedule>
            </div>

            <div class="pt-4 p-5" *ngIf="yearlySchedule == true">
                <app-yearly-schedule (gotoSchedule)="gotoSchedule($event)"></app-yearly-schedule>
            </div>

            <div class="pt-4 p-5" *ngIf="courseScheduler == true">
                <app-course-scheduler (gotoSchedule)="gotoSchedule($event)"></app-course-scheduler>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>


    <!--<mat-tab class="text-second strong" label="Yearly Schedule">
        <ng-template matTabContent>

            <div class="pt-4 p-5">
                <app-yearly-schedule></app-yearly-schedule>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>-->



    <mat-tab class="text-second strong" label="Admin">
        <ng-template matTabContent>

            <div class="pt-4 p-5">
                <app-upload-courses *ngIf="showAdminTab == 'uploadCourse'" (gotoAdminTab)="gotoAdminTab($event)"></app-upload-courses>

                <app-speakers-list *ngIf="showAdminTab == 'speakerList'" (gotoAdminTab)="gotoAdminTab($event)" (gotoSpeakerDetails)="gotoSpeakerDetails($event)"></app-speakers-list>

                <!--<app-debt-collection *ngIf="showAdminTab == 'debtCollection'" (gotoAdminTab)="gotoAdminTab($event)" (gotoDelegateDetails)="gotoDelegateDetailsFromInvoice($event)"></app-debt-collection>-->

                <app-speaker-course-numbers *ngIf="showAdminTab == 'speakerCourseNumbers'" (gotoAdminTab)="gotoAdminTab($event)" (gotoSpeakerDetails)="gotoSpeakerDetails($event)"></app-speaker-course-numbers>

                <app-speaker-details *ngIf="showAdminTab == 'speakerDetails'" [speakerId]="outSpeakerId" (gotoAdminTab)="gotoAdminTab($event)"></app-speaker-details>


            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>



    <mat-tab class="text-second strong" label="Products">
        <ng-template matTabContent>

            <div *ngIf="product_bookings == false" class="pt-4 p-5">
                <app-product-list (gotoProductBookings)="gotoProductBookings($event)"></app-product-list>
            </div>

            <div *ngIf="product_bookings == true" class="pt-4 p-5">
                <app-product-bookings [productBookingParam]="outProductBookingParam"
                                      (gotoDelegateDetails)="gotoDelegateDetailsFromProductBooking($event)"
                                      (gotoProductsList)="gotoProductsList()">
                </app-product-bookings>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>



    <!--<mat-tab class="text-second strong" label="Upload">
        <ng-template matTabContent>

            <div class="pt-4 p-5">
                <app-upload-courses></app-upload-courses>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>

    <mat-tab class="text-second strong" label="Speakers">
        <ng-template matTabContent>

            <div class="pt-4 p-5">
                <app-speakers-list></app-speakers-list>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>

    <mat-tab class="text-second strong" label="Reports">
        <ng-template matTabContent>

            <div class="pt-4 p-5">
                <app-debt-collection (gotoDelegateDetails)="gotoDelegateDetailsFromInvoice($event)"></app-debt-collection>
            </div>

            <div class="footer-top"></div>
        </ng-template>
    </mat-tab>-->


</mat-tab-group>


<div class="pt-4 p-5" *ngIf="courseScheduler == true && isShowMenu == false">
    <app-course-scheduler></app-course-scheduler>
</div>



<div class="message-box animated fadeIn" data-sound="alert" id="mb-signout">
    <div class="mb-container">
        <div class="mb-middle">
            <div class="mb-title"><span class="fa fa-sign-out"></span>Log <strong>Out</strong> ?</div>
            <div class="mb-content">
                <p>Are you sure you want to log out?</p>
                <p>Press No if youwant to continue work. Press Yes to logout current user.</p>
            </div>

            <div class="mb-footer">
                <div class="pull-right">

                    <a class="btn btn-success-logout btn-lg text-white" (click)="logout()">Yes</a>
                    <button class="btn btn-default-logout btn-lg mb-control-close" (click)="doContinue()">No</button>
                </div>
            </div>
        </div>
    </div>
</div>