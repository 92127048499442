<div class="modal-body text-center">
    <img src="assets/images/menu-icons/success.png" class="img-responsive mb-4">
    <h3 class="text-colr mb-4">Transaction Successful</h3>
    <div class="form-group row" *ngFor="let item of summary">
        <h6 for="example-text-input" class="col-md-5 col-form-label"> {{item.caption}}</h6>
        <div class="col-md-7" style="text-align:initial">
            <label for="example-text-input" class="col-form-label text-colr"> {{item.value}}</label>
        </div>
        <hr>
    </div>

    <button type="button" (click)="close()" mat-dialog-close class="btn btn-primary w-100 mt-3">
        Ok
    </button>

</div>