import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ProductRoutingModule } from './product-routing.module';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductBookingsComponent } from './product-bookings/product-bookings.component';
import { AddEditProductComponent } from './add-edit-product/add-edit-product.component';
import { ProductActionsComponent } from './product-actions/product-actions.component';
import { ProductClickableParentComponent } from './product-clickable-parent/product-clickable-parent.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        ProductListComponent,
        ProductBookingsComponent,
        AddEditProductComponent,
        ProductActionsComponent,
        ProductClickableParentComponent
    ],
    imports: [
        CommonModule,
        ProductRoutingModule,
        AgGridModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [
        ProductListComponent,
        ProductBookingsComponent,
        ProductActionsComponent,
        ProductClickableParentComponent
    ]
})
export class ProductModule { }
