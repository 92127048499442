import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import jsPDF from 'jspdf';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';
import { ClickableParentComponent } from '../../../shared/_components/edit/clickable-parent/clickable-parent.component';
import { AddEditCreditNoteComponent } from '../add-edit-credit-note/add-edit-credit-note.component';

@Component({
  selector: 'app-credit-notes',
  templateUrl: './credit-notes.component.html',
  styleUrls: ['./credit-notes.component.css']
})
export class CreditNotesComponent implements OnInit {

    //@Output() gotoMainMenu = new EventEmitter<void>();
    //@Output() gotoAllCourses = new EventEmitter<void>();

    _paramOut = {
        gotoAdmin: ""
    }

    @Output() gotoAdminTab = new EventEmitter<object>();
    @Output() gotoDelegateDetails = new EventEmitter<object>();

    @Output() gotoSuppliersList = new EventEmitter<void>();
    @Output() gotoInvoicesIssued = new EventEmitter<void>();
    @Output() gotoDebtCollection = new EventEmitter<void>();
    @Output() gotoXeroInvoices = new EventEmitter<void>();
    @Output() gotoInvoicesReceived = new EventEmitter<void>();

    CN_List: any;
    cnDownload: any = [];
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 50;


    constructor(
        private modal: NgbModal,
        //private http: HttpClient,
        private _delegateService: DelegateService,
        //private _route: Router
    ) {

        this.gridOptions = {
            suppressCellSelection: true,
            context: {
                componentParent: this
            }
        };

        

        this.domLayout = "autoHeight";
    }

    ngOnInit(): void {

        localStorage.setItem('projectPageSize', '50');
        this.getCreditNotesList();
    }

    gotoAdmin(param) {
        this._paramOut.gotoAdmin = param;
        this.gotoAdminTab.emit(this._paramOut);
    }

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'dateAdded', headerName: 'Created Date', valueFormatter: this.convertStringToDate },
        { field: 'name', headerName: 'Name', valueFormatter: this.emptyFormatter },
        { field: 'organization', headerName: 'Organisation' },
        { field: 'email', headerName: 'Email', valueFormatter: this.emptyFormatter },
        { field: 'telephone', headerName: 'Telephone', valueFormatter: this.emptyFormatter },
        { field: 'creditNoteNumber', headerName: 'Credit Note Number' },
        { field: 'invoiceNumber', headerName: 'Invoice Number' },        
        {
            field: 'Action', cellRendererFramework: ClickableParentComponent, cellRendererParams: { module: 'credit note' },
            sortable: false, filter: false, width: 120, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];


    emptyFormatter(params) {

        if (params.value == null || params.value == "")
            return '-';
        else return params.value;
    }

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        else return "";
        //else return new Date().toString();
    }

    
    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (params.value == true)
            return "<div class='paid status-circles mt-10px'></div>";
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;



    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#cnGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#cnGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        debugger;

        //if (e.colDef.field != "Action") {
        //    this._paramOut.gotoAdmin = "speakerDetails";
        //    this._paramOut.id = e.data.speakerId;
        //    this.gotoAdminTab.emit(this._paramOut);
        //}
        this.gotoDelegateDetails.emit({ id: e.data.speakerId });

    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }




    

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getCreditNotesList() {


        this._delegateService.getCreditNotesList().subscribe((result: any) => {

            if (result.success == true) {

                this.CN_List = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditCreditNoteComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {

            this.getCreditNotesList();
        }, (reason) => {

            //console.log('Dismissed action: ' + reason);
        });
    }

    openModal(param) {
        const modalRef = this.modal.open(AddEditCreditNoteComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getCreditNotesList());
    }

    delete(id) {
        
        if (id > 0) {
            this._delegateService.deleteCreditNote(id).subscribe((result: any) => {

                if (result.success == true) {
                    this.getCreditNotesList()

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    gotoInvoicesReceivedList() {
        
        this.gotoInvoicesReceived.emit();
    }

    gotoInvoicesIssuedList() {
        this.gotoInvoicesIssued.emit();
    }

    gotoXeroInvoicesList() {

        this.gotoXeroInvoices.emit();
    }

    gotoDebtCollectionList() {
        this.gotoDebtCollection.emit();
    }

    gotoSuppliers() {
        this.gotoSuppliersList.emit();
    }

    @ViewChild('printSection', { static: false }) el!: ElementRef

    download(param:any) {
        debugger;

        this.cnDownload = param;
        setTimeout(() => {


            $("#section-to-print").css("display", "block");

            let pdf = new jsPDF('p', 'px', 'a4', true);


            pdf.setFontSize(8);


            pdf.html(this.el.nativeElement, {

                callback: (pdf) => {
                    pdf.save(param.invoiceNumber + '.pdf');
                },
                margin: [15, 15, 15, 15],
                autoPaging: 'text',
                x: 25,
                y: 25,
                width: 350, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            })

            setTimeout(() => {
                $("#section-to-print").css("display", "none");
            }, 4000);
        }, 1000);
    }
}
