import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsModule } from './_components/layouts/layouts.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpVerificationComponent } from './_components/otp-verification/otp-verification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CreatePinComponent } from './_components/create-pin/create-pin.component';
import { ValidatePinComponent } from './_components/validate-pin/validate-pin.component';
import { NumberOnlyDirective } from './_directives/number-only.directive';
import { SuccessComponent } from './_components/success/success.component';
import { ErrorComponent } from './_components/error/error.component';
import { ShowMsgComponent } from './_components/show-msg/show-msg.component';
import { SuccessSummaryComponent } from './_components/success-summary/success-summary.component';
import { DeleteComponent } from './_components/delete/delete.component';
import { DashboardModule } from '../core/dashboard/dashboard.module';
import { UnauthoriseComponent } from './_components/unauthorise/unauthorise.component';
import { TvScreenComponent } from './_components/tv-screen/tv-screen.component';
import { ClickableParentComponent } from './_components/edit/clickable-parent/clickable-parent.component';
import { ActionsComponent } from './_components/edit/actions/actions.component';


@NgModule({
    declarations: [OtpVerificationComponent,
        CreatePinComponent,
        ValidatePinComponent,
        NumberOnlyDirective,
        SuccessComponent,
        ErrorComponent,
        ShowMsgComponent,
        SuccessSummaryComponent,
        DeleteComponent,
        UnauthoriseComponent,
        TvScreenComponent,
        ClickableParentComponent,
        ActionsComponent
    ],
    imports: [
        CommonModule,
        LayoutsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatCardModule,
        MatIconModule,
        FormsModule,
        
    ],
    exports: [
        NumberOnlyDirective,
        ClickableParentComponent,
        ActionsComponent
    ]
})
export class SharedModule { }
