import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CoursesService {

    baseurl: string;
    token: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') base: string) {
        this.baseurl = base;
        this.token = 'Bearer ' + localStorage.getItem('Token');
    }

    public get currentUserValue(): any {
        return "";// this.currentUserSubject;
    }

    upload(uploadFiles: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.token
        });
        return this.http.post(this.baseurl + '/Upload/UploadFiles', uploadFiles, {
            reportProgress: true,
            observe: 'events'
        });
    }

    getScheduleData(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetScheduleData',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    companiesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'x-api-key': 'KjTFKEGi7R11DuvLSPaV16IJLfTPCkKV3qowOXtE',
            'Access-Control-Allow-Origin': '*/*',
            'Authorization': 'API Key'
        });

        debugger;
        return this.http.get<any>('https://angular-exercise.trunarrative.cloud/TruProxyAPI/rest/Companies/v1/Officers?CompanyNumber=10241297', { headers })
            .pipe(map(resp => {

                debugger;
                return resp;
            }));
    }

    getCoursesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCoursesList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCoursesListBySubject(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetCoursesListBySubject', req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getUnassignedCoursesDates(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetUnassignedCoursesDates', req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    sendCourseSchedulerLink(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/SendCourseSchedulerLink', req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    assignSpeakerToCourse(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/AssignSpeakerToCourse', req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    sendReviewRequestToAdmin() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/SendReviewRequestToAdmin', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCoursesListWithId() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCoursesListWithId', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    getTVUpcomingCoursesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetTVUpcomingCoursesList', { headers })
            .pipe(map(resp => {

                return resp;
            }));
    }

    getUpcomingCoursesList(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetUpcomingCoursesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));


        //return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetUpcomingCoursesList', { headers })
        //    .pipe(map(resp => {
        //        return resp;
        //    }));
    }

    getAllCoursesList(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetAllCoursesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
        //return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetAllCoursesList', { headers })
        //    .pipe(map(resp => {
        //        return resp;
        //    }));
    }

    getArchivedCoursesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetArchivedCoursesList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getUpcomingCoursesReport() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetUpcomingCoursesReport', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getSpeakersList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetSpeakersList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getMimeoUsersList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetMimeoUsersList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getHotelsList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetHotelsList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getHotelRoomsList(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetHotelRoomsList',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateSpeaker(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateSpeaker',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    updateMaterialSent(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateMaterialSent',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateDelegateMaterialSent(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateDelegateMaterialSent',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateDelegatePayment(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateDelegatePayment',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateFoundationCertificate(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateFoundationCertificate',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }
    addDelegateToCourse(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/AddDelegateToCourse',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    createMeetingLink(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/CreateMeeting',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCourseTitleById(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCourseTitleById',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getHotelsListByLocation(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetHotelsListByLocation',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getLocationsList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetLocationsList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCourseTypesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCourseTypesList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    getSubjectsList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetSubjectsList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCurrentCourseList(title: any) {
        
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCurrentCourseList',
            {
                params: {
                    id: title.value
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getUnassignedCoursesList(req: any) {
        
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetUnassignedCoursesList', req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCurrentCourseViewList(title: any, id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        // response is different

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCurrentCourseViewList',
            {
                params: {
                    title: title.value,
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getYearlySchedule(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetYearlyScheduleData',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    // need to add this service in controller
    getSpeakersByCourse(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/GetSpeakersByCourse',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateCourseSchedule(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateCourseSchedule',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateCourseToAssignSchedule(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateCourseToAssignSchedule',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateCourseData(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateCourseData',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertCourseData(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/InsertCourseData',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteCourseSchedule(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/DeleteCourseSchedule',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteCourse(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/DeleteCourse',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteSpeaker(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/DeleteSpeaker',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteCourseTitle(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/DeleteCourseTitle',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }


    cancelCourse(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/CancelCourse',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }





    //updateCourseData
    updateRooms(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/UpdateRooms',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getCourseTitlesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetCourseTitlesList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    addEditCourseTitle(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/AddEditCourseTitle',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    addEditSpeaker(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Courses/v1/AddEditSpeaker',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }
}
