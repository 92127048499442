<div class="modal-body text-center">

    <!--<button (click)="CloseOTP()" type="button" class="close">&times;</button>-->
    <!--<button *ngIf="intervalFlag" (click)="CloseOTP()" type="button" class="close">&times;</button>-->
    <mat-icon *ngIf="intervalFlag" (click)="CloseOTP()" class="close">close</mat-icon>
    <img src="assets/images/accept.svg" class="img-responsive mb-4">
    <h4 class="text-colr">PIN Creation</h4>
    <p>{{text}}</p>
    <!--<input type="text" class="form-control"  (keypress)="isNumeric?FormValidators.numberOnly($event):''">-->
    <input type="text" appNumberOnly class="form-control" placeholder="Enter Pin" required="" [(ngModel)]="otpValue" [maxLength]="maxLength">

    <input type="text" appNumberOnly class="form-control mt-3" placeholder="Re-Enter Pin" required="" [(ngModel)]="otpValue" [maxLength]="maxLength">


    <select class="form-control mt-3">
        <option value="">Select Secret Question</option>
    </select>

    <div class="modal-footer">

        <button [disabled]="!intervalFlag" type="button" (click)="ResendOTP()" class="btn btn-success">
            Resend OTP
        </button>

        <button type="button" (click)="send()" [disabled]="otpValue == null || otpValue == undefined || otpValue == '' " class="btn btn-primary">
            Send Now
        </button>
        <p class="counter " *ngIf="!intervalFlag">{{timeLeft}} </p>

    </div>
</div>