import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DelegateService } from '../../../common/_services/delegate-services';
import { AddEditInhouseComponent } from '../../inhouse/add-edit-inhouse/add-edit-inhouse.component';
import { Location } from '@angular/common';
import jsPDF from 'jspdf';

@Component({
    selector: 'app-product-bookings',
    templateUrl: './product-bookings.component.html',
    styleUrls: ['./product-bookings.component.css']
})
export class ProductBookingsComponent implements OnInit {

    productList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "Latest Product Bookings";
    paginationPageSize: any = 10;

    @Input() productBookingParam: any;

    @Output() gotoProductsList = new EventEmitter<void>();
    @Output() gotoDelegateDetails = new EventEmitter<void>();

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'dateAdded', headerName: 'Booking Date', width: 200, valueFormatter: this.convertStringToDate },
        { field: 'invoice.invoiceNumber', headerName: 'Invoice#' },
        { field: 'delegateData.name', headerName: 'Delegate' },
        { field: 'delegateData.email', headerName: 'Email' },
        { field: 'delegateData.organization', headerName: 'Organisation' },
        { field: 'delegateData.telephone', headerName: 'Telephone' },
        { field: 'productTitle', headerName: 'Product Title', width: 200 },
        { field: 'invoice.isInvoicePaid', headerName: 'Paid', width: 100, cellRenderer: params => this.reconcileCellRendererFunc(params.value) },
        { field: 'invoice.isInvoicePaid', headerName: 'Status', width: 100, cellRenderer: params => this.getProductStatus(params) },

    ];


    reconcileCellRendererFunc(paid) {

        
        if (paid == true)
            return "<div class='green status-circles mt-10px'></div>";
        else return "<div class='red status-circles mt-10px'></div>";
    }

    getProductStatus(prod) {

        
        //if (prod.data.invoice != null) {
        //    const givenDate = new Date(prod.data.invoice.dateAdded);
        //    const today = new Date();
        //    const timeDiff = today.getTime() - givenDate.getTime();
        //    const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24)));

        //    const status = this.getColor(daysDiff);


        //    if (status == 'green')
        //        return "<div class='green status-circles mt-10px'></div>";
        //    else if (status == 'amber')
        //        return "<div class='amber status-circles mt-10px'></div>";
        //    else if (status == 'red')
        //        return "<div class='red status-circles mt-10px'></div>";
        //    else if (status == 'redborder-simple')
        //        return "<div class='debt-red status-circles mt-10px'></div>";
        //    else "";
        //}

        var status = 'red';

        // product is paid
        if (prod.data.invoice.isInvoicePaid == true) {
            status = 'green';
        }
        else {
            status = this.getColor(this.getDaysCount(prod.data.invoice.dateAdded));
        }

        if (status == 'green')
            return "<div class='green status-circles mt-10px'></div>";
        else if (status == 'amber')
            return "<div class='amber status-circles mt-10px'></div>";
        else if (status == 'red')
            return "<div class='red status-circles mt-10px'></div>";
        else if (status == 'redborder-simple')
            return "<div class='debt-red status-circles mt-10px'></div>";
        else "";


    }

    getColor(value) {
        
        if (value < 28) {
            return 'green';
        } else if (value > 28 && value <= 49) {
            return 'amber';
        } else if (value > 63) {
            return 'red';
        } else if (value > 49) {
            return 'redborder-simple';
        }
        else
            return 'green';
    }

    //DAYS COUNT
    getDaysCount(dateString): string {
        
        const givenDate = new Date(dateString);
        const today = new Date();
        const timeDiff = today.getTime() - givenDate.getTime();
        const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24)));
        return daysDiff.toString();
    }



    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        else return "";
        //else return new Date().toString();
    }



    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        private _route: Router,
        private location: Location,
        private _commonService: CommonServiceService,
        private _delegateService: DelegateService) {

        _commonService.setBackNavigation();
        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        this.gridOptions = {
            suppressCellSelection: true
        };

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#productBookingGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#productBookingGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        //if (e.colDef.field != "Action")

        this.gotoDelegateDetails.emit(e.data);
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }

    ngOnInit(): void {
        localStorage.setItem('projectPageSize', '10');

        //if (this.productBookingParam.allBookings == true) {
        //    this.getProductBookingsList(true);
        //    //this._delegateId = this.delegateDetail;

        //    ////this._delegateId = localStorage.getItem('delegateId');
        //    //if (Number(this._delegateId) > 0) {
        //    //    this.getDelegateData();
        //    //}
        //}
        //else

        if (this.productBookingParam.allBookings) {
            this.listTitle = "Latest Product Bookings";
        }
        else {
            this.listTitle = "Product Bookings";
        }

        debugger;
        this.getProductBookingsList(this.productBookingParam);

    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    getProductBookingsList(data) {
        this._delegateService.getPurchasesList(data).subscribe((result: any) => {

            if (result.success == true) {


                this.productList = result.data;
                
                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }



    addNew() {
        const modalRef = this.modal.open(AddEditInhouseComponent, {
            size: 'lg'
        });
    }

    gotoProducts() {
        this.gotoProductsList.emit();
    }

    gotoBookings() {
        var req = { allBookings: true, id: 0 }

        this.listTitle = "Latest Product Bookings";
        this.location.go('/product/bookings?type=true');
        this._commonService.setBackNavigation();

        this.getProductBookingsList(req);
    }
}
