import { CalendarEventAction } from "angular-calendar";

export class BaseRequest {
    public dev = new ClientDeviceInfo();
    public loc = new DeviceLocation();
    public user = new Users();
    public act = new Act();
    public custInfo = new Object();
    public args: string[];
}


export class ClientDeviceInfo {
    public Chl: number;
    public App: number;
    public Ver: string;
    public Imei1: string;
    public Imei2: string;
    public SrNo: string;
    public IP: string;
    public Net: number;
    public OsVer: string;
    public Model: string;
    public Make: string;
}

export class DeviceLocation {
    public BtsId: string;
    public BtsLoc: string;
    public Src: string;
    public Lat: string;
    public Long: string;
}

export class Users {
    public ABit: string;
    public Act: [1, 2, 3, 4, 5, 6, 7, 8, 9];
    public Bid: string;
    public Lslt: string | null;
    public Luat: string | null;
    public Rid: string | null;
    public Sid: string | null;
    public SidN: string;
    public Uid: number;
    public Unm: string | null;
    public CBit: number;
    public pwd: string;
    public Npwd: string;
    public Mpn: string;
}

export class Act {
    public Typ: number;
    public Lvl: number;
    public Tid: string;
    public date: string;
    public nid: string;
    public otp: string;
    public otpid: string;
    public Tdata = new Object();
    public Bdata = new Object();
    public bio = new bio();
}

export class Tdata {
    public AccType: number;
    public CNIC: string;
    public ContactNo: string;
}

export class bio {
    public ImageType: number;
    public Area: number;
    public FingerNumber: number;
    public AcquiredAfis: string;
}

export enum levels {
    zero = 0,
    one = 1,
    two,
    three,
    four
}

export const userRoles = {
    SuperAdmin: { value: 1, name: "SuperAdmin" },
    Admin: { value: 2, name: "Admin" },
    Employee: { value: 3, name: "Employee" },
    Guest: { value: 4, name: "Guest" },
    ViewOnly: { value: 5, name: "ViewOnly" },
    TVScreen: { value: 6, name: "TVScreen" },
    Speaker: { value: 7, name: "Speaker" }
}

export class LoginUserDetail {
    public name: string | null;
    public balance: string | null;
    public Sid: string | null;
    public Uid: any;
    public Unm: string | null;
    public Act: any;
}

export enum ActivitiesList {
    LOGIN = 1,
    LOGOUT = 2,
    AGENT_REGISTRATION = 5,
    BALANCE_INQUIRY = 6,
    CASH_DEPOSIT = 7,
    CASH_WITHDRAWAL = 8,
    CREATE_PIN = 9,
    VIEW_LIMITS = 10,
    BILL_PAYMENT = 11,
    BILL_PAYMENT_POSTPAID = 12,
    MOBILE_TOP_UP = 13,
    AGENT_TRANSFER = 14,
    FRANCHISE_TRANSFER = 15,
    RECENT_TRANSACTIONS_HISTORY = 16,
    SEND_MONEY_TRN = 17,
    SEND_MONEY_WALLET = 18,
    RECEIVE_MONEY_TRN = 19,
    HELP = 20,
}

export class CalendarSchedule {
    id?: string | number;
    start: Date;
    end?: Date;
    title: string;
    color?: string;
    actions?: CalendarEventAction[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;


}