//import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, ColumnApi, ColumnResizedEvent, Grid, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DelegateService } from '../../../common/_services/delegate-services';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { ClickableParentComponent } from '../../../shared/_components/edit/clickable-parent/clickable-parent.component';
import { AddEditOrganisationComponent } from './add-edit-organisation/add-edit-organisation.component';
//import { AddEditInhouseComponent } from '../add-edit-inhouse/add-edit-inhouse.component';
//import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-organisations',
    templateUrl: './organisations.component.html',
    styleUrls: ['./organisations.component.css']
})
export class OrganisationsComponent implements OnInit {

    organisationList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;
    title: any = "Organisations";
    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 50;

    @Input() showUpcomingCourses: any;

    @Output() gotoInhouseDetails = new EventEmitter<void>();

    // Each Column Definition results in one Column.
    //public columnDefs: ColDef[];
    public columnDefs: ColDef[] = [
        { field: 'organisationName', headerName: 'Organisation' },
        { field: 'contactName', headerName: 'Contact Name' },
        { field: 'contactEmail', headerName: 'Contact Email' },
        //{ field: 'telephone', headerName: 'Telephone' },
        { field: 'postcode', headerName: 'Postcode' },
        //{ field: 'paid', headerName: 'Paid', width: 100, cellRenderer: this.reconcileCellRendererFunc },
        {
            field: 'Action', cellRendererFramework: ClickableParentComponent,
            sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }

    ];

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (params.value == true)
            return "<div class='paid status-circles mt-10px'></div>";
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        //private http: HttpClient,
        private _delegateService: DelegateService,
        private _commonService: CommonServiceService,
        private location: Location,
    ) {
        debugger;
        _commonService.setBackNavigation();
        //this.location.go('/inhouse/list');

        this.gridOptions = {
            context: {
                componentParent: this
            },
            //defaultColDef: {
            //    resizable: true,
            //},
            rowClass: 'grid-row',
            suppressCellSelection: true,

        };

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#organisationGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#organisationGrid") as HTMLElement;
        elm.style.height = '535px';
        //elm.style.width = '100%';
        
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        
        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        if (e.colDef.field != "Action")
            this.gotoInhouseDetails.emit(e.data);
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }

    upcomingCourses() {
        this.title = "Upcoming Courses";
        this.location.go('/inhouse/list/u');


        this._commonService.setBackNavigation();

        var req = {
            isUpcoming: true
        }

        this._delegateService.getOrganisationList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.organisationList = result.data;



                // Each Column Definition results in one Column.
                this.columnDefs = [
                    { field: 'trainingDate', headerName: 'Training Date', valueFormatter: this.convertStringToDate },
                    { field: 'organisationName', headerName: 'Organisation' },
                    { field: 'trainerName', headerName: 'Trainer' },
                    { field: 'contactName', headerName: 'Contact Name' },
                    //{ field: 'telephone', headerName: 'Telephone' },
                    { field: 'subject', headerName: 'Subject' },

                    {
                        field: 'Action', cellRendererFramework: ClickableParentComponent,
                        sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
                    }

                ];

                //this.gridOptions.columnDefs = this.columnDefs;



                this.gridOptions.onColumnResized = (params: ColumnResizedEvent) => {
                    
                    this.gridOptions.api!.sizeColumnsToFit();
                    console.log(params);
                };

                this.gridOptions.api!.sizeColumnsToFit();

                //this.setFixedHeight();
                //this.gridOptions.api!.sizeColumnsToFit();

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }


    ngOnInit(): void {
        debugger;
        localStorage.setItem('projectPageSize', '50');
        
        if (this.showUpcomingCourses) {
            this.upcomingCourses();
        }
        else
            this.getOrganisationList(false);
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getOrganisationList(isUpcoming) {

        this.location.go('/inhouse/list/o');
        this._commonService.setBackNavigation();
        
        this.title = "Organisations";

        var req = {
            isUpcoming: false
        }

        this._delegateService.getOrganisationList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.organisationList = result.data;

                this.columnDefs = [
                    { field: 'organisationName', headerName: 'Organisation' },
                    { field: 'contactName', headerName: 'Contact Name' },
                    { field: 'contactEmail', headerName: 'Contact Email' },
                    { field: 'telephone', headerName: 'Telephone' },
                    { field: 'postcode', headerName: 'Postcode' },
                    //{ field: 'paid', headerName: 'Paid', width: 100, cellRenderer: this.reconcileCellRendererFunc },
                    {
                        field: 'Action', cellRendererFramework: ClickableParentComponent,
                        sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
                    }

                ];




                //this.gridOptions.api!.sizeColumnsToFit();



                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditOrganisationComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {
            this.getOrganisationList(false);
        }, (reason) => {
            
            //console.log('Dismissed action: ' + reason);
        });
    }

    openModal(param) {

        const modalRef = this.modal.open(AddEditOrganisationComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getOrganisationList(false));
    }


    delete(id) {
        if (id > 0) {
            this._delegateService.deleteOrganisation(id).subscribe((result: any) => {

                if (result.success == true) {
                    this.getOrganisationList(false)

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

}
