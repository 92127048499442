<!--<div class="app-admin-wrap layout-sidebar-large">
    <div>-->
<!--<app-top-menu></app-top-menu>-->
<!--</div>
    <div class="side-content-wrap">
        <app-side-menu></app-side-menu>
    </div>
    <app-footer></app-footer>
</div>-->



<div id="layout-wrapper">

    <app-top-menu></app-top-menu>

    <!--<app-side-menu></app-side-menu>-->
    <!--<app-an-header></app-an-header>-->
    <router-outlet></router-outlet>
    
    <app-footer></app-footer>

</div>
