import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-validate-pin',
  templateUrl: './validate-pin.component.html',
  styleUrls: ['./validate-pin.component.css']
})
export class ValidatePinComponent implements OnInit {

    maxLength: number;
    mPin: string;
    placeholder: string = "";
    text: string;
    otpForm: FormGroup;
    //  private subscription: Subscription;


    @Output() Validatepin: EventEmitter<any> = new EventEmitter<any>();
    @Output() Close = new EventEmitter<any>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
      
        this.text = "Please enter your PIN";
        this.maxLength = 4;

        for (var i = 0; i < this.maxLength; i++) {
            this.placeholder += "x";
        }

        //this.subscription = this.alertService.OtpClearObservable$.subscribe((res) => {
        //    this.otpValue = "";
        //});
    }

    send() {
        this.Validatepin.emit(this.mPin);
    }

    CloseOTP() {
        this.Close.emit("Close");
    }

}
