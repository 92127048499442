

<div class="row">
    <div class="col-lg-6">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Speakers Courses Number</span>
        </h2>
    </div>
    <div class="col-lg-6 pt-1">

        <div class="pull-right">


            <mat-form-field class="example-form-field mr-2" appearance="fill">
                <mat-label>Duration</mat-label>
                <mat-date-range-input [formGroup]="coursesCountDateFilter"
                                      [rangePicker]="coursesCountDateFilterPicker">
                    <input matStartDate placeholder="Start date" formControlName="start">
                    <input matEndDate placeholder="End date" formControlName="end" (dateChange)="addEvent($event)">
                </mat-date-range-input>
                <!--<mat-hint>MM/DD/YYYY � MM/DD/YYYY</mat-hint>-->
                <mat-datepicker-toggle matSuffix [for]="coursesCountDateFilterPicker"></mat-datepicker-toggle>
                <mat-date-range-picker #coursesCountDateFilterPicker></mat-date-range-picker>
            </mat-form-field>


            <div class="pull-right">
                <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                    Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="gotoAdmin('speakerList')">Speaker List</button>
                    <!--<button mat-menu-item (click)="gotoAdmin('debtCollection')">Debt Collection</button>-->
                    <button mat-menu-item (click)="gotoAdmin('uploadCourse')">Upload Course Schedule</button>
                </mat-menu>
            </div>
        </div>




    </div>

</div>





<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>


<ag-grid-angular #agGrid
                 id="speakersGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="speakersList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">

</ag-grid-angular>