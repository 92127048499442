import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {


    @Output() closeError = new EventEmitter<any>();

    public Text: string;
    public Status: string;
    constructor(public dialogRef: MatDialogRef<SuccessComponent>,
        //@Optional() is used to prevent error if no data is passed
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        
        //console.log(data);

        this.Text = data["desc"];
        //this.Status = data["status"];
    }

    ngOnInit() {

    }

    //genericMessasge() {
    //    this.closeGenericMessasge.emit({ Yes: CodeStatus.OK })
    //}

    close() {
        this.dialogRef.close(true);
    }

}
