import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';
import { FinanceService } from '../../../../common/_services/finance-services';
import { DeleteComponent } from '../../../../shared/_components/delete/delete.component';

@Component({
  selector: 'app-invoice-received-clickable-parent',
    template: `<app-invoice-received-actions (onClicked)="clicked($event)" [cell]="cell"></app-invoice-received-actions>`,
    styleUrls: ['./invoice-received-clickable-parent.component.css']
})
export class InvoiceReceivedClickableParentComponent implements OnInit {

    private params: any;
    public cell: any;
    public _refDialog: any;
    public url = environment.apiURL;
    @Output() openModal = new EventEmitter<any>();
    @Output() refreshGrid = new EventEmitter();

    agInit(params: any): void {
        //
        this.params = params;
        this.cell = { row: params.value, col: params.colDef.headerName, id: params.data.courseId };

        //console.log(this.params.context.componentParent);
    }

    constructor(//private _router: Router,
        //private modal: NgbModal,
        private _dialog: MatDialog,
        private _financeService: FinanceService
    ) {

    }



    public clicked(data: any): void {
        //
        debugger;
        if (data.Type == 'view')
            location.href = "view-course/" + data.Cell.id;
        //this._router.navigateByUrl("view-project/" + data.Cell.pid);

        if (data.Type == 'edit') {
            this.params.context.componentParent.openModal(this.params.data);
        }
        
        if (data.Type == 'delete')
            this.params.context.componentParent.deleteSupplierInvoice(this.params.data);
            //this.delete(this.params.data.supplierId);

        if (data.Type == 'sendInvoice')
            this.params.context.componentParent.sendInvoice(this.params.data);
    }


    


    ngOnInit() {
    }

    refresh(): boolean {
        return false;
    }

}
