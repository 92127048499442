import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUserService } from '../../../../../common/_services/auth-user.service';
import { Location } from '@angular/common';
import { CourseDetailsComponent } from '../../../../../core/courses/course-details/course-details.component';
import { AuthenticationService } from '../../../../../common/guards/AuthenticationService';
import { TabIndex } from '../../../../../common/validation/messages';
import { userRoles } from '../../../../../common/model/request';
import { CommonServiceService } from '../../../../../common/_services/common-service.service';


@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
    user: any;
    tabIndex: any = 0;
    all_courses: boolean = false;
    course_titles: boolean = false;
    inhouse_detail: boolean = false;
    mimeo: boolean = false;

    isShowMenu: boolean = true;


    monthlySchedule: boolean = false;
    yearlySchedule: boolean = false;
    courseScheduler: boolean = false;

    //isCoursesUpload: boolean = false;
    //isSpeakers: boolean = false;
    //isDebt: boolean = false;

    showAdminTab: any = "speakerList";

    goBackProductId: any = 0;
    delegate_detail: boolean = false;
    outDelegateID: any;
    outInhouseID: any;
    outSupplierId: any;
    outProductBookingParam: any;
    outSpeakerId: any;

    delegate_detailForInvoice: boolean = false;

    delegate_detailForProductBooking: boolean = false;

    gobacktoProductBookings: boolean = false;
    gobacktoIndividualProductBookings: boolean = false;
    gobacktoInvoicesList: boolean = false;
    gobacktoDebtCollection: boolean = false;

    course_detail: boolean = false;
    product_bookings: boolean = false;
    received_invoices: boolean = false;
    debtCollection: boolean = false;
    supplierDetails: boolean = false;
    invoiceList: boolean = false;
    suppliersList: boolean = false;
    xero_invoices: boolean = false;
    creditNotes: boolean = false;

    outShowUpcomingCourses: boolean = true;

    outCourse: any = {
        id: 0,
        title: ""
    };
    _params: any;

    @ViewChild(CourseDetailsComponent, { static: true }) child: CourseDetailsComponent;

    constructor(private _router: Router,
        //private _route: Router,
        private _authService: AuthUserService,
        private _auth: AuthenticationService,
        private location: Location,
        private route: ActivatedRoute,
        private _commonService: CommonServiceService) {


        this.route.queryParamMap
            .subscribe((params) => {
                this._params = { ...params.keys, ...params };
                this.outDelegateID = +this._params.params.delegateId;
                this.outCourse.id = +this._params.params.cid;
                this.outInhouseID = +this._params.params.inhouseId;
                this.outSupplierId = +this._params.params.supplierId;
                this.outSpeakerId = +this._params.params.id
            });


        //if (this._delegateId == null || this._delegateId == undefined
        //    || this._delegateId == "null" || Number.isNaN(this._delegateId)) {
        //    this._delegateId = 0; // user will add new delegate
        //}

        if (this._auth.userRole() == userRoles.Speaker.name) {

            this.isShowMenu = false;
        }

        this.populate();
    }

    ngOnInit() {

        this.user = {
            role: this._auth.userRole(),
            name: localStorage.getItem("userName")

        }

        //this.child.callMe('Calling from the parent!');
    }


    populate() {

        //DASHBOARD
        if (window.location.pathname == "/home/dashboard") {

            this.tabIndex = TabIndex.Dashboard;
        }

        //COURSE
        else if (window.location.pathname == "/courses/course-titles") {
            this.disableCourseTabs();

            this.course_titles = true;
            this.tabIndex = TabIndex.Courses;
        }
        else if (window.location.pathname == "/courses/all-courses") {
            this.disableCourseTabs();

            this.all_courses = true;
            this.tabIndex = TabIndex.Courses;
        }
        else if (window.location.pathname == "/courses/course-detail") {
            this.disableCourseTabs();

            this.course_detail = true;
            //this.course_titles = false;
            this.tabIndex = TabIndex.Courses;
        }
        else if (window.location.pathname == "/courses/mimeo") {
            this.disableCourseTabs();

            //this.course_detail = false;
            //this.course_titles = false;
            this.mimeo = true;
            this.tabIndex = TabIndex.Courses;
        }

        //PRODUCTS
        else if (window.location.pathname == "/product/list") {
            this.tabIndex = TabIndex.Products;
        }
        else if (window.location.pathname == "/product/bookings") {
            this.product_bookings = true;
            this.tabIndex = TabIndex.Products;
        }


        //DELEGATE
        else if (window.location.pathname == "/delegates/all-delegates") {
            this.tabIndex = TabIndex.Delegates;
        }
        else if (window.location.pathname == "/delegates/delegate-details") {
            this.delegate_detail = true;
            this.tabIndex = TabIndex.Delegates;
        }

        //INHOUSE
        else if (window.location.pathname == "/inhouse/list") {

            this.tabIndex = TabIndex.Inhouse;
        }
        else if (window.location.pathname == "/inhouse/details") {
            this.inhouse_detail = true;
            this.tabIndex = TabIndex.Inhouse;
        }

        //INVOICE
        else if (window.location.pathname == "/finance/all-invoices") {
            this.disableFinanceTabs();
            //this.supplierDetails = false;
            this.invoiceList = true;
            //this.suppliersList = false;
            this.tabIndex = TabIndex.Finance;
            //this.tabIndex = TabIndex.Invoices;
        }

        //MONTHLY SCHEDULE
        else if (window.location.pathname == "/schedules/schedule") {

            this.courseScheduler = false;

            this.monthlySchedule = true;
            this.yearlySchedule = false;

            this.tabIndex = TabIndex.Schedule;
        }

        //YEARLY SCHEDULE
        else if (window.location.pathname == "/schedules/yearly-schedule") {
            this.courseScheduler = false;
            this.monthlySchedule = false;
            this.yearlySchedule = true;

            this.tabIndex = TabIndex.Schedule;
        }

        //COURSE SCHEDULER        
        else if (window.location.pathname == "/schedules/course-scheduler") {
            this.courseScheduler = true;
            this.monthlySchedule = false;
            this.yearlySchedule = false;

            this.tabIndex = TabIndex.Schedule;
        }

        //FINANCE
        else if (window.location.pathname == "/finance/suppliers-list") {
            this.disableFinanceTabs();

            //this.supplierDetails = false;
            //this.invoiceList = false;
            this.suppliersList = true;

            this.tabIndex = TabIndex.Finance;
        }
        else if (window.location.pathname == "/finance/received-invoices") {
            this.disableFinanceTabs();
            this.received_invoices = true;
            this.tabIndex = TabIndex.Finance;
        }
        else if (window.location.pathname == "/finance/invoices-list") {
            this.disableFinanceTabs();
            this.invoiceList = true;
            this.tabIndex = TabIndex.Finance;
        }
        else if (window.location.pathname == "/finance/supplier-details") {
            this.disableFinanceTabs();
            //this.invoiceList = false;
            this.supplierDetails = true;
            this.tabIndex = TabIndex.Finance;
        }
        else if (window.location.pathname == "/finance/xero-invoices") {
            this.disableFinanceTabs();
            this.xero_invoices = true;
            this.tabIndex = TabIndex.Finance;
        }
        else if (window.location.pathname == "/finance/debt-collection") {
            this.disableFinanceTabs();
            this.debtCollection = true;
            this.tabIndex = TabIndex.Finance;
        }
        else if (window.location.pathname == "/finance/credit-notes") {
            this.disableFinanceTabs();
            this.creditNotes = true;
            this.tabIndex = TabIndex.Finance;
        }



        //UPLOAD COURSE
        else if (window.location.pathname == "/admin/upload-courses") {

            this.showAdminTab = "uploadCourse";
            //this.isCoursesUpload = true;
            //this.isDebt = false;
            //this.isSpeakers = false;

            this.tabIndex = TabIndex.Admin;
        }

        //SPEAKERS
        else if (window.location.pathname == "/admin/speakers") {

            this.showAdminTab = "speakerList";
            //this.isCoursesUpload = false;
            //this.isDebt = false;
            //this.isSpeakers = true;

            this.tabIndex = TabIndex.Admin;
        }
        else if (window.location.pathname == "/admin/speaker/details") {
            this.showAdminTab = "speakerDetails";
            this.tabIndex = TabIndex.Admin;
        }

        //REPORTS
        //// debt collection report
        //else if (window.location.pathname == "/admin/report/debt-collection") {

        //    this.showAdminTab = "debtCollection";
        //    //this.isCoursesUpload = false;
        //    //this.isDebt = true;
        //    //this.isSpeakers = false;

        //    this.tabIndex = TabIndex.Admin;
        //}

        // total number of speakers courses
        else if (window.location.pathname == "/admin/report/speakerCourses") {

            this.showAdminTab = "speakerCourseNumbers";

            this.tabIndex = TabIndex.Admin;
        }

    }

    disableCourseTabs() {
        this.mimeo = false;
        this.all_courses = false;
        this.course_titles = false;
        this.course_detail = false;
        this.inhouse_detail = false;
    }

    disableFinanceTabs() {
        this.invoiceList = false;
        this.supplierDetails = false;
        this.xero_invoices = false;
        this.suppliersList = false;
        this.received_invoices = false;
        this.debtCollection = false;
        this.creditNotes = false;

        this.gobacktoDebtCollection = false;
        this.gobacktoInvoicesList = false;
    }

    yourFn(event: any) {
        //this.tabIndex = event.index;
    }

    menuChange() {
        var scrollhide = document.getElementById('sidebar-menu');
        if (document.body.classList.contains('vertical-collpsed')) {
            document.body.className = "";
            if (scrollhide) {
                scrollhide.style.height = "100%";
                scrollhide.style.overflow = "hidden scroll";
            }
        } else {
            document.body.className = "sidebar-enable";
            document.body.className = "vertical-collpsed";
            if (scrollhide) {
                scrollhide.style.height = "";
                scrollhide.style.overflow = "";
            }
        }
    }

    doContinue() {
        $(".message-box").hide();
    }

    logout() {
        this._authService.logout();
        this._router.navigateByUrl("/account/login");
    }

    logoutConfirmation() {
        $(".message-box").show();
    }

    filterToggleAll: boolean = true;
    filterTogglePrevious: boolean = false;
    filterToggleCurrent: boolean = false;
    filterToggleDraft: boolean = false;
    toggleFilterValue: any = "all";
    isHVRVisible = false;
    isReadOnlyVisible = false;

    getFilterToggleValue(val: any) {
        this.filterToggleAll = false;
        //this.filterToggleDraft = false;
        this.toggleFilterValue = val;

        // call filter
        this.onSubmit();
    }

    onSubmit() {
        //
    }

    gotoDelegateDetailsFromInvoice(e: any) {
        debugger;
        if (e.organisationId == null) {
            this.tabIndex = TabIndex.Delegates;
            this.delegate_detail = true;
            this.delegate_detailForInvoice = true;
            this.outDelegateID = e.id;
            this.location.go('/delegates/delegate-details?delegateId=' + e.id);
        }
        else {
            this.gotoInhouseDetails({ id: e.organisationId });
        }
    }



    gotoDelegateDetailsFromProductBooking(e: any) {

        this.tabIndex = TabIndex.Delegates;
        this.goBackProductId = e.productId;
        this.delegate_detail = true;
        this.delegate_detailForProductBooking = true;
        this.outDelegateID = e.delegateId;
        this.location.go('/delegates/delegate-details?delegateId=' + e.delegateId);

    }

    gotoDelegateDetails(e: any) {

        this.tabIndex = TabIndex.Delegates;
        this.delegate_detail = true;
        this.outDelegateID = e.id;
        this.location.go('/delegates/delegate-details?delegateId=' + e.id);
        //var obj = {
        //    index: 3
        //}

        //localStorage.setItem("delegateId", e.id);

        //this.gotoMainMenu(obj);
    }

    gotoMimeo() {
        this.disableCourseTabs();
        this.mimeo = true;
        this.location.go('/courses/mimeo');
    }

    gotoCourseTitles() {
        this.disableCourseTabs();
        this.course_titles = true;
        this.location.go('/courses/course-titles');
    }


    gotoInhouseDetails(e: any) {
        this.tabIndex = TabIndex.Inhouse;
        this.inhouse_detail = true;
        this.outInhouseID = e.id;
        this.location.go('/inhouse/details?organisationId=' + e.id);

    }




    gotoProductBookings(e: any) {
        this.product_bookings = true;
        this.outProductBookingParam = e;
        this.location.go('/product/bookings?type=' + e.allBookings);
    }

    gotoProductsList() {
        this.product_bookings = false;
        this.location.go('/product/list');

    }

    gotoSuppliersList() {
        this.disableFinanceTabs();

        this.suppliersList = true;

        this.location.go('/finance/suppliers-list');

    }

    gotoInvoicesReceived() {
        this.disableFinanceTabs();

        this.received_invoices = true;

        this.location.go('/finance/received-invoices');
    }

    gotoDebtCollection() {
        this.disableFinanceTabs();

        this.debtCollection = true;

        this.location.go('/finance/debt-collection');

    }

    gotoCreditNotes() {
        this.disableFinanceTabs();

        this.creditNotes = true;

        this.location.go('/finance/credit-notes');

    }

    gotoXeroInvoices() {

        this.disableFinanceTabs();
        this.xero_invoices = true;
        this.location.go('/finance/xero-invoices');
    }


    gotoSchedule(e: any) {
        if (e.showYearlySchedule == true) {
            this.monthlySchedule = false;
            this.yearlySchedule = true;
            this.courseScheduler = false;
            this.location.go('/schedules/yearly-schedule');
        }
        else if (e.showMonthlySchedule == true) {
            this.monthlySchedule = true;
            this.yearlySchedule = false;
            this.courseScheduler = false;
            this.location.go('/schedules/schedule');
        }
        else if (e.showSpeakersSchedule == true) {
            this.monthlySchedule = false;
            this.yearlySchedule = false;

            this.courseScheduler = true;

            this.location.go('/schedules/course-scheduler');
        }
    }


    gotoSpeakerDetails(e: any) {

        this.tabIndex = TabIndex.Admin;

        this.outSpeakerId = e.id;

        this.showAdminTab = "speakerDetails";
        this.location.go('/admin/speaker/details?id=' + e.id);

    }

    gotoAdminTab(e: any) {

        this.showAdminTab = e.gotoAdmin;

        if (e.gotoAdmin == "uploadCourse")
            this.location.go('/admin/upload-courses');

        else if (e.gotoAdmin == "speakerList")
            this.location.go('/admin/speakers');

        //else if (e.gotoAdmin == "debtCollection")
        //    this.location.go('/admin/report/debt-collection');

        else if (e.gotoAdmin == "speakerCourseNumbers")
            this.location.go('/admin/report/speakerCourses');

        //else if (e.gotoAdmin == "speakerDetails")
        //    this.location.go('/admin/speaker/details?id=' + e.id);

    }


    gotoSupplierDetails(e: any) {

        this.disableFinanceTabs();

        this.supplierDetails = true;

        this.outSupplierId = e.supplierId;
        this.location.go('/finance/supplier-details?supplierId=' + e.supplierId);
    }


    gotoAllInhouse(e: any) {

        this.outShowUpcomingCourses = e;
        this.inhouse_detail = false;
        this.location.go('/inhouse/list');

    }

    backNavigation() {

        debugger;
        var previousPath = localStorage.getItem("PREVIOUS_PATH");

        if (previousPath != null && previousPath != "" && previousPath != undefined) {
            if (previousPath == '/finance/invoices-list') {
                this.gobacktoInvoicesList = true;
                this.tabIndex = TabIndex.Finance;
                //this.gotoInvoicesIssued();
            }
            else if (previousPath == '/finance/debt-collection') {
                this.gobacktoDebtCollection = true;
                this.tabIndex = TabIndex.Finance;
                //this.gotoInvoicesIssued();
            }
            else if (previousPath == '/product/bookings?type=true') {
                this.gotoProductBookings({ allBookings: true, id: 0 });
                this.gobacktoProductBookings = true;
                this.gobacktoIndividualProductBookings = false;
                this.tabIndex = TabIndex.Products;
            }
            else if (previousPath == '/product/bookings?type=false') {
                this.gotoProductBookings({ allBookings: false, id: this.goBackProductId });
                this.gobacktoIndividualProductBookings = true;
                this.gobacktoProductBookings = false;
                this.tabIndex = TabIndex.Products;
            }
            else if (previousPath == '/inhouse/list') {
                this.gotoAllInhouse(true);
            }
            else if (previousPath == '/inhouse/list/o') {
                this.gotoAllInhouse(false);
            }
            else if (previousPath == '/inhouse/list/u') {
                this.gotoAllInhouse(true);
            }
            else
                this._router.navigateByUrl(previousPath);
        }
    }



    allDelegates() {
        this.delegate_detail = false;
        //this.location.go('/delegates/all-delegates');

    }

    gotoInvoicesIssued() {

        this.disableFinanceTabs();
        //this.received_invoices = false;
        //this.supplierDetails = false;
        this.invoiceList = true;
        //this.suppliersList = false;
        this.location.go('/finance/invoices-list');
    }


    gotoCourseDetails(e: any) {
        debugger;
        this.disableCourseTabs();


        this.tabIndex = TabIndex.Courses;

        //var obj = {
        //    index: 1
        //}
        //this.tabIndex = 1;
        //localStorage.setItem("courseId", e.courseId);

        //this.gotoMainMenu(obj);
        this.outCourse = {
            id: e.courseId,
            title: e.title
        };

        if (this.outCourse.title == undefined)
            this.outCourse.title = "";


        if (e.isInhouse == true) {
            this.location.go('/inhouse/details?organisationId=' + e.organisationId + '&rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
            this.inhouse_detail = true;
            this.tabIndex = TabIndex.Inhouse;
            this.gotoInhouseDetails({ id: e.organisationId });

        }
        else {
            this.course_detail = true;
            this.location.go('/courses/course-detail?cid=' + e.courseId + '&rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
        }
        //this.child.testFunction();
    }

    allCourses() {
        this.disableCourseTabs();

        //this.course_titles = false;
        //this.course_detail = false;
        this.all_courses = true;
        this.location.go('/courses/all-courses');
    }

    gotoMainMenu(tab) {


        //this.disableFinanceTabs();



        if (tab.index == TabIndex.Dashboard) {
            this.tabIndex = TabIndex.Dashboard;
            this.location.go('/home/dashboard');
        }
        else if (tab.index == TabIndex.Courses) {
            if (this.course_detail == false) {
                this.disableCourseTabs();

                this.all_courses = true;
                //this.course_detail = false;

                this.tabIndex = TabIndex.Courses;
                this.location.go('/courses/all-courses');
            }
        }
        else if (tab.index == TabIndex.Products) {

            if (this.gobacktoProductBookings == true)
                this.gotoProductBookings({ allBookings: true, id: 0 });
            else if (this.gobacktoIndividualProductBookings == true)
                this.gotoProductBookings({ allBookings: false, id: this.goBackProductId });
            else {
                this.product_bookings = false;
                this.tabIndex = TabIndex.Products;
                this.location.go('/product/list');
            }
        }
        else if (tab.index == TabIndex.Delegates) {


            if (this.delegate_detailForInvoice == true) {
                this.delegate_detail = true;
                //this.delegate_detailForInvoice = false;

            }
            else if (this.delegate_detailForProductBooking == true) {
                this.delegate_detail = true;
                //this.delegate_detailForProductBooking = false;
            }
            else {
                this.delegate_detail = false;
                this.location.go('/delegates/all-delegates');
            }

            this.tabIndex = TabIndex.Delegates;



        }
        else if (tab.index == TabIndex.Inhouse) {


            //this.outShowUpcomingCourses = false;

            if (window.location.pathname == "/inhouse/details") {
                this.inhouse_detail = true;
                this.tabIndex = TabIndex.Inhouse;
            }

            else {
                this.inhouse_detail = false;

                this.tabIndex = TabIndex.Inhouse;
                this.location.go('/inhouse/list');
            }
        }

        //else if (tab.index == TabIndex.Invoices) {
        //    //this.tabIndex = TabIndex.Invoices;
        //    this.tabIndex = TabIndex.Finance;
        //    this.location.go('/invoices/all-invoices');
        //}

        else if (tab.index == TabIndex.Schedule) {
            this.courseScheduler = false;
            this.monthlySchedule = true;
            this.yearlySchedule = false;

            this.tabIndex = TabIndex.Schedule;
            this.location.go('/schedules/schedule');
        }
        //else if (tab.index == TabIndex.YearlySchedule) {
        //    this.tabIndex = TabIndex.YearlySchedule;
        //    this.location.go('/schedules/yearly-schedule');
        //}

        else if (tab.index == TabIndex.Finance) {

            if (this.gobacktoInvoicesList == true) {
                this.gotoInvoicesIssued();
            }
            else if (this.gobacktoDebtCollection == true) {
                this.gotoDebtCollection();
            }
            else {
                this.disableFinanceTabs();
                //this.received_invoices = false;
                //this.supplierDetails = false;
                //this.invoiceList = false;
                this.suppliersList = true;
                this.tabIndex = TabIndex.Finance;
                this.location.go('/finance/suppliers-list');
            }
        }
        else if (tab.index == TabIndex.Admin) {
            this.tabIndex = TabIndex.Admin;

            this.showAdminTab = "speakerList";
            //this.isDebt = false;
            //this.isCoursesUpload = true;
            //this.isSpeakers = false;

            this.location.go('/admin/speakers');
            //this.location.go('/reports/debt-collection');
        }

        //else if (tab.index == TabIndex.Upload) {
        //    this.tabIndex = TabIndex.Upload;
        //    this.location.go('/uploads/courses');
        //}
        //else if (tab.index == TabIndex.Speakers) {
        //    this.tabIndex = TabIndex.Speakers;
        //    this.location.go('/speakers/list');
        //}

        if (this.delegate_detailForInvoice == false &&
            this.delegate_detailForProductBooking == false ) {
            debugger;
            //if (this.inhouse_detail == false) {
            //    debugger;
            //    this._commonService.setBackNavigation();
            //}
            this._commonService.setBackNavigation();
        }
        else {
            this.delegate_detailForInvoice = false;
            this.delegate_detailForProductBooking = false;
            //this.inhouse_detail = false;
        }
    }


    changeTab(tabIndex: number) {
        this.tabIndex = tabIndex;
    }

    //objectKeys = Object.keys;

    //my_menu = {
    //    'main1': [],
    //    'main2': [
    //        {
    //            title: 'sub1',
    //            index:1
    //        },
    //        {
    //            title: 'sub2',
    //            index:2
    //        },
    //        {
    //            title: 'sub3',
    //            index: 3
    //        },
    //    ],
    //};

}
