import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ActivatedRoute, Router } from '@angular/router';
//import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { regExpressions } from '../../../common/validation/messages';
//import { CoursesService } from '../../../common/_services/courses-services';
//import { DelegateService } from '../../../common/_services/delegate-services';


import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
    selector: 'app-add-edit-inhouse',
    templateUrl: './add-edit-inhouse.component.html',
    styleUrls: ['./add-edit-inhouse.component.css']
})
export class AddEditInhouseComponent implements OnInit {

    start_d1: any = new Date();
    submitted = false;
    public _title = "Add Training";
    public locationsList: any;
    inhouseEditForm: FormGroup;
    _params: any;
    _inhouseId: any = 0;
    currentInhouse: any;
    organisationId: any = 0;

    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private _route: Router,
        private route: ActivatedRoute) {


        this.organisationId = 0;

        //this.route.queryParamMap
        //    .subscribe((params) => {
        //        this._params = { ...params.keys, ...params };
        //        this.organisationId = this._params.params.organisationId
        //    });


        //if (this.organisationId == null || this.organisationId == undefined
        //    || this.organisationId == "null" || Number.isNaN(this.organisationId)) {
        //    this.organisationId = 0; // user will add new delegate
        //}


    }

    ngOnInit(): void {

        this.loading = true;

        this.inhouseEditForm = this.formBuilder.group({
            name: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            postcode: [''],
            telephone: [''],
            email: ['', [Validators.required, Validators.pattern(regExpressions.email)]],

            invoiceName: ['', Validators.required],
            invoiceAddress: ['', Validators.required],
            invoiceTelephone: [''],
            purchaseOrder: [''],
            invoiceEmail: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
            invoice_date: [this.start_d1.value, Validators.required],
        });



        if (Object.keys(this.data).length === 0) {
            // this is add case
            this.organisationId = this.data.organisationId;
        }
        else {
            this.isNew = false;
            

            this._inhouseId = this.data.inHouseId;


            if (Number(this._inhouseId) > 0) {
                
                this.currentInhouse = this.data;
                this.populateForm();

                this.organisationId = this.currentInhouse.organisationId;
                
            }


            if (this.data.inHouseId == undefined) {
                this.organisationId = this.data.organisationId;
                this._inhouseId = 0;
            }

        }

        this.loading = false;
    }

    addEvent(event: MatDatepickerInputEvent<Date>) {
        
        if (event.value != null) {

            this.start_d1 = new Date(event.value);
            this.start_d1.setDate(event.value.getDate());
            this.start_d1.setMonth(event.value.getMonth());
            this.start_d1.setFullYear(event.value.getFullYear());

        }

    }

    get f() { return this.inhouseEditForm.controls; }

    populateForm() {
        if (this.currentInhouse != null) {

            this._title = "Edit Training";

            this.inhouseEditForm.controls.name.setValue(this.currentInhouse.contactName);
            //this.inhouseEditForm.controls.organization.setValue(this.currentInhouse.organisation);            
            this.inhouseEditForm.controls.address1.setValue(this.currentInhouse.contactAddress);
            this.inhouseEditForm.controls.postcode.setValue(this.currentInhouse.postcode);
            this.inhouseEditForm.controls.telephone.setValue(this.currentInhouse.telephone);
            this.inhouseEditForm.controls.email.setValue(this.currentInhouse.email);

            this.inhouseEditForm.controls.invoiceName.setValue(this.currentInhouse.invoiceName);
            this.inhouseEditForm.controls.invoiceAddress.setValue(this.currentInhouse.invoiceAddress);
            this.inhouseEditForm.controls.invoiceTelephone.setValue(this.currentInhouse.invoiceTelephone);
            this.inhouseEditForm.controls.purchaseOrder.setValue(this.currentInhouse.purchaseOrder);
            this.inhouseEditForm.controls.invoiceEmail.setValue(this.currentInhouse.invoiceEmail);
            //this.inhouseEditForm.controls.invoice_date.setValue(this.currentInhouse.InvoiceDate);
            
            this.start_d1 = new Date(this.currentInhouse.invoiceDate);
        }
    }



    onSubmit() {

        //if (Number(this.organisationId) > 0) {
        var req = {

            request: {
                InHouseId: Number(this._inhouseId),
                ContactName: this.inhouseEditForm.controls.name.value,
                OrganisationId: Number(this.organisationId),// this.inhouseEditForm.controls.organization.value,
                ContactAddress: this.inhouseEditForm.controls.address1.value,
                //address2: this.inhouseEditForm.controls.address2.value,
                Postcode: this.inhouseEditForm.controls.postcode.value,
                Telephone: this.inhouseEditForm.controls.telephone.value,
                Email: this.inhouseEditForm.controls.email.value,
                //ExpectedTrainingDate: this.inhouseEditForm.controls.startDate_day1.value
                InvoiceName: this.inhouseEditForm.controls.invoiceName.value,
                InvoiceAddress: this.inhouseEditForm.controls.invoiceAddress.value,
                InvoiceTelephone: this.inhouseEditForm.controls.invoiceTelephone.value,
                PurchaseOrder: this.inhouseEditForm.controls.purchaseOrder.value,
                InvoiceEmail: this.inhouseEditForm.controls.invoiceEmail.value,
                InvoiceDate: this.start_d1 //this.inhouseEditForm.controls.invoice_date.value


            }
        };

        this._delegateService.insertInhouse(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
        //}
        //else {
        //    alert("Invalid organisation");
        //}

    }

    close() { }

    //delete(id) {

    //    if (id > 0) {
    //        this._delegateService.deleteDelegate(id).subscribe((result: any) => {

    //            if (result.success == true) {


    //            } else {

    //                //this._commonService.error(result.data.desc);
    //            }

    //        }, (_error: any) => {
    //            //this.loading = false;
    //        })
    //    }
    //}

    //gotoMainMenu(tab) {
    //    if (tab.index == 0) {
    //        this._route.navigateByUrl("/courses/all-courses");
    //    }
    //    else if (tab.index == 2) {
    //        this._route.navigateByUrl("/schedule");
    //    }
    //}

}
