import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404'
    },
    { path: 'upload-courses', component: DashboardComponent },

    { path: 'report/debt-collection', component: DashboardComponent },
    { path: 'report/speakerCourses', component: DashboardComponent },

    { path: 'speakers', component: DashboardComponent },
    { path: 'speaker/details', component: DashboardComponent },


    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
