import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
    selector: 'app-add-edit-delegate',
    templateUrl: './add-edit-delegate.component.html',
    styleUrls: ['./add-edit-delegate.component.css']
})
export class AddEditDelegateComponent implements OnInit {

    public _title = "Add";
    public locationsList: any;
    delegateEditForm: FormGroup;
    _params: any;
    _delegateId: any = 0;
    currentDelegate: any;
    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private _route: Router,
        private _commonService: CommonServiceService,
        private route: ActivatedRoute) {

        this.getLocationsList();

        //this.route.queryParamMap
        //    .subscribe((params) => {
        //        this._params = { ...params.keys, ...params };
        //        this._delegateId = this._params.params.delegateId
        //    });


        //if (this._delegateId == null || this._delegateId == undefined
        //    || this._delegateId == "null" || Number.isNaN(this._delegateId)) {
        //    this._delegateId = 0; // user will add new delegate
        //}
    }

    ngOnInit(): void {

        this.loading = true;

        this.delegateEditForm = this.formBuilder.group({
            name: ['', Validators.required],
            organization: [''],
            sector: [''],
            department: [''],
            address1: ['', Validators.required],
            address2: [''],
            location: [''],
            postcode: [''],
            telephone: [''],
            email: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
            notes: ['']
        });

        //this.getLocationsList();

        //if (Number(this._delegateId) > 0) {
        //    this.getDelegateData();
        //}


        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this._delegateId = this.data.id;

            if (Number(this._delegateId) > 0) {
                //this.getDelegateData();
                this.currentDelegate = this.data;
                this.populateForm();
                //setTimeout(() => {
                //    this.populateForm();
                //}, 1000) 
            }

        }

        this.loading = false;
    }

    getDelegateData() {
        this._delegateService.getDelegateData(Number(this._delegateId)).subscribe((result: any) => {

            if (result.success == true) {

                if (result.data.length > 0) {
                    this.currentDelegate = result.data[0];
                    this.populateForm();
                }
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    get f() { return this.delegateEditForm.controls; }

    populateForm() {
        if (this.currentDelegate != null) {

            
            this._title = "Edit";
            
            this.delegateEditForm.controls.name.setValue(this.currentDelegate.name);
            this.delegateEditForm.controls.organization.setValue(this.currentDelegate.organization);
            this.delegateEditForm.controls.sector.setValue(this.currentDelegate.sector);
            this.delegateEditForm.controls.department.setValue(this.currentDelegate.department);
            this.delegateEditForm.controls.address1.setValue(this.currentDelegate.address1);
            this.delegateEditForm.controls.address2.setValue(this.currentDelegate.address2);
            this.delegateEditForm.controls.postcode.setValue(this.currentDelegate.postcode);
            this.delegateEditForm.controls.telephone.setValue(this.currentDelegate.telephone);
            this.delegateEditForm.controls.email.setValue(this.currentDelegate.email);
            this.delegateEditForm.controls.notes.setValue(this.currentDelegate.specialNotes);
            //this.delegateEditForm.controls.location.patchValue(this.currentDelegate.cityId);
            this.delegateEditForm.controls.location.setValue(this.currentDelegate.cityName);

        }
    }

    getLocationsList() {

        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data

                //this.delegateEditForm.controls.location.patchValue(this.locationsList[0].cityId);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    onSubmit() {

        var req = {

            request: {
                id: Number(this._delegateId),
                name: this.delegateEditForm.controls.name.value,
                organization: this.delegateEditForm.controls.organization.value,
                department: this.delegateEditForm.controls.department.value,
                sector: this.delegateEditForm.controls.sector.value,
                address1: this.delegateEditForm.controls.address1.value,
                address2: this.delegateEditForm.controls.address2.value,
                //cityId: this.delegateEditForm.controls.location.value,
                cityName: this.delegateEditForm.controls.location.value,
                postcode: this.delegateEditForm.controls.postcode.value,
                telephone: this.delegateEditForm.controls.telephone.value,
                email: this.delegateEditForm.controls.email.value,
                specialNotes: this.delegateEditForm.controls.notes.value

            }
        };

        this._delegateService.insertDelegate(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show errror popup
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    close() { }

    delete(id) {

        if (id > 0) {
            this._delegateService.deleteDelegate(id).subscribe((result: any) => {

                if (result.success == true) {


                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    gotoMainMenu(tab) {
        if (tab.index == 0) {
            this._route.navigateByUrl("/courses/all-courses");
        }
        else if (tab.index == 2) {
            this._route.navigateByUrl("/schedule");
        }
    }
}
