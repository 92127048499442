<!--<div class="row mt-10">
    <div class="col-md-4">
        <div class="row mt-10">
            <div class="col-md-12">
                <app-course-details></app-course-details>
                <app-course-speaker-details></app-course-speaker-details>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="row mt-10">
            <div class="col-md-12">
                <app-course-dates-details></app-course-dates-details>
                <app-course-delegate-details></app-course-delegate-details>
            </div>
        </div>
    </div>
</div>-->


<div class="row mt-10">
    <div class="col-md-12">

        <app-course-details></app-course-details>

    </div>
</div>