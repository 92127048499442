
<!--<app-an-header></app-an-header>-->

<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#d4330d" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white">
        Loading...
    </p>
</ngx-spinner>


