import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';
import { CoursesService } from '../../../../common/_services/courses-services';
import { DeleteComponent } from '../../../../shared/_components/delete/delete.component';

@Component({
  selector: 'app-course-title-clickable-parent',
  //templateUrl: './course-title-clickable-parent.component.html',
    template: `<app-course-title-actions (onClicked)="clicked($event)" [cell]="cell"></app-course-title-actions>`,
    styleUrls: ['./course-title-clickable-parent.component.css']
})
export class CourseTitleClickableParentComponent implements OnInit {

    private params: any;
    public cell: any;
    public _refDialog: any;
    public url = environment.apiURL;
    @Output() openModal = new EventEmitter<any>();
    @Output() refreshGrid = new EventEmitter();

    agInit(params: any): void {
        
        this.params = params;
        this.cell = { row: params.value, col: params.colDef.headerName, id: params.data.courseId };

        //console.log(this.params.context.componentParent);
    }

    constructor(
        private _dialog: MatDialog,
        private _courseService: CoursesService) {

    }



    public clicked(data: any): void {
        //
        if (data.Type == 'view')
            location.href = "view-course/" + data.Cell.id;
        //this._router.navigateByUrl("view-project/" + data.Cell.pid);

        if (data.Type == 'edit') {
            
            this.params.context.componentParent.openModal(this.params.data);

        }

        if (data.Type == 'delete')
            this.delete(this.params.data.id);

    }


    delete(id: any) {

        
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {
            
            if (id > 0) {
                this._courseService.deleteCourseTitle(id).subscribe((result: any) => {

                    if (result.success == true) {
                        this.params.context.componentParent.getCourseTitlesList();

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }



        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });

    }


    ngOnInit() {
    }

    refresh(): boolean {
        return false;
    }

}
