<div class="row">
    <div class="col-lg-6">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Mimeo</span>
        </h2>
        <!--REVERT-->
    </div>
    <div class="col-lg-6 pt-1">

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="allCourses()">All Courses</button>
                <button mat-menu-item (click)="courseTitlesList()">Course Titles</button>
                
            </mat-menu>
        </div>



        <!--<button class="book-btn pull-right filter-apply" (click)="addNew()">
            Add new course title
        </button>


        <button class="book-btn pull-right filter-apply mr-1" (click)="allCourses()">
            All courses
        </button>-->

    </div>

</div>