
export class MaskEnum {

    static cnic = "00000-0000000-0";
    static DateFormat = 'dd/MM/yyyy';
    static LatLong = '00.00000000';
    static DateFormatForBackend = 'yyyy-MM-dd';
    static DateFormatDisplay = 'dd/MM/yyyy';
    //static DateFormatDisplay = 'DD/MM/YYYY';
}

export const inputMasks: { [key: string]: any } = {
    cnic: [/\d/, /\d/, '/\d/', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
    msisdn: [/\d/, /\d/, '/\d/', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
};

export const regExpressions: { [key: string]: RegExp } = {
    password: /^(?=.*?[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
    // msisdn: /^(1876)([0-9]{7})$/,
    msisdn: /^(03)([0-9]{9})$/,
    trn: /^()([0-9]{9})$/,
    amount: /^([1-9][0-9]*)$/,
    cnic: /[0-9]{13}$/,
    alphabets: /^[a-zA-Z \-\']+/,

    //////OS Change Set  
    agentUserID: /[0-9]{4}$/,
    agentPin: /[0-9]{4}$/,
    otp: /[0-9]{4}$/,
    franchiseID: /[0-9]{4}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //email: /^[a-zA-Z0-9._.'�%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    refrenceNo: /[a-zA-Z0-9]{14,24}$/,
    ibanno: /[a-zA-Z0-9]{11,24}$/,
    ntn: /[a-zA-Z0-9]{7,19}$/,
    businessNamePattern: /[a-zA-Z0-9.,()!?:/+*=@'_ -]*/,
    forbiddenchars: /^[^#&$]+$/,
    //ibanno: /^(?:[A-Za-z0-9]{5}|[A-Za-z0-9]{9})$/,
    inputCode: /[0-9]{6}$/,
    telephone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
};


export const errorMessages: { [key: string]: string } = {
    msisdn: 'MSISDN must be starts with 1876 and total of 11 digits',
    // msisdn: 'MSISDN must be starts with 13 and total of 11 digits',
    cnic: 'Please enter valid CNIC. CNIC must be 13 digits',
    amount: 'Please enter valid amount.',
    fullName: 'Full name must be between 1 and 128 characters',
    email: 'Email must be a valid email address (username@domain.com)',
    confirmEmail: 'Email addresses must match',
    password: 'Password must be between 7 and 15 characters, and contain at least one uppercase letter, one number and special character',
    confirmPassword: 'Passwords must match',
    trn: 'Please enter valid TRN',

    agentUserID: 'Agent User ID must be 4 digits.',
    franchiseID: 'Franchise ID must be 4 digits.',
    agentPin: 'Agent Pin must be 4 digits.',
    agentPinConfirm: 'Agent Pin must match.',
    refrenceNo: 'Reference number must be entered.',
    termsCondition: "You Must agree with terms and conditions",
    subAgencyAgreement: "You Must agree with subagency agreement",
    ibanno: " Please enter IBAN/Number correctly",
    otp: "OTP must be entered and 5 in length",
    ntn: "NTN number lies between 7 to 19",
    businessNamePattern: "Business name contains alphanumeric with .,()!?:/+*=@'_- only",
    forbiddenchars: "#, $, & are not allowed",
    inputCode: "Invalid code",
    courseTitle: "Course title required",
    startDate: "Invalid date",
    name: "Name required",
    postcode: "Postcode required",
    required: "Invalid data",
    keyRequired: "Secret key required"
};

export enum TabIndex {
    Dashboard = 0,
    Courses,    
    Delegates,
    Inhouse,
    Finance,
    Schedule,
    //YearlySchedule,
    Admin,
    Products,
    Upload,
    Speakers,
    Reports
}

export enum CourseType {
    FullDay = 1,
    Certificates,
    eLearning,
    Products,
    Inhouse,
    Webinar
}

export enum XeroInvoiceStatus {
    ALL = 0,
    DRAFT,
    SUBMITTED,
    DELETED,
    AUTHORISED,
    PAID,
    VOIDED   
}

