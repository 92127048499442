import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoursesService } from '../../../common/_services/courses-services';

@Component({
  selector: 'app-add-edit-speakers',
  templateUrl: './add-edit-speakers.component.html',
  styleUrls: ['./add-edit-speakers.component.css']
})
export class AddEditSpeakersComponent implements OnInit {

    currentDate: any = new FormControl(new Date());
    submitted = false;
    public _title = "Add Speaker";
    public locationsList: any;
    speakerEditForm: FormGroup;
    _params: any;
    _speakerId: any = 0;
    public subjectsList: any;
    
    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService,
        //private _delegateService: DelegateService,
        private _route: Router) {


    }

    ngOnInit(): void {
        
        this.loading = true;

        this.speakerEditForm = this.formBuilder.group({
            speakerName: ['', Validators.required],
            address1: [''],
            initials: [''],
            telephone: [''],
            mobile: [''],
            email: ['', [Validators.required, Validators.email]],
            email1: [''],
            fees: [''],
            subjectIds:['']
        });

        this.getSubjectsList();

        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this.populateForm(this.data);
        }

        

        this.loading = false;
    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {
                
                this.subjectsList = result.data;
            } else {

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    get f() { return this.speakerEditForm.controls; }

    populateForm(__data) {
        
        this._title = "Edit Speaker";
        this._speakerId = __data.speakerId;
        this.speakerEditForm.controls.speakerName.setValue(__data.speakerName);
        this.speakerEditForm.controls.address1.setValue(__data.address);
        this.speakerEditForm.controls.initials.setValue(__data.initials);
        this.speakerEditForm.controls.telephone.setValue(__data.telephoneHome);
        this.speakerEditForm.controls.mobile.setValue(__data.mobileNumber);
        this.speakerEditForm.controls.email.setValue(__data.email1);
        this.speakerEditForm.controls.email1.setValue(__data.email2);
        this.speakerEditForm.controls.fees.setValue(__data.fees);

        this.speakerEditForm.controls.subjectIds.setValue(__data.subjectIds.split(", ").map(Number));

    }


    onSubmit() {

        var req = {
            speaker: {
                SpeakerId: Number(this._speakerId),
                SpeakerName: this.speakerEditForm.controls.speakerName.value,
                address: this.speakerEditForm.controls.address1.value,
                initials: this.speakerEditForm.controls.initials.value,
                telephoneHome: this.speakerEditForm.controls.telephone.value,
                mobileNumber: this.speakerEditForm.controls.mobile.value,
                email1: this.speakerEditForm.controls.email.value,
                email2: this.speakerEditForm.controls.email1.value,
                fees: parseInt(this.speakerEditForm.controls.fees.value),           
                SubjectIds: this.speakerEditForm.controls.subjectIds.value.join(', '),
            }
        };

        
        this._coursesService.addEditSpeaker(req).subscribe((result: any) => {

            
            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show error popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

}
