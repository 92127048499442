


<div class="row">
    <div class="col-lg-3">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">{{listTitle}}</span>
        </h2>

    </div>
    <div class="col-lg-9 pt-1">

        

        <div class="pull-right">

            <mat-form-field appearance="fill" ngDefaultControl class="mr-2">
                <mat-label>Start from</mat-label>
                <input matInput [matDatepicker]="picker_day" placeholder=""
                       [ngModel]="start_date" (dateChange)="addEvent($event)">
                <mat-datepicker-toggle matSuffix [for]="picker_day"></mat-datepicker-toggle>
                <mat-datepicker #picker_day></mat-datepicker>
            </mat-form-field>

            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="mb-2 mr-2">
                <mat-button-toggle value="all" (change)="getFilterToggleValue('all')" [checked]="filterToggleAll" class="toggle-color">Paid</mat-button-toggle>
                <!--<mat-button-toggle value="draft" (change)="getFilterToggleValue('Draft')">Draft</mat-button-toggle>
                <mat-button-toggle value="awaiting approval" (change)="getFilterToggleValue('Awaiting Approval')">Awaiting Approval</mat-button-toggle>
                <mat-button-toggle value="awaiting payment" (change)="getFilterToggleValue('Awaiting Payment')">Awaiting Payment</mat-button-toggle>
                <mat-button-toggle value="paid" (change)="getFilterToggleValue('Paid')">Paid</mat-button-toggle>-->
            </mat-button-toggle-group>



            <button *ngIf="invoicesList.length > 0" class="book-btn mr-2 filter-apply" (click)="syncData()">
                Reconcile
            </button>

            
            <button *ngIf="invoicesList.length > 0" class="book-btn mr-2" [ngClass]="{  'bg-blue-select': isSelectAll == true }" (click)="toggleSelectAll()">{{ selectAllText }}</button>

            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="gotoSuppliersList()">Suppliers List</button>
                <button mat-menu-item (click)="gotoInvoicesReceivedList()">Received Invoices</button>
                <button mat-menu-item (click)="gotoInvoicesIssuedList()">Issued Invoices</button>
                <button mat-menu-item (click)="gotoCreditNotesList()">Credit Notes</button>
            </mat-menu>
        </div>


        <!--<button class="book-btn pull-right filter-apply" (click)="addNewSupplier()">
            Add Supplier
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="gotoInvoicesReceivedList()">
            Invoices Received
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="gotoInvoicesIssuedList()">
            Invoices Issued
        </button>-->
        <!--<button class="book-btn pull-right filter-apply mr-1" (click)="gotoBookingsList(true)">
            All Bookings
        </button>-->

    </div>

</div>


<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>


<ag-grid-angular #agGrid
                 id="supplierGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="invoicesList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize"
                 [frameworkComponents]="frameworkComponents">

</ag-grid-angular>

