<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="inhouseEditForm" (ngSubmit)="onSubmit()" name="inhouseEditForm" autocomplete="off">

        <div class="row">

            <div class="col-6">

                <label class="text-second">Organisation:</label>
                <input type="text" class="form-control no-radius" placeholder="Organisation"
                       required formControlName="organization" maxlength="250" />
            </div>

            <div class="col-6">
                <label class="text-second">Contact Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Contact name"
                       [ngClass]="{  'is-invalid': f.name.errors}"
                       required formControlName="name" maxlength="250" />
                <div *ngIf="f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.pattern">{{errors.name}}</div>
                </div>
            </div>

            <div class="col-12">
                <label class="text-second">Address Line 1:</label>
                <input type="text" class="form-control no-radius" placeholder="Address Line 1"
                       [ngClass]="{  'is-invalid': f.address1.errors}"
                       required formControlName="address1" maxlength="250" />
            </div>

            <div class="col-4">

                <label class="text-second">Postcode:</label>
                <input type="text" class="form-control no-radius" placeholder="Postcode"
                       pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                       [ngClass]="{  'is-invalid': f.postcode.errors}"
                       required formControlName="postcode" maxlength="20" />
                <div *ngIf="f.postcode.errors" class="invalid-feedback">
                    <div *ngIf="f.postcode.errors.pattern">{{errors.postcode}}</div>
                </div>
            </div>

            <div class="col-4">
                <label class="text-second">Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Telephone"
                       formControlName="telephone" maxlength="50" />

            </div>

            <div class="col-4">
                <label class="text-second">Email:</label>
                <input type="text" class="form-control no-radius" placeholder="Email"
                       [ngClass]="{  'is-invalid': f.email.errors && f.email.errors.pattern}"
                       required formControlName="email" maxlength="250" />
                <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.pattern">{{errors.email}}</div>
                </div>
            </div>

            <!--<div class="col-6 mt-3">
            <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                <mat-label>Expected training date</mat-label>
                <input matInput [matDatepicker]="picker_day1" formControlName="startDate_day1" placeholder=""
                       [ngModel]="start_d1"
                       [ngClass]="{'is-invalid':  f.startDate_day1.errors}">
                <mat-datepicker-toggle matSuffix [for]="picker_day1"></mat-datepicker-toggle>
                <mat-datepicker #picker_day1></mat-datepicker>
            </mat-form-field>
        </div>-->

        

            <div class="col-12">

                <div class="row">
                    <div class="col-md-2">
                        <label class="text-second">Notes:</label>
                    </div>
                    <div class="col-md-10 mt-2">
                        <textarea class="form-control  no-radius" formControlName="notes" placeholder="10,000 Characters Limit" maxlength="10000" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">


            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>

            <div class="">
                <button [disabled]="!inhouseEditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>
        </div>

    </form>

    <!--<button class="book-btn" (click)="delete(1)">
        Delete
    </button>-->
</div>

