<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="productEditForm" (ngSubmit)="onSubmit()" name="productEditForm" autocomplete="off">

        <div class="row">

            <div class="col-md-12">

                <label class="text-second">Product Title:</label>
                <input type="text" class="form-control no-radius" placeholder="Product title"
                       [ngClass]="{  'is-invalid': f.p_title.errors}"
                       required formControlName="p_title" maxlength="250" />
                <div *ngIf="f.p_title.errors" class="invalid-feedback">
                    <div *ngIf="f.p_title.errors.pattern">{{errors.name}}</div>
                </div>

            </div>

            <div class="col-3">
                <label class="text-second">Cost:</label>
                <input type="text" class="form-control no-radius" placeholder="Cost"
                       (input)="applyTax($event.target.value)"
                       (keypress)="validateAmount($event, $event.target.value)"
                       formControlName="cost" maxlength="250" />
            </div>

            <div class="col-3">
                <label class="text-second">Total Tax:</label>
                <input type="text" class="form-control no-radius" placeholder="Total Tax"
                       (keypress)="validateAmount($event, $event.target.value)"
                       formControlName="totalTax" maxlength="8" />
                <div *ngIf="f.totalTax.errors" class="invalid-feedback">
                    <div *ngIf="f.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>
            <div class="col-3">
                <label class="text-second">Total:</label>
                <input type="text" class="form-control no-radius" placeholder="Total"
                       (keypress)="validateAmount($event, $event.target.value)"
                       [ngClass]="{  'is-invalid': f.total.errors}"
                       required formControlName="total" maxlength="8" />
                <div *ngIf="f.total.errors" class="invalid-feedback">
                    <div *ngIf="f.total.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-3 mt-3">
                <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                    <mat-label>Expiry date</mat-label>
                    <input matInput [matDatepicker]="picker_day1" formControlName="expiryDate" placeholder=""
                           [ngModel]="currentDate"
                           [ngClass]="{'is-invalid':  submitted && f.expiryDate.errors}">
                    <mat-datepicker-toggle matSuffix [for]="picker_day1"></mat-datepicker-toggle>
                    <mat-datepicker #picker_day1></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="modal-footer">

            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>
            <div class="">
                <button [disabled]="!productEditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>
        </div>

    </form>


</div>

