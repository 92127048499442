<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>






<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>





<div class="p-4">

    <form class="form-horizontal" [formGroup]="cn_EditForm" (ngSubmit)="onSubmit()" name="cn_EditForm" autocomplete="off">

        <div class="row">

            <div class="col-md-12" *ngIf="isEditMode == false">
                <label class="text-second">Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Delegate name"
                       [ngClass]="{  'is-invalid': f.name.errors}"
                       required formControlName="name" maxlength="250" />
                <div *ngIf="f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.pattern">{{errors.name}}</div>
                </div>
            </div>

            <div class="col-md-6" *ngIf="isEditMode == true">
                <label class="text-second">Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Delegate name"
                       [ngClass]="{  'is-invalid': f.name.errors}"
                       required formControlName="name" maxlength="250" />
                <div *ngIf="f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.pattern">{{errors.name}}</div>
                </div>
            </div>

            <div class="col-6" *ngIf="isEditMode == true">
                <label class="text-second">Credit Note Number:</label>
                <input type="text" class="form-control no-radius" placeholder="Credit Note Number"
                       formControlName="cn_number" maxlength="25" />
            </div>

            <div class="col-6">
                <label class="text-second">Organisation:</label>
                <input type="text" class="form-control no-radius" placeholder="Organisation"
                       formControlName="organization" maxlength="250" />
            </div>

            <div class="col-6">
                <label class="text-second">Department:</label>
                <input type="text" class="form-control no-radius" placeholder="Department"
                       formControlName="department" maxlength="250" />
            </div>
            <div class="col-12">
                <label class="text-second">Address:</label>
                <input type="text" class="form-control no-radius" placeholder="Address"
                       [ngClass]="{  'is-invalid': f.address1.errors}"
                       formControlName="address1" maxlength="250" />
            </div>
            <div class="col-4">
                <label class="text-second">City:</label>

                <input type="text" class="form-control no-radius" placeholder="Town/City"
                       [ngClass]="{  'is-invalid': f.location.errors}"
                       formControlName="location" maxlength="250" />
                <div *ngIf="f.location.errors" class="invalid-feedback mt-0">
                    <div *ngIf="f.location.errors.pattern">{{errors.required}}</div>
                </div>
            </div>
            <div class="col-4">
                <label class="text-second">Postcode:</label>
                <input type="text" class="form-control no-radius" placeholder="Postcode"
                       [ngClass]="{  'is-invalid': f.postcode.errors}"
                       formControlName="postcode" maxlength="20" />
                <div *ngIf="f.postcode.errors" class="invalid-feedback">
                    <div *ngIf="f.postcode.errors.pattern">{{errors.postcode}}</div>
                </div>
            </div>
            <div class="col-4">
                <label class="text-second">Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Telephone"
                       formControlName="telephone" maxlength="50" />
            </div>
            <div class="col-6">

                <label class="text-second">Email:</label>
                <input type="text" class="form-control no-radius" placeholder="Email"
                       [ngClass]="{  'is-invalid': f.email.errors && f.email.errors.pattern}"
                       required formControlName="email" maxlength="250" />
                <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.pattern">{{errors.email}}</div>

                </div>

            </div>

            <div class="col-6">

                <label class="text-second">Invoice Number:</label>
                <input type="text" class="form-control no-radius" placeholder="Invoice Number"
                       [ngClass]="{  'is-invalid': f.invoiceNumber.errors}"
                       required formControlName="invoiceNumber" maxlength="250" />
                <div *ngIf="f.invoiceNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.invoiceNumber.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-3">
                <label class="text-second">Subtotal:</label>
                <input type="text" class="form-control no-radius" placeholder="Subtotal"
                       [ngClass]="{  'is-invalid': f.subtotal.errors}"
                       required formControlName="subtotal" maxlength="8"  (input)="applyTax(true);" />
                <div *ngIf="f.subtotal.errors" class="invalid-feedback">
                    <div *ngIf="f.subtotal.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-3">
                <label class="text-second">Total Tax:</label>
                <input type="text" class="form-control no-radius" placeholder="Total Tax"
                       formControlName="totalTax" maxlength="8" (input)="applyTax(false);" />
                <div *ngIf="f.totalTax.errors" class="invalid-feedback">
                    <div *ngIf="f.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-3">
                <label class="text-second">Total:</label>
                <input type="text" class="form-control no-radius" placeholder="Total"
                       [ngClass]="{  'is-invalid': f.total.errors}"
                       required formControlName="total" maxlength="8" />
                <div *ngIf="f.total.errors" class="invalid-feedback">
                    <div *ngIf="f.total.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>


            <div class="col-3">
                <div class="col-md-12  mt-3">
                    <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                        <mat-label>Invoice date</mat-label>
                        <input matInput [matDatepicker]="picker_invoiceDate" formControlName="invoiceDate" placeholder=""
                               [ngModel]="invoiceDate" (dateChange)="addEvent($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker_invoiceDate"></mat-datepicker-toggle>
                        <mat-datepicker #picker_invoiceDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>


            <div class="col-12">

                <div class="row">

                    <div class="col-md-2">
                        <label class="text-second">
                            Description:
                        </label>
                    </div>
                    <div class="col-md-10 mt-2">
                        <textarea class="form-control  no-radius" formControlName="notes" placeholder="10,000 Characters Limit" maxlength="10000" rows="5"></textarea>
                    </div>
                </div>

            </div>
        </div>

        <div class="modal-footer">


            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>

            <div class="">
                <button [disabled]="!cn_EditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>

        </div>

    </form>

</div>

