import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { AuthenticationService } from '../../../common/guards/AuthenticationService';
import { DelegateService } from '../../../common/_services/delegate-services';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { CoursesService } from '../../../common/_services/courses-services';
import { Location } from '@angular/common';
import { DashboardService } from '../../../common/_services/dashboard.service';
import { catchError } from 'rxjs';
import { CourseType } from '../../../common/validation/messages';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

    @ViewChild('modalContentDetails', { static: true }) modalContentDetails: TemplateRef<any>;
    @ViewChild('modalCourseDetails', { static: true }) modalCourseDetails: TemplateRef<any>;
    @Output() gotoCourseDetails = new EventEmitter<void>();
    public _refDialog: any;

    uRole: string = "";
    approvalsList: any = [];
    thisWeekList: any = [];
    notificationsList: any = [];

    an_stats: any;
    _booking: any;
    _course: any;

    step = 0;



    constructor(private authService: AuthenticationService,
        private _delegateService: DelegateService,
        private _dashboardService: DashboardService,
        private modal: NgbModal,
        private _dialog: MatDialog,
        private _router: Router,
        private location: Location
    ) {

        //this.getNotifications();
    }

    ngOnInit(): void {

        this.uRole = this.authService.userRole();

        this.getApprovalsList();
        this.getThisWeekCoursesList();
        this.getStats();
        this.getNotifications();
    }

    public ngAfterViewInit(): void {
        //this.createChartGauge();
        //this.createChartPie();
        //this.createChartColumn();
        //this.createChartLine();
        //this.createChart();
        //this.createChart2();
    }

    getNotifications() {

        // call the notification service
        this._dashboardService.getNotifications().subscribe((result: any) => {

            if (result.success == true) {

                this.notificationsList = result.data

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {

            this._router.navigateByUrl("/unauthorise");
            //this.loading = false;
        })

        this.refreshNotificationsList();
    }

    refreshNotificationsList() {
        setTimeout(() => {

            this.getNotifications();

        }, 600000); // 1000 = 1 second
    }

    actionTaken(_data: any) {

        this._dashboardService.notificationActionTaken(_data)
            .subscribe((result: any) => {


                if (result.success == true) {

                    this.getNotifications();

                } else {
                    //show errror popup

                }

            }, (_error: any) => {
                console.log(_error);
                //this.loading = false;
            })

    }

    getApprovalsList() {
        this._delegateService.getApprovalsList().subscribe((result: any) => {

            if (result.success == true) {

                this.approvalsList = result.data

                //var a = CourseType.Certificates;
                //var name = CourseType[3];
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {

            this._router.navigateByUrl("/unauthorise");
            //this.loading = false;
        })
    }

    getCourseType(type) {
        return CourseType[type];
    }

    getValue(param) {
        if (param == null || param == "")
            return "-";
        else return param;
    }

    getThisWeekCoursesList() {

        this._dashboardService.getThisWeekCoursesList().subscribe((result: any) => {

            if (result.success == true) {

                this.thisWeekList = result.data.filter(i => i.isCancelled === false)

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {

            this._router.navigateByUrl("/unauthorise");
            //this.loading = false;
        })
    }

    getStats() {

        this._dashboardService.getStats().subscribe((result: any) => {

            if (result.success == true) {
                // debugger;
                this.an_stats = result.data;

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {

            this._router.navigateByUrl("/unauthorise");
            //this.loading = false;
        })
    }

    bookingDetail(data: any) {
        this._booking = data;
        this.modal.open(this.modalContentDetails, { size: 'lg' });
    }

    rejectBooking(_data: any) {
        //this._booking = data;


        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to reject this booking?", title: "Reject Booking" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            this._delegateService.rejectBooking(_data)
                .subscribe((result: any) => {


                    if (result.success == true) {

                        this.modal.open(this.modalCourseDetails, { size: 'lg' });
                        this.modal.dismissAll();

                        this.getApprovalsList();

                    } else {
                        //show errror popup

                    }

                }, (_error: any) => {
                    console.log(_error);
                    //this.loading = false;
                })
            //alert("delete");

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    viewCourse(data: any) {
        this._course = data;
        this.modal.open(this.modalCourseDetails, { size: 'lg' });
    }

    courseDetails(data: any) {
        this.gotoCourseDetails.emit(data);

        //this._router.navigateByUrl('/courses/all-courses?cid=' + data.courseId + '&rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
        //this._router.navigate(['/courses/all-courses?rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0]);

        //setTimeout(() => this._router.navigate(["/courses/all-courses"]), 0);
        //setInterval(() => {
        //this._router.navigateByUrl('/courses/course-detail?cid=' + data.courseId);
        //}, 1000);
        //this._router.navigateByUrl('/delegates/delegate-details?delegateId=27&rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
        //this._router.navigate(['/courses/all-courses']);

        //this.location.go('/courses/course-detail?cid=' + data.course_ID);
        //this._router.navigateByUrl('/courses/course-detail?cid=' + data.course_ID);
    }

    approve(_data: any) {
        //this._booking = data;

        //var req = {

        //    webCourseId: this.courseId,
        //    courseName: this.courseName,
        //    courseCost: this.courseCost,
        //    courseDate: this.courseDate,
        //    courseLocation: this.courseLocation,
        //    source: this.bookingForm.controls.source.value,
        //    name: this.bookingForm.controls.name.value,
        //    organization: this.bookingForm.controls.organization.value,
        //    department: this.bookingForm.controls.department.value,
        //    address: this.bookingForm.controls.address.value,
        //    city: this.bookingForm.controls.city.value,
        //    postcode: this.bookingForm.controls.postcode.value,
        //    telephone: this.bookingForm.controls.telephone.value,
        //    email: this.bookingForm.controls.email.value,
        //    sector: this.bookingForm.controls.sector.value,
        //    invoice_name: this.bookingForm.controls.invoice_name.value,
        //    invoice_organization: this.bookingForm.controls.invoice_organization.value,
        //    invoice_department: this.bookingForm.controls.invoice_department.value,
        //    invoice_address: this.bookingForm.controls.invoice_address.value,
        //    invoice_city: this.bookingForm.controls.invoice_city.value,
        //    invoice_postcode: this.bookingForm.controls.invoice_postcode.value,
        //    invoice_telephone: this.bookingForm.controls.invoice_telephone.value,
        //    invoice_email: this.bookingForm.controls.invoice_email.value,
        //    invoice_purchaseOrder: this.bookingForm.controls.invoice_purchaseOrder.value,
        //    invoice_promoCode: this.bookingForm.controls.invoice_promoCode.value,
        //    booker_name: this.bookingForm.controls.booker_name.value,
        //    booker_telephone: this.bookingForm.controls.booker_telephone.value,
        //    booker_email: this.bookingForm.controls.booker_email.value,
        //    goGreen: $("input[name='gogreen']:checked").val(),
        //    policyPack: $("input[name='policypack']:checked").val(),
        //    manualPurchase: $("input[name='manual_purchase']:checked").val(),
        //    txt_other: this.bookingForm.controls.txt_other.value
        //};

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to approve this booking?", title: "Approve" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            this._delegateService.approveBooking(_data)
                //.pipe(catchError((error) => { 
                //    //handleError here
                //})
                .subscribe((result: any) => {

                    //if (result == (null || undefined))
                    //    this.modal.dismissAll();
                    if (result.success == true) {

                        this.modal.open(this.modalCourseDetails, { size: 'lg' });
                        this.modal.dismissAll();

                        this.getApprovalsList();

                    } else {
                        this.modal.dismissAll();
                        //show errror popup

                    }

                }, (_error: any) => {
                    console.log(_error);
                    //this.loading = false;
                })

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });



    }


    setStep(index: number) {
        this.step = index;

        setTimeout(() => {

            $(".mat-expansion-panel-header").find(".mat-expansion-panel-header-title").css("color", "black");
            $(".mat-expanded").find(".mat-expansion-panel-header-title").css("color", "white");
        }, 10);

    }

    setStep_closed() {
        //this.step = index;

        setTimeout(() => {

            $(".mat-expansion-panel-header").find(".mat-expansion-panel-header-title").css("color", "black");
            $(".mat-expanded").find(".mat-expansion-panel-header-title").css("color", "white");
        }, 10);

    }

    nextStep() {
        //if (this.courseDuration == 4) { // this is certificate
        //    if (this.step == 2)
        //        this.step = 6;
        //    else if (this.step == 6)
        //        this.step = 3;
        //    else
        //        this.step++;
        //}
        //else
        this.step++;


    }

    prevStep() {

        //if (this.courseDuration == 4) { // this is certificate
        //    if (this.step == 3)
        //        this.step = 6;
        //    else if (this.step == 6)
        //        this.step = 2;
        //    else
        //        this.step--;
        //}
        //else
        this.step--;
    }

    private getRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    //#55BF3B'], // green
    //#DDDF0D'], // yellow
    //#DF5353'], // red
    private createChart(): void {
        const chart = Highcharts.chart('chart-1', {
            title: {
                text: 'Sales of petroleum products March, Norway',
                align: 'left'
            },
            xAxis: {
                categories: ['Jet fuel', 'Duty-free diesel', 'Petrol', 'Diesel', 'Gas oil']
            },
            yAxis: {
                title: {
                    text: 'Million liter'
                }
            },
            labels: {
                items: [{
                    html: 'Total liter',
                    style: {
                        left: '50px',
                        top: '18px',
                        color: ( // theme
                            // Highcharts.defaultOptions.title.style &&
                            // Highcharts.defaultOptions.title.style.color
                            'green'
                        ) || 'black'
                    }
                }]
            },
            series: [{
                type: 'column',
                name: '2020',
                data: [59, 83, 65, 228, 184]
            }, {
                type: 'column',
                name: '2021',
                data: [24, 79, 72, 240, 167]
            }, {
                type: 'column',
                name: '2022',
                data: [58, 88, 75, 250, 176]
            }, {
                type: 'spline',
                name: 'Average',
                data: [47, 83.33, 70.66, 239.33, 175.66],
                marker: {
                    lineWidth: 2,
                    lineColor: 'black', //Highcharts.getOptions().colors[3],
                    fillColor: 'white'
                }
            }, {
                type: 'pie',
                name: 'Liter',
                data: [{
                    name: '2020',
                    y: 619,
                    color: '#55BF3B' // Highcharts.getOptions().colors[0] // 2020 color
                }, {
                    name: '2021',
                    y: 586,
                    color: '#DDDF0D' //Highcharts.getOptions().colors[1] // 2021 color
                }, {
                    name: '2022',
                    y: 647,
                    color: '#DF5353'//Highcharts.getOptions().colors[2] // 2022 color
                }],
                center: [80, 70],
                size: 100,
                showInLegend: false,
                dataLabels: {
                    enabled: false
                }
            }]
        } as any);

        setInterval(() => {
            chart.series[0].points[0].update(this.getRandomNumber(0, 100));
        }, 1000);
    }

    private createChartGauge(): void {
        const chart = Highcharts.chart('chart-gauge', {
            chart: {
                type: 'solidgauge',
            },
            title: {
                text: 'Gauge Chart',
            },
            credits: {
                enabled: false,
            },
            pane: {
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '85%'],
                size: '100%',
                background: {
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc',
                },
            },
            yAxis: {
                min: 0,
                max: 100,
                stops: [
                    [0.1, '#55BF3B'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#DF5353'], // red
                ],
                minorTickInterval: null,
                tickAmount: 2,
                labels: {
                    y: 16,
                },
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: -25,
                        borderWidth: 0,
                        useHTML: true,
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
            series: [{
                name: null,
                data: [this.getRandomNumber(0, 100)],
                dataLabels: {
                    format: '<div style="text-align: center"><span style="font-size: 1.25rem">{y}</span></div>',
                },
            }],
        } as any);

        setInterval(() => {
            chart.series[0].points[0].update(this.getRandomNumber(0, 100));
        }, 1000);
    }

    private createChartPie(): void {
        let date = new Date();
        const data: any[] = [];

        for (let i = 0; i < 5; i++) {
            date.setDate(new Date().getDate() + i);
            data.push({
                name: `${date.getDate()}/${date.getMonth() + 1}`,
                y: this.getRandomNumber(0, 1000),
            });
        }

        const chart = Highcharts.chart('chart-pie', {
            chart: {
                type: 'pie',
            },
            title: {
                text: 'Pie Chart',
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                headerFormat: `<span class="mb-2">Date: {point.key}</span><br>`,
                pointFormat: '<span>Amount: {point.y}</span>',
                useHTML: true,
            },
            series: [{
                name: null,
                innerSize: '50%',
                data,
            }],
        } as any);

        setInterval(() => {
            date.setDate(date.getDate() + 1);
            chart.series[0].addPoint({
                name: `${date.getDate()}/${date.getMonth() + 1}`,
                y: this.getRandomNumber(0, 1000),
            }, true, true);
        }, 1500);
    }

    private createChartColumn(): void {
        let date = new Date();
        const data: any[] = [];

        for (let i = 0; i < 10; i++) {
            date.setDate(new Date().getDate() + i);
            data.push({
                name: `${date.getDate()}/${date.getMonth() + 1}`,
                y: this.getRandomNumber(0, 1000),
            });
        }

        const chart = Highcharts.chart('chart-column' as any, {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Column Chart',
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                min: 0,
                title: undefined,
            },
            xAxis: {
                type: 'category',
            },
            tooltip: {
                headerFormat: `<div>Date: {point.key}</div>`,
                pointFormat: `<div>{series.name}: {point.y}</div>`,
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            series: [{
                name: 'Amount',
                data,
            }],
        } as any);

        setInterval(() => {
            date.setDate(date.getDate() + 1);
            chart.series[0].addPoint({
                name: `${date.getDate()}/${date.getMonth() + 1}`,
                y: this.getRandomNumber(0, 1000),
            }, true, true);
        }, 1500);
    }

    private createChartLine(): void {
        let date = new Date();
        const data: any[] = [];

        for (let i = 0; i < 10; i++) {
            date.setDate(new Date().getDate() + i);
            data.push([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 1000)]);
        }

        const chart = Highcharts.chart('chart-line', {
            chart: {
                type: 'line',
            },
            title: {
                text: 'Line Chart',
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: null,
                }
            },
            xAxis: {
                type: 'category',
            },
            tooltip: {
                headerFormat: `<div>Date: {point.key}</div>`,
                pointFormat: `<div>{series.name}: {point.y}</div>`,
                shared: true,
                useHTML: true,
            },
            series: [{
                name: 'Amount',
                data,
            }],
        } as any);

        setInterval(() => {
            date.setDate(date.getDate() + 1);
            chart.series[0].addPoint([`${date.getDate()}/${date.getMonth() + 1}`, this.getRandomNumber(0, 1000)], true, true);
        }, 1500);
    }

    private createChart2(): void {
        const chart = Highcharts.chart('chart-2', {
            title: {
                text: 'U.S Solar Employment Growth by Job Category, 2010-2020'
            },

            subtitle: {
                text: 'Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>'
            },

            yAxis: {
                title: {
                    text: 'Number of Employees'
                }
            },

            xAxis: {
                accessibility: {
                    rangeDescription: 'Range: 2010 to 2020'
                }
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2010
                }
            },

            series: [{
                name: 'Installation & Developers',
                data: [43934, 48656, 65165, 81827, 112143, 142383,
                    171533, 165174, 155157, 161454, 154610]
            }, {
                name: 'Manufacturing',
                data: [24916, 37941, 29742, 29851, 32490, 30282,
                    38121, 36885, 33726, 34243, 31050]
            }, {
                name: 'Sales & Distribution',
                data: [11744, 30000, 16005, 19771, 20185, 24377,
                    32147, 30912, 29243, 29213, 25663]
            }, {
                name: 'Operations & Maintenance',
                data: [null, null, null, null, null, null, null,
                    null, 11164, 11218, 10077]
            }, {
                name: 'Other',
                data: [21908, 5548, 8105, 11248, 8989, 11816, 18274,
                    17300, 13053, 11906, 10073]
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
        } as any);

        setInterval(() => {
            chart.series[0].points[0].update(this.getRandomNumber(0, 100));
        }, 1000);
    }

}
