<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>
<div class="modal-body- p-4">


    <form class="form-horizontal" [formGroup]="insertForm" (ngSubmit)="onInsertSubmit()" name="cuForm" autocomplete="off">


        <div class="row">

            <div class="col-4">
                <label class="text-second">Title:</label>
                <mat-select formControlName="courseTitle" #select (selectionChange)="populateTitleData($event)"
                            class="form-control no-radius">
                    <mat-option *ngFor="let title of titlesList" [value]="title">{{title.title}}</mat-option>
                </mat-select>
            </div>



            <!--<div class="col-12">
        <label class="text-second">Title:</label>
        <input type="text" class="form-control no-radius" placeholder="Course title"
               [ngClass]="{  'is-invalid': i.courseTitle.errors}"
               required formControlName="courseTitle" maxlength="250" />
        <div *ngIf="i.courseTitle.errors" class="invalid-feedback">
            <div *ngIf="i.courseTitle.errors.pattern">{{errors.courseTitle}}</div>
        </div>
    </div>-->
            <div class="col-4">
                <label class="text-second">Speaker:</label>
                <mat-select formControlName="speaker" #select
                            class="form-control no-radius">
                    <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerName">{{spkr.speakerName}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4">
                <label class="text-second">Location:</label>
                <mat-select formControlName="location" #select
                            class="form-control no-radius">
                    <mat-option *ngFor="let loc of locationsList" [value]="loc.cityName">{{loc.cityName}}</mat-option>
                </mat-select>
            </div>
            <div class="col-4">
                <label class="text-second">Subject:</label>
                <mat-select formControlName="subject" #select
                            class="form-control no-radius">
                    <mat-option *ngFor="let sub of subjectsList" [value]="sub.title">{{sub.title}}</mat-option>
                </mat-select>

            </div>
            <div class="col-4">
                <label class="text-second">Fees:</label>
                <input type="text" class="form-control no-radius" placeholder="Course Fees"
                       [ngClass]="{  'is-invalid': i.fees.errors}"
                       required formControlName="fees" maxlength="250" />
                <div *ngIf="i.fees.errors" class="invalid-feedback">
                    <div *ngIf="i.fees.errors.pattern">{{errors.courseTitle}}</div>
                </div>

            </div>

            <div class="col-4">
                <label class="text-second">Course Type:</label>

                <mat-select formControlName="courseType" #select id="hearing" [(ngModel)]="_courseTypeId"
                            class="form-control no-radius text-second">

                    <mat-option selected value="Select source" class=" text-second">Course type</mat-option>

                    <mat-option value="1">
                        Full day
                    </mat-option>

                    <mat-option value="2">
                        Certificate
                    </mat-option>

                    <mat-option value="3">
                        eLearning
                    </mat-option>

                    <mat-option value="4">
                        Product
                    </mat-option>

                    <mat-option value="5">
                        Inhouse
                    </mat-option>

                    <mat-option value="6">
                        Webinar
                    </mat-option>

                </mat-select>
            </div>

            <div class="col-4">
                <label class="text-second">Start Time:</label>
                <input type="text" class="form-control no-radius" placeholder="09:45"
                       formControlName="startTime" maxlength="8" />
            </div>

            <div class="col-4">
                <label class="text-second">Finish Time:</label>
                <input type="text" class="form-control no-radius" placeholder="10:45"
                       formControlName="endTime" maxlength="8" />
            </div>


            <div class="col-12">
                <div class="row mt-2">
                    <div class="col-md-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Day 1</mat-label>
                            <input matInput [matDatepicker]="picker_day1" formControlName="startDate_day1" placeholder=""
                                   [ngModel]="start_d1" (dateChange)="addEvent('d1', $event)"
                                   [ngClass]="{'is-invalid':  submitted && i.startDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_day1"></mat-datepicker-toggle>
                            <mat-datepicker #picker_day1></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Day 2</mat-label>
                            <input matInput [matDatepicker]="picker_day2" formControlName="startDate_day2" placeholder=""
                                   [ngModel]="start_d2" (dateChange)="addEvent('d2', $event)"
                                   [ngClass]="{'is-invalid': submitted &&  i.startDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_day2"></mat-datepicker-toggle>
                            <mat-datepicker #picker_day2></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Day 3</mat-label>
                            <input matInput [matDatepicker]="picker_day3" formControlName="startDate_day3" placeholder=""
                                   [ngModel]="start_d3" (dateChange)="addEvent('d3', $event)"
                                   [ngClass]="{'is-invalid': submitted &&  i.startDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_day3"></mat-datepicker-toggle>
                            <mat-datepicker #picker_day3></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Day 4</mat-label>
                            <input matInput [matDatepicker]="picker_day4" formControlName="startDate_day4" placeholder=""
                                   [ngModel]="start_d4" (dateChange)="addEvent('d4', $event)"
                                   [ngClass]="{'is-invalid': submitted &&  i.startDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_day4"></mat-datepicker-toggle>
                            <mat-datepicker #picker_day4></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

            </div>

        </div>


        <div class="row">




        </div>

        <div class="modal-footer">
            <!--(click)="close.emit()"-->
            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>

            <div class="">
                <button [disabled]="!insertForm.valid"
                        class="book-btn">
                    Save
                </button>

            </div>
        </div>





    </form>
</div>










