import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseType, errorMessages } from '../../../common/validation/messages';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
  selector: 'app-ripa-booking',
  templateUrl: './ripa-booking.component.html',
  styleUrls: ['./ripa-booking.component.css']
})
export class RipaBookingComponent implements OnInit {

    //https://localhost:44384/booking-ripa?courseName=RIPA Essentials&courseCost=69&productId=44
    //https://localhost:44384/booking-ripa?courseName=GDPR E-Learning Course&courseCost=20&productId=38


    loading = false;
    step = 1;
    bookingForm: FormGroup;
    delegatesList: any;
    coursesList: any;
    locationsList: any;
    currentCoursesList: any;
    errors: any = errorMessages;

    _params: any;
    courseId: any;
    productId: any;
    courseName: any;
    courseCost: any;
    courseDate: any;
    courseLocation: any;
    selectedSource: any = "Select source";
    selectedSector: any = "Select sector";
    other_source: any = false;
    courseDuration: any = "";
    subject: any;
    speaker: any;
    webCourseId: any;
    acceptedTC: any = false;
    validEmail: any = false;
    validNames: any = true;

    constructor(private _delegateService: DelegateService,
        private _coursesService: CoursesService,
        private formBuilder: FormBuilder,
        private _route: Router,
        private route: ActivatedRoute) {

        this.route.queryParamMap
            .subscribe((params) => {

                this._params = { ...params.keys, ...params };

                this.productId = this._params.params.productId;
                this.courseName = this._params.params.courseName;
                this.courseCost = this._params.params.courseCost;
                //this.courseDate = this._params.params.courseDate;
                //this.courseLocation = this._params.params.courseLocation;
                //this.courseDuration = this._params.params.courseDuration;
                //this.subject = this._params.params.subject;
                //this.speaker = this._params.params.speaker;
            });


        //if (this._delegateId == null || this._delegateId == undefined
        //    || this._delegateId == "null" || Number.isNaN(this._delegateId)) {
        //    this._delegateId = 0; // user will add new delegate
        //}
    }

    ngOnInit(): void {

        $('body').addClass("booking-bg");

        this.courseDuration = this.courseDuration.substring(0, 1);

        this.bookingForm = this.formBuilder.group({
            //bookingCourse: ['', Validators.required],
            //bookingDelegate: ['', Validators.required],
            //bookingCurrentCourses: ['', Validators.required],
            source: ['', Validators.required],
            txt_other: [''],
            licences:[''],
            name: ['', Validators.required],
            organization: ['', Validators.required],
            department: [''],
            address: ['', Validators.required],
            city: ['', Validators.required],
            postcode: ['', Validators.required],
            telephone: ['', Validators.required],
            email: ['', Validators.required],
            sector: ['', Validators.required],
            bagAddress: [''],

            invoice_name: ['', Validators.required],
            invoice_organization: ['', Validators.required],
            invoice_department: [''],
            invoice_address: ['', Validators.required],
            invoice_city: ['', Validators.required],
            invoice_postcode: ['', Validators.required],
            invoice_telephone: ['', Validators.required],
            invoice_email: ['', Validators.email],
            invoice_purchaseOrder: [''],
            invoice_promoCode: [''],

            booker_name: ['', Validators.required],
            booker_telephone: ['', Validators.required],
            booker_email: ['', Validators.email],

            invoice_details: [false],
            invoice_tc: [false, Validators.required],
            rdGogreen: [''],
            policypack: [''],
            blog_details: [''],
            booknow: [''],
            manual_purchase_qty:[''],

            postal_name: [''],
            postal_address: [''],
            postal_city: [''],
            postal_postcode: [''],
            postal_telephone: ['']
        });


        //this.getDelegatesList();
        //this.getCoursesList();
        //this.getLocationsList();
        //this.selectedSource = "Select source";
        //$("#hearing").prop("selectedIndex", 0);
        this.bookingForm.controls.source.patchValue("Select source");
        this.bookingForm.controls.sector.patchValue("Select sector");

        setTimeout(() => {
            //this.bookingForm.controls.city.patchValue("Select city");
            //this.bookingForm.controls.invoice_city.patchValue("Select city");
            //this.bookingForm.controls.postal_city.patchValue("Select city");
        }, 100);
    }


    validateEmails() {


        var emails = this.bookingForm.controls.email.value.replace(/\s/g, '').split(",");
        var names = this.bookingForm.controls.name.value.split(",");
        this.validEmail = true;
        var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {
            if (emails[i] == "" || !regex.test(emails[i])) {
                this.validEmail = false;
            }
        }

        if (this.validEmail)
            if (emails != "" && (emails.length == names.length)) {
                this.validEmail = true;
                this.validNames = true;
                $("#_validNames").hide();
            }
            else {
                this.validEmail = false;
                this.validNames = false;
                $("#_validNames").show();
            }
    }

    getLocationsList() {
        this.loading = true;
        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data;
                this.loading = false;


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    get f() { return this.bookingForm.controls; }

    orderHandbook(h) {

        if (h == 'handbook-show') { $('#handbook-show').show(); }
        if (h == 'handbook-hide') { $('#handbook-show').hide(); }

    } // function orderHandbook()

    goGreen(h) {

        if (h == 'green-show') { $('#green-show').show(); }
        if (h == 'green-hide') { $('#green-show').hide(); }

    } // function goGreen()

    termConditions(val) {
        this.acceptedTC = val;
    }

    updateInvoiceDetails(val) {

        if (val == true) {

            this.bookingForm.controls.invoice_name.setValue(this.bookingForm.controls.name.value);
            this.bookingForm.controls.invoice_organization.setValue(this.bookingForm.controls.organization.value);
            this.bookingForm.controls.invoice_department.setValue(this.bookingForm.controls.department.value);
            this.bookingForm.controls.invoice_address.setValue(this.bookingForm.controls.address.value);
            this.bookingForm.controls.invoice_city.setValue(this.bookingForm.controls.city.value);
            this.bookingForm.controls.invoice_postcode.setValue(this.bookingForm.controls.postcode.value);
            this.bookingForm.controls.invoice_telephone.setValue(this.bookingForm.controls.telephone.value);
            this.bookingForm.controls.invoice_email.setValue(this.bookingForm.controls.email.value);
            //this.bookingForm.controls.invoice_purchaseOrder.setValue(this.bookingForm.controls.purchaseOrder.value);
            //this.bookingForm.controls.invoice_promoCode.setValue(this.bookingForm.controls.promoCode.value);


        } else {
            this.bookingForm.controls.invoice_name.setValue('');
            this.bookingForm.controls.invoice_organization.setValue('');
            this.bookingForm.controls.invoice_department.setValue('');
            this.bookingForm.controls.invoice_address.setValue('');
            this.bookingForm.controls.invoice_city.setValue('');
            this.bookingForm.controls.invoice_postcode.setValue('');
            this.bookingForm.controls.invoice_telephone.setValue('');
            this.bookingForm.controls.invoice_email.setValue('');
            //this.bookingForm.controls.invoice_purchaseOrder.setValue('');
            //this.bookingForm.controls.invoice_promoCode.setValue('');
        }

    }

    updatePostalDetails(val) {

        if (val == true) {

            this.bookingForm.controls.postal_name.setValue(this.bookingForm.controls.name.value);
            this.bookingForm.controls.postal_address.setValue(this.bookingForm.controls.address.value);
            this.bookingForm.controls.postal_city.setValue(this.bookingForm.controls.city.value);
            this.bookingForm.controls.postal_postcode.setValue(this.bookingForm.controls.postcode.value);
            this.bookingForm.controls.postal_telephone.setValue(this.bookingForm.controls.telephone.value);


        } else {
            this.bookingForm.controls.postal_name.setValue('');
            this.bookingForm.controls.postal_address.setValue('');
            this.bookingForm.controls.postal_city.setValue('');
            this.bookingForm.controls.postal_postcode.setValue('');
            this.bookingForm.controls.postal_telephone.setValue('');
        }

    }

    updateBookerDetails(val) {

        if (val == 'delegate') {

            this.bookingForm.controls.booker_name.setValue(this.bookingForm.controls.name.value);
            this.bookingForm.controls.booker_telephone.setValue(this.bookingForm.controls.telephone.value);
            this.bookingForm.controls.booker_email.setValue(this.bookingForm.controls.email.value);

        } else if (val == 'invoice') {

            this.bookingForm.controls.booker_name.setValue(this.bookingForm.controls.invoice_name.value);
            this.bookingForm.controls.booker_telephone.setValue(this.bookingForm.controls.invoice_telephone.value);
            this.bookingForm.controls.booker_email.setValue(this.bookingForm.controls.invoice_email.value);

        } else if (val == 'none') {

            this.bookingForm.controls.booker_name.setValue('');
            this.bookingForm.controls.booker_telephone.setValue('');
            this.bookingForm.controls.booker_email.setValue('');

        }


    }




    onSourceChanged(title: any) {

        if (title.value == "Other") {
            this.other_source = true;
        }
        else {
            this.other_source = false;
        }
    }

    setStep(index: number) {
        this.step = index;
        
        setTimeout(() => {

            $(".mat-expansion-panel-header").find(".mat-expansion-panel-header-title").css("color", "black");
            $(".mat-expanded").find(".mat-expansion-panel-header-title").css("color", "white");
        }, 10);

    }

    setStep_closed() {
        //this.step = index;
        
        setTimeout(() => {

            $(".mat-expansion-panel-header").find(".mat-expansion-panel-header-title").css("color", "black");
            $(".mat-expanded").find(".mat-expansion-panel-header-title").css("color", "white");
        }, 10);

    }

    nextStep() {
        if (this.courseDuration == 4) { // this is certificate
            if (this.step == 2)
                this.step = 6;
            else if (this.step == 6)
                this.step = 3;
            else
                this.step++;
        }
        else
            this.step++;


    }

    prevStep() {

        if (this.courseDuration == 4) { // this is certificate
            if (this.step == 3)
                this.step = 6;
            else if (this.step == 6)
                this.step = 2;
            else
                this.step--;
        }
        else
            this.step--;
    }

    

    getCurrentCourseList(title: any) {

        if (title.value == null ||
            title.value == undefined)
            title = { value: title }


        this._coursesService.getCurrentCourseList(title).subscribe((result: any) => {


            if (result.success == true) {

                this.currentCoursesList = result.data



            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    onBookingSubmit() {

        //var valdata = $("#bookingForm").serialize();
        var _courseType = CourseType.eLearning;

        

        var req = {
            postal_name: this.bookingForm.controls.postal_name.value,
            postal_address: this.bookingForm.controls.postal_address.value,
            postal_city: this.bookingForm.controls.postal_city.value,
            postal_postcode: this.bookingForm.controls.postal_postcode.value,
            postal_telephone: this.bookingForm.controls.postal_telephone.value,

            courseType: _courseType,
            speaker: this.speaker,
            subject: this.subject,
            //courseDuration: this.courseDuration,
            //webCourseId: this.webCourseId,
            courseName: this.courseName,
            courseCost: this.courseCost,
            courseDate: this.courseDate,
            //courseLocation: this.courseLocation,
            source: this.bookingForm.controls.source.value,
            name: this.bookingForm.controls.name.value,
            organization: this.bookingForm.controls.organization.value,
            department: this.bookingForm.controls.department.value,
            address: this.bookingForm.controls.address.value,
            bagAddress: this.bookingForm.controls.bagAddress.value,
            city: this.bookingForm.controls.city.value,
            postcode: this.bookingForm.controls.postcode.value,
            telephone: this.bookingForm.controls.telephone.value,
            email: this.bookingForm.controls.email.value,
            sector: this.bookingForm.controls.sector.value,
            invoice_name: this.bookingForm.controls.invoice_name.value,
            invoice_organization: this.bookingForm.controls.invoice_organization.value,
            invoice_department: this.bookingForm.controls.invoice_department.value,
            invoice_address: this.bookingForm.controls.invoice_address.value,
            invoice_city: this.bookingForm.controls.invoice_city.value,
            invoice_postcode: this.bookingForm.controls.invoice_postcode.value,
            invoice_telephone: this.bookingForm.controls.invoice_telephone.value,
            invoice_email: this.bookingForm.controls.invoice_email.value,
            invoice_purchaseOrder: this.bookingForm.controls.invoice_purchaseOrder.value,
            invoice_promoCode: this.bookingForm.controls.invoice_promoCode.value,
            booker_name: this.bookingForm.controls.booker_name.value,
            booker_telephone: this.bookingForm.controls.booker_telephone.value,
            booker_email: this.bookingForm.controls.booker_email.value,
            goGreen: $("input[name='gogreen']:checked").val(),
            policyPack: $("input[name='policypack']:checked").val(),
            manualPurchase: $("input[name='manual_purchase']:checked").val(),
            manualPurchaseQty: Number(this.bookingForm.controls.manual_purchase_qty.value),
            subscription: this.bookingForm.controls.blog_details.value, //$("input[name='blog_details']:checked").val(),
            leaflet: this.bookingForm.controls.booknow.value, //$("input[name='booknow']:checked").val(),
            txt_other: this.bookingForm.controls.txt_other.value,
            BuyProduct: true,
            ProductId: Number(this.productId) ,
            BuyLicenses: Number(this.bookingForm.controls.licences.value), 
        };

        
        this._delegateService.insertBooking(req).subscribe((result: any) => {


            if (result.success == true) {

                this.bookingForm.reset();
                this.step = 1;

                this.bookingForm.controls.source.patchValue("Select source");
                this.bookingForm.controls.sector.patchValue("Select sector");
                //this.bookingForm.controls.city.patchValue("Select city");
                //this.bookingForm.controls.invoice_city.patchValue("Select city");
                //this.bookingForm.controls.postal_city.patchValue("Select city");

                this._route.navigateByUrl("/booking-confirmation");

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            
            //this.loading = false;
        })

    }

}
