import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-pin',
  templateUrl: './create-pin.component.html',
  styleUrls: ['./create-pin.component.css']
})
export class CreatePinComponent implements OnInit {

    isNumeric: boolean;
    maxLength: number;
    otpValue: string;
    ResendotpValue: string;
    placeholder: string = "";
    text: string;
    otpForm: FormGroup;
    //  private subscription: Subscription;


    @Output() otpSend: EventEmitter<any> = new EventEmitter<any>();
    @Output() ResendotpSend: EventEmitter<any> = new EventEmitter<any>();
    @Output() Close = new EventEmitter<any>();


    timeLeft: number;
    configTime: number;
    intervalFlag = false;
    interval: any;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any/*, private alertService: AlertService*/) {

        this.startTimer();
    }

    ngOnInit() {
        
        this.data = {
            isEmail: false,
            otpResendWait: 30
        }
        //if (this.data.isEmail) {
        //    this.text = "Enter OTP you received on your Email account";
        //} else {
        //    this.text = "Enter OTP sms send to your Mobile Number";
        //}

        this.text = "Setup your PIN & Secret Question";

        this.isNumeric = true;// this.data.isNumeric;
        this.maxLength = 4;//this.data.maxLength;

        if (this.data.otpResendWait == 0) {
            this.timeLeft = 60;
            this.configTime = 60;
        } else {
            this.timeLeft = this.data.otpResendWait;
            this.configTime = this.data.otpResendWait;
        }

        for (var i = 0; i < this.maxLength; i++) {
            this.placeholder += "x";
        }

        //this.subscription = this.alertService.OtpClearObservable$.subscribe((res) => {
        //    this.otpValue = "";
        //});

    }

    send() {
        
        this.otpSend.emit(this.otpValue);
    }

    ResendOTP() {
        this.pauseTimer();
        this.timeLeft = this.configTime;
        this.startTimer();

        this.ResendotpSend.emit(this.ResendotpValue);
    }

    CloseOTP() {
        this.Close.emit("Close");
    }

    //// Link resource
    //https://stackblitz.com/edit/timer-with-pause?file=src%2Fapp%2Fapp.component.html
    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.intervalFlag = false;
                this.timeLeft--;
            } else {
                this.intervalFlag = true;
                //this.timeLeft = 60;
            }
        }, 1000)
    }

    pauseTimer() {
        clearInterval(this.interval);
    }

}
