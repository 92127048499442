<!--<canvas ngChartjs [datasets]="lineChartData" [labels]="lineChartLabels"
        [options]="lineChartOptions" [legend]="lineChartLegend" [chartType]="lineChartType"
        [inlinePlugins]="inlinePlugin">
</canvas>-->
<!--<highcharts-chart [Highcharts]="Highcharts"
                  [options]="chartOptions"
                  style="width: 100%; height: 400px; display: block;"></highcharts-chart>-->
<!--<script src="https://github.highcharts.com/highcharts.js"></script>
<script src="https://github.highcharts.com/gantt/modules/gantt.js"></script>-->
<!--<script src="https://code.highcharts.com/gantt/highcharts-gantt.js"></script>
<script src="https://code.highcharts.com/gantt/modules/accessibility.js"></script>-->


<style>
    #chart {
        /*max-width: 100%;*/
        /*max-width: 2500px;*/
        min-width: 320px;
        height: 470px;
        /*width: 2500px;*/
    }

    .single-width {
        width: 100%;
    }

    .all-width {
        width: 2500px;
    }
</style>



<div class="row">
    <div class="col-lg-9">


        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Courses Schedule</span> YEARLY
        </h2>

    </div>
    <div class="col-lg-3 pt-1">

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">

                <button mat-menu-item (click)="monthlySchedule()">Monthly Schedule</button>
                <button mat-menu-item (click)="courseScheduler()">Course Scheduler</button>
            </mat-menu>
        </div>



        <!--<button class="book-btn pull-right filter-apply" (click)="addNew()">
            Add new course
        </button>-->
    </div>

</div>

<div class="row mt-10- bg-white ml-0 pb-4 yearly-grid-min-height">
    <div class="col-md-3 pl-1">
        <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>First campaign</mat-label>
            <mat-date-range-input [formGroup]="campaignOne"
                                  [rangePicker]="campaignOnePicker">
                <input matStartDate placeholder="Start date" formControlName="start">
                <input matEndDate placeholder="End date" formControlName="end" (dateChange)="addEvent($event)">
            </mat-date-range-input>
            <!--<mat-hint>MM/DD/YYYY � MM/DD/YYYY</mat-hint>-->
            <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <div class="col-md-3 pl-1">
        <mat-select #select [(ngModel)]="selectedCourse" id="main_crs"
                    (selectionChange)="applyFilters()"
                    class="form-control no-radius top-dd">
            <mat-option [value]="0">Select course</mat-option>
            <mat-option *ngFor="let course of courses" [value]="course.title">{{course.title}}</mat-option>
        </mat-select>
    </div>


    <div class="col-md-3 pl-1">
        <mat-checkbox id="showFilters" [(ngModel)]="showFiltersModel" class="text-second"
                      (change)="showAdvancedFilters($event.checked)">
            <span class="text-second">Show Advanced Filters</span>
        </mat-checkbox>
    </div>

    <div id="advanced-filters" class="row">


        <div class="col-md-2 pl-1">
            <mat-select #select [(ngModel)]="filter_Subject"
                        (selectionChange)="applyFilters()"
                        class="form-control no-radius top-dd-">
                <mat-option [value]="0">Filter by subject</mat-option>
                <mat-option *ngFor="let sub of subjectsList" [value]="sub.subjectId">{{sub.title}}</mat-option>
            </mat-select>
        </div>

        <div class="col-md-2 pl-1">
            <mat-select #select [(ngModel)]="filter_CourseType"
                        (selectionChange)="applyFilters()"
                        class="form-control no-radius top-dd-">
                <mat-option [value]="0">Filter by course type</mat-option>
                <mat-option *ngFor="let course of courseTypesList" [value]="course.id">{{course.title}}</mat-option>
            </mat-select>
        </div>

        <div class="col-md-2 pl-1">
            <mat-select #select [(ngModel)]="filter_Speaker"
                        (selectionChange)="applyFilters()"
                        class="form-control no-radius top-dd-">
                <mat-option [value]="0">Filter by speaker</mat-option>
                <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
            </mat-select>
        </div>

        <div class="col-md-2 pl-1">
            <mat-select #select [(ngModel)]="filter_Location"
                        (selectionChange)="applyFilters()"
                        class="form-control no-radius top-dd-">
                <mat-option [value]="0">Filter by location</mat-option>
                <mat-option *ngFor="let loc of locationsList" [value]="loc.cityId">{{loc.cityName}}</mat-option>
            </mat-select>
        </div>
        <div class="col-md-2 pl-1">
            <mat-checkbox id="showAll" [(ngModel)]="showModel" class="text-second"
                          (change)="showAll($event.checked)">
                <span class="text-second">Show all (Non-archived)</span>
            </mat-checkbox>
        </div>
    </div>



    <!--style="max-height:{{maxHeight}}; min-width:{{minWidth}};"-->
    <div class="scrolling-container mt-4">
        <div id="chart" [ngClass]="chartWidth == true ? 'single-width' : 'all-width'" class="chart-overflow" data-highcharts-chart="0"></div>
    </div>



    <!--<highcharts-chart class=" pb-5" id="divchart" [Highcharts]="Highcharts"
                      [constructorType]="'ganttChart'"
                      [options]="chartOptions"
                      [runOutsideAngular]=true
                      [callbackFunction]="chartCallback"
                      [(update)]="updateFlag"
                      [ngStyle]="{'width':width +'%','height':height+'px','display':'block'}">
    </highcharts-chart>-->
</div>




