<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>



<div class="modal-header primary">
    <h5 class="modal-title primary">{{_title}}</h5>
</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="supplierEditForm" (ngSubmit)="onSubmit()" name="supplierEditForm" autocomplete="off">

        <div class="row">

            <div class="col-md-12">

                <label class="text-second">Supplier Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Supplier name"
                       [ngClass]="{  'is-invalid': f.name.errors}"
                       required formControlName="name" maxlength="250" />
                <div *ngIf="f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.pattern">{{errors.name}}</div>
                </div>

            </div>

            <div class="col-12">
                <label class="text-second">Address Line 1:</label>
                <input type="text" class="form-control no-radius" placeholder="Address Line 1"
                       [ngClass]="{  'is-invalid': f.address1.errors}"
                       formControlName="address1" maxlength="250" />

                <label class="text-second">Address 2:</label>
                <input type="text" class="form-control no-radius" placeholder="Address Line 2"
                       formControlName="address2" maxlength="250" />

            </div>
            <div class="col-6">
                <label class="text-second">City:</label>
                <!--<mat-select formControlName="city" #select (selectionChange)='onCitySelected($event)'
                            class="form-control no-radius text-second">
                    <mat-option *ngFor="let loc of locationsList" [value]="loc.cityId">{{loc.cityName}}</mat-option>
                </mat-select>-->

                <input type="text" class="form-control no-radius" placeholder="Town/City"
                       [ngClass]="{  'is-invalid': f.city.errors}"
                       required formControlName="city" maxlength="250" />
                <div *ngIf="f.city.errors" class="invalid-feedback mt-0">
                    <div *ngIf="f.city.errors.pattern">{{errors.required}}</div>
                </div>
            </div>
            <div class="col-6">
                <label class="text-second">Postcode:</label>
                <input type="text" class="form-control no-radius" placeholder="Postcode"
                       [ngClass]="{  'is-invalid': f.postcode.errors}" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                       formControlName="postcode" maxlength="20" />
                <div *ngIf="f.postcode.errors" class="invalid-feedback">
                    <div *ngIf="f.postcode.errors.pattern">{{errors.postcode}}</div>
                </div>
            </div>

            <div class="col-4">
                <label class="text-second">Bank Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Bank name"
                       formControlName="bankName" maxlength="250" />
            </div>
            <div class="col-4">
                <label class="text-second">Sort code:</label>
                <input type="text" class="form-control no-radius" placeholder="Sort code"
                       formControlName="sortCode" maxlength="250" />
            </div>

            <div class="col-4">
                <label class="text-second">Account number:</label>
                <input type="text" class="form-control no-radius" placeholder="Account number"
                       formControlName="accountNumber" maxlength="50" />
            </div>

            <div class="col-12">
                <label class="text-second">Bank address:</label>
                <input type="text" class="form-control no-radius" placeholder="Bank address"
                       [ngClass]="{  'is-invalid': f.bankAddress.errors}"
                       formControlName="bankAddress" maxlength="250" />
            </div>

            <div class="col-6">
                <label class="text-second">VAT Number:</label>
                <input type="text" class="form-control no-radius" placeholder="VAT Number"
                       formControlName="VATNumber" maxlength="250" />
            </div>
            <div class="col-6">
                <label class="text-second">Company Number:</label>
                <input type="text" class="form-control no-radius" placeholder="Company Number"
                       formControlName="companyNumber" maxlength="250" />
            </div>

            <div class="col-6">
                <label class="text-second">Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Telephone"
                       formControlName="telephone" maxlength="50" />
            </div>
            <div class="col-6">

                <label class="text-second">Email:</label>
                <input type="text" class="form-control no-radius" placeholder="Email"
                       [ngClass]="{  'is-invalid': f.email.errors && f.email.errors.pattern}"
                       formControlName="email" maxlength="250" />
                <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.pattern">{{errors.email}}</div>
                </div>

            </div>
            
        </div>

        <div class="modal-footer">


            <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                Cancel
            </button>

            <div class="">
                <button [disabled]="!supplierEditForm.valid"
                        class="book-btn">
                    Save
                </button>
            </div>
           
        </div>

    </form>


</div>

