import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-course',
  templateUrl: './view-course.component.html',
  styleUrls: ['./view-course.component.css']
})
export class ViewCourseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
    callMe(value: string) {
        console.log('Called : ' + value);
    }
}
