import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import jsPDF from 'jspdf';
import { FinanceService } from '../../../common/_services/finance-services';

@Component({
    selector: 'app-speaker-details',
    templateUrl: './speaker-details.component.html',
    styleUrls: ['./speaker-details.component.css']
})
export class SpeakerDetailsComponent implements OnInit {

    @Input() speakerId: any;
    coursesList: any = [];
    displayCourse: any = [];
    speakerData: any;

    _paramOut = {
        gotoAdmin: ""
    }
    @Output() gotoAdminTab = new EventEmitter<object>();

    __endDate = new Date();

    coursesCountDateFilter = new FormGroup({

        start: new FormControl(new Date(2022, 1, 1)),
        end: new FormControl(new Date(2024, 6, 30)),
    });

    constructor(private _financeService: FinanceService) {

        this.__endDate.setDate(this.__endDate.getDate() + 90);

        this.coursesCountDateFilter = new FormGroup({
            start: new FormControl(new Date()),
            end: new FormControl(new Date(this.__endDate.getFullYear(), this.__endDate.getMonth(), this.__endDate.getDate())),
        });
    }

    ngOnInit(): void {

        if (this.speakerId) {
            if (Number(this.speakerId) > 0) {
                this.getSpeakerData();
            }
        }

        this.showCourses();


    }

    getSpeakerData() {


        this._financeService.getSpeakerData(this.speakerId).subscribe((result: any) => {

            if (result.success == true) {

                this.speakerData = result.data[0];
                debugger;
                this.getSpeakerCourses();

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }


    getSpeakerCourses() {
        var req = {
            SpeakerId: this.speakerId,
            startDate: this.coursesCountDateFilter.value.start,
            endDate: this.coursesCountDateFilter.value.end
        }


        this._financeService.getIndividualSpeakersCourses(req).subscribe((result: any) => {

            if (result.success == true) {

                this.coursesList = result.data;

                this.displayCourse = [];


                this.coursesList.sort(this.sortFunction);

                let data = new Set(this.coursesList.map(item => item.month))
                data.forEach((month) => {
                    this.displayCourse.push({
                        date: this.coursesList.filter(i => i.month === month)[0].month,
                        courses: this.coursesList.filter(i => i.month === month)
                    })
                })

                this.displayCourse.forEach( (value) => {
                    value.courses.sort(this.sortDatesFunction);
                }); 

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    sortDatesFunction(a, b) {
        var dateA = new Date(a.date).getTime();
        var dateB = new Date(b.date).getTime();
        return dateA > dateB ? 1 : -1;
    };

    sortFunction(a, b) {
        var dateA = new Date(a.month).getTime();
        var dateB = new Date(b.month).getTime();
        return dateA > dateB ? 1 : -1;
    };


    printPDF() {
        $("#section-to-print").css("display", "block");
        window.print();
        $("#section-to-print").css("display", "none");
    }

    goBack() {
        // will be fix through routing
    }

    showProducts() {
        $("#course-section").hide();
        $("#prod-section").show();

        $("#show-prod-container").addClass("bg-purple-gradient text-white");
        $("#show-prod-container").removeClass("bg-lg flip-btn-border");

        $("#show-course-container").addClass("bg-lg flip-btn-border text-main");
        $("#show-course-container").removeClass("bg-purple-gradient text-white");

    }

    showCourses() {
        $("#course-section").show();
        $("#prod-section").hide();

        $("#show-course-container").addClass("bg-purple-gradient text-white");
        $("#show-course-container").removeClass("bg-lg flip-btn-border");

        $("#show-prod-container").addClass("bg-lg flip-btn-border text-main");
        $("#show-prod-container").removeClass("bg-purple-gradient text-white");
    }


    addEvent(event: MatDatepickerInputEvent<Date>) {
        this.getSpeakerCourses();
    }

    sendEmail() {

    }

    gotoAdmin(param) {
        this._paramOut.gotoAdmin = param;
        this.gotoAdminTab.emit(this._paramOut);
    }


    @ViewChild('printSection', { static: false }) el!: ElementRef

    downloadPDF() {
        $("#section-to-print").css("display", "block");

        let pdf = new jsPDF('p', 'px', 'a4', true);
        

        //margins = {
        //    top: 30,
        //    bottom: 40,
        //    left: 35,
        //    width: 600
        //};

        //pdf.setFontSize(12);
        //pdf.text(200, 30, fileName);
        pdf.setFontSize(8);
        //pdf.setFontStyle('italic');
        //pdf.text(420, 35, 'Total Records : ' + totalRecords);
        
        pdf.html(this.el.nativeElement, {
            
            callback: (pdf) => {
                pdf.save(this.speakerData.speakerName + '.pdf');
            },
            margin: [15, 15, 15, 15],
            autoPaging: 'text',
            x: 25,
            y: 25,
            width: 350, //target width in the PDF document
            windowWidth: 675 //window width in CSS pixels
        })

        setTimeout(() => {
            $("#section-to-print").css("display", "none");
        }, 5000);

        
    }

    //ngAfterViewInit() {
    //    console.log("afterinit");
    //    setTimeout(() => {
    //        console.log(this.el.nativeElement.innerText);
    //    }, 4000);
    //}
}
