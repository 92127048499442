import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CoursesService } from '../../../common/_services/courses-services';
import {
    CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView,
} from 'angular-calendar';
import {
    startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { errorMessages } from '../../../common/validation/messages';
import { environment } from '../../../../../environments/environment';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '../../../common/guards/AuthenticationService';
import { userRoles } from '../../../common/model/request';
import { MatDialog } from '@angular/material/dialog';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { CommonServiceService } from '../../../common/_services/common-service.service';


const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
    purple: {
        primary: '#862b78',
        secondary: '#862b78',
    },
};
@Component({
    selector: 'app-course-scheduler',
    templateUrl: './course-scheduler.component.html',
    styleUrls: ['./course-scheduler.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
    ]
})
export class CourseSchedulerComponent implements OnInit {

    _paramOut = {
        showMonthlySchedule: false,
        showYearlySchedule: false,
        showSpeakersSchedule: false
    }

    @Output() gotoSchedule = new EventEmitter<object>();


    @ViewChild('modalAssignCourse', { static: true }) modalAssignCourse: TemplateRef<any>;

    //@ViewChild('modalContentEdit', { static: true }) modalContentEdit: TemplateRef<any>;

    //@ViewChild('modalContentInsert', { static: true }) modalContentInsert: TemplateRef<any>;

    @ViewChild('select') select: MatSelect;

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    public speakersListByCourse: any;
    public coursesList: any;
    public speakersList: any;
    public locationsList: any;
    public subjectsList: any;
    public currentCoursesList: any;
    public url = environment.apiURL;
    public _title = "Assign course";
    public _refDialog: any;

    //public _courseTitle: string;
    public _subjectTitle: string;
    public _speaker: string;
    public _location: string;


    assignCourseForm: FormGroup;
    coursesForm: FormGroup;
    cuForm: FormGroup;
    insertForm: FormGroup;

    errors: any = errorMessages;
    start_date: any = new FormControl(new Date());

    speakerName: any;
    selectedCourse: any;

    isAssign: any = false;
    isSpeaker: any = false;
    filter_Speaker: any = 0;
    filter_SelectedCourse: any = 0;

    isSearchBySpeaker: boolean = false;
    isSearchByCourse: boolean = false;
    showCoursesList: boolean = false;
    showUnassignedCourses: boolean = false;
    isViewCourseDates: boolean = false;
    coursesDatesList: any;
    unassignedSpeakersList: any;






    getSpeakersByCourse(_courseId) {
        var req = {
            courseId: _courseId
        }
        this._coursesService.getSpeakersByCourse(req).subscribe((result: any) => {

            if (result.success == true) {

                this.speakersListByCourse = result.data;



            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }



    public toggleSelectionPopulateSpeaker(item: any) {
        item.selected = !item.selected;


        if (item.selected) {
            this.getSpeakersByCourse(item.courseId);

            this.events = [];
            var setDate = true;

            // all the speakers in this.speakersListByCourse
            if (this.speakersListByCourse.length > 0)
                this.speakersListByCourse.forEach((item: any) => {

                    if (item != null) {

                        if (setDate == true) {
                            this.viewDate = new Date(item.day1Date);
                            setDate = false;
                        }

                        this.events = [
                            ...this.events,
                            {
                                id: item.courseId,
                                start: startOfDay(new Date(item.day1Date)),
                                end: new Date(item.day1Date),
                                title: "D1 - " + item.speakerName,
                                //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                                color: colors.yellow,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                id: item.courseId,
                                start: startOfDay(new Date(item.day2Date)),
                                end: new Date(item.day2Date),
                                title: "D2 - " + item.speakerName,
                                color: colors.red,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                id: item.courseId,
                                start: startOfDay(new Date(item.day3Date)),
                                end: new Date(item.day3Date),
                                title: "D3 - " + item.speakerName,
                                color: colors.blue,
                                actions: this.actions,
                                allDay: true,

                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                id: item.courseId,
                                start: startOfDay(new Date(item.day4Date)),
                                end: new Date(item.day4Date),
                                title: "D4 - " + item.speakerName,
                                color: colors.purple,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.refresh.next();
                    }
                });
        }
        else {
            var __index = this.events.length - 1;
            this.events.slice().reverse().forEach((__item, index) => {
                index = __index;
                if (__item.id === item.courseId)
                    this.events.splice(index, 1);
                __index--;
            });
        }

        this.refresh.next();
    }


    public toggleSelection(item: any, event) {

        
        //item.selected = !item.selected;

        var itemSelected = $('#' + item.courseId).is(':checked');
        if (itemSelected)
            this.getSpeakersByCourse(item.courseId);
        //if (item.selected) {
        //    this.getSpeakersByCourse(item.courseId);
        //}

        this.events = [];
        var setDate = true;


        if (itemSelected) {
            if (item.datesToAssign != null) {
                if (setDate == true) {
                    this.viewDate = new Date(item.datesToAssign.day1Date);
                    setDate = false;
                }

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.datesToAssign.day1Date)),
                        end: new Date(item.datesToAssign.day1Date),
                        title: "D1 - " + item.courseTitle,
                        //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                        color: colors.yellow,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.datesToAssign.day2Date)),
                        end: new Date(item.datesToAssign.day2Date),
                        title: "D2 - " + item.courseTitle,
                        color: colors.red,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.datesToAssign.day3Date)),
                        end: new Date(item.datesToAssign.day3Date),
                        title: "D3 - " + item.courseTitle,
                        color: colors.blue,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.datesToAssign.day4Date)),
                        end: new Date(item.datesToAssign.day4Date),
                        title: "D4 - " + item.courseTitle,
                        color: colors.purple,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.refresh.next();
            }
        }
        else {
            var __index = this.events.length - 1;
            this.events.slice().reverse().forEach((__item, index) => {
                index = __index;
                if (__item.id === item.courseId)
                    this.events.splice(index, 1);
                __index--;
            });
        }

        this.refresh.next();
    }

    //public moveSelected(direction: any) {
    //    if (direction === 'left') {
    //        this.list2.forEach(item => {
    //            if (item.selected) {
    //                this.list1.push(item);
    //            }
    //        });
    //        this.list2 = this.list2.filter(i => !i.selected);
    //    } else {
    //        this.list1.forEach(item => {
    //            if (item.selected) {
    //                this.list2.push(item);
    //            }
    //        });
    //        this.list1 = this.list1.filter(i => !i.selected);
    //    }
    //}

    //public moveAll(direction: any) {
    //    if (direction === 'left') {
    //        this.list1 = [...this.list1, ...this.list2];
    //        this.list2 = [];
    //    } else {
    //        this.list2 = [...this.list2, ...this.list1];
    //        this.list1 = [];
    //    }
    //}


    modalData: {
        action: string;
        event: CalendarEvent;
    };


    constructor(private http: HttpClient,
        private modal: NgbModal,
        private _auth: AuthenticationService,
        private formBuilder: FormBuilder,
        private _commonService: CommonServiceService,
        private _dialog: MatDialog,
        private _coursesService: CoursesService) {

        setTimeout(() => {
            $("#main_crs div:nth-child(1) div:first-child").css("color", "white");
            $("#main_crs div:nth-child(2) div:first-child").css("color", "white");
        }, 200);

        
        if (this._auth.userRole() == userRoles.Speaker.name) {
            this.isSpeaker = true;
        }

        this.getCoursesListBySubject(false);


    }


    ngOnInit(): void {

        this.getSpeakersList();

        this.assignCourseForm = this.formBuilder.group({
            assignCourse_speakers: ['', Validators.required],
            assignCourse_courses: ['', Validators.required],
            assignCourse_courseDates: ['', Validators.required]

        });


    }


    getSpeakersList() {

        this._coursesService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data;

                //this.cuForm.controls.speaker.patchValue(this.speakersList[0].speakerName);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    getLocationsList() {

        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data

                this.cuForm.controls.location.patchValue(this.locationsList[0].cityName);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {

                this.subjectsList = result.data

                this.cuForm.controls.subject.patchValue(this.subjectsList[0].title);


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    get f() { return this.cuForm.controls; }

    get i() { return this.insertForm.controls; }

    getUnassignedCoursesListNew(id: any) {
        
        this.showUnassignedCourses = false;

        if (id.value == null ||
            id.value == undefined)
            id = { value: id }

        var req = {
            CourseTitleId: id.value,
            SpeakerId: this.filter_Speaker
        }


        this._coursesService.getUnassignedCoursesList(req).subscribe((result: any) => {


            if (result.success == true) {
                
                this.currentCoursesList = result.data;

                this.showUnassignedCourses = true;

                this.events = [];

            } else {

                this._commonService.error("No unassigned course found.");
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getUnassignedCoursesList(id: any) {

        
        if (id.value == null ||
            id.value == undefined)
            id = { value: id }

        var req = {
            CourseTitleId: id.value,
            SpeakerId: this.filter_Speaker
        }


        this._coursesService.getUnassignedCoursesList(req).subscribe((result: any) => {


            if (result.success == true) {

                this.currentCoursesList = result.data

                this.events = [];
                var setDate = true;

                result.data.forEach((element: any) => {
                    if (element.datesToAssign != null) {
                        //if (setDate == true) {
                        //    this.viewDate = new Date(element.datesToAssign.day1Date);
                        //    setDate = false;
                        //}

                        if (element.datesToAssign.day1Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.datesToAssign.day1Date);
                                setDate = false;
                            }

                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.datesToAssign.id + '|d1|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.datesToAssign.day1Date)),
                                    end: new Date(element.datesToAssign.day1Date),
                                    title: "D1 - " + element.courseTitle,
                                    //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                                    color: colors.yellow,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }

                        if (element.datesToAssign.day2Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.datesToAssign.day2Date);
                                setDate = false;
                            }


                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.datesToAssign.id + '|d2|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.datesToAssign.day2Date)),
                                    end: new Date(element.datesToAssign.day2Date),
                                    title: "D2 - " + element.courseTitle,
                                    color: colors.red,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }

                        if (element.datesToAssign.day3Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.datesToAssign.day3Date);
                                setDate = false;
                            }


                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.datesToAssign.id + '|d3|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.datesToAssign.day3Date)),
                                    end: new Date(element.datesToAssign.day3Date),
                                    title: "D3 - " + element.courseTitle,
                                    color: colors.blue,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }

                        if (element.datesToAssign.day4Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.datesToAssign.day4Date);
                                setDate = false;
                            }


                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.datesToAssign.id + '|d4|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.datesToAssign.day4Date)),
                                    end: new Date(element.datesToAssign.day4Date),
                                    title: "D4 - " + element.courseTitle,
                                    color: colors.purple,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }


                        this.refresh.next();






                        //var _calData = {
                        //    start: subDays(startOfDay(element.datesToAssign.day1Date), 1),
                        //    end: addDays(element.datesToAssign.day1Date, 1),
                        //    title: element.courseTitle,
                        //    color: colors.green,
                        //    actions: this.actions,
                        //    allDay: true,
                        //    resizable: {
                        //        beforeStart: true,
                        //        afterEnd: true,
                        //    },
                        //    draggable: true
                        //}


                    }
                });

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getUnassignedCoursesDates(event:any) {

        if (event.value == null ||
            event.value == undefined)
            event = { value: event }

        var req = {
            CourseTitleId: event.value,
            //SpeakerId: this.filter_Speaker
        }

        

        this._coursesService.getUnassignedCoursesDates(req).subscribe((result: any) => {


            if (result.success == true) {

                this.coursesDatesList = result.data.courseDates;
                this.unassignedSpeakersList = result.data.unassignedSpeakers;

                this.isViewCourseDates = true;

               
                // todo: need to check all these variables
                this.events = [];
                this.showUnassignedCourses = false;
                //this.filter_SelectedCourse = 0;

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    getCoursesListBySubject(isCallingFromPopup) {


        
        var req = {
            SpeakerId: this.filter_Speaker
        };

        if (isCallingFromPopup == true) {
            req = {
                SpeakerId: this.assignCourseForm.controls.assignCourse_speakers.value
            };

            this.filter_Speaker = this.assignCourseForm.controls.assignCourse_speakers.value;
        }

        this._coursesService.getCoursesListBySubject(req).subscribe((result: any) => {


            if (result.success == true) {

                this.coursesList = result.data;

                this.showCoursesList = true;

                this.events = [];
                this.showUnassignedCourses = false;
                this.filter_SelectedCourse = 0;

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    updateData(event, newStart, newEnd) {



        
        var req = {
            startDate: new Date(newStart),
            //endDate: newEnd,
            toBeUpdate: event.cssClass
        };

        req.startDate = new Date();
        req.startDate.setDate(newStart.getDate());
        req.startDate.setMonth(newStart.getMonth());
        req.startDate.setFullYear(newStart.getFullYear());

        // update the data in DatesToAssign

        this._coursesService.updateCourseToAssignSchedule(req).subscribe((result: any) => {


            if (result.success == true) {



            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    deleteData(event) {
        var req = {
            //startDate: new Date(newStart),
            //endDate: newEnd,
            courseId: event.id,
            toBeUpdate: event.cssClass
        };

        this._coursesService.deleteCourseSchedule(req).subscribe((result: any) => {


            if (result.success == true) {

                this.events = this.events.filter((iEvent) => iEvent !== event);
                this.refresh.next();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    getScheduleData(ids: any) {


        var req = { ids: ids.value }
        this._coursesService.getScheduleData(req).subscribe((result: any) => {
            //this.loading = false;



            if (result.success == true) {




                this.events = [];

                result.data.forEach((element: any) => {
                    if (element.datesToAssign != null) {
                        this.viewDate = new Date(element.datesToAssign.day1Date);
                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.datesToAssign.day1Date)),
                                end: new Date(element.datesToAssign.day1Date),
                                title: "D1 - " + element.courseTitle,
                                //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                                color: colors.yellow,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.datesToAssign.day2Date)),
                                end: new Date(element.datesToAssign.day2Date),
                                title: "D2 - " + element.courseTitle,
                                color: colors.red,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.datesToAssign.day3Date)),
                                end: new Date(element.datesToAssign.day3Date),
                                title: "D3 - " + element.courseTitle,
                                color: colors.blue,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.datesToAssign.day4Date)),
                                end: new Date(element.datesToAssign.day4Date),
                                title: "D4 - " + element.courseTitle,
                                color: colors.purple,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];



                        this.refresh.next();






                        //var _calData = {
                        //    start: subDays(startOfDay(element.datesToAssign.day1Date), 1),
                        //    end: addDays(element.datesToAssign.day1Date, 1),
                        //    title: element.courseTitle,
                        //    color: colors.green,
                        //    actions: this.actions,
                        //    allDay: true,
                        //    resizable: {
                        //        beforeStart: true,
                        //        afterEnd: true,
                        //    },
                        //    draggable: true
                        //}


                    }
                });

                //if(result.data)
            } else {
                //this.clearForm();
                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

        //let headers = new HttpHeaders({
        //    'Content-Type': 'application/json ',
        //    'Authorization': 'Bearer ' + localStorage.getItem('Token')

        //});


        //this.http.get<any>(this.url + 'api/Account/v1/Verification', { headers }).subscribe(result => {
        //    //localStorage.setItem("Token", result.verification.token);
        //    //this.verificationData = result.verification;
        //}, error => console.log(error));


    }

    beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
        console.log(renderEvent.period.start);
        console.log(renderEvent.period.end);
        renderEvent.body.forEach((day) => {
            const dayOfMonth = day.date.getDate();
            if (dayOfMonth > 21 && dayOfMonth < 23 && day.inMonth) {
                //day.cssClass = 'bg-pink';
            }
        });
    }

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fas fa-fw fa-pencil-alt text-white"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {

                var eventDataArray: any;
                if (event.cssClass != null && event.cssClass != undefined) {
                    eventDataArray = event.cssClass.split("|");

                    this._subjectTitle = eventDataArray[3];
                    this._speaker = eventDataArray[4];
                    this._location = eventDataArray[5];


                }

                this.start_date = event.start;
                this.cuForm.controls.courseTitle.setValue(event.title);
                this.cuForm.controls.speaker.patchValue(this._speaker);
                this.cuForm.controls.location.patchValue(this._location);
                this.cuForm.controls.subject.patchValue(this._subjectTitle);
                this._title = "Edit";
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fas fa-fw fa-trash-alt text-white"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this._title = "Delete";


                //this._refDialog = this._dialog.open(DeleteComponent, {
                //    autoFocus: false,
                //    disableClose: true,
                //    data: { message: "Are you sure to delete this record?", title: "Delete" }
                //});

                //this._refDialog.componentInstance.redirect.subscribe((data) => {



                //    this.deleteData(event);
                //    //this.events = this.events.filter((iEvent) => iEvent !== event);
                //    //this.refresh.next();

                //    //this.deleteEvent(event);
                //    //this.handleEvent('Deleted', event);
                //});

                //this._refDialog.componentInstance.closePopup.subscribe((data) => {

                //    this._refDialog.close();
                //});












                //if (confirm("Are you sure to delete ")) {
                //    console.log("Implement delete functionality here");
                //}

                //this.modal.open(this.modalContentDetails, { size: 'lg' });

            },
        },
    ];

    //addNew() {
    //    this._title = "Add";
    //    this.modal.open(this.modalContentInsert, { size: 'lg' });
    //}

    onInsertSubmit() {

        var d2 = this.insertForm.controls.startDate_day2.value ? this.insertForm.controls.startDate_day2.value : null;
        var d3 = this.insertForm.controls.startDate_day3.value ? this.insertForm.controls.startDate_day3.value : null;
        var d4 = this.insertForm.controls.startDate_day4.value ? this.insertForm.controls.startDate_day4.value : null;


        var req = {
            subjectTitle: this.insertForm.controls.subject.value,
            courseTitle: this.insertForm.controls.courseTitle.value,
            location: this.insertForm.controls.location.value,
            dates: {
                day1Date: this.insertForm.controls.startDate_day1.value,
                day2Date: d2,
                day3Date: d3,
                day4Date: d4
            },
            speakerName: this.insertForm.controls.speaker.value


        };

        this._coursesService.insertCourseData(req).subscribe((result: any) => {


            if (result.success == true) {

                //if (event.cssClass != null && event.cssClass != undefined) {
                //    var eventDataArray = event.cssClass.split("|");

                //    this.events = this.events.filter((iEvent) => iEvent !== event);

                //    this.events = [
                //        ...this.events,
                //        {
                //            cssClass: eventDataArray[0] + '|' + eventDataArray[1] + '|' + eventDataArray[2] + '|' +
                //                req.subject + '|' + req.speakerName + '|' + req.location,
                //            id: event.id,
                //            start: startOfDay(new Date(req.startDate)),
                //            end: new Date(req.startDate),
                //            title: req.courseTitle,
                //            //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                //            color: colors.yellow,
                //            actions: this.actions,
                //            allDay: true,
                //            resizable: {
                //                beforeStart: true,
                //                afterEnd: true,
                //            },
                //            draggable: true

                //        },
                //    ];

                //    this.refresh.next();
                //}

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })



    }

    onUpdateSubmit(event) {


        var req = {
            startDate: this.cuForm.controls.StartDate.value,
            courseTitle: this.cuForm.controls.courseTitle.value,
            courseId: event.id,
            subject: this.cuForm.controls.subject.value,
            speakerName: this.cuForm.controls.speaker.value,
            location: this.cuForm.controls.location.value,
            toBeUpdate: event.cssClass
        };

        this._coursesService.updateCourseData(req).subscribe((result: any) => {


            if (result.success == true) {

                if (event.cssClass != null && event.cssClass != undefined) {
                    var eventDataArray = event.cssClass.split("|");

                    this.events = this.events.filter((iEvent) => iEvent !== event);

                    this.events = [
                        ...this.events,
                        {
                            cssClass: eventDataArray[0] + '|' + eventDataArray[1] + '|' + eventDataArray[2] + '|' +
                                req.subject + '|' + req.speakerName + '|' + req.location,
                            id: event.id,
                            start: startOfDay(new Date(req.startDate)),
                            end: new Date(req.startDate),
                            title: req.courseTitle, // need to update the day in title
                            //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                            color: colors.yellow,
                            actions: this.actions,
                            allDay: true,
                            resizable: {
                                beforeStart: true,
                                afterEnd: true,
                            },
                            draggable: true

                        },
                    ];

                    this.refresh.next();
                }

                this.modal.dismissAll();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })


    }

    refresh = new Subject<void>();

    events: CalendarEvent[] = [
        //{
        //    start: subDays(startOfDay(new Date()), 1),
        //    end: addDays(new Date(), 1),
        //    title: 'A 3 day event',
        //    color: colors.red,
        //    actions: this.actions,
        //    allDay: true,
        //    resizable: {
        //        beforeStart: true,
        //        afterEnd: true,
        //    },
        //    draggable: true,
        //},
        //{
        //    start: startOfDay(new Date()),
        //    title: 'An event with no end date',
        //    color: colors.yellow,
        //    actions: this.actions,
        //},
        //{
        //    start: subDays(endOfMonth(new Date()), 3),
        //    end: addDays(endOfMonth(new Date()), 3),
        //    title: 'A long event that spans 2 months',
        //    color: colors.blue,
        //    allDay: true,
        //},
        //{
        //    start: addHours(startOfDay(new Date()), 2),
        //    end: addHours(new Date(), 2),
        //    title: 'A draggable and resizable event',
        //    color: colors.yellow,
        //    actions: this.actions,
        //    resizable: {
        //        beforeStart: true,
        //        afterEnd: true,
        //    },
        //    draggable: true,
        //},
    ];

    activeDayIsOpen: boolean = false;



    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

        //if (isSameMonth(date, this.viewDate)) {
        //    if (
        //        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        //        events.length === 0
        //    ) {
        //        this.activeDayIsOpen = false;
        //    } else {
        //        this.activeDayIsOpen = true;
        //    }
        //    this.viewDate = date;
        //}
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {

        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {

                this.updateData(event, newStart, newEnd);

                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {

        //this.modalData = { event, action };

        //if (action == "Clicked") {

        //    var eventDataArray: any;
        //    if (event.cssClass != null && event.cssClass != undefined) {
        //        eventDataArray = event.cssClass.split("|");

        //        this._subjectTitle = eventDataArray[3];
        //        this._speaker = eventDataArray[4];
        //        this._location = eventDataArray[5];


        //    }

        //    this._title = "Details";

        //    this.modal.open(this.modalContentDetails, { size: 'lg' });
        //}
        //else if (action == "Edited") {
        //    this.modal.open(this.modalContentEdit, { size: 'lg' });
        //}

    }

    addEvent(): void {

        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.red,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
            },
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {

        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }


    monthlySchedule() {
        this._paramOut.showMonthlySchedule = true;
        this.gotoSchedule.emit(this._paramOut);
    }

    yearlySchedule() {
        this._paramOut.showYearlySchedule = true;
        this.gotoSchedule.emit(this._paramOut);
    }

    sendReviewRequest() {
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to send admin review request?", title: "Send Request" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {
            

            // need to write the service to send email to speaker



            this._coursesService.sendReviewRequestToAdmin().subscribe((result: any) => {


                if (result.success == true) {

                    this._commonService.success("Link sent successfully.");

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })



        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {

            this._refDialog.close();
        });
    }

    onTrainerSelected(event) {

        this.speakerName = event.source.selected._element.nativeElement.innerText;
    }

    onAssignCourseFormSubmit() {
        //this.modal.open(this.modalAssignCourse, { size: 'lg' });
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to assign \"" + this.selectedCourse + "\" to \"" + this.speakerName + "\"? By assigning this course, other speakers will not be able to see this.", title: "Assign course" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {


            // need to write the service to send email to speaker

            if (Number(this.filter_Speaker) > 0) {
                var req = {
                    SpeakerId: this.filter_Speaker,
                    CourseId: this.assignCourseForm.controls.assignCourse_courseDates.value
                };

                this._coursesService.assignSpeakerToCourse(req).subscribe((result: any) => {


                    if (result.success == true) {

                        this._commonService.success("Course assigned successfully.");

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }
            else {

                this._commonService.error("Speaker not selected.");
            }


        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {

            this._refDialog.close();
        });

        
        //if (confirm("Are you sure to delete ")) {
        //    console.log("Implement delete functionality here");
        //}
    }

    sendLinkToSpeaker() {
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to send link to a speaker to check availability by see/suggest upcoming course dates?", title: "Send Email" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {


            // need to write the service to send email to speaker

            if (Number(this.filter_Speaker) > 0) {
                var req = {
                    SpeakerId: this.filter_Speaker
                    //SpeakerName: this.speakerName
                };

                this._coursesService.sendCourseSchedulerLink(req).subscribe((result: any) => {


                    if (result.success == true) {

                        this._commonService.success("Link sent successfully.");

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }
            else {

                this._commonService.error("Speaker not selected.");
            }


        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {

            this._refDialog.close();
        });

        
        //if (confirm("Are you sure to delete ")) {
        //    console.log("Implement delete functionality here");
        //}


    }


    close() {
        this.isAssign = false;
        this.modal.dismissAll();
    }

    onCourseDatesSelected(event) {
        this.selectedCourse = event.source.selected._element.nativeElement.innerText;
    }

    assignCoursePopup() {
        this.isAssign = true;
        this.modal.open(this.modalAssignCourse, { size: 'lg' });

    }

    searchBySpeaker() {
        this.isSearchBySpeaker = true;
        this.isSearchByCourse = false;
        this.isViewCourseDates = false;

        this.showCoursesList = false;
        this.showUnassignedCourses = false;
    }

    searchByCourse() {
        this.isSearchBySpeaker = false;
        this.isSearchByCourse = true;
        this.isViewCourseDates = false;

        // reset the speakerId
        //this.filter_Speaker = 0;
        this.getCoursesListBySubject(false);
    }

    ViewCourseDates() {
        this.isSearchBySpeaker = false;
        this.isSearchByCourse = false;

        this.isViewCourseDates = true;

        this.filter_Speaker = 0;
        this.getCoursesListBySubject(false);
        //this.getUnassignedCoursesDates();
    }
}
