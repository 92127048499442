


<div class="row">
    <div class="col-lg-7">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">{{title}}</span>

        </h2>

    </div>
    <div class="col-lg-5 pt-1">

        <!--<button class="book-btn pull-right filter-apply" (click)="addNew()">
            Add Organisation

        </button>-->

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNew()">Add Organisation</button>
                <button mat-menu-item (click)="getOrganisationList(false)">Organisations</button>
                <button mat-menu-item (click)="upcomingCourses()">Upcoming Courses</button>


                <!--<button mat-menu-item (click)="allCourses(false)">All Courses</button>
                <button mat-menu-item (click)="allCourses(true)">Archive Courses</button>-->
            </mat-menu>
        </div>

        <!--<button class="book-btn pull-right filter-apply mr-1" (click)="getInhouseList(false)">
            All Trainings
        </button>
        <button class="book-btn pull-right filter-apply mr-1" (click)="getInhouseList(true)">
            Upcoming Trainings
        </button>-->

    </div>

</div>


<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>


<ag-grid-angular #agGrid
                 id="organisationGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="organisationList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">

</ag-grid-angular>

