import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { BaseRequest } from '../common/model/request';
import { CommonServiceService } from '../common/_services/common-service.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    baseurl: string;

    constructor(private _router: Router,
        private _commonService: CommonServiceService,
        private http: HttpClient, @Inject('BASE_URL') base: string) {
        this.baseurl = base;
    }

    

    login(req: any) {
        
        return this.http.post<any>(this.baseurl + 'api/Account/v1/Login', req)
            .pipe(map(response => {
                return response;
            }));
    }

    authorize(req: any) {
        
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': 'Bearer ' + localStorage.getItem('Token')

        });
        
        return this.http.post<any>(this.baseurl + 'api/Account/v1/Authorize', req, { headers })
            .pipe(map(response => {
                return response;
            }));
    }

    verifySecretKey(req: any) {

        return this.http.post<any>(this.baseurl + 'api/Account/v1/VerifySecretKey', req)
            .pipe(map(response => {
                return response;
            }));
    }
}


