import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { FinanceService } from '../../../common/_services/finance-services';

@Component({
    selector: 'app-speaker-course-numbers',
    templateUrl: './speaker-course-numbers.component.html',
    styleUrls: ['./speaker-course-numbers.component.css']
})
export class SpeakerCourseNumbersComponent implements OnInit {

    _paramOut = {
        gotoAdmin: ""
    }

    @Output() gotoSpeakerDetails = new EventEmitter<object>();
    @Output() gotoAdminTab = new EventEmitter<object>();

    __endDate = new Date();
    
    coursesCountDateFilter = new FormGroup({
        //start: new FormControl(new Date(year, month, 13)),
        //end: new FormControl(new Date(year, month, 16)),

        start: new FormControl(new Date()),
        end: new FormControl(new Date()),
    });

    speakersList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    paginationPageSize: any = 50;


    constructor(private _financeService: FinanceService) {

        this.__endDate.setDate(this.__endDate.getDate() + 90);
        
        this.coursesCountDateFilter = new FormGroup({
            start: new FormControl(new Date()),
            end: new FormControl(new Date(this.__endDate.getFullYear(), this.__endDate.getMonth(), this.__endDate.getDate())),
        });


        this.gridOptions = {
            suppressCellSelection: true,
            context: {
                componentParent: this
            }
        };

        this.domLayout = "autoHeight";
    }

    ngOnInit(): void {


        localStorage.setItem('projectPageSize', '10');
        this.getAllSpeakersCoursesList();
    }

    gotoAdmin(param) {
        this._paramOut.gotoAdmin = param;
        this.gotoAdminTab.emit(this._paramOut);
    }

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'speaker_Name', headerName: 'Title' },
        { field: 'initials', headerName: 'Initials' },
        { field: 'email', headerName: 'Email' },
        { field: 'mobile_Number', headerName: 'Mobile Number' },
        { field: 'total_Courses', headerName: 'Total Courses' },
        //{
        //    field: 'Action', cellRendererFramework: SpeakersClickableParentComponent,
        //    sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        //}
    ];

    //convertStringToDate(params) {

    //    var strDate = params.value;
    //    if (strDate != null && strDate != "" && strDate != undefined) {
    //        var date = new Date(strDate);
    //        var dd = String(date.getDate()).padStart(2, '0');
    //        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    //        var yyyy = date.getFullYear();

    //        var _date = dd + '/' + mm + '/' + yyyy;
    //        return _date;

    //        //return new Date(strDate).toString();
    //    }
    //    else return "";
    //    //else return new Date().toString();
    //}

    //reconcileCellRendererFunc(params) {
    //    var strDate = params.value;
    //    if (params.value == true)
    //        return "<div class='paid status-circles mt-10px'></div>";
    //    else return "<div class='not-paid status-circles mt-10px'></div>";
    //}

    public rowData = [];
    //    { make: 'Toyota', model: 'Celica', price: 35000 },
    //    { make: 'Ford', model: 'Mondeo', price: 32000 },
    //    { make: 'Porsche', model: 'Boxster', price: 72000 }
    //];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;



    //setAutoHeight() {
    //    this.gridApi.setDomLayout("autoHeight");
    //    //document.querySelector("#dGrid").style.height = "";
    //    var elm = document.querySelector("#speakersGrid") as HTMLElement;


    //    var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

    //    // elm_outer.offsetHeight 
    //    elm.style.height = screen.height - 300 + "px";
    //    //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    //}

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#speakersGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            //{ make: 'Toyota', model: 'Celica', price: 35000 },
            //{ make: 'Ford', model: 'Mondeo', price: 32000 },
            //{ make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);

        //if (e.colDef.field != "Action")
            this.gotoSpeakerDetails.emit(e.data);

    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }






    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    //methodName = {

    //    nonArchive: "nonArchive",
    //    archive: "archive"
    //}


    addEvent(event: MatDatepickerInputEvent<Date>) {

        this.getAllSpeakersCoursesList();
    }

    getAllSpeakersCoursesList() {


        var req = {
            startDate: this.coursesCountDateFilter.value.start,
            endDate: this.coursesCountDateFilter.value.end
        }


        this._financeService.getAllSpeakersCoursesList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data;
                debugger;
                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    //addNew() {
    //    const modalRef = this.modal.open(AddEditSpeakersComponent, {
    //        size: 'lg'
    //    });

    //    modalRef.result.then((result) => {

    //        this.getSpeakersList();
    //    }, (reason) => {

    //        //console.log('Dismissed action: ' + reason);
    //    });
    //}

    //openModal(param) {

    //    const modalRef = this.modal.open(AddEditSpeakersComponent, {
    //        size: 'lg'
    //    });
    //    modalRef.componentInstance.data = param;

    //    if (localStorage.getItem('projectPageSize') != null)
    //        this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

    //    modalRef.componentInstance.refreshGrid.subscribe(event => this.getSpeakersList());
    //}


}
