import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-summary',
  templateUrl: './success-summary.component.html',
  styleUrls: ['./success-summary.component.css']
})
export class SuccessSummaryComponent implements OnInit {

    summary: any;

    constructor(public dialogRef: MatDialogRef<SuccessSummaryComponent>, private _route: Router,
        //@Optional() is used to prevent error if no data is passed
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
        this.summary = data.data;
    }

    ngOnInit() {
    }


    close() {
        this._route.navigateByUrl("/home/dashboard");
        this.dialogRef.close(true);
    }

}
