import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { CoursesService } from '../../../common/_services/courses-services';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { AddCourseComponent } from '../add-course/add-course.component';

@Component({
    selector: 'app-course-clickable-parent',
    template: `
        <app-course-actions (onClicked)="clicked($event)" [cell]="cell"></app-course-actions>
    `
})
export class CourseClickableParentComponent implements OnInit {

    private params: any;
    public cell: any;
    public _refDialog: any;
    public url = environment.apiURL;
    @Output() openModal = new EventEmitter<any>();
    @Output() refreshGrid = new EventEmitter();
    
    agInit(params: any): void {
        //
        this.params = params;
        this.cell = { row: params.value, col: params.colDef.headerName, id: params.data.courseId };

        //console.log(this.params.context.componentParent);
    }

    constructor(private _router: Router,
        private modal: NgbModal,
        private _dialog: MatDialog,
        private _courseService: CoursesService) {

    }

   

    public clicked(data: any): void {
        //
        if (data.Type == 'view')
            location.href = "view-course/" + data.Cell.id;
        //this._router.navigateByUrl("view-project/" + data.Cell.pid);

        if (data.Type == 'edit') {
           
            this.params.context.componentParent.openModal(this.params.data);

            //this.params.action(this.params);
            //this.openModal.emit();
            //this.edit(this.params.data);
            //location.href = "course?id=" + data.Cell.id;
            //this._router.navigateByUrl("edit-project/" + data.Cell.pid);
        }

        if (data.Type == 'delete')
            this.delete(data.Cell.id);

    }



    //edit(_data) {
    //    
    //    this._refDialog = this._dialog.open(AddCourseComponent, {
    //        autoFocus: false,
    //        //disableClose: true,
    //        data: { _data },

    //    });
    //}




    delete(id: any) {

        

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {
           
            if (id > 0) {
                this._courseService.deleteCourse(id).subscribe((result: any) => {

                if (result.success == true) {
                    this.params.context.componentParent.refreshGridMethod();

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }



        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });

    }

    genericMessage(Desc: any, Status: string) {

        //this._refDialog = this._dialog.open(ShowMessageComponent, {
        //    disableClose: true,
        //    autoFocus: false,
        //    data: { message: Desc, status: Status }
        //});

        //this._refDialog.componentInstance.closeGenericMessasge.subscribe((data) => {
        //    this._refDialog.close();
        //    
        //    if (Status == "Success")
        //        location.href = 'projects';
        //    //else if (Status == "Completed")
        //    //  this._router.navigateByUrl('projects');
        //});

    }

    ngOnInit() {
    }

    refresh(): boolean {
        return false;
    }

}
