import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../common/guards/auth.guard';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404',
        canActivate: [AuthGuard],
    },
    { path: 'all-invoices', component: DashboardComponent },
    {
        path: '',
        redirectTo: 'all-invoices',
        pathMatch: 'full'
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoicesRoutingModule { }
