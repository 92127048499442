<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>






<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="delegateEditForm" (ngSubmit)="onSubmit()" name="delegateEditForm" autocomplete="off">

        <div class="row">

            <div class="col-md-6">
                <label class="text-second">Name:</label>
                <input type="text" class="form-control no-radius" placeholder="Delegate name"
                       [ngClass]="{  'is-invalid': f.name.errors}"
                       required formControlName="name" maxlength="250" />
                <div *ngIf="f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.pattern">{{errors.name}}</div>
                </div>
            </div>
            <div class="col-6">
                <label class="text-second">Organisation:</label>
                <input type="text" class="form-control no-radius" placeholder="Organisation"
                       formControlName="organization" maxlength="250" />
            </div>
            <div class="col-6">
                <label class="text-second">Sector:</label>
                <input type="text" class="form-control no-radius" placeholder="Sector"
                       formControlName="sector" maxlength="250" />
            </div>
            <div class="col-6">
                <label class="text-second">Department:</label>
                <input type="text" class="form-control no-radius" placeholder="Department"
                       formControlName="department" maxlength="250" />
            </div>
            <div class="col-12">
                <label class="text-second">Address Line 1:</label>
                <input type="text" class="form-control no-radius" placeholder="Address Line 1"
                       [ngClass]="{  'is-invalid': f.address1.errors}"
                       required formControlName="address1" maxlength="250" />

                <label class="text-second">Address 2:</label>
                <input type="text" class="form-control no-radius" placeholder="Address Line 2"
                       formControlName="address2" maxlength="250" />

            </div>
            <div class="col-6">
                <label class="text-second">City:</label>
                <!--<mat-select formControlName="location" #select
                            class="form-control no-radius text-second">
                    <mat-option *ngFor="let loc of locationsList" [value]="loc.cityId">{{loc.cityName}}</mat-option>
                </mat-select>-->

                <input type="text" class="form-control no-radius" placeholder="Town/City"
                       [ngClass]="{  'is-invalid': f.location.errors}"
                       required formControlName="location" maxlength="250" />
                <div *ngIf="f.location.errors" class="invalid-feedback mt-0">
                    <div *ngIf="f.location.errors.pattern">{{errors.required}}</div>
                </div>
            </div>
            <div class="col-6">
                <label class="text-second">Postcode:</label>
                <input type="text" class="form-control no-radius" placeholder="Postcode"
                       [ngClass]="{  'is-invalid': f.postcode.errors}"
                       required formControlName="postcode" maxlength="20" />
                <div *ngIf="f.postcode.errors" class="invalid-feedback">
                    <div *ngIf="f.postcode.errors.pattern">{{errors.postcode}}</div>
                </div>
            </div>
            <div class="col-6">
                <label class="text-second">Telephone:</label>
                <input type="text" class="form-control no-radius" placeholder="Telephone"
                       formControlName="telephone" maxlength="50" />
            </div>
            <div class="col-6">

                <label class="text-second">Email:</label>
                <input type="text" class="form-control no-radius" placeholder="Email"
                       [ngClass]="{  'is-invalid': f.email.errors && f.email.errors.pattern}"
                       required formControlName="email" maxlength="250" />
                <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.pattern">{{errors.email}}</div>
                </div>
                <!--{{f.email.errors && f.email.errors.pattern}}
                    {{delegateEditForm.valid}}-->
                </div>
                <div class="col-12">

                    <div class="row">

                        <div class="col-md-2">
                            <label class="text-second">
                                Notes:
                            </label>
                        </div>
                        <div class="col-md-10 mt-2">
                            <textarea class="form-control  no-radius" formControlName="notes" placeholder="10,000 Characters Limit" maxlength="10000" rows="5"></textarea>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">


                <button type="button" class="book-btn" (click)="activeModal.close('Close click')">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!delegateEditForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>
                <!--<button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>-->
            </div>

    </form>

    <!--<button class="book-btn" (click)="delete(1)">
        Delete
    </button>-->
</div>

