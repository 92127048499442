
<div class="modal-header primary confirmation-header">

    <h5 class="modal-title primary">{{Header}}</h5>

</div>
<div class="modal-body- text-center p-4">

    
    <p>{{Text}}</p>
</div>

<div class="modal-footer modal-footer-button-margin">

    <button type="button" (click)="close()" class="book-btn">
        No
    </button>

    <button type="button" (click)="ok()" mat-dialog-close class="book-btn">
        Yes
    </button>
</div>

<!--<div class="row">

    <div class="col-md-2">
    </div>
    <div class="col-md-4">
        
    </div>

    <div class="col-md-4 ">
       
    </div>
    <div class="col-md-2">
    </div>
</div>-->


<!--<div class="modal-footer">
</div>-->
