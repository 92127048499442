import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError, map } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class RequestService {

    baseurl: string;
    token: string;
    _header_options = {};

    constructor(private _router: Router,
        private http: HttpClient,
        @Inject('BASE_URL') base: string,
        private modal: NgbModal,
    ) {
        this.baseurl = base;
        this.token = 'Bearer ' + localStorage.getItem('Token');

        this._header_options = {
            'Content-Type': 'application/json ',
            'Authorization': this.token
        }
    }

    POST_REQUEST(_HEADER_OPTIONS, _BASE_URL, _PATH, _REQUEST_PARAMS) {
        let headers = new HttpHeaders(_HEADER_OPTIONS);
        debugger;
        return this.http.post<any>(_BASE_URL + _PATH,
            JSON.stringify(_REQUEST_PARAMS), { headers })
            //.pipe(map(resp => {


            //    debugger;
            //    return resp;
            //}
            //));


            .pipe(
                map(result => {
                    //debugger;
                    if (result == null) {
                        this.modal.dismissAll();
                        this._router.navigateByUrl("/account/login");
                    }
                    else return result;
                }),
                catchError((err, caught) => {
                    //debugger;
                    console.log(err);
                    return "exception";
                })
            )
    }
}