import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Color } from 'highcharts/highcharts-gantt';
import { BehaviorSubject } from 'rxjs';
import { CoursesService } from '../../../common/_services/courses-services';
import * as Highcharts from "highcharts/highcharts-gantt";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

declare var require: any;
let noData = require("highcharts/modules/no-data-to-display");
let boost = require("highcharts/modules/boost");
let moreHighcharts = require("highcharts/highcharts-more");

noData(Highcharts);
boost(Highcharts);
moreHighcharts(Highcharts);

const today = new Date();
const day = today.getDate();
const month = today.getMonth() + 1;
const year = today.getFullYear();

@Component({
    selector: 'app-yearly-schedule',
    templateUrl: './yearly-schedule.component.html',
    styleUrls: ['./yearly-schedule.component.css']
})
export class YearlyScheduleComponent implements OnInit {

    _paramOut = {
        showMonthlySchedule: false,
        showYearlySchedule: false,
        showSpeakersSchedule: false
    }

    @Output() gotoSchedule = new EventEmitter<object>();


    __startDateDisplay: any = [];
    __endDateDisplay: any = [];
    //public today: Date = new Date();
    public cars: any;
    public dateFormat: any;// = Highcharts.dateFormat;
    public series: any;
    updateFlag = false;
    selectedCourse: any;
    public that = this;
    filter_Subject: any = 0;
    filter_CourseType: any = 0;
    filter_Speaker: any = 0;
    filter_Location: any = 0;

    public speakersList: any;
    public locationsList: any;
    public subjectsList: any;
    public courseTypesList: any;

    flag = true;

    public coursesList: any;
    public courses: any;

    width;
    height;
    minWidth:any = "100%";
    maxHeight;
    chartWidth;

    showFiltersModel: any = false;
    showModel: any = false;
    //subject = new BehaviorSubject('init ;');

    loading = false;

    private source$ = new BehaviorSubject<Highcharts.Options>({});
    _data = this.source$.asObservable();

    Highcharts: typeof Highcharts = Highcharts;

    constructor(private _coursesService: CoursesService) {

        //this.dateAdapter.setLocale('en-GB');

        setTimeout(() => {
            $("#main_crs div:nth-child(1) div:first-child").css("color", "white");
            $("#main_crs div:nth-child(2) div:first-child").css("color", "white");
        }, 200);


    }

    ngOnInit() {
        //let _chartOptions = this._chartOptions();
        //let chart = Highcharts.ganttChart("chart", this.ChartOptions());
        $("#advanced-filters").hide();

        this.width = 100; this.height = 250; this.minWidth = '100%'; this.maxHeight = '100%'; this.chartWidth = true;
        this.getCoursesList();


        this.getLocationsList();
        this.getSpeakersList();
        this.getSubjectsList();
        this.getCourseTypesList();
    }

    getCourseTypesList() {
        this.loading = true;
        this._coursesService.getCourseTypesList().subscribe((result: any) => {

            if (result.success == true) {

                this.courseTypesList = result.data;

                this.loading = false;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    getLocationsList() {
        this.loading = true;
        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data;
                this.loading = false;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getSpeakersList() {
        this.loading = true;
        this._coursesService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data;

                this.loading = false;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {
                this.subjectsList = result.data;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getCoursesList() {

        this._coursesService.getCoursesList().subscribe((result: any) => {

            if (result.success == true) {

                this.courses = result.data;
                this.selectedCourse = 0;
                setTimeout(() => {
                    this.selectedCourse = 0;
                }, 400);
                //this.selectedCourse = this.courses[0].title;
                //this.getYearlySchedule({ value: this.selectedCourse });
                this.applyFilters();

            } else {

                //this._commonService.error(result.data.desc);
            }
        }, (_error: any) => {
            //this.loading = false;
        })
    }

    showAdvancedFilters(isShowFilter) {

        if (isShowFilter) {

            $("#advanced-filters").show();
            //this.showFiltersModel = true;
        }
        else {
            $("#advanced-filters").hide();
            //resetFilters();
            //this.showFiltersModel = false;
        }
    }

    showAll(isShowAll) {

        if (isShowAll) {
            this.minWidth = '200%';
            this.chartWidth = false;
            this.maxHeight = '1020px';
            this.getYearlySchedule({});
            this.showModel = true;
        }
        else {
            this.minWidth = '100%';
            this.chartWidth = true;
            this.maxHeight = '100%';
            //this.getYearlySchedule({ value: this.selectedCourse });
            this.applyFilters();
            this.showModel = false;
        }
    }

    applyFilters() {

        this.showModel = false;
        this.minWidth = '100%';
        this.chartWidth = true;
        this.maxHeight = '100%';
        
        if (this.selectedCourse == 0) {
            this.selectedCourse = "";
            this.minWidth = '200%';
            this.chartWidth = false;
            this.maxHeight = '1020px';
        }

        var req = {
            startDate: this.campaignOne.value.start,
            endDate: this.campaignOne.value.end,
            subject: "",
            subjectId: this.filter_Subject,
            courseTitle: this.selectedCourse,
            courseTypeId: this.filter_CourseType,
            cityId: this.filter_Location,
            speakerId: this.filter_Speaker
        };



        this.getYearlySchedule(req);

        if (this.selectedCourse == "")
            this.selectedCourse = 0;
    }

    applyDropdown(title: any) {
        this.showModel = false;
        this.minWidth = '100%';
        this.chartWidth = true;
        this.maxHeight = '100%';
        this.getYearlySchedule(title);
    }

    getYearlySchedule(request: any) {

        var s_dateObj_display = new Date();
        var s_month_display = s_dateObj_display.getUTCMonth();
        var s_day_display = s_dateObj_display.getUTCDate();
        var s_year_display = s_dateObj_display.getUTCFullYear();

        var e_dateObj_display = new Date();
        var e_month_display = e_dateObj_display.getUTCMonth();
        var e_day_display = e_dateObj_display.getUTCDate();
        var e_year_display = e_dateObj_display.getUTCFullYear();



        //var req = {
        //    startDate: this.campaignOne.value.start,
        //    endDate: this.campaignOne.value.end,
        //    subject: "",
        //    subjectId: 0,
        //    courseTitle: title.value,
        //    courseTypeId: 0,
        //    cityId: 0,
        //    speakerId:0
        //};


        this._coursesService.getYearlySchedule(request).subscribe((result: any) => {

            if (result.success == true) {
                $("#divchart").show();

                //
                this.coursesList = result.data

                var data: any[] = [];
                this.__startDateDisplay = [];
                this.__endDateDisplay = [];
                var _subject = "";
                var _y = 1;
                var _overlapped = true;

                this.series = [{}];

                this.multipleDateRangeOverlaps(this.coursesList);
                
                this.coursesList.forEach((element: any) => {
                    if (element.dates.courseStartDate != null
                        && element.dates.courseEndDate != null) {

                        this.__startDateDisplay.push(element.dates.courseStartDate);
                        this.__endDateDisplay.push(element.dates.courseEndDate);

                        var s_dateObj = new Date(element.dates.courseStartDate);
                        var s_month = s_dateObj.getUTCMonth();
                        var s_day = s_dateObj.getUTCDate();
                        var s_year = s_dateObj.getUTCFullYear();


                        var e_dateObj = new Date(element.dates.courseEndDate);
                        var e_month = e_dateObj.getUTCMonth();
                        var e_day = e_dateObj.getUTCDate();
                        var e_year = e_dateObj.getUTCFullYear();


                        var _color = '#0d6efd'; // blue


                        if (element.location.toLowerCase() == 'online')
                            _color = '#9d257e';
                        else
                            _color = '#FFA500';


                        if (element.isOverlapped == true) {
                            var obj = {
                                id: element.courseTitle,
                                name: element.courseTitle,
                                start: Date.UTC(s_year, s_month, s_day),
                                end: Date.UTC(e_year, e_month, e_day),
                                color: _color,
                                className: _overlapped ? "tile-bg overlapped-up " + element.location : "tile-bg overlapped-down " + element.location,
                                //y:_y
                                //completed: 0.25
                            };
                            _overlapped = !_overlapped;
                        }
                        else
                            var obj = {
                                id: element.courseTitle,
                                name: element.courseTitle,
                                start: Date.UTC(s_year, s_month, s_day),
                                end: Date.UTC(e_year, e_month, e_day),
                                color: _color,
                                className: "tile-bg " + element.location,
                                //y:_y
                                //completed: 0.25
                            };





                        _y = _y + 1;

                        data.push(obj);


                        const minDate = new Date(
                            Math.min(
                                ...this.__startDateDisplay.map(element => {
                                    return new Date(element);
                                }),
                            ),
                        );



                        s_dateObj_display = minDate;
                        s_month_display = s_dateObj_display.getUTCMonth();
                        s_day_display = s_dateObj_display.getUTCDate();
                        s_year_display = s_dateObj_display.getUTCFullYear();

                        const maxDate = new Date(
                            Math.max(
                                ...this.__endDateDisplay.map(element => {
                                    return new Date(element);
                                }),
                            ),
                        );


                        e_dateObj_display = maxDate;
                        e_month_display = e_dateObj_display.getUTCMonth();
                        e_day_display = e_dateObj_display.getUTCDate();
                        e_year_display = e_dateObj_display.getUTCFullYear();


                    }

                    _subject = element.subjectTitle + ' - ' + '<br>' + element.speakerName;
                });


                this.series = [{
                    type: "gantt",
                    name: _subject,
                    borderWidth: 1.5,
                    borderColor: '#20396f', // blue text color
                    data: data,
                    turboThreshold: 1000000
                }];



                this.updateFlag = true;
                this.chartOptions = {
                    title: {
                        text: "Courses Yearly Schedule"
                    },
                    accessibility: {
                        keyboardNavigation: {
                            seriesNavigation: {
                                mode: 'serialize'
                            }
                        }
                    },
                    xAxis: {

                        //height: '100%',
                        width: '100%',
                        plotLines: [{
                            value: Date.UTC(year, month, day),
                            color: 'green',
                            width: 2
                        }],
                        //scrollbar: {
                        //    enabled: true
                        //},
                        //tickInterval: 1000 * 60 * 60,
                        //opposite: false,
                        //labels: {
                        //    style: {
                        //        'color': 'rgba(0, 0, 0, 0.87)',
                        //        textAlign: 'center !important'
                        //    }
                        //},
                        min: Date.UTC(s_year_display, s_month_display, s_day_display - 5, 10), // Add the gap in days before
                        max: Date.UTC(e_year_display, e_month_display, e_day_display + 5, 10)  // add the gap in days after
                        //min: Date.UTC(2023, 0, 1, 10),
                        //max: Date.UTC(2023, 11, 30, 10)
                    },
                    series: this.series,

                    yAxis: {

                        //uniqueNames: true,
                        scrollbar: {
                            enabled: true,
                            showFull: false,
                            liveRedraw: false
                        },
                        grid: {
                            enabled: true
                        },
                        breaks: [
                            {
                                breakSize: 0.5,
                                from: 0,
                                to: 0
                            }
                        ],
                        //reversed: true,
                        gridLineWidth: 3,
                        //gridLineDashStyle: 'LongDashDot',
                        uniqueNames: true,
                        height: '100%',
                        width: '100%',
                        //minWidth :'200%',
                        opposite: false,

                    },
                    //navigator: {
                    //    adaptToUpdatedData: false,
                    //    enabled: true,
                    //    series: {
                    //        type: "gantt",
                    //        pointPlacement: 0.5,
                    //        pointPadding: 0.25,
                    //        marker: { enabled: false }
                    //    },
                    //    yAxis: {
                    //        reversed: true
                    //        //categories: []
                    //    }
                    //},
                    chart: {
                        //height: this.height,
                        //width: this.width
                        scrollablePlotArea: {
                            minWidth: this.minWidth, // setting the chart width
                            scrollPositionX: 1
                        },
                        events: {
                            click() {

                            },
                            load() {
                                //let chart = this;

                                //const point = chart.series[0].points[1];


                                //console.log(point);
                                //point.graphic.translate(0, -1 * point.shapeArgs.height);
                                //chart.series[0].points[1].update({
                                //    dataLabels: {
                                //        y: -1 * point.shapeArgs.height
                                //    }
                                //}, false);
                                //
                                //setTimeout(() => {
                                //    chart.series[0].addPoint(point);//.points[0].graphic.translate(0, 10);
                                //}, 200);
                                //point.series.graph?.translate(0,10);
                                //chart.series[0].points[0].graphic.translate(0, 10);
                                //chart.series[0].points[0].graphic.translate(0, 10);
                                //chart.series[0].points[2].graphic.translate(0, 12);
                            }
                        }
                    },
                    time: {
                        useUTC: false,
                    },
                    "tooltip": {
                        "xDateFormat": '%A, %b %e, %Y',
                        formatter: function () {
                            //let parent = (this.point.parent) ? (this.point.parent) : this.key;

                            var _loc = this.point.getClassName().split(' ').pop();

                            var tt = this.series.name.split('-');

                            var spkr = this.series.name.split('<br>');

                            this.series.name = tt[0].toString() + ' - ' + (_loc ? _loc : '') + '<br>' + spkr[1];


                            const cDate = {
                                x2: this.point.x2,
                            };

                            var currentDate = this.point.x2 ? this.point.x2 : 0;
                            var numDate = new Date(currentDate);
                            numDate.setDate(numDate.getDate() - 1);


                            return this.series.name + '<br />' + this.key + '<br />' +
                                'Start: ' + Highcharts.dateFormat('%A, %b %d %Y', this.point.x) + '<br>' +
                                'End: ' + Highcharts.dateFormat('%A, %b %d %Y', numDate.getTime());

                        }
                    },
                    plotOptions: {
                        series: {
                            //opacity:0.5,
                            animation: false,
                            events: {
                                afterAnimate() {
                                    //let chart = this;

                                    //const point = this.chart.series[0].points[1];

                                    //console.log(point);
                                    //
                                    ////point.graphic.translate(0, -1 * point.shapeArgs.height);
                                    //this.chart.series[0].points[1].update({
                                    //    dataLabels: {
                                    //        y: -1 * point.shapeArgs?.height
                                    //    }
                                    //}, false);



                                    //setTimeout(() => {
                                    //    chart.series[0].addPoint(point);//.points[0].graphic.translate(0, 10);
                                    //}, 200);
                                    //point.series.graph?.translate(0, 10);
                                    //chart.series[0].points[0].graphic.translate(0, 10);
                                    //chart.series[0].points[0].graphic.translate(0, 10);
                                    //chart.series[0].points[2].graphic.translate(0, 12);
                                },
                                mouseOver: function () {


                                    //this.chart.series.forEach(series => setSeriesOpacity(series, 0.75));
                                    //this.chart.series.forEach((__item, index) => {
                                    //__item.data[index].update({ color: new Highcharts.Color(__item.data[0].color ? __item.data[0].color : 'red').setOpacity(0.5).get() });


                                    //this.chart.series.points[index].visible = false;
                                    //this.setSeriesOpacity(this.series, 0.75)
                                    //});
                                },
                                mouseOut: function () {
                                    //this.chart.series.forEach(series => setSeriesOpacity(series, 1));
                                },
                            },
                            point: {

                                events: {

                                    mouseOver: function () {

                                        //var _loc = this.getClassName().split(' ').pop();

                                        //var tt = this.series.name.split('-');

                                        //var spkr = this.series.name.split('<br>');

                                        //this.series.name = tt[0].toString() + ' - ' + (_loc ? _loc : '') + '<br>' + spkr[1];


                                        //this.series.name = this.series.name.replace('####', _loc?_loc:'');

                                        //if (this.color?.toString() == '')
                                        //this.update({ color: new Highcharts.Color(this.color ? this.color : 'red').setOpacity(0.5).get() });
                                        //setPointOpacity(this, 1);



                                    },
                                    mouseOut: function () {
                                        //this.update({ color: new Highcharts.Color(this.color ? this.color : 'red').setOpacity(1).get() });
                                        //setPointOpacity(this, 0.25);
                                    },
                                }
                            }

                        }
                    },


                };



                setTimeout(() => {
                    $(".overlapped-down").addClass("transform-down");
                    $(".overlapped-up").addClass("transform-up");
                }, 200);

                Highcharts.ganttChart("chart", this.chartOptions);
                window.dispatchEvent(new Event('resize'));

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addEvent(event: MatDatepickerInputEvent<Date>) {
        
        this.applyFilters();
    }

    convertStringToDate(strDate) {
        if (strDate != null && strDate != "" && strDate != undefined)
            return new Date(strDate);
        else return new Date();
    }

    dateRangeOverlaps(a_start, a_end, b_start, b_end) {

        if (this.convertStringToDate(a_start) < this.convertStringToDate(b_start) && this.convertStringToDate(b_start) < this.convertStringToDate(a_end)) return true; // b starts in a
        if (this.convertStringToDate(a_start) < this.convertStringToDate(b_end) && this.convertStringToDate(b_end) < this.convertStringToDate(a_end)) return true; // b ends in a
        if (this.convertStringToDate(b_start) < this.convertStringToDate(a_start) && this.convertStringToDate(a_end) < this.convertStringToDate(b_end)) return true; // a in b
        return false;

        //return ((this.convertStringToDate(a_start)) <= (this.convertStringToDate(b_end)) && (this.convertStringToDate(b_start)) < (this.convertStringToDate(a_end)) ? true : false);

        //this.convertStringToDate(a_start);
    }

    multipleDateRangeOverlaps(courses) {

        if (courses.length < 2)
            return false;

        var i, j, k = 0;
        if (courses.length < 2)
            throw new TypeError('Arguments length must be a multiple of 2');

        else {
            for (i = 0; i < courses.length - 1; i += 1) {
                k = 0;
                for (j = i + 1; j < courses.length; j += 1) {
                    if (courses[i].courseTitle == courses[j].courseTitle)
                        if (
                            this.dateRangeOverlaps(
                                courses[i].dates.courseStartDate, courses[i].dates.courseEndDate,
                                courses[j].dates.courseStartDate, courses[j].dates.courseEndDate
                            )
                        ) {
                            courses[i].isOverlapped = true;
                            courses[j].isOverlapped = true;
                            

                            //if (courses[i].isOverlapped == false)
                            //    courses[i].isOverlapped = k;

                            //if (courses[j].isOverlapped == false)
                            //    courses[j].isOverlapped = k + 1;
                            //// return true;
                            //k++;
                        }
                }
            }
            return false;
        }

        /*let i = 0, j = 0;
        let timeIntervals = timeEntries.filter(entry => entry.from != null && entry.to != null && entry.from.length === 8 && entry.to.length === 8);

        if (timeIntervals != null && timeIntervals.length > 1)
            for (i = 0; i < timeIntervals.length - 1; i += 1) {
                for (j = i + 1; j < timeIntervals.length; j += 1) {
                    if (
                        this.dateRangeOverlaps(
                            timeIntervals[i].from.getTime(), timeIntervals[i].to.getTime(),
                            timeIntervals[j].from.getTime(), timeIntervals[j].to.getTime()
                        )
                    ) return true;
                }
            }
        return false;*/
    }




    //Highcharts: typeof Highcharts = Highcharts;

    chartCallback: Function = function (chart) {
        console.log("Chart instance: ", chart);
    };




    //chartOptions: Highcharts.Options = {
    chartOptions: Highcharts.Options = {
        title: {
            text: "Courses Yearly Schedule"
        },
        accessibility: {
            keyboardNavigation: {
                seriesNavigation: {
                    mode: 'serialize'
                }
            }
        },
        xAxis: {

            height: '100%',
            width: '100%',
            plotLines: [{
                //value: Date.UTC(2022, 6, 10),
                color: 'blue',
                width: 1
            }],
            //tickInterval: 1000 * 60 * 60,
            //opposite: false,
            //labels: {
            //    style: {
            //        'color': 'rgba(0, 0, 0, 0.87)',
            //        textAlign: 'center !important'
            //    }
            //},
            min: Date.UTC(2021, 5, 1),
            max: Date.UTC(2025, 12, 31)
        },
        //series: //this.series
        series: [
            //    {
            //        type: "gantt",
            //        name: "Project 2",
            //        data: [
            //            {

            //                name: "test",
            //                start: Date.UTC(2014, 10, 18),
            //                end: Date.UTC(2014, 11, 25),
            //                completed: 0.25
            //            }
            //        ]
            //    }],
            //[
            //{
            //    type: "gantt",
            //    name: "Project 2",
            //    data: [
            //        {

            //            name: "test",
            //            start: Date.UTC(2014, 10, 18),
            //            end: Date.UTC(2014, 11, 25),
            //            completed: 0.25
            //        }
            //        ]
            //},
            {

                type: "gantt",
                name: "Project 1",
                data: [
                    {

                        id: '1',
                        name: "GDPR Cert",
                        start: Date.UTC(2014, 10, 18),
                        end: Date.UTC(2014, 11, 25),
                        //completed: 0.25
                    },
                    {
                        id: '2',
                        name: "FOI Cert",
                        start: Date.UTC(2014, 11, 27),
                        end: Date.UTC(2014, 12, 29)
                    },
                    {
                        id: '2',
                        name: "Cyber",
                        start: Date.UTC(2015, 1, 20),
                        end: Date.UTC(2015, 2, 25),
                        //completed: {
                        //    amount: 0.12,
                        //    fill: "#fa0"
                        //}
                    },
                    {
                        name: 'CCPA',
                        id: 'CCPA',
                        color: '#FFA500',
                        //name: "Run acceptance tests",
                        start: Date.UTC(2015, 5, 23),
                        end: Date.UTC(2015, 6, 26)
                    }
                    ,
                    {
                        name: 'CCPA',
                        id: 'CCPA',
                        color: '#FFA500',
                        //name: "Run acceptance tests",
                        start: Date.UTC(2015, 8, 23),
                        end: Date.UTC(2015, 9, 26)
                    }
                ]
            }],
        yAxis: {
            //reversed: true,
            gridLineWidth: 3,
            //gridLineDashStyle: 'LongDashDot',
            uniqueNames: true,
            height: '100%',
            width: '100%',
            opposite: false,
            //scrollbar: {
            //    enabled: true
            //}
        },

        chart: {
            scrollablePlotArea: {
                minWidth: this.minWidth, // setting the chart width
                scrollPositionX: 1
            },
            //height: 500,
            //width: 1500
            events: {
                load() {

                }
            }
        },

        plotOptions: {
            series: {
                //opacity:0.5,
                animation: false,
                events: {
                    mouseOver: function () {
                        //this.chart.series.forEach(series => setSeriesOpacity(series, 0.75));
                        this.chart.series.forEach((__item, index) => {
                            //__item.data[index].update({ color: new Highcharts.Color(__item.data[0].color ? __item.data[0].color : 'red').setOpacity(0.5).get() });


                            //this.chart.series.points[index].visible = false;
                            //this.setSeriesOpacity(this.series, 0.75)
                        });
                    },
                    mouseOut: function () {
                        //this.chart.series.forEach(series => setSeriesOpacity(series, 1));
                    },
                },
                point: {
                    events: {
                        mouseOver: function () {

                            //this.update({ color: new Highcharts.Color(this.color ? this.color : 'red').setOpacity(0.5).get() });
                            //setPointOpacity(this, 1);
                        },
                        mouseOut: function () {
                            //this.update({ color: new Highcharts.Color(this.color ? this.color : 'red').setOpacity(1).get() });
                            //setPointOpacity(this, 0.25);
                        },
                    }
                }



            }
        },
    };

    campaignOne = new FormGroup({
        //start: new FormControl(new Date(year, month, 13)),
        //end: new FormControl(new Date(year, month, 16)),

        start: new FormControl(new Date(2022, 1, 1)),
        end: new FormControl(new Date(2024, 6, 30)),
    });




    setSeriesOpacity(series, opacity) {
        const seriesColor = this.colorToRGBAString(series.color, opacity);
        series.data.forEach(data => {
            data.color = seriesColor;
        });
        series.render();
    };

    colorToRGBAString(color, opacity) {
        if (color[0] === '#') {
            const hex = Number(`0x${color.substr(1)}`);
            const b = (hex & 0xff);
            const g = ((hex >> 8) & 0xff);
            const r = ((hex >> 16) & 0xff);
            return this.createRGBAString({ red: r, green: g, blue: b }, opacity);
        }
        return this.createRGBAString(this.getRGBA(color), opacity);
    };

    createRGBAString(rgb, alpha) {
        return `rgba(${rgb.red},${rgb.green},${rgb.blue},${alpha})`;
    };

    getRGBA(str) {
        const match = str.match(/rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*([\d.]+))?\s*\)/);
        return match ? {
            red: match[1],
            green: match[2],
            blue: match[3],
            alpha: match[4]
        } : {};
    };

    setPointOpacity(element, opacity) {
        element.color = this.colorToRGBAString(element.color, opacity);
        element.series.render();
    };


    
    monthlySchedule() {
        this._paramOut.showMonthlySchedule = true;
        this.gotoSchedule.emit(this._paramOut);
    }

    courseScheduler() {
        this._paramOut.showSpeakersSchedule = true;
        this.gotoSchedule.emit(this._paramOut);
    }

}
