import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../common/guards/AuthenticationService';
import { AuthUserService } from '../../../common/_services/auth-user.service';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
    selector: 'app-tv-screen',
    templateUrl: './tv-screen.component.html',
    styleUrls: ['./tv-screen.component.css'],
    providers: [DatePipe]
})
export class TvScreenComponent implements OnInit {

    @ViewChild('modalBookingAlert', { static: true }) modalBookingAlert: TemplateRef<any>;

    user: any;
    coursesList: any;
    currentTime: any;
    currentDate: any;
    latestBooking: any = [];
    bookingNumber: string;
    showBookingAlert: boolean = false;

    constructor(private _router: Router,
        private _authService: AuthUserService,
        private _auth: AuthenticationService,
        private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private modal: NgbModal,
        private datePipe: DatePipe) {

        this.getTVUpcomingCoursesList();
    }

    ngOnInit(): void {

        // display clock
        this.updateTime();
        setInterval(() => {
            this.updateTime();
        }, 1000);

        // hiding the top menu
        document.body.className = "";
        $(".menu_less").hide();
        this.user = {
            role: this._auth.userRole(),
            name: localStorage.getItem("userName")

        }

        setTimeout(
            function () {
                location.reload();
            }, 1000 * 30 * 60);

        setTimeout(
            function () {
                $(".page-header").hide();
                $(".menu_less").show();
            }, 10000);
    }

    updateTime(): void {
        const now = new Date();
        this.currentDate = this.datePipe.transform(now, 'dd-MM-yyyy');
        this.currentTime = this.datePipe.transform(now, 'HH:mm');
    }


    getTVUpcomingCoursesList() {

        this._coursesService.getTVUpcomingCoursesList().subscribe((result: any) => {
            this.showBookingAlert = false;
            if (result.success == true) {
                this.coursesList = result.data;
                this.getNewBooking();
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {

            //this.loading = false;
        })
    }

    getNewBooking() {
        this._delegateService.getNewBooking().subscribe((result: any) => {
            if (result.success == true) {
                this.latestBooking = result.data.newBooking; //result.data.slice(-1)[0];
                this.bookingNumber = this.getOrdinalSuffix(result.data.bookingNumber + 1);
                this.showBookingAlert = true;
                this.modal.open(this.modalBookingAlert);
            } else {
                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {

            this._router.navigateByUrl("/unauthorise");
            //this.loading = false;
        })
    }

    getOrdinalSuffix(n: number): string {
        //const suffixes = ["th", "st", "nd", "rd"];
        const remainder10 = n % 10;
        const remainder100 = n % 100;

        if (remainder10 === 1 && remainder100 !== 11) {
            return `${n}st`;
        } else if (remainder10 === 2 && remainder100 !== 12) {
            return `${n}nd`;
        } else if (remainder10 === 3 && remainder100 !== 13) {
            return `${n}rd`;
        } else {
            return `${n}th`;
        }
    }

    logout() {
        this._authService.logout();
        this._router.navigateByUrl("/account/login");
    }

    getDate() {

        var date = new Date();
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        var _date = dd + '/' + mm + '/' + yyyy;
        return _date;
    }


    hideShowHeader(val) {
        if (val == true) {
            $(".page-header").hide();
            $(".menu_less").show();
        }
        else {
            $(".page-header").show();
            $(".menu_less").hide();
        }
    }

    getPendingBookingsClass(className: string) {
        //debugger;
        if (className.toLowerCase() == "green")
            return "green_text";
        else if (className.toLowerCase() == "red")
            return "red_text";
        else if (className.toLowerCase() == "amber")
            return "amber_text";
        else if (className.toLowerCase() == "grey")
            return "gray_text";
    }

    formatName(fullName: string) {
        if (fullName) {
            const nameParts = fullName.split(' ');
            if (nameParts.length > 1) {
                const firstName = nameParts[0];
                const secondNameInitial = nameParts[1].charAt(0);
                return `${firstName} ${secondNameInitial}.`;
            } else {
                return fullName; // Handle case where there's only one part
            }
        }
    }
}
