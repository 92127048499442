import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../../common/validation/messages';
import { CommonServiceService } from '../../../../common/_services/common-service.service';
import { DelegateService } from '../../../../common/_services/delegate-services';

@Component({
  selector: 'app-add-edit-organisation',
  templateUrl: './add-edit-organisation.component.html',
  styleUrls: ['./add-edit-organisation.component.css']
})
export class AddEditOrganisationComponent implements OnInit {

    start_d1: any = new Date();
    submitted = false;
    public _title = "Add Organisation";
    public locationsList: any;
    inhouseEditForm: FormGroup;
    _params: any;
    _organisationId: any = 0;
    currentOrganisation: any;
    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _delegateService: DelegateService,
        private _commonService: CommonServiceService,
        private _route: Router) {

        //this.getLocationsList();

        //this.route.queryParamMap
        //    .subscribe((params) => {
        //        this._params = { ...params.keys, ...params };
        //        this._delegateId = this._params.params.delegateId
        //    });


        //if (this._delegateId == null || this._delegateId == undefined
        //    || this._delegateId == "null" || Number.isNaN(this._delegateId)) {
        //    this._delegateId = 0; // user will add new delegate
        //}
    }

    ngOnInit(): void {

        this.loading = true;

        this.inhouseEditForm = this.formBuilder.group({
            name: ['', Validators.required],
            organization: ['', Validators.required],
            address1: ['', Validators.required],
            address2: [''],
            postcode: [''],
            telephone: [''],
            email: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
            notes: ['']
            //startDate_day1: [this.start_d1.value, Validators.required],
        });



        //if (Object.keys(this.data).length === 0) {
        //    // this is add case
        //}
        //else {
        //    this.isNew = false;
        //    this._inhouseId = this.data.id;

        //    if (Number(this._inhouseId) > 0) {
        //        //this.getDelegateData();
        //        this.currentInhouse = this.data;
        //        this.populateForm();
        //        //setTimeout(() => {
        //        //    this.populateForm();
        //        //}, 1000)
        //    }

        //}

        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this.currentOrganisation = this.data;
            this.populateForm();
        }



        this.loading = false;
    }

    //getDelegateData() {
    //    this._delegateService.getDelegateData(Number(this._organisationId)).subscribe((result: any) => {

    //        if (result.success == true) {

    //            if (result.data.length > 0) {
    //                this.currentOrganisation = result.data[0];
    //                this.populateForm();
    //            }
    //        } else {

    //            //this._commonService.error(result.data.desc);
    //        }

    //    }, (_error: any) => {
    //        //this.loading = false;
    //    })
    //}

    get f() { return this.inhouseEditForm.controls; }

    populateForm() {
        if (this.currentOrganisation != null) {

            this._title = "Edit Organisation";

            this._organisationId = this.currentOrganisation.id;

            this.inhouseEditForm.controls.name.setValue(this.currentOrganisation.contactName);
            this.inhouseEditForm.controls.organization.setValue(this.currentOrganisation.organisationName);
            this.inhouseEditForm.controls.address1.setValue(this.currentOrganisation.address);
            this.inhouseEditForm.controls.postcode.setValue(this.currentOrganisation.postcode);
            this.inhouseEditForm.controls.telephone.setValue(this.currentOrganisation.telephone);
            this.inhouseEditForm.controls.email.setValue(this.currentOrganisation.contactEmail);
            this.inhouseEditForm.controls.notes.setValue(this.currentOrganisation.notes);

        }
    }

   

    onSubmit() {

        var req = {

            request: {
                Id: Number(this._organisationId),
                ContactName: this.inhouseEditForm.controls.name.value,
                OrganisationName: this.inhouseEditForm.controls.organization.value,
                Address: this.inhouseEditForm.controls.address1.value,
                Postcode: this.inhouseEditForm.controls.postcode.value,
                Telephone: this.inhouseEditForm.controls.telephone.value,
                ContactEmail: this.inhouseEditForm.controls.email.value,
                notes: this.inhouseEditForm.controls.notes.value
            }


        };

        this._delegateService.insertOrganisation(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show errror popup
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    close() { }

    delete(id) {

        if (id > 0) {
            this._delegateService.deleteDelegate(id).subscribe((result: any) => {

                if (result.success == true) {


                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    gotoMainMenu(tab) {
        if (tab.index == 0) {
            this._route.navigateByUrl("/courses/all-courses");
        }
        else if (tab.index == 2) {
            this._route.navigateByUrl("/schedule");
        }
    }

}
