import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

    invoicesList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 10;

    _paramOut = {
        showReceivedInvoices: false,
        showSuppliers: false,
        id: 0
    }

    @Output() gotoSuppliersList = new EventEmitter<void>();
    @Output() gotoInvoicesReceived = new EventEmitter<object>();
    @Output() gotoDebtCollection = new EventEmitter<void>();
    @Output() gotoXeroInvoices = new EventEmitter<void>();
    @Output() gotoDelegateDetails = new EventEmitter<void>();
    @Output() gotoCreditNotes = new EventEmitter<void>();

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'dateAdded', headerName: 'Date', width: 325, valueFormatter: this.convertStringToDate },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 225 },
        { field: 'delegateName', headerName: 'Issued To', width: 225, valueFormatter: this.getName },
        { field: 'amount', headerName: 'Amount', width: 225, cellRenderer: params => this.currencyFormatter(params.value), },
        {
            field: 'transactionType', headerName: 'Transaction Type', width: 225,
            cellRenderer: params => params.value == null ? "-" : params.value,
        },
        { field: 'reconcileOn', headerName: 'Reconcile On', width: 250, cellRenderer: this.reconcileCellRendererFunc, valueFormatter: this.convertStringToDate },

    ];

    getName(param) {
        //debugger;
        if (param.data.organisationName != null)
            return param.data.organisationName
        else
            return param.value;
    }

    // DATA FORMATTING
    currencyFormatter(currency) {

        if (currency != null) {
            var sansDec = currency.toFixed(0);
            var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '<span>&#163;</span>' + `${formatted}`;
        }
        else return '-';
    }

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        //return new Date(strDate).toString();
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        //private http: HttpClient,
        private _delegateService: DelegateService,
        private _commonService: CommonServiceService,
        private _route: Router,
    ) {

        _commonService.setBackNavigation();

        this.gridOptions = {
            context: {
                componentParent: this
            },
            rowClass: 'grid-row',
            suppressCellSelection: true
        };

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#iGrid").style.height = "";
        var elm = document.querySelector("#iGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#iGrid").style.height = "400px";
        var elm = document.querySelector("#iGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        //    //this.rowData$ = this.http
        //    //    .get<any[]>('http://www.ag-grid.com/example-assets/row-data.json');

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        //this._route.navigateByUrl('/delegates/delegate-details?delegateId=' + e.data.id + '&rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
        
        this.gotoDelegateDetails.emit(e.data);
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }


    //openModal(param) {
    //    const modalRef = this.modal.open(AddEditDelegateComponent, {
    //        size: 'lg'
    //    });
    //    modalRef.componentInstance.data = param;

    //    if (this._methodToload == this.methodName.archive)
    //        modalRef.componentInstance.refreshGrid.subscribe(event => this.getInvoicesList(true));
    //    else if (this._methodToload == this.methodName.nonArchive)
    //        modalRef.componentInstance.refreshGrid.subscribe(event => this.getInvoicesList(false));
    //}



    ngOnInit(): void {
        //
        localStorage.setItem('projectPageSize', '50');
        this.getInvoicesList(false);
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getInvoicesList(isArch) {

        if (isArch == true) {
            this.listTitle = "Archived Delegates";
            this._methodToload = this.methodName.archive;
        } else {
            this.listTitle = "Delegates";
            this._methodToload = this.methodName.nonArchive;
        }

        var req = {
            type: ""
        };
        this._delegateService.getInvoicesList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.invoicesList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }



    gotoSuppliers() {

        this.gotoSuppliersList.emit();
    }

    gotoInvoicesReceivedList() {
        this.gotoInvoicesReceived.emit();
    }

    gotoXeroInvoicesList() {

        this.gotoXeroInvoices.emit();
    }

    gotoDebtCollectionList() {
        this.gotoDebtCollection.emit();
    }

    gotoCreditNotesList() {
        this.gotoCreditNotes.emit();
    }
}
