<!--<div class="page_heading">Course Booking Confirmation</div>-->
<h2 class="mb-4 text-second font-ag-light text-center" style="font-size: 2rem;">
    <span class="text-main font-ag-bold">Course Booking Confirmation</span>
</h2>

<div style="
            margin: 20px;
            text-align:center;
            font: normal 14px/24px Arial,Tahoma sans-serif;
            background: #eee;
            padding:10px;
            border: solid 1px black;
        ">

    <p>Thank you for your booking. You will shortly receive an email confirmation. This will contain confirmation of your booking, invoice and directions for the venue.</p>

    <p style="font-weight:bold;">
        If you do not receive confirmation in the next 24 hours please call the office on 01924 470252 to confirm your booking or email
        <a href="mailto:info@actnow.org.uk">info@actnow.org.uk</a>.
    </p>

    <p>If you have any further queries regarding the booking please email <a href="mailto:info@actnow.org.uk">info@actnow.org.uk</a>.</p>

    <p>To ensure delivery to your inbox, add <a href="mailto:info@actnow.org.uk">info@actnow.org.uk</a> to your address book/contacts.</p>

</div>


<h2 class="mb-4 text-second font-ag-light text-center" style="font-size: 2rem;"><span class="text-main font-ag-bold">Bookings & Cancellation</span> Policy</h2>


<ul class="font-ag text-gray" style="margin: 0 0 10px 30px;">

    <li class="list-inside">Act Now Training will invoice your organisation upon receipt of the booking. Receipt of a booking form by us implies that you have the necessary internal approvals.</li>

    <li class="list-inside">The invoice is payable within 30 days of being issued regardless of whether the event has taken place.</li>

    <li class="list-inside">If you book within 10 days of the course date please check by phone or email that your place is confirmed.</li>

    <li class="list-inside">Confirmation and directions to the venue will be sent to you a minimum of 7 days before the course date.</li>

    <li class="list-inside">It is your responsibility to ensure we have your correct details especially email address. Failure to do so could lead to you not receiving joining instructions. In such cases you are still liable to pay the invoice.</li>

    <li class="list-inside"><strong>The full fee will remain payable even if the delegate does not attend.</strong></li>

    <li class="list-inside">We reserve the right to cancel the event or change the venue or speakers due to circumstances beyond our control. In such cases we will not be liable for refunding any expenses incurred.</li>

    <li class="list-inside">For the latest info on courses, venues etc please check the front page of our website or <a href="https://twitter.com/@ActNowTraining" target="_blank">follow us on twitter</a>.</li>

    <li class="list-inside">We reserve the right to refuse any bookings (and cancel any bookings) especially from consultants, those in the business of information rights training/consultancy and those whose business directly or indirectly competes with ours.</li>

    <div style="margin:10px 0;font-weight:bold;">CERTIFICATE COURSES</div>

    <li class="list-inside">If the invoice remains unpaid, we reserve the right not to release your exam marks and/or issue your certificate until the invoice has been paid in full.</li>

    <div style="margin:10px 0;font-weight:bold;">REFUND/CANCELLATION POLICY</div>

    <li class="list-inside">You can cancel your booking up to 30 days before the event without charge. After this you cannot cancel your booking nor transfer it to another course. This is irrespective of when booked.</li>

    <li class="list-inside">Substitute delegates are welcome at any time without charge.</li>

    <li class="list-inside">Transfers to another date for the same course are considered as another course.</li>

    <div style="margin:10px 0;font-weight:bold;">INCLEMENTAL WEATHER/TRANSPORT PROBLEMS</div>

    <li class="list-inside">We do not cancel courses due to inclemental weather or transport problems. If you cannot attend due to these reasons the invoice remains payable.</li>

</ul>
