import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoursesService } from '../../../../common/_services/courses-services';
import { DelegateService } from '../../../../common/_services/delegate-services';

@Component({
  selector: 'app-add-edit-course-titles',
  templateUrl: './add-edit-course-titles.component.html',
  styleUrls: ['./add-edit-course-titles.component.css']
})
export class AddEditCourseTitlesComponent implements OnInit {

    currentDate: any = new FormControl(new Date());
    submitted = false;
    public _title = "Add Course Title";
    public locationsList: any;
    courseTitleEditForm: FormGroup;
    _params: any;
    _courseTitleId: any = 0;
    subjectsList: any;

    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    _courseTypeId: any = 0;

    start_d1: any = new FormControl(new Date());
    start_d2: any;
    

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService,
        //private _delegateService: DelegateService,
        private _route: Router) {


    }

    ngOnInit(): void {
        
        this.loading = true;

        this.courseTitleEditForm = this.formBuilder.group({
            p_title: ['', Validators.required],
            cost: [''],
            //startDate_day1: [this.start_d1.value, Validators.required],
            courseCode: [''],
            subject: ['', Validators.required],
            courseType: ['', Validators.required],
            duration: [''],
            startTime: [''],
            endTime: [''],


        });


        this.getSubjectsList();

        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this.populateForm(this.data);
        }


        this.loading = false;

    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {
                this.subjectsList = result.data;
                
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })



        
    }

    get f() { return this.courseTitleEditForm.controls; }

    populateForm(__data) {
        
        this._title = "Edit Course Title";
        this._courseTitleId = __data.id;
        this.courseTitleEditForm.controls.p_title.setValue(__data.title);
        this.courseTitleEditForm.controls.cost.setValue(__data.courseCost);
        this.courseTitleEditForm.controls.startTime.setValue(__data.startTime);
        this.courseTitleEditForm.controls.endTime.setValue(__data.endTime);
        this.courseTitleEditForm.controls.duration.setValue(__data.courseDuration);
        this.courseTitleEditForm.controls.courseCode.setValue(__data.courseCode);
        
        this.courseTitleEditForm.controls.subject.patchValue(__data.subjectId);
        //this.courseTitleEditForm.controls.courseType.patchValue(__data.courseTypeId);
        this._courseTypeId = ""+__data.courseTypeId;
               // SubjectId: parseInt(this.courseTitleEditForm.controls.subject.value),
                //    CourseTypeId: parseInt(this.courseTitleEditForm.controls.courseType.value),
                        
    }

    

    onSubmit() {

        var req = {
            Id: Number(this._courseTitleId),
            Title: this.courseTitleEditForm.controls.p_title.value,
            CourseCost: parseInt(this.courseTitleEditForm.controls.cost.value),
            StartTime: this.courseTitleEditForm.controls.startTime.value,
            CourseCode: this.courseTitleEditForm.controls.courseCode.value,
            FinishTime: this.courseTitleEditForm.controls.endTime.value,
            SubjectId: parseInt(this.courseTitleEditForm.controls.subject.value),            
            CourseTypeId: parseInt(this.courseTitleEditForm.controls.courseType.value),
            CourseDuration: parseInt(this.courseTitleEditForm.controls.duration.value),
                    
        };

        this._coursesService.addEditCourseTitle(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show error popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    close() { }

    

}
