import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FinanceService {

    baseurl: string;
    token: string;

    constructor(private _router: Router,
        private http: HttpClient,
        @Inject('BASE_URL') base: string) {
        this.baseurl = base;
        this.token = 'Bearer ' + localStorage.getItem('Token');
    }

    public get currentUserValue(): any {
        return "";// this.currentUserSubject;
    }


    getSuppliersList() {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Finance/v1/GetSuppliersList',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertSupplier(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/InsertSupplier',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteSupplier(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Finance/v1/DeleteSupplier',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteSupplierInvoice(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Finance/v1/DeleteSupplierInvoice',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getUnpaidReceivedInvoicesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);
        return this.http.get<any>(this.baseurl + 'api/Finance/v1/GetUnpaidReceivedInvoicesList',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
       
    }

    markUnpaidReceivedInvoices() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);
        return this.http.get<any>(this.baseurl + 'api/Finance/v1/MarkUnpaidReceivedInvoices',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
       
    }

    

    getReceivedInvoicesList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetReceivedInvoicesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getSupplierData(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Finance/v1/GetSupplierData',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertInvoiceReceived(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/InsertInvoiceReceived',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getAccountantReport(req) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);
        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetAccountantReport',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getDebtCollectionList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetDebtCollectionList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getAllSpeakersCoursesList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetAllSpeakersCoursesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getIndividualSpeakersCourses(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetIndividualSpeakersCourses',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getSpeakerData(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetSpeakerData',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    GetXeroInvoices(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetXeroInvoices',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateInvoiceFromXero(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/UpdateInvoiceFromXero',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    GetXeroToken(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Finance/v1/GetXeroToken',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getDebtCollectionCount() {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Finance/v1/GetDebtCollectionCount',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

}
