import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { DelegateService } from '../../../common/_services/delegate-services';
import { AddEditInhouseComponent } from '../../inhouse/add-edit-inhouse/add-edit-inhouse.component';
import { AddEditProductComponent } from '../add-edit-product/add-edit-product.component';
import { ProductClickableParentComponent } from '../product-clickable-parent/product-clickable-parent.component';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

    productList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "Products";
    paginationPageSize: any = 10;

    _paramOut = {
        allBookings: false, id: 0
    }

    @Output() gotoProductBookings = new EventEmitter<object>();

    // Each Column Definition results in one Column.
    //public columnDefs: ColDef[] = [
    //    { field: 'dateAdded', headerName: 'Booking Date', width: 200, valueFormatter: this.convertStringToDate },
    //    { field: 'delegateName', headerName: 'Delegate', width: 325 },
    //    { field: 'productTitle', headerName: 'Product Title', width: 200 },
    //    { field: 'isPaid', headerName: 'Paid', width: 100, cellRenderer: this.reconcileCellRendererFunc },

    //];


    public columnDefs: ColDef[] = [
        //{ field: 'dateAdded', headerName: 'Added Date', width: 200, valueFormatter: this.convertStringToDate },
        { field: 'title', headerName: 'Product Title', width: 325 },
        //{
        //    field: 'cost', headerName: 'Cost', width: 200,
        //    cellRenderer: params => params.value == null ? "-" : params.value,
        //},
        { field: 'expiryDate', headerName: 'Expiry Date', width: 200, valueFormatter: this.convertStringToDate },
        {
            field: 'Action', cellRendererFramework: ProductClickableParentComponent,
            sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;

            //return new Date(strDate).toString();
        }
        else return "";
        //else return new Date().toString();
    }

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (params.value == true)
            return "<div class='paid status-circles mt-10px'></div>";
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        private http: HttpClient,
        private _delegateService: DelegateService,
        private _route: Router) {

        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        this.gridOptions = {
            suppressCellSelection: true,
            context: {
                componentParent: this
            }
        };

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#productGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#productGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    
    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        //if (e.colDef.field != "Action")
        if (e.colDef.field != "Action") {
            this._paramOut.allBookings = false;
            this._paramOut.id = e.data.id
            this.gotoProductBookings.emit(this._paramOut);
        }
    }


    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }

    ngOnInit(): void {

        localStorage.setItem('projectPageSize', '50');
        this.gotoArchivedProducts(false);
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    gotoArchivedProducts(isArchive) {

        if (isArchive == false) {
            this.listTitle = "Products";
            this._methodToload = this.methodName.nonArchive;
        } else {
            this.listTitle = "Archived Products";
            this._methodToload = this.methodName.archive;
        }

        var req = {
            isArchived: isArchive
        };

        this._delegateService.getProductList(req).subscribe((result: any) => {

            if (result.success == true) {

                this.productList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditProductComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {
            
            this.gotoArchivedProducts(false);
        }, (reason) => {
            
            //console.log('Dismissed action: ' + reason);
        });
    }

    openModal(param) {

        const modalRef = this.modal.open(AddEditProductComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        //modalRef.componentInstance.refreshGrid.subscribe(event => this.gotoArchivedProducts(false));

        if (this._methodToload == this.methodName.archive)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.gotoArchivedProducts(true));
        else if (this._methodToload == this.methodName.nonArchive)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.gotoArchivedProducts(false));


    }

    gotoBookingsList(isAllBookings) {
        this._paramOut.allBookings = isAllBookings;
        this._paramOut.id = 0
        this.gotoProductBookings.emit(this._paramOut);
    }
}
