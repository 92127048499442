<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">

    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>

            </div>
        </div>

        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 1.5rem;">
                    <span class="text-main font-ag-bold">Course Report</span>
                </h2>

                <h5 class="mt-3 mb-2 text-second font-ag-light pull-right" style="font-size: 1rem;">
                    <span class="font-ag-bold pull-right">{{_date | date : 'dd-MM-yy'}}</span>
                </h5>
            </div>
        </div>

        <table class="full-width mt-3 course-report">
            <tr class=" mt-5" style="border-bottom: 1pt solid black; font-weight: bold;">
                <td style="width:10%" class="v-align-top">Date</td>
                <td style="width:50%" class="v-align-top">Course</td>
                <td style="width:15%" class="v-align-top">Speaker</td>
                <td style="width:10%" class="v-align-top"></td>
                <td style="width:5%" class="v-align-top">Delegates</td>
                <td style="width:10%" class="v-align-top text-right">Amount</td>
            </tr>
        </table>

        
        <table *ngFor="let dates of displayCourse | keyvalue : originalOrder" class="full-width text-second course-report">

            <tr>
                <td colspan="3" class="pt-4">
                    <h6>
                        {{dates.key}}
                    </h6>
                </td>
            </tr>

            <tr *ngFor="let course of dates.value" style="border-bottom: 1pt solid black;">
                <td style="width:10%" class="v-align-top">{{course.startDate | date : 'dd-MM-yy' }}</td>
                <td style="width:50%" class="v-align-top">{{course.title}}</td>
                <td style="width:15%" class="v-align-top">{{course.speaker}}</td>
                <td style="width:10%" class="v-align-top">{{getCourseType(course.courseTypeId)}}</td>
                <td style="width:5%" class="v-align-top">{{course.courseBookings}} [{{course.thisWeekBookings | number}}]</td>
                <td style="width:10%" class="v-align-top text-right">{{course.amount | number : '1.0-2'}}</td>
            </tr>
        </table>




    </div>
</div>




<div class="row">
    <div class="col-lg-6">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">{{listTitle}}</span>
        </h2>
    </div>
    <div class="col-lg-6 pt-1">

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNew()">Add New Course</button>
                <button mat-menu-item (click)="courseTitlesList()">Course Titles</button>
                <button mat-menu-item (click)="upcomingCourses()">Upcoming Courses</button>
                <button mat-menu-item (click)="allCourses(false)">All Courses</button>
                <button mat-menu-item (click)="allCourses(true)">Archive Courses</button>
                <button mat-menu-item (click)="gotoMimeoTab()">Mimeo</button>
                <button mat-menu-item (click)="upcomingCoursesReport()">Course Report</button>
            </mat-menu>
        </div>

        <!--<button class="book-btn pull-right filter-apply" (click)="addNew()">
            Add new course
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="allCourses(true)">
            Archived courses
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="allCourses(false)">
            All courses
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="upcomingCourses()">
            Upcoming courses
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="courseTitlesList()">
            Course Titles
        </button>-->
        <!--<button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="changeTab(1)">Dashboard</button>
            <button mat-menu-item (click)="changeTab(2)">Courses</button>

            <ng-container>
                <button mat-menu-item [matMenuTriggerFor]="sub_menu">Courses</button>
                <mat-menu #sub_menu="matMenu">
                    <button mat-menu-item (click)="changeTab(2)">Course Titles</button>
                    <button mat-menu-item (click)="changeTab(2)">Upcoming Courses</button>
                    <button mat-menu-item (click)="changeTab(2)">All Courses</button>
                    <button mat-menu-item (click)="changeTab(2)">Archive Courses</button>
                </mat-menu>
            </ng-container>

            <button mat-menu-item (click)="changeTab(3)">Delegates</button>-->
        <!--<ng-container *ngFor="let mainItem of objectKeys(my_menu)">
            <button mat-menu-item [matMenuTriggerFor]="sub_menu">{{ mainItem }}</button>
            <mat-menu #sub_menu="matMenu">
                <button *ngFor="let subItem of my_menu[mainItem]" (click)="changeTab(subItem.index)" mat-menu-item>{{ subItem.title }}</button>
            </mat-menu>
        </ng-container>-->
        <!--</mat-menu>-->
    </div>

</div>

<!--<button (click)="setAutoHeight()">Auto Height</button>
<button (click)="setFixedHeight()">Fixed Height</button>-->
<!--<div class="outer-div">-->
<!--<div class="grid-wrapper">-->

<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>

</div>
<div [ngStyle]="style">
    <ag-grid-angular #agGrid
                     id="myGrid"
                     class="ag-theme-alpine ag-theme-balham"
                     [rowClassRules]="rowClassRules"
                     [domLayout]="domLayout"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     [rowData]="coursesList"
                     [animateRows]="true"
                     (gridReady)="onGridReady($event)"
                     (cellClicked)="onCellClicked($event)"
                     (rowClicked)="onRowClicked()"
                     [gridOptions]="gridOptions"
                     [pagination]="true"
                     [suppressPaginationPanel]="false"
                     (paginationChanged)="onPaginationChanged()"
                     [paginationPageSize]="paginationPageSize">
    </ag-grid-angular>


    <!--<div class="pg-size">

        <div>
            <button (click)="onBtPageFive()">To Page 5</button>
        </div>

        <div style="margin-top: 6px">

            <span class="value" id="lbFromRecords">-</span>
            <span class="label"> to </span>
            <span class="value" id="lbToRecords">-</span>
            <span class="label"> of </span>
            <span class="value" id="lbTotalRecords">-</span>

            <i class="material-icons" (click)="onBtFirst()">first_page</i>
            <i class="material-icons" (click)="onBtPrevious()">navigate_before</i>

            <span class="label">Page </span>
            <span class="value" id="lbCurrentPage">-</span>
            <span class="label"> of </span>
            <span class="value" id="lbTotalPages">-</span>

            <i class="material-icons" (click)="onBtNext()">navigate_next</i>
            <i class="material-icons" (click)="onBtLast()" id="btLast">last_page</i>

        </div>
    </div>-->
</div>
<!--</div>-->
<!--</div>-->
<!--<mat-tab-group (selectedTabChange)="gotoMainMenu($event)" [selectedIndex]="0" mat-align-tabs="start">
    <mat-tab class="text-second" label="Courses">

        <ng-template mat-tab-label matTabContent>
            <span class="text-second strong">Courses</span>
        </ng-template>

        <p>Upcoming course details</p>
        <div class="outer-div">
            <div class="grid-wrapper">
                <ag-grid-angular style="width: 100%; height:500px;"
                                 class="ag-theme-alpine"
                                 [columnDefs]="columnDefs"
                                 [defaultColDef]="defaultColDef"
                                 [rowData]="coursesList"
                                 [animateRows]="true"
                                 (gridReady)="onGridReady($event)"
                                 (cellClicked)="onCellClicked($event)">

                </ag-grid-angular>
            </div>
        </div>

    </mat-tab>

    <mat-tab label="Delegates">
        <ng-template mat-tab-label matTabContent>
            <span class="text-second strong">Delegates</span>
        </ng-template>
    </mat-tab>

    <mat-tab label="Schedule">
        <ng-template mat-tab-label>
            <span class="text-second strong">Schedule</span>
        </ng-template>
    </mat-tab>
</mat-tab-group>-->


<ng-template #modalContentInsert let-close="close">

    <!--<app-add-course (close)="onClose()" (refreshGrid)="refreshGrid()"></app-add-course>-->

</ng-template>


<ng-template #modalContentEdit let-close="close">

    Edit course

</ng-template>