import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
    baseurl: string;
    token: string;

    constructor(private http: HttpClient,
        @Inject('BASE_URL') base: string) {
        this.baseurl = base;
        this.token = 'Bearer ' + localStorage.getItem('Token');
    }

    //constructor(private _router: Router, private http: HttpClient, @Inject('BASE_URL') base: string) {
    //    this.baseurl = base;
    //}

    balanceInquiry(req: any) {
        return this.http.post<any>(this.baseurl + 'api/AgentRegistrationService/v1/BalanceInquiry', req)
            .pipe(map(response => {
                return response;
            }));
    }

    
    getThisWeekCoursesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Courses/v1/GetThisWeekCoursesList',
            {
                headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getStats() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Dashboard/v1/GetStats',
            {
                headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getNotifications() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Dashboard/v1/GetNotifications',
            {
                headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }


    notificationActionTaken(req: any) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Dashboard/v1/NotificationActionTaken',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }
}
