
<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">

    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>

                <div class="col-12 pull-left">
                    <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 1.5rem;">
                        <span class="text-main font-ag-bold">Remittance Advice</span>
                    </h2>
                </div>
                <div class="col-12 pull-left">
                    <h2 class="mb-3 text-second font-ag-light" style="font-size: 1.2rem;">
                        <span class="text-second font-ag-bold">{{ currentInvoice.supplierName }}</span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mb-3 text-second font-ag-light" style="font-size: 14px;">
                    <span class="text-second font-ag-bold">Act Now Training Ltd</span>
                    <p>
                        Annexe 2, Suite 45,
                        <br />
                        Batley Business Park,
                        <br />
                        Technology Drive, Batley
                        <br />
                        West Yorkshire, WF17 6ER
                        <br />
                        Tel: 01924 470252
                        <br />
                        Email: info@actnow.org.uk
                    </p>
                </h2>
            </div>
            <!--<span class="mb-3 pull-right" style="font-weight: bold;">{{ coursesCountDateFilter.value.start | date:'longDate' }} - {{ coursesCountDateFilter.value.end | date:'longDate' }}</span>-->
        </div>

        <table class="full-width mt-5">
            <tr class=" mt-5" style="border-bottom: 2pt solid black; font-weight: bold;">
                <td style="width:50%" class="v-align-top">Invoice number (Invoice date)</td>
                <td style="width:15%" class="v-align-top">Date paid</td>
                <td style="width:20%" class="v-align-top">Payment method</td>
                <td style="width:15%" class="v-align-top text-right">Amount</td>
            </tr>
        </table>

        <table class="full-width text-second">
            <tr style="border-bottom: 2pt solid black;">
                <td style="width:50%" class="v-align-top">{{currentInvoice.invoiceNumber}} ({{currentInvoice.invoiceDate | date:'dd-MM-yy'}})</td>
                <td style="width:15%" class="v-align-top">{{currentInvoice.datePaid | date:'dd-MM-yy'}}</td>
                <td style="width:20%" class="v-align-top">{{currentInvoice.paymentMethod}}</td>
                <td style="width:15%" class="v-align-top text-right">&#163;{{currentInvoice.grossTotal}}</td>
            </tr>
        </table>

        <table class="full-width pull-right">
            <tr style="font-weight: bold;">
                <td style="width:100%" class="v-align-top  text-right" colspan="4">Total &nbsp;&nbsp;&nbsp;&nbsp;&#163;{{currentInvoice.grossTotal}}</td>
            </tr>
        </table>

    </div>
</div>


<div id="printSection_notPaidInvoices" #printSection_notPaidInvoices class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">
    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>

            </div>
        </div>

        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 1.5rem;">
                    <span class="text-main font-ag-bold">Unpaid Invoices</span>
                </h2>

                <h5 class="mt-3 mb-2 text-second font-ag-light pull-right" style="font-size: 1rem;">
                    <span class="font-ag-bold pull-right">{{_date | date : 'dd-MM-yy'}}</span>
                </h5>
            </div>
        </div>


        <!--<table class="full-width text-second mt-3" style="border-bottom: 1pt solid black;" *ngFor="let invoice of unpaidInvoicesList">
            <tr>
                <td style="width:100%" colspan="3" class="v-align-top">
                    <h5>{{invoice.name}}</h5>
                </td>
            </tr>
            <tr>
                <td style="width:33%" class="v-align-top">Invoice date: <strong>{{invoice.invoiceDate | date:'dd-MM-yy'}}</strong></td>
                <td style="width:33%" class="v-align-top">Invoice number: <strong>{{invoice.invoiceNumber}}</strong></td>
                <td style="width:34%" class="v-align-top">Amount: <strong>&#163;{{invoice.grossTotal}}</strong></td>
            </tr>
            <tr>
                <td style="width:33%" class="v-align-top">Bank: <strong>{{invoice.bankName}}</strong></td>
                <td style="width:33%" class="v-align-top">Account number: <strong>{{invoice.accountNumber}}</strong></td>
                <td style="width:34%" class="v-align-top">Sort code: <strong>{{invoice.sortCode}}</strong></td>
            </tr>
        </table>-->


        <table class="full-width text-second mt-3" style="border-bottom: 1pt solid black;" *ngFor="let _invoice of displayUnpaidInvoice">
            <tr>
                <td style="width:100%" colspan="4" class="v-align-top">
                    <h5>{{_invoice.name}}</h5>
                </td>
            </tr>
            <tr>
                <td style="width:100%" colspan="4" class="v-align-top">

                    <table style="width:100%" *ngFor="let inv of _invoice.invoices">
                        <tr>
                            <td style="width:33%" class="v-align-top">Invoice date: <strong>{{inv.invoiceDate | date:'dd-MM-yy'}}</strong></td>
                            <td style="width:33%" class="v-align-top">Invoice number: <strong>{{inv.invoiceNumber}}</strong></td>
                            <td style="width:34%" class="v-align-top">Amount: <strong>&#163;{{inv.grossTotal}}</strong></td>
                        </tr>

                    </table>
                </td>
            </tr>
            <tr>
                <td style="width:25%" class="v-align-top">Bank: <strong>{{_invoice.invoices[0].bankName}}</strong></td>
                <td style="width:30%" class="v-align-top">Account number: <strong>{{_invoice.invoices[0].accountNumber}}</strong></td>
                <td style="width:25%" class="v-align-top">Sort code: <strong>{{_invoice.invoices[0].sortCode}}</strong></td>
                <td style="width:20%" class="v-align-top">Total: <strong>&#163;{{getTotal(_invoice.invoices)}}</strong></td>
            </tr>
        </table>


    </div>

</div>

<style type="text/css">
    table {
        page-break-inside: auto
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    thead {
        display: table-header-group
    }

    tfoot {
        display: table-footer-group
    }
</style>

<!--Accountant Report-->
<div id="printSection_accountantReport" #printSection_accountantReport class="full-width" style="display:none; top: 0; position: absolute; height:100% !important; width:100%!important">
    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>
            </div>
        </div>

        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 1.5rem;">
                    <span class="text-main font-ag-bold">{{reportName}}</span>
                </h2>

                <h5 class="mt-3 mb-2 text-second font-ag-light pull-right" style="font-size: 1rem;">
                    <span class="font-ag-bold pull-right">{{_date | date : 'dd-MM-yy'}}</span>
                </h5>
            </div>
        </div>
        
        <table class="full-width text-second mt-3 page-break-" style=" border-bottom: 1pt solid black;" *ngFor="let _invoice of displayAccountantReport">
            <tr>
                <td style="width:100%" colspan="3" class="v-align-top">
                    <h5>{{_invoice.supplier}}</h5>
                </td>
            </tr>
            <tr>
                <td style="width:100%" colspan="3" class="v-align-top">

                    <table style="width:100%">
                        <tr>
                            <th style="width:15%" class="v-align-top">Invoice date</th>
                            <th style="width:15%" class="v-align-top">Invoice No.</th>
                            <th style="width:15%" class="v-align-top">Net</th>
                            <th style="width:15%" class="v-align-top">VAT</th>
                            <th style="width:15%" class="v-align-top">Amount</th>
                            <th style="width:15%" class="v-align-top">Date Paid</th>
                            <th style="width:25%" class="v-align-top">Payment Method</th>
                        </tr>
                        <tr *ngFor="let inv of _invoice.invoices">
                            <td style="width:15%" class="v-align-top">{{inv.invoiceDate | date:'dd-MM-yy'}}</td>
                            <td style="width:15%" class="v-align-top">{{inv.invoiceNumber}}</td>
                            <td style="width:15%" class="v-align-top">&#163;{{inv.netTotal}}</td>
                            <td style="width:15%" class="v-align-top">&#163;{{inv.vat}}</td>
                            <td style="width:15%" class="v-align-top">&#163;{{inv.grossTotal}}</td>
                            <td style="width:15%" class="v-align-top">{{inv.datePaid | date : 'dd-MM-yy'}}</td>
                            <td style="width:25%" class="v-align-top">{{inv.paymentMethod}}</td>
                        </tr>

                    </table>

                </td>
            </tr>
            
            <!--<div style="break-after: page;"></div>-->

        </table>

    </div>
</div>









<div class="row">
    <div class="col-lg-7">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Received Invoices</span>
        </h2>

    </div>
    <div class="col-lg-5 pt-1">

        <div class="pull-right">

            <button *ngIf="unpaidInvoicesList.length > 0" class="book-btn mr-2 filter-apply" (click)="markAllPaid()">
                Mark all paid
            </button>

            <button mat-icon-button [matMenuTriggerFor]="accounts" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4 mr-2">
                Account Reports <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #accounts="matMenu">
                <button mat-menu-item (click)="viewAccountantReportsPopup()">Accountant Reports</button>
                <button mat-menu-item (click)="printUnpaid()">Print Unpaid</button>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNewInvoice()">Add Invoice</button>
                <button mat-menu-item (click)="gotoSuppliers()">Suppliers List</button>
                <button mat-menu-item (click)="gotoInvoicesIssuedList()">Issued Invoices</button>
                <button mat-menu-item (click)="gotoDebtCollectionList()">Debt Collection</button>
                <button mat-menu-item (click)="gotoCreditNotesList()">Credit Notes</button>
                <button mat-menu-item (click)="gotoXeroInvoicesList()">Xero Invoices</button>
            </mat-menu>
        </div>
    </div>
</div>


<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>

<ag-grid-angular #agGrid
                 id="invoicesReceivedGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="invoicesList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">
</ag-grid-angular>


<ng-template #modalAccountantReports let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Accountant Reports</h5>
    </div>
    <div class="modal-body- p-4">
        <form class="form-horizontal" [formGroup]="accountantReportsForm" (ngSubmit)="onAccountantReportsFormSubmit()" name="accountantReportsForm" autocomplete="off">
            <div class="row panel- mt-2 subscribe-style- bg-white">
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="Invoices Received" (click)="updateReportName('Invoices Received')">
                        Invoices Received
                    </mat-radio-button>
                    <br />
                    <!--<mat-radio-button value="other">
                        <span style="font-weight:bold;">Other</span>
                    </mat-radio-button>-->
                </mat-radio-group>

                <input type="text" class="form-control no-radius" placeholder="2024" pattern="^\d{4}$"
                       [ngClass]="{  'is-invalid': f.year.errors}"
                       required formControlName="year" maxlength="4" />

                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="mb-2 mr-2">
                    <mat-button-toggle value="1" (change)="getFilterToggleValue('1')" class="toggle-color-">Q1</mat-button-toggle>
                    <mat-button-toggle value="2" (change)="getFilterToggleValue('2')">Q2</mat-button-toggle>
                    <mat-button-toggle value="3" (change)="getFilterToggleValue('3')">Q3</mat-button-toggle>
                    <mat-button-toggle value="4" (change)="getFilterToggleValue('4')">Q4</mat-button-toggle>
                    <!--<mat-button-toggle value="0" (change)="getFilterToggleValue('0')">Full year</mat-button-toggle>-->
                </mat-button-toggle-group>
            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close(); resetAccountantReportsForm();">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!accountantReportsFormValidate"
                            class="book-btn">
                        Download report
                    </button>

                </div>
            </div>

        </form>



    </div>

</ng-template>
