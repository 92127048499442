<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute;">

    <div class="row">
        <div class="col-5 pull-left">
            <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
        </div>
        <div class="col-7 text-right">

            <h2 class="mb-3 text-second font-ag-light" style="font-size: 3rem;">
                <span class="text-main font-ag-bold">{{speakerData.speakerName}}</span>
            </h2>

            <span class="mb-3 pull-right" style="font-weight: bold;">{{ coursesCountDateFilter.value.start | date:'longDate' }} - {{ coursesCountDateFilter.value.end | date:'longDate' }}</span>
        </div>

        <table class="full-width">
            <tr style="border-bottom: 1pt solid black; font-weight: bold;">
                <td class="v-align-top w15">Date</td>
                <td class="v-align-top w70">Course</td>
                <td class="v-align-top w15">Delegate numbers</td>
            </tr>
        </table>

        <table class="full-width" *ngFor="let monthData of displayCourse">

            <tr>
                <td colspan="3" class="pt-4">
                    {{monthData.date | date : 'MMMM yyyy' }}
                    <!--<label class="text-second p-1 pull-right- p-rl-10px bg-yellow-gradient mb-2">{{monthData.date | date : 'MMMM yyyy' }}</label>-->
                </td>
            </tr>

            <tr *ngFor="let course of monthData.courses" style="border-bottom:1pt solid black;">

                <td class="v-align-top w15">
                    {{course.date | date : 'dd-MM-yy'}}
                </td>

                <td class="v-align-top w70">
                    <span *ngIf="course.title.includes('Certificate')">{{course.day}} - </span>
                    {{course.title}}
                </td>
                <td class="v-align-top w15">
                    <span *ngIf="course.isInhouse == true">N/A</span>
                    <span *ngIf="course.isInhouse != true">{{course.totalDelegates}}</span>
                </td>
            </tr>
            <!--<div class="page-break"></div>-->
        </table>

        <!--<div class="col-12" *ngFor="let monthData of displayCourse">
            <div class="upcoming-container">
                <div class="upcoming-container-title-section color">

                    <label class="text-second p-1 pull-right- p-rl-10px bg-yellow-gradient">{{monthData.date | date : 'MMMM yyyy' }}</label>

                    <hr class="u-line-" />
                </div>
                <div class="upcoming-container-card-section">
                    <div *ngFor="let course of monthData.courses" class="">

                        <div class="row mt-2">
                            <div class="col-2 pr-0">
                                <div class="row bg-white- h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">{{course.date | date : 'dd-MM-yy'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 pr-10px">
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 crs-margins">
                                        <label class="text-second fw-normal">
                                            {{course.title}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 pr-10px">
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 crs-margins">
                                        <label class="text-second fw-normal">
                                            {{course.totalDelegates}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>-->

    </div>
</div>




<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Speaker</span> DETAILS
        </h2>

    </div>
    <div class="col-lg-3 pt-1">

        <!--<button class="book-btn pull-right filter-apply" (click)="goBack()">
            Back
        </button>-->

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="gotoAdmin('speakerList')">Speaker List</button>
                <!--<button mat-menu-item (click)="gotoAdmin('debtCollection')">Debt Collection</button>-->
                <button mat-menu-item (click)="gotoAdmin('speakerCourseNumbers')">Speaker Course Numbers</button>
                <button mat-menu-item (click)="gotoAdmin('uploadCourse')">Upload Course Schedule</button>
            </mat-menu>
        </div>


    </div>

</div>




<div class="row no-bg pb-0 p-3 ml-0 mb-0 m-2 pt-0 mt-0">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <label class="text-second-- top-dd">{{speakerData.speakerName}} ({{speakerData.initials}})</label>

            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="row no-bg">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">


            </div>
            <div class="col-md-4">
                <label class="text-second fw-normal pull-right">
                    Lifetime Value:
                    <span class="income-box">
                        &#163;000
                    </span>
                </label>
            </div>
        </div>
    </div>

</div>



<div class="row outer-div bg-lg ml-0 mb-0 mt-0 m-2 pt-0 p-3">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Mobile number:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.mobileNumber}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Email:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.email1}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Personal email:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.email2}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Address:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.address}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Alternate contact:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.altName}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Relationship:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.altRelationship}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Contact mobile:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.altMobile}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Contact email:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{speakerData.altEmail}}</label>
                    </div>
                </div>





                <!--

                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto pb-3-">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Invoice Address:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3 lbl-address">
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceName!=null">
                            {{currentDelegate.invoiceName}}
                        </label>
                        <label class="text-second w-inherit-" *ngIf="currentDelegate.invoiceAddress!=null">
                            {{currentDelegate.invoiceAddress}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceEmail!=null">
                            {{currentDelegate.invoiceEmail}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceDepartment!=null">
                            {{currentDelegate.invoiceDepartment}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceOrganization!=null">
                            {{currentDelegate.invoiceOrganization}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceTelephone!=null">
                            {{currentDelegate.invoiceTelephone}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceCity!=null">
                            {{currentDelegate.invoiceCity}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoicePostcode!=null">
                            {{currentDelegate.invoicePostcode}}
                        </label>


                        <label class="text-second lbl-notes-" *ngIf="(currentDelegate.invoiceName == null) &&
                               (currentDelegate.invoiceAddress==null) &&
                               (currentDelegate.invoiceEmail==null) &&
                               (currentDelegate.invoiceDepartment==null) &&
                               (currentDelegate.invoiceOrganization==null) &&
                               (currentDelegate.invoiceCity==null) &&
                               (currentDelegate.invoiceTelephone==null) &&
                               (currentDelegate.invoicePostcode==null)">
                            N/A
                        </label>

                        <div class="btn-holder">
                            <button class="book-btn pull-right filter-apply" (click)="updateInvoiceAddress()">
                                Update
                            </button>
                        </div>

                    </div>
                </div>-->
            </div>
        </div>
    </div>

    <div class="col-md-8">

        <div class="row">
            <div class="col-12 flip-border-bottom-white">

                <div id="show-course-container" class="flip-btn pull-left p-2 cursor-pointer" (click)="showCourses()">

                    <span class="strong m-2">Courses</span>

                </div>
                <!--<div id="show-prod-container" class="flip-btn pull-left bg-white p-2 cursor-pointer">

                    <span class="strong m-2">XYZ</span>

                </div>-->
            </div>
        </div>

        <div class="col-12">
            <div class="col-12" id="course-section">

                <div class="row">
                    <div class="col-md-12">

                        <div class="pull-right-">


                            <mat-form-field class="example-form-field" appearance="fill">
                                <mat-label>Duration</mat-label>
                                <mat-date-range-input [formGroup]="coursesCountDateFilter"
                                                      [rangePicker]="coursesCountDateFilterPicker">
                                    <input matStartDate placeholder="Start date" formControlName="start">
                                    <input matEndDate placeholder="End date" formControlName="end" (dateChange)="addEvent($event)">
                                </mat-date-range-input>
                                <!--<mat-hint>MM/DD/YYYY � MM/DD/YYYY</mat-hint>-->
                                <mat-datepicker-toggle matSuffix [for]="coursesCountDateFilterPicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #coursesCountDateFilterPicker></mat-date-range-picker>
                            </mat-form-field>

                            <!--<button class="book-btn pull-right filter-apply" (click)="printPDF()">
                                Print
                            </button>-->

                            <button class="book-btn pull-right filter-apply mr-2" (click)="downloadPDF()">
                                Download
                            </button>

                            <!--<button class="book-btn pull-right filter-apply mr-2" (click)="sendEmail()">
                                Send email
                            </button>-->

                            <div class="row mt-2">
                                <div class="col-md-2 pr-0">
                                    <label class="text-second p-1">Date</label>
                                </div>
                                <div class="col-md-8 pr-10px">
                                    <label class="text-second p-1">Courses</label>
                                </div>
                                <div class="col-md-2 pr-10px">
                                    <label class="text-second p-1">Delegates</label>
                                </div>
                            </div>

                            <!--<label class="text-second p-1">Courses</label>


                            <label class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{currentDelegate.bookedCourses.length}}</label>-->

                            <hr class="u-line-" />


                            <div *ngFor="let monthData of displayCourse">
                                <div class="upcoming-container">
                                    <div class="upcoming-container-title-section color">
                                        <!--Group Month | {{monthData.date | date : 'MMMM yyyy' }}-->

                                        <label class="text-second p-1 pull-right- p-rl-10px bg-yellow-gradient">{{monthData.date | date : 'MMMM yyyy' }}</label>

                                        <hr class="u-line-" />
                                    </div>
                                    <div class="upcoming-container-card-section">
                                        <div *ngFor="let course of monthData.courses" class="">

                                            <div class="row mt-2">
                                                <div class="col-md-2 pr-0">
                                                    <!--<label class="text-second fw-normal">Course Date:</label><br>-->
                                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                                        <div class="col-md-12 p-1 pl-1">
                                                            <label class="text-second dt-box-f mt-1">{{course.date | date : 'dd-MM-yy'}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-8 pr-10px">
                                                    <!--<label class="text-second fw-normal">Course:</label><br>-->
                                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                                        <div class="col-md-12 crs-margins">
                                                            <label class="text-second fw-normal">
                                                                {{course.title}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 pr-10px">
                                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                                        <div class="col-md-12 crs-margins">
                                                            
                                                            <label *ngIf="course.isInhouse == true" class="text-second fw-normal">
                                                                N/A
                                                            </label>
                                                            <label *ngIf="course.isInhouse != true" class="text-second fw-normal">
                                                                {{course.totalDelegates}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--<div *ngFor="let crs of coursesList">
                                <div class="row">
                                    <div class="col-md-12 pr-0">
                                        <label class="text-second fw-normal">Course Date:</label><br>
                                        <div class="row bg-white h-crs-detail m-0 mb-2">
                                            <div class="col-md-12 p-1 pl-1">
                                                <label class="text-second dt-box-f mt-1 mr-4">{{crs.date |  date:'dd-MM-yy'}}</label>
                                                <label class="text-second dt-box-f mt-1">{{crs.title}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                            <!--<button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="addNew()">Add New Speaker</button>
                                 <button mat-menu-item (click)="gotoAdmin('debtCollection')">Debt Collection</button>
                                <button mat-menu-item (click)="gotoAdmin('uploadCourse')">Upload Course Schedule</button>
                            </mat-menu>-->
                        </div>

                        <!--<label class="text-second p-1">Courses</label>


                        <label class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{currentDelegate.bookedCourses.length}}</label

                                            <hr class="u-line-" />>-->
                        <!--<div *ngIf="currentDelegate.bookedCourses.length == 0" class="text-center pt-5">
                            <img class="information-icon" src="assets/images/icone-information.png" />
                            <br />
                            <span class="not-found">No data found</span>
                        </div>-->
                        <!--<div *ngFor="let crs of currentDelegate.bookedCourses">
                        <div class="row">
                            <div class="col-md-2 pr-0">
                                <label class="text-second fw-normal">Course Date:</label><br>
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">{{crs.courseStartDate |  date:'dd-MM-yy'}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pr-0">
                                <label class="text-second fw-normal">Course:</label><br>
                                <div class="row bg-white h-crs-detail m-0 mb-2">
                                    <div class="col-md-12 crs-margins">
                                        <label class="text-second fw-normal"
                                               [ngClass]="crs.courseTitle.length > 100 ? 'crs-size-max' : 'crs-size'">
                                            {{crs.courseTitle}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 pr-0">
                                <label class="text-second fw-normal">Invoice Date:</label><br />
                                <div class="row bg-white h-crs-detail m-0 mb-3">
                                    <div class="col-md-12 p-1 pl-1">
                                        <label class="text-second dt-box-f mt-1">{{crs.invoiceDate | date:'dd-MM-yy'}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <label class="text-second fw-normal">Invoice</label><br>
                                <div class="row bg-green- h-crs-detail m-0 mb-2" [ngClass]="getStatus(crs)">
                                    <div class="col-md-12 p-1 pl-1 text-center">
                                        <label class="dt-box-f mt-1" [ngClass]="getStatus(crs) == 'redborder-simple' ? 'text-second' : 'text-white'">
                                            {{crs.invoiceNumber}}
                                        </label>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="row mt-loc-row-">-->
                        <!--<div class="col-md-4 pr-0">
                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                <div class="col-md-12">
                                    <label class="text-second fw-normal">Location: &nbsp;</label>
                                    <label class="text-second dt-box-f mt-1">{{crs.location}}</label>
                                </div>
                            </div>
                        </div>-->
                        <!--<div class="col-md-2 pr-0">
                            <label class="text-second fw-normal">Location:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1">{{crs.location}}</label>
                                </div>
                            </div>
                        </div>



                        <div class="col-md-2 ml-1">
                            <label class="text-second fw-normal">Fees:&nbsp; </label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                <div class="col-md-12 p-1 pl-1">-->
                        <!--<label class="text-second fw-normal">&nbsp;</label>-->
                        <!--<label class="text-second dt-box-f mt-1">{{crs.courseCost | currency: 'GBP'}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 ml-1">
                            <label class="text-second fw-normal">Date Paid: </label><br>
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <label [ngClass]="{'not-paid': crs.reconcileDate == null}" class="text-second dt-box-f mt-1">&nbsp;{{crs.reconcileDate | date:'dd-MM-yy'}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label class="text-second fw-normal">Payment Type:</label><br />
                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                <div class="col-md-12 p-1 pl-1">
                                    <label class="text-second dt-box-f mt-1"> {{crs.transactionType}}</label>-->
                        <!--<label class="text-second fw-normal">Type: </label>
                        <label class="text-second dt-box-f mt-1">&nbsp; {{crs.transactionType}}</label>-->
                        <!--</div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label class="text-second fw-normal">PO Number</label><br>
                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                <div class="col-md-12 p-1 pl-1">-->
                        <!--<label class="text-second fw-normal">Type: </label>-->
                        <!--<label class="text-second dt-box-f mt-1"> {{crs.poNumber}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <label class="text-second fw-normal">Paid: </label><br />
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12">
                                        <label class="text-second fw-normal pr-2-">&nbsp; </label>
                                        <input type="checkbox"
                                               id="{{crs.courseId}}_chk"
                                               (click)="onPaidClick($event, crs)"
                                               [checked]="crs.isInvoicePaid" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <label class="text-second fw-normal">&nbsp; </label><br />
                                <div class="row bg-white h-crs-detail m-0 mb-2-">
                                    <div class="col-md-12 pt-1">
                                        <label class="text-second fw-normal pr-2-">&nbsp; </label>
                                        <a href=javascript:void() style="height: 21px" (click)="changeDelegateCourse(crs.courseId)" data-title="Swap course" class=" filter-apply">
                                            <i class="material-icons">swap_horiz</i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-loc-row-">-->
                        <!--<div class="col-md-4">
                            <label class="text-second fw-normal">Paid: </label><br>
                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                <div class="col-md-12 p-1 pl-1">
                                    <div class="pull-center">
                                        <input type="checkbox"
                                               id="{{crs.courseId}}_chk"
                                               (click)="onPaidClick($event, crs)"
                                               [checked]="crs.isInvoicePaid" />
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        <!--</div>

                        <div class="col-md-12">-->
                        <!--<button class="book-btn pull-right filter-apply" (click)="changeDelegateCourse(crs.courseId)">
                            Change course
                        </button>-->
                        <!--</div>
                            <hr class="u-line" />
                        </div>-->
                    </div>
                </div>

            </div>


            <div class="col-12" id="prod-section">
                <div class="row">



                    <!--<div *ngIf="currentDelegate.myProducts.length == 0" class="text-center pt-5">
                        <img class="information-icon" src="assets/images/icone-information.png" />
                        <br />
                        <span class="not-found">No data found</span>
                    </div>


                    <div class="col-md-12" *ngIf="currentDelegate.myProducts.length > 0">

                        <label class="text-second  p-1">Products</label>
                        <label class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{currentDelegate.myProducts.length}}</label>
                        <hr />




                        <div class="col-md-12" *ngIf="currentDelegate.myProducts.length == 0">

                            <label class="text-second  p-1">Products</label>
                            <label class="text-second p-1 pull-right">{{currentDelegate.myProducts.length}}</label>
                            <hr />

                            <div class="row">
                                <div class="col-md-12 pr-0">
                                    <label class="text-second fw-normal">No Product Purchased</label><br>
                                </div>
                            </div>

                        </div>

                    </div>-->
                </div>
            </div>
        </div>
    </div>









    <!--<div class="col-8">

        <div class="container">
            <div class="front">Front</div>
            <div class="back">Back</div>
        </div>


    </div>-->



</div>
