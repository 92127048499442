import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './AuthenticationService';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthenticationService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        

        this.ionViewDidLoad();

        if (!this.authService.isTokenExpired()) {
            return true;
        }

        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });

        return false;


    }


    checkTimeout() {

        setTimeout(() => {
            

            // if token not expired
            if (!this.authService.isTokenExpired()) {

                //// verify if the session not also expired
                //this.authService.validateSession().subscribe((result: any) => {
                //    
                //    if (result.success == true) {
                //        // session not expired


                //    }
                //    else {
                //        console.log("Server error");
                //    }

                //}, (_error: any) => {

                //    // if session expired then redirect to login
                //    if (_error.status == 401) {
                //        console.log("Session expired");
                //        this.router.navigate(['/account/login']);
                //    }
                //    else {
                //        console.log("Other Exception: "+_error);
                //        this.router.navigate(['/account/login']);
                //    }
                //})
            }
            // the token is expired
            else {
                console.log("Token expired");
                this.router.navigate(['/account/login']);
            }

            //if (!this.authService.isTokenExpired()) {
            //    //return true;

            //}
            //else {
            //    this.router.navigate(['/account/login']);
            //    //return false;
            //}
            this.checkTimeout();
        }, 120000);
    }


    ionViewDidLoad() {

        this.checkTimeout();

        // Attach event listeners to reset the idle timeout
        document.addEventListener('mousemove', this.resetIdleTimeout);
        document.addEventListener('keydown', this.resetIdleTimeout);

        // Initialize the idle timeout
        //this.resetIdleTimeout();




    }



    

    // Create a variable to store the timeout ID
    idleTimeout: any;

    // Function to reset the idle timeout
    resetIdleTimeout() {

        // Set the idle timeout duration in milliseconds (1 minute)
        var idleTimeoutDuration = 2400000; // 60,000 milliseconds = 1 minute, this is for 30 mins

        // Clear the existing timeout, if any
        window.clearTimeout(this.idleTimeout);
        
        //console.log(this.idleTimeout);

        // Set a new timeout for 1 minute
        this.idleTimeout = setTimeout(() => {
            // Redirect to the login page when the timeout occurs
            //alert("logout");
            window.location.href = '/account/login';
            //this.router.navigate(['/account/login']); //  login page URL
        }, idleTimeoutDuration);

    }


    



}