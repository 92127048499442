<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">
    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>
            </div>
        </div>
        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 1.5rem;">
                    <span class="text-main font-ag-bold">Marketing Report</span>
                </h2>
                <h5 class="mt-3 mb-2 text-second font-ag-light pull-right" style="font-size: 1rem;">
                    <span class="font-ag-bold pull-right">{{_date | date : 'dd-MM-yy'}}</span>
                </h5>
            </div>
        </div>
        <table class="full-width mt-4 course-report">
            <tr class=" mt-5" style="border-bottom: 1pt solid black; font-weight: bold;">
                <td style="width:10%" class="v-align-top text-left">Booking</td>
                <td style="width:20%" class="v-align-top text-left">Delegate</td>
                <td style="width:25% !important" class="v-align-top text-left">Email</td>
                <td style="width:45%" class="v-align-top">Course</td>
            </tr>
        <!--</table>
        <table class="full-width text-second course-report">-->
            <tr *ngFor="let data of marketingReportList" style="border-bottom: 1pt solid black;">
                <td style="width:10%" class="v-align-top">{{data.bookingDate | date : 'dd-MM-yy' }}</td>
                <td style="width:20%" class="v-align-top">{{data.delgateName}}</td>
                <td style="max-width:25% !important" class="v-align-top marketing-email-width">
                    {{data.delegateEmail}}
                </td>
                <td style="width:45%" class="v-align-top">{{data.courseTitle}}</td>
            </tr>
        </table>
    </div>
</div>



<div class="row">
    <div class="col-lg-7">
        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">{{listTitle}}</span>
        </h2>
    </div>
    <div class="col-lg-5 pt-1">
        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNew()">Add New Delegate</button>
                <button mat-menu-item (click)="getDelegatesList(false)">Non-Archive Delegates</button>
                <button mat-menu-item (click)="getDelegatesList(true)">Archive Delegates</button>
                <button mat-menu-item (click)="marketingReport()">Marketing Report</button>
            </mat-menu>
        </div>
    </div>
</div>


<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>
<!-- Button to clear selection -->
<!--<button (click)="clearSelection()">Clear Selection</button>-->
<!-- AG Grid Angular Component -->
<ag-grid-angular #agGrid
                 id="dGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="delegatesList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">




    <!-- //[rowSelection]="'multiple'"
        [enableFilter]="true"
        [pagination]="true"
    [paginationPageSize]="paginationPageSize"-->
</ag-grid-angular>

