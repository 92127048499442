
<div class="modal-body text-center">
     
    <!--<button (click)="CloseOTP()" type="button" class="close">&times;</button>-->
    <!--<button *ngIf="intervalFlag" (click)="CloseOTP()" type="button" class="close">&times;</button>-->
    <mat-icon *ngIf="intervalFlag" (click)="CloseOTP()" class="close">close</mat-icon>
    <img src="assets/images/accept.svg" class="img-responsive mb-4">
    <h4 class="text-colr">OTP CONFIRMATION</h4>
    <p>{{text}}</p>
    <input type="text" appNumberOnly class="form-control"  [placeholder]="placeholder" required="" [(ngModel)]="otpValue" [maxLength]="maxLength">


    <div class="modal-footer">

        <button [disabled]="!intervalFlag" type="button" (click)="ResendOTP()" class="btn btn-success">
            Resend OTP
        </button> 
         
        <button type="button" (click)="send()" [disabled]="otpValue == null || otpValue == undefined || otpValue == '' " class="btn btn-primary">
            Send Now
        </button>
        <p class="counter " *ngIf="!intervalFlag">{{timeLeft}} </p>

    </div>
</div>
