export class Activity {
    id: number;
    parentId: number;
    name: string;
    url: string;
    icon: string;
}

export class MainActivity extends Activity {
    subActivities: Activity[];
}

export class SubActivity extends Activity {
    childActivities: ChildActivity[];
}

export class ChildActivity extends Activity {
    common: string;
}
