import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-stats',
  templateUrl: './marketing-stats.component.html',
  styleUrls: ['./marketing-stats.component.css']
})
export class MarketingStatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
