import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
    selector: 'app-checkbox-renderer',
    //template: `
    //<button type="button" (click)="onClick($event)">{{label}}</button>
    //`

    template: `
                <div class="pull-center">
                    <input
                      id="{{id}}"
                      type="checkbox" 
                      (click)="onClick($event)"
                      [checked]="params.value"
                    />
                </div>
            `
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {


    params;
    label: string;
    id;

    agInit(params): void {
        this.params = params;
        this.label = this.params.label || null;
        this.id = this.params.node.data.invoiceNumber || null
    }

    refresh(params?: any): boolean {
        return true;
    }

    onClick($event) {
        if (this.params.onClick instanceof Function) {
            // put anything into params u want pass into parents component
            
            const params = {
                action: $event.currentTarget.checked,
                delegateId: this.params.node.data.id,
                toBeUpdated: this.params.column.colDef.headerName,
                rowData: this.params.node.data
            }

            this.params.onClick(params);

        }
    }
}
