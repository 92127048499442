import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoicesRoutingModule } from './invoices-routing.module';
import { InvoicesComponent } from './invoices/invoices.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        InvoicesComponent
    ],
    imports: [
        CommonModule,
        InvoicesRoutingModule,
        AgGridModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [
        InvoicesComponent
    ]
})
export class InvoicesModule { }
