<!--<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                &copy; Actnow {{currentYear}}. All rights reserved.
            </div>

        </div>
    </div>
</footer>-->




<footer class="footer text-white font-ag-light">

    <div class="container-fluid bg-second- tile-bg pt-2 pl-2 pr-2 pb-3">

        <div class="actnow-container-">

            <div class="row">



                <div class="col-12 col-lg-6 order-2 order-lg-1">

                    <div class="d-flex- flex-column justify-content-center justify-content-lg-between h-100">

                        <!--<div class="d-flex justify-content-center justify-content-lg-end">-->
                        <!--<div class="d-flex justify-content-center justify-content-lg-end text-lg-right">-->



                        <svg class="mb-2 width-80-no" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 200 49" style="float:left; width:150px; enable-background:new 0 0 200 49;" xml:space="preserve">
                        <style type="text/css">
                            .st0 {
                                clip-path: url(#SVGID_00000090281762106670702120000002538493339532450958_);
                                fill: #FFFFFF;
                            }

                            .st1_200 {
                                fill: #FFFFFF;
                            }
</style>
                        <g>
                        <g>
                        <defs>
                        <path id="SVGID_1_200" d="M176.39,1.05l-12.45,47.68h10.36l3.59-13.76c-1.95-1.31-3.23-3.53-3.23-6.06c0-4.03,3.27-7.3,7.3-7.3
                                                c4.03,0,7.3,3.27,7.3,7.3c0,2.52-1.28,4.75-3.22,6.06l3.59,13.76H200L187.55,1.05H176.39z" />
                                        </defs>
                        <use xlink:href="#SVGID_1_200" style="overflow:visible;fill:#FFFFFF;" />
                        <clipPath id="SVGID_00000008136480224766966180000012563364067017111463_">
                        <use xlink:href="#SVGID_1_200-" style="overflow:visible;" />
                                        </clipPath>
                                        
                        <rect x="152.36" y="-4.72" transform="matrix(0.7071 -0.7071 0.7071 0.7071 35.6995 135.9624)" style="clip-path:url(#SVGID_00000008136480224766966180000012563364067017111463_);fill:#FFFFFF;" width="59.21" height="59.21" />
                                    </g>
                        <polygon class="st1_200" points="97.21,1.05 102.81,1.05 102.81,31.48 97.86,31.48 84.53,10.14 84.88,31.48 79.32,31.48 79.32,1.05
                                        84.45,1.05 97.9,22.56   " />
                        <g>
                        <path class="st1_200" d="M119.49,5.49c-5.22,0-8.71,4.22-8.71,10.56c0,6.86,3.79,10.95,8.84,10.95c3.79,0,8.67-2.76,8.8-10.09
                                            C128.54,10.96,125.48,5.49,119.49,5.49 M119.44,32.26c-3.84,0-7.37-1.08-10.48-4.66c-3.02-3.49-4.05-7.46-4.05-11.3
                                            c0-9.31,6.29-16.04,14.61-16.04c7.55,0,14.61,5.6,14.57,15.99C134.06,24.98,128.8,32.26,119.44,32.26" />
                                    </g>
                        <polygon class="st1_200" points="169.09,1.05 162.41,31.48 155.77,31.48 151.5,10.32 147.11,31.48 140.47,31.48 134.13,1.05
                                        139.95,1.05 144.09,24.24 148.83,1.05 154.13,1.05 158.96,24.24 163.23,1.05   " />
                        <polygon class="st1_200" points="79.32,35.91 79.32,36.95 83.25,36.95 83.25,48.41 84.43,48.41 84.43,36.95 88.38,36.95 88.38,35.91
                                        " />
                        <g>
                        <path class="st1_200" d="M91.27,41.84h2.94c0.81,0,1.58-0.02,2.11-0.12c1.33-0.27,1.81-1.28,1.81-2.28c0-1.22-0.6-2.14-1.82-2.39
                                            c-0.35-0.07-0.51-0.07-2.09-0.09h-2.94V41.84z M99.64,48.41h-1.49l-3.95-5.54h-2.94v5.54h-1.19V35.89h3.51
                                            c2.18,0.02,2.73,0.02,3.51,0.28c2.05,0.73,2.2,2.69,2.2,3.31c0,1.31-0.57,2.04-0.94,2.41c-0.9,0.85-2.09,0.92-2.76,0.98
                                            L99.64,48.41z" />
                                    </g>
                        <path class="st1_200" d="M106.21,37.31l2.41,6.37h-4.85L106.21,37.31z M105.74,35.91l-5.1,12.5h1.33l1.42-3.77h5.6l1.43,3.77h1.29
                                        l-4.98-12.5H105.74z" />
                        <rect x="113.33" y="35.91" class="st1_200" width="1.19" height="12.5" />
                        <polygon class="st1_200" points="126.63,35.91 126.74,46.6 119.28,35.91 118.13,35.91 118.13,48.41 119.28,48.41 119.28,37.61
                                        126.63,48.41 127.8,48.41 127.8,35.91    " />
                        <rect x="131.41" y="35.91" class="st1_200" width="1.19" height="12.5" />
                        <polygon class="st1_200" points="144.71,35.91 144.81,46.6 137.36,35.91 136.21,35.91 136.21,48.41 137.36,48.41 137.36,37.61
                                        144.71,48.41 145.87,48.41 145.87,35.91  " />
                        <g>
                        <path class="st1_200" d="M158.7,48.41h-1.03v-2.09c-0.14,0.37-0.53,1.35-1.7,1.95c-0.58,0.3-1.31,0.46-2.09,0.46
                                            c-3.45,0-5.31-2.83-5.31-6.46c0-3.86,1.98-6.68,5.6-6.68c3.2,0,4.2,2.14,4.62,3.17l-1.12,0.6c-0.12-0.53-0.74-2.76-3.42-2.76
                                            c-2.57,0-4.43,2.07-4.43,5.6c0,3.19,1.42,5.49,4.12,5.49c2.99,0,3.77-2.76,3.72-4.73h-4.6v-1.08h5.63V48.41z" />
                                    </g>
                        <path class="st1_200" d="M13.58,6.57l4.18,14.01H9.35L13.58,6.57z M10.26,1.05L0,31.48h6.08l1.77-5.95h11.42l1.77,5.95h6.08L16.73,1.05
                                        H10.26z" />
                        <g>
                        <path class="st1_200" d="M53.88,22.99c-0.39,0.99-1.12,2.76-2.63,4.57c-2.15,2.5-5.56,4.7-10.52,4.7c-8.06,0-14.23-5.91-14.23-16.08
                                            c0-10.35,6.68-15.91,14.44-15.91c9.49,0,11.73,7.29,12.42,9.4l-5.69,1.51c-0.09-0.56-0.34-1.85-1.12-3.02
                                            c-0.65-0.95-2.33-2.67-5.48-2.67c-4.96,0-8.41,4.1-8.41,10.61c0,6.94,3.66,10.78,8.45,10.78c2.84,0,4.57-1.38,5.82-2.98
                                            c0.99-1.33,1.38-2.46,1.59-3.15L53.88,22.99z" />
                                    </g>
                        <polygon class="st1_200" points="76.58,6.22 68.18,6.22 68.18,31.49 62.49,31.49 62.49,6.22 54.25,6.22 54.25,1.05 76.58,1.05  " />
                                </g>
                                </svg>
                        <!--REVERT-->
                        <!--</div>-->

                    </div>

                </div>


                <div class="col-12 col-lg-6 order-1 order-lg-2">

                    <div class="d-flex flex-column justify-content-center justify-content-lg-between h-100" style="justify-content: end !important;">

                        <div class="text-right">

                            <p class="footer-content-size">
                                &copy; Act Now Training Ltd
                                {{currentYear}}
                            </p>
                            <!--REVERT-->
                            <!--<p class="footer-content-size">
                                &copy; Track My Contacts
                                {{currentYear}}
                            </p>-->
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>

</footer>

