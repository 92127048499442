import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CoursesService } from '../../../../common/_services/courses-services';
import { DelegateService } from '../../../../common/_services/delegate-services';
import { AddEditCourseTitlesComponent } from '../add-edit-course-titles/add-edit-course-titles.component';
import { CourseTitleClickableParentComponent } from '../course-title-clickable-parent/course-title-clickable-parent.component';

@Component({
    selector: 'app-course-titles-list',
    templateUrl: './course-titles-list.component.html',
    styleUrls: ['./course-titles-list.component.css']
})
export class CourseTitlesListComponent implements OnInit {

    //@Output() gotoMainMenu = new EventEmitter<void>();
    @Output() gotoAllCourses = new EventEmitter<void>();
    @Output() gotoMimeo = new EventEmitter<void>();

    courseTitlesList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 10;




    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'title', headerName: 'Title' },
        { field: 'subject', headerName: 'Subject' },
        { field: 'courseType', headerName: 'Course Type' },
        { field: 'courseDuration', headerName: 'CourseDuration' },
        { field: 'courseCost', headerName: 'Course Cost' },
        { field: 'courseCode', headerName: 'Course Code' },
        { field: 'startTime', headerName: 'Start Time' },
        { field: 'endTime', headerName: 'End Time' },
        {
            field: 'Action', cellRendererFramework: CourseTitleClickableParentComponent,
            sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        //private http: HttpClient,
        private _courseService: CoursesService,
        //private _route: Router
    ) {

        this.gridOptions = {
            context: {
                componentParent: this
            },
            rowClass: 'grid-row',
            suppressCellSelection: true
        };

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#titlesGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#titlesGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }




    ngOnInit(): void {

        localStorage.setItem('projectPageSize', '10');
        this.getCourseTitlesList();
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getCourseTitlesList() {
        
        
        this._courseService.getCourseTitlesList().subscribe((result: any) => {

            if (result.success == true) {

                this.courseTitlesList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditCourseTitlesComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {
            
            this.getCourseTitlesList();
        }, (reason) => {
            
            //console.log('Dismissed action: ' + reason);
        });
    }

    openModal(param) {
        
        const modalRef = this.modal.open(AddEditCourseTitlesComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getCourseTitlesList());
    }

    allCourses() {
        this.gotoAllCourses.emit();

    }

    gotoMimeoTab() {
        this.gotoMimeo.emit();
    }
}
