import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
    selector: 'app-add-edit-credit-note',
    templateUrl: './add-edit-credit-note.component.html',
    styleUrls: ['./add-edit-credit-note.component.css']
})
export class AddEditCreditNoteComponent implements OnInit {

    public _title = "Add";

    cn_EditForm: FormGroup;
    @Input() data;
    @Output() refreshGrid = new EventEmitter();
    //_params: any;
    _cnId: any = 0;
    currentCreditNote: any;
    isNew: any = true;
    invoiceDate: any = new FormControl(new Date());
    isEditMode: any = false;

    constructor(private formBuilder: FormBuilder,
        private _delegateService: DelegateService,
        private _commonService: CommonServiceService,
        public activeModal: NgbActiveModal,) { }

    ngOnInit(): void {
        this.cn_EditForm = this.formBuilder.group({
            name: ['', Validators.required],
            organization: [''],
            department: [''],
            address1: [''],
            location: [''],
            postcode: [''],
            telephone: [''],
            email: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
            invoiceNumber: ['', Validators.required],
            subtotal: ['', Validators.required],
            invoiceDate: [this.invoiceDate.value],
            notes: [''],
            cn_number: [''],
            totalTax: [''],
            total: ['', Validators.required],
        });



        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this._cnId = this.data.id;

            if (Number(this._cnId) > 0) {
                this.currentCreditNote = this.data;
                this.populateForm();

            }

        }
    }

    get f() { return this.cn_EditForm.controls; }

    populateForm() {

        if (this.currentCreditNote != null) {

            this.isEditMode = true;
            this._title = "Edit";

            this.cn_EditForm.controls.name.setValue(this.currentCreditNote.name);
            this.cn_EditForm.controls.organization.setValue(this.currentCreditNote.organization);
            this.cn_EditForm.controls.department.setValue(this.currentCreditNote.department);
            this.cn_EditForm.controls.address1.setValue(this.currentCreditNote.address);
            this.cn_EditForm.controls.postcode.setValue(this.currentCreditNote.postcode);
            this.cn_EditForm.controls.telephone.setValue(this.currentCreditNote.telephone);
            this.cn_EditForm.controls.email.setValue(this.currentCreditNote.email);
            this.cn_EditForm.controls.notes.setValue(this.currentCreditNote.description);
            this.cn_EditForm.controls.location.setValue(this.currentCreditNote.cityName);
            this.cn_EditForm.controls.invoiceNumber.setValue(this.currentCreditNote.invoiceNumber);
            this.cn_EditForm.controls.subtotal.setValue(this.currentCreditNote.amount);
            this.cn_EditForm.controls.cn_number.setValue(this.currentCreditNote.creditNoteNumber);
            this.cn_EditForm.controls.total.setValue(this.currentCreditNote.total);
            this.cn_EditForm.controls.totalTax.setValue(this.currentCreditNote.tax);
            this.invoiceDate = this.currentCreditNote.invoiceDate;

            this.applyTax(false);
        }
    }

    addEvent(event: MatDatepickerInputEvent<Date>) {

        if (event.value != null) {

            //this.invoiceDate = new Date(event.value);
            //this.invoiceDate.setDate(event.value.getDate());
            //this.invoiceDate.setMonth(event.value.getMonth());
            //this.invoiceDate.setFullYear(event.value.getFullYear());


            this.invoiceDate = this.adjustDateForTimeOffset(event.value);

            // console.log(event.value)
        }

    }

    adjustDateForTimeOffset(dateToAdjust) {
        var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
        return new Date(dateToAdjust.getTime() - offsetMs);
    }


    onSubmit() {

        var req = {

            request: {
                id: Number(this._cnId),
                creditNoteNumber: this.cn_EditForm.controls.cn_number.value,
                name: this.cn_EditForm.controls.name.value,
                organization: this.cn_EditForm.controls.organization.value,
                department: this.cn_EditForm.controls.department.value,
                address: this.cn_EditForm.controls.address1.value,
                cityName: this.cn_EditForm.controls.location.value,
                postcode: this.cn_EditForm.controls.postcode.value,
                telephone: this.cn_EditForm.controls.telephone.value,
                email: this.cn_EditForm.controls.email.value,
                description: this.cn_EditForm.controls.notes.value,
                invoiceNumber: this.cn_EditForm.controls.invoiceNumber.value,
                amount: Number(this.cn_EditForm.controls.subtotal.value),
                invoiceDate: this.invoiceDate,
                tax: Number(this.cn_EditForm.controls.totalTax.value),
                total: Number(this.cn_EditForm.controls.total.value)
            }
        };

        debugger;
        this._delegateService.insertCreditNote(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show errror popup
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    applyTax(_applyTax) {

        var subtotal = Number(this.cn_EditForm.controls.subtotal.value);
        //var totalTax = Number(this.delegateProductForm.controls.totalTax.value);

        var tax = (subtotal * 20) / 100;



        //var total = (Number(this.delegateProductForm.controls.subtotal.value) +
        //    Number(this.delegateProductForm.controls.shippingCost.value));

        //var tax = (total * 20) / 100;

        debugger;

        if (_applyTax) {
            this.cn_EditForm.controls.total.setValue((Number(subtotal) + Number(tax)).toFixed(2));
            this.cn_EditForm.controls.totalTax.setValue(tax.toFixed(2));

        }
        else {
            var totalTax = Number(this.cn_EditForm.controls.totalTax.value);
            if (totalTax > 0) {
                this.cn_EditForm.controls.total.setValue((Number(subtotal) + Number(totalTax)).toFixed(2));
                //this.delegateProductForm.controls.totalTax.setValue(totalTax.toFixed(2));
            }
            else
                this.cn_EditForm.controls.total.setValue(Number(subtotal));
        }
        //this.delegateProductForm.controls.total.setValue((Number(total) + Number(shippingCost)));

    }
}
