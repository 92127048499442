import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

    @Output() redirect = new EventEmitter<any>();
    @Output() closePopup = new EventEmitter<any>();


    public Text: string;
    public Header: string;
    constructor(public dialogRef: MatDialogRef<DeleteComponent>,

        //@Optional() is used to prevent error if no data is passed
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

        
        this.Text = data["message"];
        this.Header = data["title"];
    }

    ngOnInit(): void {
    }


    ok() {
        this.redirect.emit({ Yes: "delete" })
    }

    close() {
        this.closePopup.emit({ remove: "no" });
    }
}
