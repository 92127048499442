<!--<p>verification works!</p>

<img src="{{verificationData.barcodeImageUrl}}" />
<br />
        Setup code: {{verificationData.setupCode}}
<br />-->
<!--<form class="form-horizontal" [formGroup]="verificationForm" (ngSubmit)="onSubmit()" name="verificationForm">

    Input security code:
    <input name="inputCode" />
    <input type="text" class="form-control" placeholder="Verification code"
           [ngClass]="{  'is-invalid': f.inputCode.errors && f.inputCode.errors.pattern}"
           required formControlName="inputCode" maxlength="250" />
    <div *ngIf="f.inputCode.errors" class="invalid-feedback">
        <div *ngIf="f.inputCode.errors.pattern">{{errors.googleVerificationCode}}</div>
    </div>
</form>-->
<!--<form class="form-horizontal" [formGroup]="verificationForm" (ngSubmit)="onSubmit()" name="verificationForm">

    Input security code:


    <input type="text" class="form-control" placeholder="Verification code"
           [ngClass]="{  'is-invalid': f.inputCode.errors && f.inputCode.errors.pattern}"
           required formControlName="inputCode" maxlength="250" />
    <div *ngIf="f.inputCode.errors" class="invalid-feedback">
        <div *ngIf="f.inputCode.errors.pattern">{{errors.inputCode}}</div>
    </div>

    <div class="mt-3">
        <button [disabled]="loading || !verificationForm.valid" class="btn btn-primary w-100">
            Submit
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        </button>

    </div>
</form>-->



<div class="account-pages my-5 pt-sm-5">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-xl-5 col-sm-8">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="p-2 text-center">
                            <a href="#" class="logo"><img height="80px" src="assets/images/loginlogo.png" alt="logo"></a>

                        </div>
                        <form class="form-horizontal" [formGroup]="verificationForm" (ngSubmit)="onSubmit()" name="verificationForm">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group form-group-custom mb-4">
                                        <div class="col-md-12 mb-4 mt-2" style="text-align:center;">
                                            
                                                <img src="{{verificationData.barcodeImageUrl}}" />
                                            
                                        </div>
                                        <input type="text" onkeypress="return event.charCode > 47 && event.charCode < 58;" pattern="[0-9]{6}" class="form-control" placeholder="Verification code"
                                               [ngClass]="{  'is-invalid': f.inputCode.errors && f.inputCode.errors.pattern}"
                                               required formControlName="inputCode" id="inputCode" maxlength="6" />
                                        <div *ngIf="f.inputCode.errors" class="invalid-feedback">
                                            <div *!ngIf="f.inputCode.errors.pattern">{{errors.inputCode}}</div>
                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <button  [disabled]="loading || !verificationForm.valid" class="btn btn-primary w-100">
                                            Log In
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

