<mat-select formControlName="dummy" #select
            class="form-control no-radius " style="display:none">
    <mat-option value="xxx">xxx</mat-option>
</mat-select>






<div class="modal-header primary">

    <h5 class="modal-title primary">{{_title}}</h5>

</div>


<div class="p-4">

    <form class="form-horizontal" [formGroup]="invoiceForm" (ngSubmit)="onPaymentSubmit()" name="invoiceForm" autocomplete="off">

        <div class="row">

               <div class="col-4">
                    <label class="text-second">Supplier:</label>
                    <mat-select formControlName="supplier" #select
                                class="form-control no-radius text-second">
                        <mat-option selected value="none" class=" text-second">Select supplier</mat-option>
                        <mat-option *ngFor="let sup of suppliersList" [value]="sup.supplierId">{{sup.name}}</mat-option>
                    </mat-select>
                </div>

            <div class="col-4">

                <label class="text-second">Invoice Number:</label>
                <input type="text" class="form-control no-radius" placeholder="Invoice Number"
                       [ngClass]="{  'is-invalid': i.invoiceNumber.errors}"
                       required formControlName="invoiceNumber" maxlength="250" />
                <div *ngIf="i.invoiceNumber.errors" class="invalid-feedback">
                    <div *ngIf="i.invoiceNumber.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>



            <div class="col-4">
                <label class="text-second">Paid By:</label>

                <mat-select *ngIf="true" formControlName="source" #select
                            class="form-control no-radius text-second mt-3-">

                    <mat-option selected value="none" class=" text-second">Select source</mat-option>

                    <mat-option value="BACS">
                        BACS
                    </mat-option>

                    <mat-option value="Cash">
                        Cash
                    </mat-option>

                    <mat-option value="Cheque">
                        Cheque
                    </mat-option>

                    <mat-option value="Credit Note">
                        Credit Note
                    </mat-option>

                    <mat-option value="Direct Debit">
                        Direct Debit
                    </mat-option>

                    <mat-option value="AMEX">
                        AMEX
                    </mat-option>

                    <mat-option value="Paypal">
                        Paypal
                    </mat-option>

                    <mat-option value="Phone">
                        Phone
                    </mat-option>

                    <mat-option value="Shopify">
                        Shopify
                    </mat-option>

                    <mat-option value="Written Off">
                        Written Off
                    </mat-option>

                    <mat-option value="Other">
                        Other
                    </mat-option>

                    <mat-option value="Cancelled">
                        Cancelled
                    </mat-option>

                </mat-select>

            </div>

            <div class="col-3">
                <label class="text-second">Subtotal:</label>
                <input type="text" class="form-control no-radius" placeholder="Subtotal"
                       [ngClass]="{  'is-invalid': i.subtotal.errors}"
                       required formControlName="subtotal" maxlength="250" (input)="applyTax($event.target.value)" />
                <div *ngIf="i.subtotal.errors" class="invalid-feedback">
                    <div *ngIf="i.subtotal.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>
            <div class="col-3">
                <label class="text-second">Total Tax:</label>
                <input type="text" class="form-control no-radius" placeholder="Total Tax"
                       [ngClass]="{  'is-invalid': i.totalTax.errors}"
                       required formControlName="totalTax" maxlength="250" (input)="changeTax($event.target.value)" />
                <div *ngIf="i.totalTax.errors" class="invalid-feedback">
                    <div *ngIf="i.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>
            <div class="col-3">
                <label class="text-second">Total:</label>
                <input type="text" class="form-control no-radius" placeholder="Total"
                       [ngClass]="{  'is-invalid': i.total.errors}"
                       required formControlName="total" maxlength="250" />
                <div *ngIf="i.total.errors" class="invalid-feedback">
                    <div *ngIf="i.total.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-3">
                <label class="text-second">Expenses:</label>
                <input type="text" class="form-control no-radius" placeholder="Expense"
                       [ngClass]="{  'is-invalid': i.expense.errors}"
                       required formControlName="expense" maxlength="250" />
                <div *ngIf="i.expense.errors" class="invalid-feedback">
                    <div *ngIf="i.expense.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-6">

                <label class="text-second">Services Details:</label>
                <input type="text" class="form-control no-radius" placeholder="Services Details"
                       [ngClass]="{  'is-invalid': i.serviceDetails.errors}"
                       required formControlName="serviceDetails" maxlength="250" />
                <div *ngIf="i.serviceDetails.errors" class="invalid-feedback">
                    <div *ngIf="i.serviceDetails.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-6">

                <label class="text-second">Payment Reference:</label>
                <input type="text" class="form-control no-radius" placeholder="Payment Reference"
                       [ngClass]="{  'is-invalid': i.paymentRef.errors}"
                       required formControlName="paymentRef" maxlength="250" />
                <div *ngIf="i.paymentRef.errors" class="invalid-feedback">
                    <div *ngIf="i.paymentRef.errors.pattern">{{errors.courseTitle}}</div>
                </div>
            </div>

            <div class="col-6">
                <div class="col-md-12  mt-3">
                    <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                        <mat-label>Invoice date</mat-label>
                        <input matInput [matDatepicker]="picker_invoiceDate" formControlName="invoiceDate" placeholder=""
                               [ngModel]="_invoiceDate" (dateChange)="addEvent('_invoiceDate', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker_invoiceDate"></mat-datepicker-toggle>
                        <mat-datepicker [startAt]="_date" #picker_invoiceDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-6">
                <div class="col-md-12  mt-3">
                    <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                        <mat-label>Payment date</mat-label>
                        <input matInput [matDatepicker]="picker_paymentDate" formControlName="p_paymentDate" placeholder=""
                               [ngModel]="_paymentDate" (dateChange)="addEvent('_paymentDate', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker_paymentDate"></mat-datepicker-toggle>
                        <mat-datepicker [startAt]="_date" #picker_paymentDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>


        </div>




        <div class="modal-footer">
            <button type="button" class="book-btn" (click)="close()">
                Cancel
            </button>

            <div class="">
                <button [disabled]="!invoiceForm.valid"
                        class="book-btn">
                    Save
                </button>

            </div>
        </div>

    </form>
</div>

