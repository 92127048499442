export class ResponseCode {
    public static readonly VerificationOTP1: string = "1020101";
    public static readonly VerificationOTP2: string = "1020102";
    public static readonly OK: string = '00';
    public static readonly OTPExpired: string = 'BBS06';
    public static readonly InvalidOTP: string = 'BBS05'; 
    public static readonly SessionExpired: string = 'BBS07'; 

    
    public static Nadra: boolean;

}

export class CodeStatus {
    public static readonly Warning: string = 'Warning';
    public static readonly Error: string = 'Error';
    public static readonly Remarks: string = 'Remarks';
    public static readonly Success: string = 'Success';
    public static readonly Alert: string = 'Alert';
    public static readonly Reject: string = 'Rejected reason';
}

export class Retries {
    public static Count: number = 4;
}
