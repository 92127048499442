import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';

@Component({
    selector: 'app-add-edit-product',
    templateUrl: './add-edit-product.component.html',
    styleUrls: ['./add-edit-product.component.css']
})
export class AddEditProductComponent implements OnInit {

    currentDate: any = null;// new FormControl(new Date());
    submitted = false;
    public _title = "Add Product";
    public locationsList: any;
    productEditForm: FormGroup;
    _params: any;
    _productId: any = 0;
    //currentInhouse: any;
    @Input() data;
    @Output() refreshGrid = new EventEmitter();

    isNew: any = true;

    loading = false;

    constructor(public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private _route: Router) {


    }

    ngOnInit(): void {
        
        this.loading = true;

        this.productEditForm = this.formBuilder.group({
            p_title: ['', Validators.required],
            cost: [''],
            expiryDate: [''], //[this.currentDate.value],
            totalTax: [''],
            total: ['', Validators.required]
        });

        
        if (Object.keys(this.data).length === 0) {
            // this is add case
        }
        else {
            this.isNew = false;
            this.populateForm(this.data);
        }


        this.loading = false;
    }



    get f() { return this.productEditForm.controls; }

    populateForm(__data) {
        
        this._title = "Edit Product";
        this._productId = __data.id;
        this.productEditForm.controls.p_title.setValue(__data.title);
        this.productEditForm.controls.cost.setValue(__data.cost);
        this.productEditForm.controls.expiryDate.setValue(__data.expiryDate);

        this.applyTax(__data.cost);

    }

    applyTax(d: any) {
        
        var tax = (d * 20) / 100;
        this.productEditForm.controls.totalTax.setValue(tax.toFixed(2));
        this.productEditForm.controls.total.setValue((Number(d) + Number(tax)).toFixed(2));
    }

    validateAmount(event: KeyboardEvent, _val): void {
        
        const charCode = event.which || event.keyCode;
        const char = String.fromCharCode(charCode);

        // Allow decimal point (46) and numbers (48-57)
        if (charCode === 190 || charCode === 46 || charCode === 8 || (charCode >= 48 && charCode <= 57)) {
            // Ensure only one decimal point
            if (charCode === 46 && _val.indexOf('.') !== -1) {

                event.preventDefault();
            }
        } else {
            event.preventDefault();
        }
    }

    onSubmit() {

        var req = {

            request: {
                Id: Number(this._productId),
                Title: this.productEditForm.controls.p_title.value,
                Cost: parseInt(this.productEditForm.controls.cost.value),
                ExpiryDate: this.productEditForm.controls.expiryDate.value
            }
        };

        this._delegateService.insertProduct(req).subscribe((result: any) => {


            if (result.success == true) {

                this.activeModal.close('Close click');
                this.refreshGrid.emit();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    close() { }

    delete(id) {

        if (id > 0) {
            this._delegateService.deleteDelegate(id).subscribe((result: any) => {

                if (result.success == true) {


                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    gotoMainMenu(tab) {
        if (tab.index == 0) {
            this._route.navigateByUrl("/courses/all-courses");
        }
        else if (tab.index == 2) {
            this._route.navigateByUrl("/schedule");
        }
    }

}
