import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { SideBarLayoutComponent } from './side-bar-layout/side-bar-layout.component';
import { SideMenuComponent } from './side-bar-layout/side-menu/side-menu.component';
import { TopMenuComponent } from './side-bar-layout/top-menu/top-menu.component';
import { FooterComponent } from './side-bar-layout/footer/footer.component';
import { RouterModule } from '@angular/router';
import { MetismenuAngularModule } from "@metismenu/angular";
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CoursesComponent } from '../../../core/courses/courses.component';
import { CoursesModule } from '../../../core/courses/courses.module';
import { DelegatesModule } from '../../../core/delegates/delegates.module';
import { SchedulesModule } from '../../../core/Schedule/schedules.module';
import { UploadsModule } from '../../../core/upload-courses/uploads.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { DashboardModule } from '../../../core/dashboard/dashboard.module';
import { InvoicesModule } from '../../../core/invoices/invoices.module';
import { InhouseModule } from '../../../core/inhouse/inhouse.module';
import { ProductModule } from '../../../core/product/product.module';
import { FinanceModule } from '../../../core/finance/finance.module';
import { SpeakersModule } from '../../../core/speakers/speakers.module';
import { ReportsModule } from '../../../core/reports/reports.module';
import { MatMenuModule } from '@angular/material/menu';
import { AdminModule } from '../../../core/admin/admin.module';

const components = [
    BlankLayoutComponent,
    SideBarLayoutComponent,
    SideMenuComponent,
    TopMenuComponent,
    FooterComponent
];

@NgModule({
    declarations: components,
    imports: [
        CoursesModule,
        DelegatesModule,
        SchedulesModule,
        UploadsModule,
        CommonModule,
        RouterModule,
        MetismenuAngularModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonToggleModule,
        HighchartsChartModule,
        DashboardModule,
        InvoicesModule,
        InhouseModule,
        ProductModule,
        FinanceModule,
        //SpeakersModule,
        //ReportsModule,
        MatMenuModule,
        AdminModule
    ],
    exports: components
})
export class LayoutsModule { }
