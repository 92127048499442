import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadsRoutingModule } from './uploads-routing.module';
import { UploadCoursesComponent } from './upload-courses.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [

        //UploadCoursesComponent
    ],
    imports: [
        CommonModule,
        UploadsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [

        //UploadCoursesComponent
    ]
})
export class UploadsModule { }
