import { Component, OnInit } from '@angular/core';
import { BaseResponse } from '../../../common/model/base-response';
import { Act, ActivitiesList, BaseRequest, levels } from '../../../common/model/request';
import { ResponseCode } from '../../../common/validation/errors-code';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DashboardService } from '../../../common/_services/dashboard.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    user: any;
    constructor(private _dashboardService: DashboardService, private _commonService: CommonServiceService) {

        
    }

    ngOnInit() {

        document.body.className = "";
        this.user = {
            name: localStorage.getItem("name"),
            balance: localStorage.getItem("balance")
        }
    }

    refreshBalance() {
        var request = new BaseRequest();
        var request = this._commonService.getBaseReq(request);
        request.act = new Act();
        request.act.Typ = ActivitiesList.BALANCE_INQUIRY;
        request.act.Lvl = levels.zero;
        this._dashboardService.balanceInquiry(request).subscribe((data: any) => {
            if (data.code == ResponseCode.OK) {

                localStorage.setItem("balance", data.act.bdata.balancE_AMOUNT);
                this.user.balance = data.act.bdata.balancE_AMOUNT;
            } else {
                this._commonService.error(data.desc);
            }
        }, (_error: any) => {

        })
    }

}
