<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">

    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>

                <div class="col-12 pull-left">
                    <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 2rem;">
                        <span class="text-main font-ag-bold">Credit Note</span>
                    </h2>
                </div>
                <!--<div class="col-12 pull-left">
                <h2 class="mb-3 text-second font-ag-light" style="font-size: 1.2rem;">
                    <span class="text-second font-ag-bold">{{ currentInvoice.supplierName }}</span>
                </h2>
            </div>-->
            </div>
        </div>

        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mb-3 text-second font-ag-light" style="font-size: 14px;">
                    <span class="text-second font-ag-bold">Act Now Training Ltd</span>
                    <p>
                        Annexe 2, Suite 45,
                        <br />
                        Batley Business Park,
                        <br />
                        Technology Drive, Batley
                        <br />
                        West Yorkshire, WF17 6ER
                        <br />
                        Tel: 01924 470252
                        <br />
                        Email: info@actnow.org.uk
                    </p>
                </h2>
            </div>
            <!--<span class="mb-3 pull-right" style="font-weight: bold;">{{ coursesCountDateFilter.value.start | date:'longDate' }} - {{ coursesCountDateFilter.value.end | date:'longDate' }}</span>-->
        </div>

        <hr />

        <div class="col-5 text-left mt-4">
            <div class="pull-left">
                <h2 class="mb-3 text-second font-ag-light" style="font-size: 16px; ">
                    <span class="text-second font-ag-bold">{{cnDownload.name}}</span>
                    <p class="pt-1" style="line-height: 2;">
                        {{cnDownload.organization}}
                        <br />
                        {{cnDownload.address}}
                        <br />
                        {{cnDownload.cityName}}, {{cnDownload.postcode}}
                        <br />

                    </p>
                </h2>
            </div>
        </div>


        <div class="col-7 text-left mt-4">
            <div class="pull-left full-width">
                <table class="full-width mt-2-">
                    <tr class="p-2- ">
                        <td style="font-weight: bold; width:50%" class="v-align-top p-2">Credit note number:</td>
                        <td style="width:50%" class=" p-2 v-align-top">{{cnDownload.creditNoteNumber}}</td>
                    </tr>

                    <tr class=" mt-2 p-2 ">
                        <td style="font-weight: bold; width: 50%" class=" p-2 v-align-top">Date:</td>
                        <td style="width:50%" class=" p-2 v-align-top">{{cnDownload.dateAdded | date:'longDate'}}</td>
                    </tr>

                    <tr class=" mt-2 p-2">
                        <td style="font-weight: bold; width: 50%" class=" p-2 v-align-top">Invoice number:</td>
                        <td style="width:50%" class=" p-2 v-align-top">{{cnDownload.invoiceNumber}}</td>
                    </tr>

                    <tr class="mt-2 p-2 ">
                        <td style="font-weight: bold; width: 50%" class=" p-2 v-align-top">Invoice date:</td>
                        <td style="width:50%" class=" p-2 v-align-top">{{cnDownload.invoiceDate | date:'longDate'}}</td>
                    </tr>

                    <tr class="mt-5-" style="border-bottom: 2pt solid black;">
                        <td style=" font-weight: bold; width: 50%" class="v-align-top pt-4 p-2">Total</td>
                        <td style="width:50%" class="v-align-top pull-right text-right pt-4 p-2">{{cnDownload.amount | currency: 'GBP'}}</td>
                    </tr>

                    <tr class=" mt-2-" style="border-bottom: 2pt solid black;">
                        <td style=" font-weight: bold; width: 50%" class="v-align-top p-2 ">VAT (20%)</td>
                        <td style="width:50%" class="p-2 v-align-top  pull-right text-right">{{cnDownload.tax | currency: 'GBP'}}</td>
                    </tr>

                    <tr class=" mt-2-" style="border-bottom: 2pt solid black;">
                        <td style=" font-weight: bold; width: 50%" class="p-2  v-align-top">Total invoice amount</td>
                        <td style="width:50%" class="p-2 v-align-top pull-right text-right">{{cnDownload.total | currency: 'GBP'}}</td>
                    </tr>
                </table>
            </div>
        </div>


        <!--<table class="full-width mt-5">
        <tr class=" mt-5" style="border-bottom: 2pt solid black; font-weight: bold;">
            <td style="width:50%" class="v-align-top">Invoice number (Invoice date)</td>
            <td style="width:15%" class="v-align-top">Date paid</td>
            <td style="width:20%" class="v-align-top">Payment method</td>
            <td style="width:15%" class="v-align-top text-right">Amount</td>
        </tr>
    </table>-->
        <!--<table class="full-width text-second">
        <tr style="border-bottom: 2pt solid black;">
            <td style="width:50%" class="v-align-top">{{currentInvoice.invoiceNumber}} ({{currentInvoice.invoiceDate | date:'dd-MM-yy'}})</td>
            <td style="width:15%" class="v-align-top">{{currentInvoice.datePaid | date:'dd-MM-yy'}}</td>
            <td style="width:20%" class="v-align-top">{{currentInvoice.paymentMethod}}</td>
            <td style="width:15%" class="v-align-top text-right">&#163;{{currentInvoice.grossTotal}}</td>
        </tr>
    </table>

    <table class="full-width pull-right">
        <tr style="font-weight: bold;">
            <td style="width:100%" class="v-align-top  text-right" colspan="4">Total &nbsp;&nbsp;&nbsp;&nbsp;&#163;{{currentInvoice.grossTotal}}</td>
        </tr>
    </table>-->



    </div>
</div>



<div class="row">
    <div class="col-lg-6">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Credit Notes</span>
        </h2>
    </div>
    <div class="col-lg-6 pt-1">

        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNew()">Add New Credit Note</button>
                <button mat-menu-item (click)="gotoSuppliers()">Suppliers List</button>
                <button mat-menu-item (click)="gotoInvoicesReceivedList()">Received Invoices</button>
                <button mat-menu-item (click)="gotoInvoicesIssuedList()">Issued Invoices</button>
                <button mat-menu-item (click)="gotoDebtCollectionList()">Debt Collection</button>
                <button mat-menu-item (click)="gotoXeroInvoicesList()">Xero Invoices</button>
            </mat-menu>
        </div>

    </div>

</div>




<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>


<ag-grid-angular #agGrid
                 id="cnGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="CN_List"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">

</ag-grid-angular>