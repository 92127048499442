import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditDelegateComponent } from './add-edit-delegate/add-edit-delegate.component';
import { DelegateDetailsComponent } from './delegate-details/delegate-details.component';
import { ViewDelegateComponent } from './view-delegate/view-delegate.component';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404'
    },
    { path: 'all-delegates', component: DashboardComponent },
    { path: 'new-delegate', component: DashboardComponent },
    { path: 'delegate-details', component: DashboardComponent },
    {
        path: '',
        redirectTo: 'all-delegates',
        pathMatch: 'full'
    },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DelegatesRoutingModule { }
