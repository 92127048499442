<link rel="stylesheet" href="https://unpkg.com/@progress/kendo-theme-default@latest/dist/all.css" />


<style>
    .cdk-overlay-container, .cdk-overlay-pane {
        z-index: 9999 !important;
    }
</style>

<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Course Scheduler</span> ##ADMIN/SPEAKERS_VIEW##

        </h2>

    </div>

    <div class="col-lg-3 pt-1" *ngIf="isSpeaker == false">


        <div class="pull-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="no-border bg-yellow-gradient submenu-btn mt-4">
                Menu <mat-icon color="warn" aria-label="Example icon-button with a heart icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="monthlySchedule()">Monthly Schedule</button>
                <button mat-menu-item (click)="yearlySchedule()">Yearly Schedule</button>

            </mat-menu>
        </div>



        <button class="book-btn pull-right filter-apply" (click)="sendLinkToSpeaker()">
            Send link to speaker
        </button>
    </div>


</div>



<div class="row mt-10- bg-white ml-0 pb-4">
    <div class="col-md-3 pl-0">

        <div *ngIf="isSpeaker == false" class="mb-2">
            <button class="book-btn mr-1 filter-apply" (click)="searchBySpeaker()">
                Search by Speaker
            </button>
            <button class="book-btn filter-apply" (click)="searchByCourse()">
                Search by Course
            </button>

            <button class="book-btn filter-apply" (click)="ViewCourseDates()">
                Courses dates
            </button>
        </div>

        <div *ngIf="isSearchBySpeaker == true">


            <mat-select #select [(ngModel)]="filter_Speaker"
                        (selectionChange)="getCoursesListBySubject(false)"
                        class="form-control no-radius top-dd- mb-3">
                <mat-option [value]="0">Filter by speaker</mat-option>
                <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
            </mat-select>

            <mat-select *ngIf="showCoursesList" #select [(ngModel)]="filter_SelectedCourse"
                        (selectionChange)="getUnassignedCoursesListNew($event)"
                        class="form-control no-radius top-dd--">
                <mat-option [value]="0">Select Course</mat-option>
                <mat-option *ngFor="let course of coursesList" [value]="course.id">{{course.title}}</mat-option>
            </mat-select>


            <div *ngIf="showUnassignedCourses" class="boxes">
                <span *ngFor="let item of currentCoursesList">
                    <input type="checkbox" id="{{item.courseId}}" (click)="toggleSelection(item, $event)">
                    <label for="{{item.courseId}}">{{item.courseTitle}} ({{item.datesToAssign.courseStartDate | date:'dd-MM-yyyy'}})</label>
                </span>
            </div>

        </div>

        <div *ngIf="isSearchByCourse">
            <!--<mat-select *ngIf="isSpeaker == false" #select [(ngModel)]="filter_Speaker"
                    (selectionChange)="getCoursesListBySubject(false)"
                    class="form-control no-radius top-dd- mb-3">
            <mat-option [value]="0">Filter by speaker</mat-option>
            <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
        </mat-select>-->


            <form [formGroup]="coursesForm" name="coursesForm">

                <mat-select formControlName="current_courses" #select id="main_crs"
                            (selectionChange)="getUnassignedCoursesListNew($event)"
                            class="form-control no-radius top-dd-">
                    <mat-option [value]="0">Select Course</mat-option>
                    <mat-option *ngFor="let course of coursesList" [value]="course.id">{{course.title}}</mat-option>
                </mat-select>
            </form>

            <div class="boxes">
                <span *ngFor="let item of currentCoursesList">
                    <input type="checkbox" id="{{item.courseId}}" (click)="toggleSelectionPopulateSpeaker(item)" checked>
                    <label for="{{item.courseId}}">{{item.courseTitle}} ({{item.datesToAssign.courseStartDate | date:'dd-MM-yyyy'}})</label>
                </span>
            </div>

            <button *ngIf="isSpeaker == true" class="book-btn pull-right filter-apply" (click)="sendReviewRequest()">
                Request admin review
            </button>

            <button *ngIf="isSpeaker == false" class="book-btn pull-right filter-apply" (click)="assignCoursePopup()">
                Assign course
            </button>
        </div>




        <div *ngIf="isViewCourseDates == true">


            <mat-select *ngIf="showCoursesList" #select [(ngModel)]="filter_SelectedCourse"
                        (selectionChange)="getUnassignedCoursesDates($event)"
                        class="form-control no-radius top-dd--">
                <mat-option [value]="0">Select Course</mat-option>
                <mat-option *ngFor="let course of coursesList" [value]="course.id">{{course.title}}</mat-option>
            </mat-select>


            <!--<mat-select *ngIf="showCoursesList" #select [(ngModel)]="filter_SelectedCourse"
                (selectionChange)="getUnassignedCoursesListNew($event)"
                class="form-control no-radius top-dd--">
        <mat-option [value]="0">Select Course</mat-option>
        <mat-option *ngFor="let course of coursesList" [value]="course.id">{{course.title}}</mat-option>
    </mat-select>


    <div *ngIf="showUnassignedCourses" class="boxes">
        <span *ngFor="let item of currentCoursesList">
            <input type="checkbox" id="{{item.courseId}}" (click)="getUnassignedCoursesDates(item, $event)">
            <label for="{{item.courseId}}">{{item.courseTitle}} ({{item.dates.courseStartDate | date:'dd-MM-yyyy'}})</label>
        </span>
    </div>-->

        </div>
    </div>


    <div *ngIf="isViewCourseDates == true" class="col-md-9">

        <table>
            <tr>
                <th>
                    Course
                </th>
                <th>
                    Day 1
                </th>
                <th>
                    Day 2
                </th>
                <th>
                    Day 3
                </th>
                <th>
                    Day 4
                </th>
                <th *ngFor="let speaker of unassignedSpeakersList">
                    {{speaker.speakerName}}
                </th>
            </tr>
            <tr *ngFor="let course of coursesDatesList">
                <td>
                    {{course.courseTitle}}
                </td>
                <td>
                    {{course.day1 | date:'dd-MM-yyyy'}}
                </td>
                <td>
                    {{course.day2 | date:'dd-MM-yyyy'}}
                </td>
                <td>
                    {{course.day3 | date:'dd-MM-yyyy'}}
                </td>
                <td>
                    {{course.day4 | date:'dd-MM-yyyy'}}
                </td>
                <td *ngFor="let speaker of unassignedSpeakersList">
                    <!--<strong>
                        outerloop: {{speaker.speakerId}}
                    </strong>-->
                    <div *ngFor="let unassignDate of course.unassignedDates">


                        <div *ngIf="unassignDate.speakerId == speaker.speakerId">
                            Yes - {{speaker.speakerName}}

                            
                            <div *ngIf="unassignDate.unassignedDay1 != null"><span [ngClass]="course.day1 == unassignDate.unassignedDay1 ? 'green' : 'red'">D1 - {{unassignDate.unassignedDay1  | date:'dd-MM-yyyy'}}</span></div>
                            <div *ngIf="unassignDate.unassignedDay2 != null"><span [ngClass]="course.day2 == unassignDate.unassignedDay2 ? 'green' : 'red'">D2 - {{unassignDate.unassignedDay2  | date:'dd-MM-yyyy'}}</span></div>
                            <div *ngIf="unassignDate.unassignedDay3 != null"><span [ngClass]="course.day3 == unassignDate.unassignedDay3 ? 'green' : 'red'">D3 - {{unassignDate.unassignedDay3  | date:'dd-MM-yyyy'}}</span></div>
                            <div *ngIf="unassignDate.unassignedDay4 != null"><span [ngClass]="course.day4 == unassignDate.unassignedDay4 ? 'green' : 'red'">D4 - {{unassignDate.unassignedDay4  | date:'dd-MM-yyyy'}}</span></div>
                        </div>
                        <!--<div *ngIf="speaker.speakerId != speaker.speakerId">
                            No - {{speaker.speakerName}} - {{speaker.speakerId}}
                        </div>-->
                    </div>
                </td>






            </tr>
        </table>
    </div>

    <div *ngIf="isViewCourseDates == false" class="col-md-9">

        <!--<ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="cal-cell-top">
                <span class="cal-day-badge" *ngIf="1 > 1">3</span>
                <span class="cal-day-number">4</span>
            </div>
            <small style="margin: 5px">There are 5 events on this day</small>
        </ng-template>-->


        <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="cal-cell-top">
                <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>


            <small *ngFor="let event of day.events" style="margin: 5px">{{ event.title }}</small>

        </ng-template>


        <div class="row text-center">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary"
                         mwlCalendarPreviousView
                         [view]="view"
                         [(viewDate)]="viewDate"
                         (viewDateChange)="closeOpenMonthViewDay()">
                        Previous
                    </div>
                    <div class="btn btn-outline-secondary"
                         mwlCalendarToday
                         [(viewDate)]="viewDate">
                        Today
                    </div>
                    <div class="btn btn-primary"
                         mwlCalendarNextView
                         [view]="view"
                         [(viewDate)]="viewDate"
                         (viewDateChange)="closeOpenMonthViewDay()">
                        Next
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <!--<div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary"
                         (click)="setView(CalendarView.Month)"
                         [class.active]="view === CalendarView.Month">
                        Month
                    </div>
                    <div class="btn btn-primary"
                         (click)="setView(CalendarView.Week)"
                         [class.active]="view === CalendarView.Week">
                        Week
                    </div>
                    <div class="btn btn-primary"
                         (click)="setView(CalendarView.Day)"
                         [class.active]="view === CalendarView.Day">
                        Day
                    </div>
                </div>
            </div>-->
        </div>
        <br />

        <!--[cellTemplate]="customCellTemplate"-->
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                     [viewDate]="viewDate"
                                     [events]="events"
                                     [refresh]="refresh"
                                     [activeDayIsOpen]="activeDayIsOpen"
                                     (dayClicked)="dayClicked($event.day)"
                                     (eventClicked)="handleEvent('Clicked', $event.event)"
                                     (eventTimesChanged)="eventTimesChanged($event)"
                                     (beforeViewRender)="beforeMonthViewRender($event)"
                                     [cellTemplate]="customCellTemplate">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                    [viewDate]="viewDate"
                                    [events]="events"
                                    [refresh]="refresh"
                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                    (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                   [viewDate]="viewDate"
                                   [events]="events"
                                   [refresh]="refresh"
                                   (eventClicked)="handleEvent('Clicked', $event.event)"
                                   (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
        </div>

    </div>
</div>

<ng-template #modalAssignCourse let-close="close">
    <div class="modal-header primary">

        <h5 class="modal-title primary">{{_title}}</h5>

    </div>
    <div class="modal-body- p-4">
        <form class="form-horizontal" [formGroup]="assignCourseForm" (ngSubmit)="onAssignCourseFormSubmit()" name="assignCourseForm" autocomplete="off">

            <mat-select #select formControlName="assignCourse_speakers"
                        (selectionChange)="getCoursesListBySubject(true); onTrainerSelected($event)"
                        class="form-control no-radius top-dd- mb-3">
                <mat-option [value]="0">Filter by speaker</mat-option>
                <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
            </mat-select>


            <mat-select formControlName="assignCourse_courses" #select
                        (selectionChange)="getUnassignedCoursesList($event)"
                        class="form-control no-radius">
                <mat-option [value]="0">Select Course</mat-option>
                <mat-option *ngFor="let course of coursesList" [value]="course.id">{{course.title}}</mat-option>
            </mat-select>

            <mat-select formControlName="assignCourse_courseDates" #select
                        (selectionChange)="onCourseDatesSelected($event)"
                        class="form-control no-radius">
                <mat-option [value]="0">Select Course Date</mat-option>
                <mat-option *ngFor="let item of currentCoursesList" [value]="item.courseId">{{item.courseTitle}} ({{item.datesToAssign.courseStartDate | date:'dd-MM-yyyy'}})</mat-option>
            </mat-select>




            <div class="modal-footer">

                <button type="button" class="book-btn" (click)="close(); close2($event);">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!assignCourseForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>

            </div>
        </form>
    </div>

</ng-template>





<!--� Experience in a senior development role in a multi-professional complex organisation.
� Experience of managing, leading, and motivating a team,
� Leading projects
� Leading service changes to improve performance
� Evidence of having successfully developed complex data solutions
� Experience of working across a full range of Business Intelligence technologies.
    Including:  o Development of Azure or other cloud-based technologies.
                o Development suing the full stack of Microsoft Business Intelligence technologies.
� Working with Secondary Care (SUS) datasets.

With a comprehensive background in spearheading complex software and website development projects, I believe my skills and experience align perfectly with the requirements of this role. With over a decade of experience in the IT industry, I have had the privilege to work with leading global brands, where I demonstrated my expertise in the .NET framework.
At Act Now Training, I have been instrumental in developing and delivering high-quality software solutions. This involved leading the successful development and launch of a website and two in-house software systems, showcasing my proficiency in managing projects from inception to completion.
My expertise in .NET 7, ASP.NET, C#, and SQL Server, combined with my proficiency in Microservices and Azure, allows me to craft scalable and robust applications that cater to evolving business needs. My role at Gizmo Connect, as a Technical Lead, further equipped me with the capability to provide hands-on technical support, conduct code reviews, and manage complex projects, ensuring they met and often surpassed organisational objectives.
While at Deutsche Gesellschaft f�r Internationale Zusammenarbeit GmbH, I led a team of developers, employing agile methodologies, achieving significant cost savings and optimising resource allocation.






� Experience of working with Primary care datasets particularly EMIS or TPP


� Responsible for ensuring Cloud and infrastructure spend is within the annually agreed budgetary planned spend,
and in line with Standing Financial Instructions.

I am technically minded and solutions-oriented Senior Software Engineer with a solid background in development and launching projects from conception to completion under budget and on time.
By collaboration with cross-functional teams, including project managers and QA engineers, we can easily ensure successful cloud and infrastructure spends within the allocated budget


� Educated to Masters level or an equivalent level of experience working at the required technical level
� Evidence of continued professional development
� Further relevant training

A passion of mine has always been to stay abreast of the latest technological trends.
I've adopted cutting-edge technologies, and have a thorough understanding of Distributed Software Architecture & Messaging Technologies in Microservices Architecture.
To upgrade myself I obtained my Masters degree in Computer Science from University of Lincoln and had many other Microsoft certifications throughout my career when required.
Recently I am preparing AZ-900 and will take exam next month.




� Able to provide and receive complex, sensitive information, identify, and grasp highly complex technical issues arising from business plans
and user specifications and able to communicate these issues to technical and non-technical staff in a clear and unambiguous way.
� In depth and thorough specialist knowledge of a full range of Business Intelligence technologies including
    o Microsoft SQL and SQL Server Integration Services (SSIS)
    o Relational database management systems o ETL and data management techniques.
    o Intermediate knowledge of PowerShell.
    o Basic experience with either Python, C# or VB.
    o Knowledge and prior experience of using REST APIs
    o Source control / team working products e.g., TFS, DevOps, Git and GitHub.
    o Data Factory pipeline creation.
    o Synapse workspace analytics.
� Ability to identify risks, anticipate issues and create solutions and to resolve problems in relation to project or service delivery.
� Ability to understand a broad range of highly complex information quickly, drawing conclusions and making solutions based on the information available
� Evidence of planning specialist and highly technical activities and projects Logical approach to problem solving a key focus on accuracy and quality.
� Ability to; adjust plans and amend plans as changes in situation/ environments require.
� Logical approach to problem solving.

 I own strong communication skills to handle intricate and sensitive information.
    I possess comprehensive expertise in a wide array of Business Intelligence technologies, notably Microsoft SQL and SQL Server Integration Services (SSIS). I'm well-versed in relational database management systems and effective ETL and data management techniques. My proficiency extends to intermediate-level PowerShell usage, along with foundational skills in Python, C#, or VB. I have hands-on familiarity with REST APIs, source control tools like TFS, DevOps, Git, and GitHub. I'm skilled in Data Factory pipeline creation and Synapse workspace analytics.
    I adeptly understand and address complex technical challenges stemming from business goals and user requirements.
    I possess a strong capacity to recognize risks, proactively foresee challenges, and formulate effective solutions for project and service delivery. My aptitude for swiftly comprehending intricate information allows me to draw conclusions and devise solutions efficiently. With demonstrated expertise in planning specialized, technical projects, I prioritize accuracy, quality, and logical problem-solving. My flexibility in adapting plans to changing circumstances ensures successful outcomes.


� Knowledge of NHS IT systems
    While I have an extensive background in various IT systems, it's important to note that I don't possess specific knowledge of NHS IT systems. However, I'm confident in my ability to quickly learn and adapt to new environments, and I'm eager to contribute my technical skills and problem-solving capabilities to any project or organization.

� Microsoft Office to an advanced level
� Ability to work without supervision, providing specialist advice across BI functions. Manages own time, working flexibly to prioritise responses to wide range of competing demands.
� Will use specialist BI knowledge to implement national policies to improve the service and has the ability to take actions which support and promote this agenda.
� Ability to run upgrades, and enhancements of the data warehouse and develop information systems to meet identified needs.
� Self-motivated, good self-management with an eye for detail and ability to track issues and prioritise when under pressure.
� Ability to move between details and the bigger picture. Demonstrates honesty and integrity and promotes organisational values understanding of commercial intent
� Embrace change, viewing it as an opportunity to learn and develop

    I have advanced proficiency in Microsoft Office. I excel in independent work, offering expert guidance across BI functions. I effectively manage my time, flexibly addressing diverse demands. I leverage specialized BI knowledge to implement national policies and enhance services. I'm skilled in data warehouse upgrades, system development, and self-management under pressure. My holistic perspective balances details and the bigger picture. I prioritize integrity, align with organizational values, and view change as a chance for growth.-->
