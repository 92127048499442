import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private authChangeSub = new Subject<boolean>()
    public authChanged = this.authChangeSub.asObservable();
    baseurl: string;
    constructor(private http: HttpClient,
        private jwtHelper: JwtHelperService,
        @Inject('BASE_URL') base: string) {
        this.baseurl = base;
    }

    //public registerUser = (route: string, body: UserForRegistrationDto) => {
    //    return this.http.post<RegistrationResponseDto>(this.createCompleteRoute(route, this.envUrl.urlAddress), body);
    //}

    //public loginUser = (route: string, body: UserForAuthenticationDto) => {
    //    return this.http.post<AuthResponseDto>(this.createCompleteRoute(route, this.envUrl.urlAddress), body);
    //}

    public sendAuthStateChangeNotification = (isAuthenticated: boolean) => {
        this.authChangeSub.next(isAuthenticated);
    }

    public logout = () => {
        localStorage.removeItem("Token");
        this.sendAuthStateChangeNotification(false);
    }

    public isTokenExpired = (): boolean => {
        const token = localStorage.getItem("Token");

        //return !!token && !!this.jwtHelper.isTokenExpired(token);
        if ((token != null) && (token != undefined))
            return this.jwtHelper.isTokenExpired(token);
        else
            return true; // this means token expired
    }

    public setLocalStorage() {
        const token = localStorage.getItem("Token");
        if ((token != null) && (token != undefined)) {

            var _obj = {
                FirstName: "",
                GUID: "",
                LastName: "",
                UserId: "",
                UserName: "",
                exp: 0,
                iat: 0,
                nbf: 0,
                role: ""
            }
            _obj = this.jwtHelper.decodeToken(token);
            localStorage.setItem("userName", _obj.FirstName + ' ' + _obj.LastName);

        }
    }


    public userRole = (): string => {

        
        const token = localStorage.getItem("Token");
        if ((token != null) && (token != undefined)) {

            var _obj = {
                FirstName: "",
                GUID: "",
                LastName: "",
                UserId: "",
                UserName: "",
                exp: 0,
                iat: 0,
                nbf: 0,
                role: ""
            }
            _obj = this.jwtHelper.decodeToken(token);
            localStorage.setItem("userName", _obj.FirstName + ' ' + _obj.LastName);
            return _obj.role;
            //return this.jwtHelper.decodeToken(token);
        }
        else return "";
        
        //return decodedToken.
    }




    public isUserAdmin = (): boolean => {
        //const token = localStorage.getItem("token");
        //const decodedToken = this.jwtHelper.decodeToken(token);
        //const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']

        return true;
        //return role === 'Administrator';
    }

    private createCompleteRoute = (route: string, envAddress: string) => {
        return `${envAddress}/${route}`;
    }

    validateSession() {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': 'Bearer ' + localStorage.getItem('Token')
        });

        return this.http.get<any>(this.baseurl + 'api/Account/v1/ValidateSession',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }
}