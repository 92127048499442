

import {
    Component, OnInit,
    ChangeDetectionStrategy,
    ViewChild,
    TemplateRef,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarEvent,
    CalendarMonthViewBeforeRenderEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView,
} from 'angular-calendar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { CoursesService } from '../../../common/_services/courses-services';
import { CalendarSchedule } from '../../../common/model/request';
import { MatSelect } from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { errorMessages } from '../../../common/validation/messages';

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
    purple: {
        primary: '#862b78',
        secondary: '#862b78',
    },
};

@Component({

    selector: 'app-view-schedule',
    templateUrl: './view-schedule.component.html',
    styleUrls: ['./view-schedule.component.css'],

    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
    ]
})



export class ViewScheduleComponent implements OnInit {

    _paramOut = {
        showMonthlySchedule: false,
        showYearlySchedule: false,
        showSpeakersSchedule: false
    }

    @Output() gotoSchedule = new EventEmitter<object>();


    @ViewChild('modalContentDetails', { static: true }) modalContentDetails: TemplateRef<any>;

    @ViewChild('modalContentEdit', { static: true }) modalContentEdit: TemplateRef<any>;

    @ViewChild('modalContentInsert', { static: true }) modalContentInsert: TemplateRef<any>;

    @ViewChild('select') select: MatSelect;

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    public coursesList: any;
    public speakersList: any;
    public locationsList: any;
    public subjectsList: any;
    public currentCoursesList: any;
    public url = environment.apiURL;
    public _title = "Details";
    public _refDialog: any;

    //public _courseTitle: string;
    public _subjectTitle: string;
    public _speaker: string;
    public _location: string;


    coursesForm: FormGroup;
    cuForm: FormGroup;
    insertForm: FormGroup;

    errors: any = errorMessages;
    start_date: any = new FormControl(new Date());

    public toggleSelection(item: any) {
        item.selected = !item.selected;



        if (item.selected) {
            if (item.dates != null) {
                //if (setDate == true) {
                //    this.viewDate = new Date(item.dates.day1Date);
                //    setDate = false;
                //}

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.dates.day1Date)),
                        end: new Date(item.dates.day1Date),
                        title: "D1 - " + item.courseTitle,
                        //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                        color: colors.yellow,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.dates.day2Date)),
                        end: new Date(item.dates.day2Date),
                        title: "D2 - " + item.courseTitle,
                        color: colors.red,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.dates.day3Date)),
                        end: new Date(item.dates.day3Date),
                        title: "D3 - " + item.courseTitle,
                        color: colors.blue,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];

                this.events = [
                    ...this.events,
                    {
                        id: item.courseId,
                        start: startOfDay(new Date(item.dates.day4Date)),
                        end: new Date(item.dates.day4Date),
                        title: "D4 - " + item.courseTitle,
                        color: colors.purple,
                        actions: this.actions,
                        allDay: true,
                        resizable: {
                            beforeStart: true,
                            afterEnd: true,
                        },
                        draggable: true



                    },
                ];
            }
        }
        else {
            var __index = this.events.length - 1;
            this.events.slice().reverse().forEach((__item, index) => {
                index = __index;
                if (__item.id === item.courseId)
                    this.events.splice(index, 1);
                __index--;
            });
        }

        this.refresh.next();
    }

    //public moveSelected(direction: any) {
    //    if (direction === 'left') {
    //        this.list2.forEach(item => {
    //            if (item.selected) {
    //                this.list1.push(item);
    //            }
    //        });
    //        this.list2 = this.list2.filter(i => !i.selected);
    //    } else {
    //        this.list1.forEach(item => {
    //            if (item.selected) {
    //                this.list2.push(item);
    //            }
    //        });
    //        this.list1 = this.list1.filter(i => !i.selected);
    //    }
    //}

    //public moveAll(direction: any) {
    //    if (direction === 'left') {
    //        this.list1 = [...this.list1, ...this.list2];
    //        this.list2 = [];
    //    } else {
    //        this.list2 = [...this.list2, ...this.list1];
    //        this.list1 = [];
    //    }
    //}


    modalData: {
        action: string;
        event: CalendarEvent;
    };


    constructor(private http: HttpClient,
        private modal: NgbModal,
        private _dialog: MatDialog,
        private formBuilder: FormBuilder,
        private _coursesService: CoursesService) {

        setTimeout(() => {
            $("#main_crs div:nth-child(1) div:first-child").css("color", "white");
            $("#main_crs div:nth-child(2) div:first-child").css("color", "white");
        }, 200);

        this.getCoursesList();
    }

    getSpeakersList() {

        this._coursesService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data

                this.cuForm.controls.speaker.patchValue(this.speakersList[0].speakerName);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    getLocationsList() {

        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data

                this.cuForm.controls.location.patchValue(this.locationsList[0].cityName);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {

                this.subjectsList = result.data

                this.cuForm.controls.subject.patchValue(this.subjectsList[0].title);


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    get f() { return this.cuForm.controls; }

    get i() { return this.insertForm.controls; }


    getCurrentCourseList(id: any) {

        if (id.value == null ||
            id.value == undefined)
            id = { value: id }


        this._coursesService.getCurrentCourseList(id).subscribe((result: any) => {


            if (result.success == true) {

                this.currentCoursesList = result.data

                this.events = [];
                var setDate = true;

                result.data.forEach((element: any) => {
                    if (element.dates != null) {
                        //if (setDate == true) {
                        //    this.viewDate = new Date(element.dates.day1Date);
                        //    setDate = false;
                        //}

                        if (element.dates.day1Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.dates.day1Date);
                                setDate = false;
                            }

                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.dates.courseDateId + '|d1|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.dates.day1Date)),
                                    end: new Date(element.dates.day1Date),
                                    title: "D1 - " + element.courseTitle,
                                    //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                                    color: colors.yellow,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }

                        if (element.dates.day2Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.dates.day2Date);
                                setDate = false;
                            }


                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.dates.courseDateId + '|d2|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.dates.day2Date)),
                                    end: new Date(element.dates.day2Date),
                                    title: "D2 - " + element.courseTitle,
                                    color: colors.red,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }

                        if (element.dates.day3Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.dates.day3Date);
                                setDate = false;
                            }


                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.dates.courseDateId + '|d3|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.dates.day3Date)),
                                    end: new Date(element.dates.day3Date),
                                    title: "D3 - " + element.courseTitle,
                                    color: colors.blue,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }

                        if (element.dates.day4Date != null) {

                            if (setDate == true) {
                                this.viewDate = new Date(element.dates.day4Date);
                                setDate = false;
                            }


                            this.events = [
                                ...this.events,
                                {
                                    cssClass: element.dates.courseDateId + '|d4|' + element.courseDuration + '|' +
                                        element.subjectTitle + '|' + element.speakerName + '|' + element.location,
                                    id: element.courseId,
                                    start: startOfDay(new Date(element.dates.day4Date)),
                                    end: new Date(element.dates.day4Date),
                                    title: "D4 - " + element.courseTitle,
                                    color: colors.purple,
                                    actions: this.actions,
                                    allDay: true,
                                    resizable: {
                                        beforeStart: true,
                                        afterEnd: true,
                                    },
                                    draggable: true



                                },
                            ];
                        }


                        this.refresh.next();






                        //var _calData = {
                        //    start: subDays(startOfDay(element.dates.day1Date), 1),
                        //    end: addDays(element.dates.day1Date, 1),
                        //    title: element.courseTitle,
                        //    color: colors.green,
                        //    actions: this.actions,
                        //    allDay: true,
                        //    resizable: {
                        //        beforeStart: true,
                        //        afterEnd: true,
                        //    },
                        //    draggable: true
                        //}


                    }
                });

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getCoursesList() {



        this._coursesService.getCoursesList().subscribe((result: any) => {


            if (result.success == true) {

                this.coursesList = result.data

                //this.coursesForm.controls.current_courses.patchValue(this.coursesList[0].title);
                this.coursesForm.controls.current_courses.patchValue(0);

                //this.getCurrentCourseList(this.coursesList[0].title);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    updateData(event, newStart, newEnd) {

        var req = {
            startDate: new Date(newStart),
            //endDate: newEnd,
            toBeUpdate: event.cssClass
        };

        this._coursesService.updateCourseSchedule(req).subscribe((result: any) => {


            if (result.success == true) {



            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    deleteData(event) {
        var req = {
            //startDate: new Date(newStart),
            //endDate: newEnd,
            courseId: event.id,
            toBeUpdate: event.cssClass
        };

        this._coursesService.deleteCourseSchedule(req).subscribe((result: any) => {


            if (result.success == true) {

                this.events = this.events.filter((iEvent) => iEvent !== event);
                this.refresh.next();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    getScheduleData(ids: any) {


        var req = { ids: ids.value }
        this._coursesService.getScheduleData(req).subscribe((result: any) => {
            //this.loading = false;



            if (result.success == true) {




                this.events = [];

                result.data.forEach((element: any) => {
                    if (element.dates != null) {
                        this.viewDate = new Date(element.dates.day1Date);
                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.dates.day1Date)),
                                end: new Date(element.dates.day1Date),
                                title: "D1 - " + element.courseTitle,
                                //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                                color: colors.yellow,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.dates.day2Date)),
                                end: new Date(element.dates.day2Date),
                                title: "D2 - " + element.courseTitle,
                                color: colors.red,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.dates.day3Date)),
                                end: new Date(element.dates.day3Date),
                                title: "D3 - " + element.courseTitle,
                                color: colors.blue,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];

                        this.events = [
                            ...this.events,
                            {
                                start: startOfDay(new Date(element.dates.day4Date)),
                                end: new Date(element.dates.day4Date),
                                title: "D4 - " + element.courseTitle,
                                color: colors.purple,
                                actions: this.actions,
                                allDay: true,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true,
                                },
                                draggable: true



                            },
                        ];



                        this.refresh.next();






                        //var _calData = {
                        //    start: subDays(startOfDay(element.dates.day1Date), 1),
                        //    end: addDays(element.dates.day1Date, 1),
                        //    title: element.courseTitle,
                        //    color: colors.green,
                        //    actions: this.actions,
                        //    allDay: true,
                        //    resizable: {
                        //        beforeStart: true,
                        //        afterEnd: true,
                        //    },
                        //    draggable: true
                        //}


                    }
                });

                //if(result.data)
            } else {
                //this.clearForm();
                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

        //let headers = new HttpHeaders({
        //    'Content-Type': 'application/json ',
        //    'Authorization': 'Bearer ' + localStorage.getItem('Token')

        //});


        //this.http.get<any>(this.url + 'api/Account/v1/Verification', { headers }).subscribe(result => {
        //    //localStorage.setItem("Token", result.verification.token);
        //    //this.verificationData = result.verification;
        //}, error => console.log(error));


    }

    beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
        console.log(renderEvent.period.start);
        console.log(renderEvent.period.end);
        renderEvent.body.forEach((day) => {
            const dayOfMonth = day.date.getDate();
            if (dayOfMonth > 21 && dayOfMonth < 23 && day.inMonth) {
                //day.cssClass = 'bg-pink';
            }
        });
    }

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fas fa-fw fa-pencil-alt text-white"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {

                var eventDataArray: any;
                if (event.cssClass != null && event.cssClass != undefined) {
                    eventDataArray = event.cssClass.split("|");

                    this._subjectTitle = eventDataArray[3];
                    this._speaker = eventDataArray[4];
                    this._location = eventDataArray[5];


                }

                this.start_date = event.start;
                this.cuForm.controls.courseTitle.setValue(event.title);
                this.cuForm.controls.speaker.patchValue(this._speaker);
                this.cuForm.controls.location.patchValue(this._location);
                this.cuForm.controls.subject.patchValue(this._subjectTitle);
                this._title = "Edit";
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fas fa-fw fa-trash-alt text-white"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this._title = "Delete";


                this._refDialog = this._dialog.open(DeleteComponent, {
                    autoFocus: false,
                    disableClose: true,
                    data: { message: "Are you sure to delete this record?", title: "Delete" }
                });

                this._refDialog.componentInstance.redirect.subscribe((data) => {



                    this.deleteData(event);
                    //this.events = this.events.filter((iEvent) => iEvent !== event);
                    //this.refresh.next();

                    //this.deleteEvent(event);
                    //this.handleEvent('Deleted', event);
                });

                this._refDialog.componentInstance.closePopup.subscribe((data) => {

                    this._refDialog.close();
                });


                //if (confirm("Are you sure to delete ")) {
                //    console.log("Implement delete functionality here");
                //}

                //this.modal.open(this.modalContentDetails, { size: 'lg' });

            },
        },
    ];

    addNew() {
        this._title = "Add";
        this.modal.open(this.modalContentInsert, { size: 'lg' });
    }

    onInsertSubmit() {

        var d2 = this.insertForm.controls.startDate_day2.value ? this.insertForm.controls.startDate_day2.value : null;
        var d3 = this.insertForm.controls.startDate_day3.value ? this.insertForm.controls.startDate_day3.value : null;
        var d4 = this.insertForm.controls.startDate_day4.value ? this.insertForm.controls.startDate_day4.value : null;


        var req = {
            subjectTitle: this.insertForm.controls.subject.value,
            courseTitle: this.insertForm.controls.courseTitle.value,
            location: this.insertForm.controls.location.value,
            dates: {
                day1Date: this.insertForm.controls.startDate_day1.value,
                day2Date: d2,
                day3Date: d3,
                day4Date: d4
            },
            speakerName: this.insertForm.controls.speaker.value


        };

        this._coursesService.insertCourseData(req).subscribe((result: any) => {


            if (result.success == true) {

                //if (event.cssClass != null && event.cssClass != undefined) {
                //    var eventDataArray = event.cssClass.split("|");

                //    this.events = this.events.filter((iEvent) => iEvent !== event);

                //    this.events = [
                //        ...this.events,
                //        {
                //            cssClass: eventDataArray[0] + '|' + eventDataArray[1] + '|' + eventDataArray[2] + '|' +
                //                req.subject + '|' + req.speakerName + '|' + req.location,
                //            id: event.id,
                //            start: startOfDay(new Date(req.startDate)),
                //            end: new Date(req.startDate),
                //            title: req.courseTitle,
                //            //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                //            color: colors.yellow,
                //            actions: this.actions,
                //            allDay: true,
                //            resizable: {
                //                beforeStart: true,
                //                afterEnd: true,
                //            },
                //            draggable: true

                //        },
                //    ];

                //    this.refresh.next();
                //}

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })



    }

    onUpdateSubmit(event) {


        var req = {
            startDate: this.cuForm.controls.StartDate.value,
            courseTitle: this.cuForm.controls.courseTitle.value,
            courseId: event.id,
            subject: this.cuForm.controls.subject.value,
            speakerName: this.cuForm.controls.speaker.value,
            location: this.cuForm.controls.location.value,
            toBeUpdate: event.cssClass
        };

        this._coursesService.updateCourseData(req).subscribe((result: any) => {


            if (result.success == true) {

                if (event.cssClass != null && event.cssClass != undefined) {
                    var eventDataArray = event.cssClass.split("|");

                    this.events = this.events.filter((iEvent) => iEvent !== event);

                    this.events = [
                        ...this.events,
                        {
                            cssClass: eventDataArray[0] + '|' + eventDataArray[1] + '|' + eventDataArray[2] + '|' +
                                req.subject + '|' + req.speakerName + '|' + req.location,
                            id: event.id,
                            start: startOfDay(new Date(req.startDate)),
                            end: new Date(req.startDate),
                            title: req.courseTitle, // need to update the day in title
                            //color: { primary: '#e3bc08', secondary: '#FDF1BA' },
                            color: colors.yellow,
                            actions: this.actions,
                            allDay: true,
                            resizable: {
                                beforeStart: true,
                                afterEnd: true,
                            },
                            draggable: true

                        },
                    ];

                    this.refresh.next();
                }

                this.modal.dismissAll();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })


    }

    refresh = new Subject<void>();

    events: CalendarEvent[] = [
        //{
        //    start: subDays(startOfDay(new Date()), 1),
        //    end: addDays(new Date(), 1),
        //    title: 'A 3 day event',
        //    color: colors.red,
        //    actions: this.actions,
        //    allDay: true,
        //    resizable: {
        //        beforeStart: true,
        //        afterEnd: true,
        //    },
        //    draggable: true,
        //},
        //{
        //    start: startOfDay(new Date()),
        //    title: 'An event with no end date',
        //    color: colors.yellow,
        //    actions: this.actions,
        //},
        //{
        //    start: subDays(endOfMonth(new Date()), 3),
        //    end: addDays(endOfMonth(new Date()), 3),
        //    title: 'A long event that spans 2 months',
        //    color: colors.blue,
        //    allDay: true,
        //},
        //{
        //    start: addHours(startOfDay(new Date()), 2),
        //    end: addHours(new Date(), 2),
        //    title: 'A draggable and resizable event',
        //    color: colors.yellow,
        //    actions: this.actions,
        //    resizable: {
        //        beforeStart: true,
        //        afterEnd: true,
        //    },
        //    draggable: true,
        //},
    ];

    activeDayIsOpen: boolean = false;



    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {

        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {

                this.updateData(event, newStart, newEnd);

                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {

        this.modalData = { event, action };

        if (action == "Clicked") {

            var eventDataArray: any;
            if (event.cssClass != null && event.cssClass != undefined) {
                eventDataArray = event.cssClass.split("|");

                this._subjectTitle = eventDataArray[3];
                this._speaker = eventDataArray[4];
                this._location = eventDataArray[5];


            }

            this._title = "Details";

            this.modal.open(this.modalContentDetails, { size: 'lg' });
        }
        else if (action == "Edited") {
            this.modal.open(this.modalContentEdit, { size: 'lg' });
        }

    }

    addEvent(): void {

        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.red,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
            },
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {

        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    ngOnInit(): void {

        this.coursesForm = this.formBuilder.group({
            current_courses: ['', Validators.required]
        });



        this.cuForm = this.formBuilder.group({
            courseTitle: ['', Validators.required],
            speaker: ['', Validators.required],
            subject: ['', Validators.required],
            location: ['', Validators.required],
            StartDate: [this.start_date.value, Validators.required]
        });

        this.insertForm = this.formBuilder.group({
            courseTitle: ['', Validators.required],
            speaker: ['', Validators.required],
            subject: ['', Validators.required],
            location: ['', Validators.required],
            startDate_day1: [this.start_date.value, Validators.required],
            startDate_day2: [''],
            startDate_day3: [''],
            startDate_day4: ['']
        });

        this.getLocationsList();
        this.getSpeakersList();
        this.getSubjectsList();
    }



    yearlySchedule() {
        this._paramOut.showYearlySchedule = true;
        this.gotoSchedule.emit(this._paramOut);
    }

    courseScheduler() {
        this._paramOut.showSpeakersSchedule = true;
        this.gotoSchedule.emit(this._paramOut);
    }
}

