import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { UploadCoursesComponent } from './upload-courses.component';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404'
    },
    { path: 'upload-courses', component: DashboardComponent },
    {
        path: '',
        redirectTo: 'course',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UploadsRoutingModule { }
