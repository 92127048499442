


<div class="row">
    <div class="col-lg-7">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">{{listTitle}}</span> TRAININGS
           
        </h2>

    </div>
    <div class="col-lg-5 pt-1">

        <button class="book-btn pull-right filter-apply" (click)="addNew()">
            Add Training

        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="getInhouseList(false)">
            All Trainings
        </button>
        <button class="book-btn pull-right filter-apply mr-1" (click)="getInhouseList(true)">
            Upcoming Trainings
        </button>

    </div>

</div>


<div class="pg-size">
    <span>Page size: </span>
    <select (change)="onPageSizeChanged()" id="page-size">
        <option value="10" selected="">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>


<ag-grid-angular #agGrid
                 id="inhouseGrid"
                 class="ag-theme-alpine ag-theme-balham"
                 [domLayout]="domLayout"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [rowData]="inhouseList"
                 [animateRows]="true"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"
                 (rowClicked)="onRowClicked()"
                 [gridOptions]="gridOptions"
                 [pagination]="true"
                 [paginationPageSize]="paginationPageSize">

</ag-grid-angular>

