import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { AddEditInhouseComponent } from '../add-edit-inhouse/add-edit-inhouse.component';
import { AddEditOrganisationComponent } from '../organisations/add-edit-organisation/add-edit-organisation.component';

@Component({
    selector: 'app-inhouse-detail',
    templateUrl: './inhouse-detail.component.html',
    styleUrls: ['./inhouse-detail.component.css']
})
export class InhouseDetailComponent implements OnInit {



    @Input() inhouseDetail: any;

    invoiceEditForm: FormGroup;
    //organisationForm: FormGroup;
    trainerForm: FormGroup;
    itemForm: FormGroup;
    paymentForm: FormGroup;


    selectedPaymentMethod: any = "none";

    currentDate: any = new FormControl(new Date());
    paymentDate_: any = new FormControl(new Date());
    reconcileDate: any = new FormControl(new Date());

    _reconcile: boolean = false;
    _xero: any;
    _inhouseTrainingDateId: any = 0;
    _inhouseItemId: any = 0;
    loading = false;
    locationsList: any;
    _params: any;
    _addEditTrainer: any = "Add Trainer";
    _organisationId: any = 0;
    _inhouseId: any = 0;
    speakersList: any;
    subjectsList: any;
    selectedTrainerId: any = 0;
    selectedTrainer: any = "";
    selectedSubjectId: any = 0;
    selectedSubject: any = "";
    submitted = false;
    _refDialog: any;
    __inhouse: any = [];
    tainingInhouse: any = [];
    currentInhouse: any = [];
    currentInhouseInvoiceDetails: any = [];
    currentInhouseTrainingDates: any = {};
    currentInhouseItems: any = [];
    inhouseCourseFeeItems: any = [];
    inhouseCourseCostItems: any = [];
    courseTotalFee: any = 0;
    lifeTimeValue: any = 0;
    courseCost: any = 0;
    courseExpense: any = 0;

    _addEditInhouseItemTitle: any = "Add Inhouse Item";


    //currentDelegate: any;

    @Output() backNavigation = new EventEmitter<void>();
    @Output() gotoAllInhouse = new EventEmitter<void>();
    @ViewChild('modalEditInvoice', { static: true }) modalContentEdit: TemplateRef<any>;
    //@ViewChild('modalEditInhouse', { static: true }) modalEditInhouse: TemplateRef<any>;
    @ViewChild('modalAddEditTrainer', { static: true }) modalAddEditTrainer: TemplateRef<any>;
    @ViewChild('modalAddEditInhouseItem', { static: true }) modalAddEditInhouseItem: TemplateRef<any>;
    @ViewChild('modalPaymentDetails', { static: true }) modalPaymentDetails: TemplateRef<any>;


    constructor(private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private _commonService: CommonServiceService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private formBuilder: FormBuilder,
        private _dialog: MatDialog) {

        _commonService.setBackNavigation();

        this.route.queryParamMap
            .subscribe((params) => {
                this._params = { ...params.keys, ...params };
                this._organisationId = this._params.params.organisationId
            });


        if (this._organisationId == null || this._organisationId == undefined
            || this._organisationId == "null" || Number.isNaN(this._organisationId)) {
            this._organisationId = 0; // user will add new delegate
        }

    }

    ngOnInit(): void {

        if (this.inhouseDetail) {
            this._organisationId = this.inhouseDetail;
        }

        if (Number(this._organisationId) > 0) {
            this.getInhouseData();
        }

        this.invoiceEditForm = this.formBuilder.group({
            invoiceName: ['', Validators.required],
            invoiceAddress: ['', Validators.required],
            invoiceEmail: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
            invoiceDepartment: [''],
            invoiceOrganisation: [''],
            invoiceTelephone: [''],
            invoiceDate: [' '],
            source: ['none'],
        });

        //this.organisationForm = this.formBuilder.group({
        //    contactName: ['', Validators.required],
        //    contactOrganisation: ['', Validators.required],
        //    contactDepartment: [''],
        //    contactAddress: ['', Validators.required],
        //    contactTelephone: [''],
        //    contactEmail: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
        //    contactPostcode: ['', Validators.required],
        //    notes: ['']

        //});

        this.trainerForm = this.formBuilder.group({
            subject: ['none', Validators.required],
            trainingDate: [this.currentDate.value, Validators.required],
            trainer: ['none', Validators.required],
            fees: ['', Validators.required],
            expense: [''],

        });

        this.itemForm = this.formBuilder.group({
            itemType: ['none', Validators.required],
            itemDate: [this.currentDate.value, Validators.required],
            description: ['', Validators.required],
            quantity: ['', Validators.required],
            amount: ['', Validators.required],

        });

        this.paymentForm = this.formBuilder.group({
            invoiceNumber: ['', Validators.required],
            p_paymentDate: [this.paymentDate_.value],
            p_reconcileDate: [this.reconcileDate.value],
            source: [''],
            subtotal: ['', Validators.required],
            totalTax: ['', Validators.required],
            total: ['', Validators.required],
            chkReconcile: [false]
        });

        this.getSpeakersList();
        this.getSubjectsList();
    }

    goBack() {

        //
        var previousPath = localStorage.getItem("PREVIOUS_PATH");

        if (previousPath != null && previousPath != "" && previousPath != undefined) {
            if (previousPath == "/finance/invoices-list") {
                //this._route.navigateByUrl('/finance/invoices-list?rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
                this.backNavigation.emit();
            }
            else if (previousPath == "/product/bookings?type=true" || previousPath == "/product/bookings?type=false") {
                this.backNavigation.emit();
            }
            else {
                //this.gotoAllInhouse.emit();
                this.backNavigation.emit();
            }
        }




    }

    getSpeakersList() {
        this.loading = true;
        this._coursesService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data;

                this.loading = false;
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getSubjectsList() {

        this._coursesService.getSubjectsList().subscribe((result: any) => {

            if (result.success == true) {
                this.subjectsList = result.data;

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    populateInvoiceAddressForm() {
        if (this.currentInhouse != null) {

            this.invoiceEditForm.controls.invoiceName.setValue(this.currentInhouse.invoiceName);
            this.invoiceEditForm.controls.invoiceAddress.setValue(this.currentInhouse.invoiceAddress);
            this.invoiceEditForm.controls.invoiceEmail.setValue(this.currentInhouse.invoiceEmail);
            this.invoiceEditForm.controls.invoiceDepartment.setValue(this.currentInhouse.invoiceDepartment);
            this.invoiceEditForm.controls.invoiceOrganisation.setValue(this.currentInhouse.organisation);
            this.invoiceEditForm.controls.invoiceTelephone.setValue(this.currentInhouse.invoiceTelephone);
            //this.invoiceEditForm.controls.source.patchValue(this.currentInhouse.invoicePaidBy);
            this.selectedPaymentMethod = this.currentInhouse.invoicePaidBy;

            if (this.currentInhouse.invoicePaidOn != null)
                this.currentDate = new Date(this.currentInhouse.invoicePaidOn);
        }
    }


    //populateOrganisationForm() {
    //    
    //    if (this._organisationId != null) {

    //        this.organisationForm.controls.contactName.setValue(this.currentInhouse.contactName);
    //        this.organisationForm.controls.contactOrganisation.setValue(this.currentInhouse.organisationName);
    //        this.organisationForm.controls.contactDepartment.setValue(this.currentInhouse.department);
    //        this.organisationForm.controls.contactPostcode.setValue(this.currentInhouse.postcode);
    //        this.organisationForm.controls.contactTelephone.setValue(this.currentInhouse.telephone);
    //        this.organisationForm.controls.contactEmail.setValue(this.currentInhouse.contactEmail);
    //        this.organisationForm.controls.contactAddress.setValue(this.currentInhouse.address);
    //        this.organisationForm.controls.notes.setValue(this.currentInhouse.notes);
    //    }
    //}


    get f() { return this.invoiceEditForm.controls; }

    //get p() { return this.organisationForm.controls; }

    get t() { return this.trainerForm.controls; }

    get i() { return this.itemForm.controls; }

    get pd() { return this.paymentForm.controls; }


    onInvoiceEditSubmit() {

        var req = {

            id: Number(this._inhouseId),
            invoiceName: this.invoiceEditForm.controls.invoiceName.value,
            invoiceAddress: this.invoiceEditForm.controls.invoiceAddress.value,
            invoiceEmail: this.invoiceEditForm.controls.invoiceEmail.value,
            invoiceDepartment: this.invoiceEditForm.controls.invoiceDepartment.value,
            invoiceOrganization: this.invoiceEditForm.controls.invoiceOrganisation.value,
            invoiceTelephone: this.invoiceEditForm.controls.invoiceTelephone.value,
            invoicePaidOn: this.invoiceEditForm.controls.invoiceDate.value,
            invoicePaidBy: this.invoiceEditForm.controls.source.value,
        };


        this._delegateService.updateInhouseInvoiceAddress(req).subscribe((result: any) => {


            if (result.success == true) {

                this.modal.dismissAll();
                this.getInhouseData();

            } else {
                //show error popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    //onIOrganisationEditSubmit() {

    //    var req = {

    //        request: {
    //            InHouseId: Number(this._inhouseId),
    //            ContactName: this.organisationForm.controls.contactName.value,
    //            Organisation: this.organisationForm.controls.contactOrganisation.value,
    //            ContactAddress: this.organisationForm.controls.contactAddress.value,
    //            Department: this.organisationForm.controls.contactDepartment.value,
    //            Postcode: this.organisationForm.controls.contactPostcode.value,
    //            Telephone: this.organisationForm.controls.contactTelephone.value,
    //            Email: this.organisationForm.controls.contactEmail.value,
    //            notes: this.organisationForm.controls.notes.value
    //        }
    //    };

    //    this._delegateService.updateInhouse(req).subscribe((result: any) => {


    //        if (result.success == true) {

    //            this.modal.dismissAll();
    //            this.getInhouseData();

    //        } else {
    //            //show errror popup

    //        }

    //    }, (_error: any) => {
    //        //this.loading = false;
    //    })

    //}

    //applyTax(d: any) {
    //    var tax = (d * 20) / 100;
    //    this.trainerForm.controls.totalTax.setValue(tax);
    //    this.trainerForm.controls.total.setValue(Number(d) + Number(tax));
    //}

    addEvent(event: MatDatepickerInputEvent<Date>) {

        if (event.value != null) {
            //
            //this.paymentDate_ = new Date(event.value);
            //this.paymentDate_.setFullYear(event.value.getFullYear());
            //this.paymentDate_.setDate(event.value.getDate());
            //this.paymentDate_.setMonth(event.value.getMonth());
            //this.paymentDate_.setHours(0, 0, 0, 0);

            //this.paymentDate = event.value
            //this.paymentDate.setDate(event.value.getDate());
            // console.log(event.value)

            this.paymentDate_ = this.adjustDateForTimeOffset(event.value);
        }
    }

    dateEvent(event: MatDatepickerInputEvent<Date>) {

        if (event.value != null) {

            this.currentDate = this.adjustDateForTimeOffset(event.value);
        }
    }

    adjustDateForTimeOffset(dateToAdjust) {
        
        var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
        return new Date(dateToAdjust.getTime() - offsetMs);
    }

    reconcileDateEvent(event: MatDatepickerInputEvent<Date>) {
        if (event.value != null) {
            //
            //this.reconcileDate = new Date(event.value);
            //this.reconcileDate.setDate(event.value.getDate());
            //this.reconcileDate.setMonth(event.value.getMonth());
            //this.reconcileDate.setFullYear(event.value.getFullYear());

            //this.reconcileDate.setDate(event.value.getDate());
            // console.log(event.value)

            this.reconcileDate = this.adjustDateForTimeOffset(event.value);
        }
    }

    onPaymentSubmit() {

        
        var req = {

            request: {
                InHouseId: Number(this._inhouseId),
                InvoiceNumber: this.paymentForm.controls.invoiceNumber.value,
                InvoicePaidBy: this.paymentForm.controls.source.value,
                NetTotal: "" + this.paymentForm.controls.subtotal.value,
                Vatrate: "" + this.paymentForm.controls.totalTax.value,
                GrossTotal: "" + this.paymentForm.controls.total.value,
                InvoicePaidOn: this.paymentDate_,// this.paymentForm.controls.p_paymentDate.value,
                ReconcileDate: this.reconcileDate, // this.paymentForm.controls.p_paymentDate.value,
                Paid: this.paymentForm.controls.chkReconcile.value,

            }
        };

        this._delegateService.updateInhousePaid(req).subscribe((result: any) => {


            if (result.success == true) {

                this.paymentForm.reset();

                this.modal.dismissAll();
                this.getInhouseData();

            } else {
                //show errror popup
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })





        //req = {
        //    courseId: this._courseId,
        //    delegateId: this._delegateId,
        //    InvoiceNumber: this.paymentForm.controls.invoiceNumber.value,
        //    InvoicePaidDate: this.paymentForm.controls.p_paymentDate.value,
        //    InvoicePaidBy: this.paymentForm.controls.source.value,
        //    SubTotal: "" + this.paymentForm.controls.subtotal.value,
        //    TotalTax: "" + this.paymentForm.controls.totalTax.value,
        //    //IsInvoicePaid: this._xero.target.checked,
        //    IsReconcile: this.paymentForm.controls.reconcile.value,
        //    CurrencyCode: "GBP",
        //    bookingId: this._bookingId,
        //    isFoundationCert: this._isFoundationCert,
        //    FC_bookingIds: this._FC_bookingId
        //};




        //this._coursesService.updateDelegatePayment(req).subscribe((result: any) => {


        //    if (result.success == true) {

        //        this.paymentForm.reset();

        //        this.paymentForm.controls.source.patchValue("none");
        //        this.modal.dismissAll();
        //        this.getInhouseData();

        //    } else {
        //        //show errror popup

        //    }

        //}, (_error: any) => {
        //    //this.loading = false;
        //})
    }



    printNo(num) {
        return Number(num).toLocaleString('en-GB');
    }

    updateInvoiceAddress() {

        this.populateInvoiceAddressForm();

        this.modal.open(this.modalContentEdit, { size: 'lg' });
    }



    updateOrganisation() {
        //this.populateOrganisationForm();

        //this.modal.open(this.modalEditInhouse, { size: 'lg' });

        const modalRef = this.modal.open(AddEditOrganisationComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = this.currentInhouse;

        modalRef.result.then((result) => {
            this.getInhouseData();
        }, (reason) => {
            
            //console.log('Dismissed action: ' + reason);
        });
    }

    getInhouseData() {


        if (this._organisationId != null) {
            this._delegateService.getInhouseData(Number(this._organisationId)).subscribe((result: any) => {

                if (result.success == true) {

                    if (result.data.length > 0) {

                        this.__inhouse = result.data.reverse();
                        this.getLifetimeValue();

                        if (this.__inhouse.length > 0) {
                            this.currentInhouse = result.data[0][3];
                            this.currentInhouseInvoiceDetails = result.data[0][0];
                            //
                            
                        }
                        
                        //this.currentInhouseTrainingDates = result.data[1];
                        //this.currentInhouseItems = result.data[2];
                    }

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    setInhouse(data) {
        this.tainingInhouse = data;
    }

    setCurrentInhouseTrainingDates(data) {

        this.currentInhouseTrainingDates = data;
    }

    setCurrentInhouseItems(data) {
        
        this.currentInhouseItems = data;

        this.inhouseCourseFeeItems = this.currentInhouseItems.filter(obj => obj.itemType === "Fee");

        //var other = this.currentInhouseItems.splice(0, this.currentInhouseItems.length, ...fee);
        this.inhouseCourseCostItems = this.currentInhouseItems.filter(obj => obj.itemType != "Fee");
        
        this.courseTotalFee = this.inhouseCourseFeeItems.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
        this.courseCost = this.courseTotalFee + (this.courseTotalFee * 20 / 100);

        //this.lifeTimeValue = this.courseTotalFee; //this.lifeTimeValue + this.courseTotalFee;

        this.courseExpense = this.inhouseCourseCostItems.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
    }

    getLifetimeValue() {
        //_inhouse.forEach(function (value) {
            
        //    this.lifeTimeValue = value[2].filter(obj => obj.itemType === "Fee").reduce((sum, item) => sum + item.amount, 0);
            
        //});

        //this.speakersListByCourse.forEach((item: any) => {

        this.__inhouse.forEach((value, index) => {
            
            this.lifeTimeValue = this.lifeTimeValue + (value[2].filter(obj => obj.itemType === "Fee").reduce((sum, item) => sum + (item.amount * item.quantity), 0));

        });
    }

    getStatus(inhouse) {
        // invoice is paid
        if (inhouse.paid == true) {
            return 'green';
        }
        else {
            return this.getColor(this.getDaysCount(inhouse.invoiceDate));
        }
    }

    onPaidClick(data) {

        
        this._inhouseId = data.inHouseId;

        this.paymentForm.controls.invoiceNumber.setValue(data.invoiceNumber);

        if (data.reconcileDate != null)
            this.reconcileDate = new Date(data.reconcileDate);
        else this.reconcileDate = null;

        if (data.invoicePaidOn != null)
            this.paymentDate_ = new Date(data.invoicePaidOn);
        else this.paymentDate_ = null;

        this._reconcile = data.paid;
        this.selectedPaymentMethod = data.invoicePaidBy;
        this.paymentForm.controls.subtotal.setValue(data.netTotal);
        this.applyTax(data.netTotal);

        this.paymentForm.controls.totalTax.setValue(data.vatrate);
        this.paymentForm.controls.total.setValue(data.grossTotal);

        this.modal.open(this.modalPaymentDetails, { size: 'lg' });


    }

    applyTax(d: any) {
        var tax = (d * 20) / 100;
        this.paymentForm.controls.totalTax.setValue(tax.toFixed(2));
        this.paymentForm.controls.total.setValue((Number(d) + Number(tax)).toFixed(2));
    }

    changeTax(val) {
        
        var subtotal = Number(this.paymentForm.controls.subtotal.value);
        this.paymentForm.controls.total.setValue((Number(subtotal) + Number(val)).toFixed(2));
        //if (val == "0" || val == "") {            
        //    this.paymentForm.controls.totalTax.setValue(0);
        //    this.paymentForm.controls.total.setValue(subtotal.toFixed(2));
        //}
        //else {
        //    this.paymentForm.controls.total.setValue((Number(subtotal) + Number(val)).toFixed(2));
        //}
    }

    close2(e: any) {
        
        $("#" + this._inhouseId + "_chk").prop("checked", !$("#" + this._inhouseId + "_chk").prop('checked'));
    }

    close() {
        this.modal.dismissAll();
    }


    //DAYS COUNT
    getDaysCount(dateString): string {
        const givenDate = new Date(dateString);
        const today = new Date();
        const timeDiff = today.getTime() - givenDate.getTime();
        const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24)));
        return daysDiff.toString();
    }

    getColor(value) {
        
        if (value < 28) {
            return 'green';
        } else if (value > 28 && value <= 49) {
            return 'amber';
        } else if (value > 63) {
            return 'red';
        } else if (value > 49) {
            return 'redborder-simple';
        }
    }

    //showInhouseDetails(data) {
    //    
    //}

    //******* START INHOUSE EXPENSES *********/

    addInhouseItems(id) {

        this._inhouseId = id;
        this._inhouseItemId = 0;

        this._addEditInhouseItemTitle = "Add Inhouse Item";
        this.itemForm.reset();
        this.itemForm.controls.itemType.patchValue("none");
        this.currentDate = new Date();

        this.modal.open(this.modalAddEditInhouseItem, { size: 'lg' });
    }

    onItemFormSubmit() {

        var req = {

            request: {
                ItemId: this._inhouseItemId,
                InhouseId: this._inhouseId,
                DateAdded: this.itemForm.controls.itemDate.value,
                ItemType: this.itemForm.controls.itemType.value,
                ItemDescription: this.itemForm.controls.description.value,
                Quantity: parseInt(this.itemForm.controls.quantity.value),
                Amount: parseInt(this.itemForm.controls.amount.value),
                OrganisationId: Number(this._organisationId)
            }
        };
        

        this._delegateService.updateInhouseItems(req).subscribe((result: any) => {

            if (result.success == true) {

                this.itemForm.reset();

                this.itemForm.controls.itemType.patchValue("none");

                this.modal.dismissAll();
                this.getInhouseData();

            } else {
                //show errror popup
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }


    editInhouseItemClick(data) {
        this.populateInhouseItemForm(data);
        this.modal.open(this.modalAddEditInhouseItem, { size: 'lg' });
    }

    deleteTraining(training) {
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this training?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {
            
            if (training.inHouseId > 0) {
                this._delegateService.deleteInhouse(training.inHouseId).subscribe((result: any) => {

                    if (result.success == true) {
                        this.getInhouseData();

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    populateInhouseItemForm(data) {
        if (data != null) {

            this._inhouseId = data.inhouseId;
            this._inhouseItemId = data.itemId;
            this.itemForm.controls.itemType.setValue(data.itemType);
            this.currentDate = data.dateAdded;
            this.itemForm.controls.description.setValue(data.itemDescription);
            this.itemForm.controls.quantity.setValue(data.quantity);
            this.itemForm.controls.amount.setValue(data.amount);

            this._addEditInhouseItemTitle = "Edit Inhouse Item";
        }
    }

    deleteInhouseItemClick(_data) {
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            if (_data.itemId > 0) {
                this._delegateService.deleteInhouseExpense(_data.itemId).subscribe((result: any) => {

                    if (result.success == true) {
                        this.getInhouseData();

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    //******* END OF INHOUSE EXPENSES *********/



    //******* START INHOUSE TRAINER *********/

    addTrainer(id) {


        this._inhouseId = id;
        this._inhouseTrainingDateId = 0;

        this.trainerForm.reset();
        this.trainerForm.controls.trainer.patchValue("none");
        this.trainerForm.controls.subject.patchValue("none");
        this.currentDate = new Date();
        this._addEditTrainer = "Add Training Dates / Trainer";

        this.modal.open(this.modalAddEditTrainer, { size: 'lg' });
    }

    onTrainerSelected(event) {
        this.selectedTrainerId = event.value;
        this.selectedTrainer = event.source.selected._element.nativeElement.innerText;
    }

    onSubjectSelected(event) {
        this.selectedSubjectId = event.value;
        this.selectedSubject = event.source.selected._element.nativeElement.innerText;
    }

    onTrainerFormSubmit() {
        
        var req = {

            request: {
                Id: this._inhouseTrainingDateId,
                InHouseId: this._inhouseId,
                TrainingDate: this.trainerForm.controls.trainingDate.value,
                TrainerName: this.selectedTrainer, //this.trainerForm.controls.trainer.value,
                SpeakerId: this.selectedTrainerId,
                TrainerFee: parseInt(this.trainerForm.controls.fees.value),
                TrainerExpense: parseInt(this.trainerForm.controls.expense.value),
                Subject: this.selectedSubject,
                SubjectId: this.selectedSubjectId//this.trainerForm.controls.subject.value,
            }
        };

        this._delegateService.updateInhouseTrainer(req).subscribe((result: any) => {

            if (result.success == true) {

                this.trainerForm.reset();

                this.trainerForm.controls.trainer.patchValue("none");
                this.trainerForm.controls.subject.patchValue("none");

                this.modal.dismissAll();
                this.getInhouseData();

            } else {
                //show errror popup
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    editTrainingDatesClick(data) {
        this.populateTrainingDatesForm(data);
        this.modal.open(this.modalAddEditTrainer, { size: 'lg' });
    }

    populateTrainingDatesForm(data) {
        if (data != null) {

            

            this._inhouseId = data.inHouseId;
            this._inhouseTrainingDateId = data.id;
            this.trainerForm.controls.subject.setValue(data.subjectId);
            this.currentDate = data.trainingDate;
            //this.trainerForm.controls.trainingDate.setValue(data.trainingDate);
            this.trainerForm.controls.trainer.setValue(data.speakerId);
            this.trainerForm.controls.fees.setValue(data.trainerFee);
            this.trainerForm.controls.expense.setValue(data.trainerExpense);

            this.selectedTrainerId = data.speakerId;
            this.selectedTrainer = data.trainerName;

            this.selectedSubjectId = data.subjectId;

            this._addEditTrainer = "Edit Training Dates / Trainer"
        }
    }

    deleteTrainingDatesClick(_data) {
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to delete this record?", title: "Delete" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            if (_data.id > 0) {
                this._delegateService.deleteInhouseTrainer(_data.id).subscribe((result: any) => {

                    if (result.success == true) {
                        this.getInhouseData();

                    } else {

                        //this._commonService.error(result.data.desc);
                    }

                }, (_error: any) => {
                    //this.loading = false;
                })
            }

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    //******* END OF INHOUSE TRAINER *********/

    allInhouse() {
        this.gotoAllInhouse.emit();
    }

    upcomingCourses(param) {
        this.gotoAllInhouse.emit(param);
    }


    addNewTraining() {
        const modalRef = this.modal.open(AddEditInhouseComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = { organisationId: this._organisationId }

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getInhouseData());
    }

    editTraining(param) {
        const modalRef = this.modal.open(AddEditInhouseComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getInhouseData());
    }
}
