import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonServiceService } from '../../../common/_services/common-service.service';


@Component({
    selector: 'app-otp-verification',
    templateUrl: './otp-verification.component.html',
    styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit {
    isNumeric: boolean;
    maxLength: number;
    otpValue: string;
    ResendotpValue: string;
    placeholder: string = "";
    text: string;
    otpForm: FormGroup;

    @Output() otpSend: EventEmitter<any> = new EventEmitter<any>();
    @Output() ResendotpSend: EventEmitter<any> = new EventEmitter<any>();
    @Output() Close = new EventEmitter<any>();

    timeLeft: number;
    configTime: number;
    intervalFlag = false;
    interval: any;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _commonService: CommonServiceService) {
        this.startTimer();
    }

    ngOnInit() {
        
        this.text = "Enter OTP sms send to your Mobile Number";
        this.maxLength = this.data.maxLength;

        if (this.data.otpResendWait == 0) {
            this.timeLeft = 60;
            this.configTime = 60;
        } else {
            this.timeLeft = this.data.otpResendWait;
            this.configTime = this.data.otpResendWait;
        }

        for (var i = 0; i < this.maxLength; i++) {
            this.placeholder += "x";
        }

         this._commonService.OtpClear.subscribe((res: any) => {
            
            this.otpValue = "";
        });

    }

    send() {
        this.otpSend.emit(this.otpValue);
    }

    ResendOTP() {
        this.pauseTimer();
        this.timeLeft = this.configTime;
        this.startTimer();

        this.ResendotpSend.emit(this.ResendotpValue);
    }

    CloseOTP() {
        this.Close.emit("Close");
    }

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.intervalFlag = false;
                this.timeLeft--;
            } else {
                this.intervalFlag = true;
            }
        }, 1000)
    }

    pauseTimer() {
        clearInterval(this.interval);
    }


}
