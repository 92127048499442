import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard/dashboard.component';
import { ViewScheduleComponent } from './view-schedule/view-schedule.component';
import { YearlyScheduleComponent } from './yearly-schedule/yearly-schedule.component';

const routes: Routes = [
    {
        path: '**',
        redirectTo: 'others/404'
    },
    { path: 'schedule', component: DashboardComponent },
    { path: 'yearly-schedule', component: DashboardComponent },
    { path: 'course-scheduler', component: DashboardComponent },
    {
        path: '',
        redirectTo: 'schedule',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchedulesRoutingModule { }
