<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">

    <div class="row">
        <div class="col-8 pull-left">
            <div class="row">
                <div class="col-12 pull-left">
                    <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
                </div>

                <div class="col-12 pull-left">
                    <h2 class="mt-3 mb-2 text-second font-ag-light" style="font-size: 1.5rem;">
                        <span class="text-main font-ag-bold">Remittance Advice</span>
                    </h2>
                </div>
                <div class="col-12 pull-left">
                    <h2 class="mb-3 text-second font-ag-light" style="font-size: 1.2rem;">
                        <span class="text-second font-ag-bold">{{ currentInvoice.supplierName }}</span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="col-4 text-left">
            <div class="pull-right">
                <h2 class="mb-3 text-second font-ag-light" style="font-size: 14px;">
                    <span class="text-second font-ag-bold">Act Now Training Ltd</span>
                    <p>
                        Annexe 2, Suite 45,
                        <br />
                        Batley Business Park,
                        <br />
                        Technology Drive, Batley
                        <br />
                        West Yorkshire, WF17 6ER
                        <br />
                        Tel: 01924 470252
                        <br />
                        Email: info@actnow.org.uk
                    </p>
                </h2>
            </div>
            <!--<span class="mb-3 pull-right" style="font-weight: bold;">{{ coursesCountDateFilter.value.start | date:'longDate' }} - {{ coursesCountDateFilter.value.end | date:'longDate' }}</span>-->
        </div>

        <table class="full-width mt-5">
            <tr class=" mt-5" style="border-bottom: 2pt solid black; font-weight: bold;">
                <td style="width:50%" class="v-align-top">Invoice number (Invoice date)</td>
                <td style="width:15%" class="v-align-top">Date paid</td>
                <td style="width:20%" class="v-align-top">Payment method</td>
                <td style="width:15%" class="v-align-top text-right">Amount</td>
            </tr>
        </table>

        <table class="full-width text-second">
            <tr style="border-bottom: 2pt solid black;">
                <td style="width:50%" class="v-align-top">{{currentInvoice.invoiceNumber}} ({{currentInvoice.invoiceDate | date:'dd-MM-yy'}})</td>
                <td style="width:15%" class="v-align-top">{{currentInvoice.datePaid | date:'dd-MM-yy'}}</td>
                <td style="width:20%" class="v-align-top">{{currentInvoice.paymentMethod}}</td>
                <td style="width:15%" class="v-align-top text-right">&#163;{{currentInvoice.grossTotal}}</td>
            </tr>
        </table>

        <table class="full-width pull-right">
            <tr style="font-weight: bold;">
                <td style="width:100%" class="v-align-top  text-right" colspan="4">Total &nbsp;&nbsp;&nbsp;&nbsp;&#163;{{currentInvoice.grossTotal}}</td>
            </tr>
        </table>

        

    </div>
</div>





<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Supplier</span> DETAILS
        </h2>

    </div>
    <div class="col-lg-3 pt-1">

        <button class="book-btn pull-right filter-apply" (click)="goBack()">
            Back
        </button>

        <button class="book-btn pull-right filter-apply mr-1" (click)="addInvoice()">
            Add invoice
        </button>

    </div>

</div>


<div class="row no-bg pb-0 p-3 ml-0 mb-0 m-2 pt-0 mt-0">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <label class="text-second-- top-dd">{{currentSupplier.name}}</label>

            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="row no-bg">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">


            </div>
            <div class="col-md-4">
                <label class="text-second fw-normal pull-right">
                    Lifetime Value:
                    <span class="income-box">
                        &#163;{{_totalValue}}
                    </span>
                </label>
            </div>
        </div>
    </div>

</div>


<div class="row outer-div bg-lg ml-0 mb-0 mt-0 m-2 pt-0 p-3">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Telephone:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.telephone}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Email:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.email}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Address 1:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.address1}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Address 2:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.address2}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Town/City:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.city}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Postcode:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.postcode}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Bank name:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.bankName}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Sort code:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.sortCode}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Account number:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.accountNumber}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Bank address:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.bankAddress}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">VAT number:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.vatnumber}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Company number:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentSupplier.companyNumber}}</label>
                    </div>
                </div>

                <div class="btn-holder">
                    <button class="book-btn pull-right filter-apply" (click)="editDetials(currentSupplier)">
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-8">


        <div class="row">
            <div class="col-md-12">

                <label class="text-second p-1">Invoices</label>


                <label class="text-second p-1 pull-right">{{invoices.length}}</label>

                <hr class="u-line-" />



                <ag-grid-angular #agGrid
                                 id="invoicesReceivedGrid"
                                 class="ag-theme-alpine ag-theme-balham"
                                 [domLayout]="domLayout"
                                 [columnDefs]="columnDefs"
                                 [defaultColDef]="defaultColDef"
                                 [rowData]="invoices"
                                 [animateRows]="true"
                                 (gridReady)="onGridReady($event)"
                                 (cellClicked)="onCellClicked($event)"
                                 (rowClicked)="onRowClicked()"
                                 [gridOptions]="gridOptions"
                                 [pagination]="true"
                                 [paginationPageSize]="paginationPageSize"
                                 [frameworkComponents]="frameworkComponents">
                </ag-grid-angular>

            </div>
        </div>
    </div>
