import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, first, catchError } from 'rxjs/operators';
import { RequestService } from './request-service';

@Injectable({
    providedIn: 'root'
})
export class DelegateService {

    baseurl: string;
    token: string;
    _header_options = {};

    constructor(private _router: Router,
        private http: HttpClient,
        @Inject('BASE_URL') base: string,
        private modal: NgbModal,
        private _requestService: RequestService) {
        this.baseurl = base;
        this.token = 'Bearer ' + localStorage.getItem('Token');

        this._header_options = {
            'Content-Type': 'application/json ',
            'Authorization': this.token
        }
    }

    


    public get currentUserValue(): any {
        return "";// this.currentUserSubject;
    }

    insertDelegate(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/InsertDelegate',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertInhouse(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/InsertInhouse',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertOrganisation(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/InsertOrganisation',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertProduct(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/InsertProduct',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getDelegateData(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetDelegateData',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    searchDelegateByEmail(email: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/SearchDelegateByEmail',
            {
                params: {
                    email: email
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getInhouseData(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetInhouseData',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteDelegate(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteDelegate',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteOrganisation(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteOrganisation',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteInhouse(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteInhouse',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteInhouseTrainer(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteInhouseTrainer',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }


    deleteProduct(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteProduct',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }


    deleteInhouseExpense(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteInhouseExpense',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getDelegatesList(req) {


        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        //return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetDelegatesList',
        //    {
        //        headers
        //    })
        //    .pipe(map(resp => {
        //        return resp;
        //    }));


        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/GetDelegatesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getMarketingReportData() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetMarketingReportData',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    getInhouseList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/GetInhouseList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    getOrganisationList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/GetOrganisationList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    getProductList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/GetProductList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getProductTitle() {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetProductTitle',
            { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getPurchasesList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/GetPurchasesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }



    getFoundationCertDDLists() {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetFoundationCertDDLists',
            {
                headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }


    getInvoicesList(req) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/GetInvoicesList',
            req, { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getApprovalsList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetApprovalsList',
            {
                headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }

    getNewBooking() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });
        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetNewBooking',
            {
                headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }


    insertBooking(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/InsertBooking',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertProductBooking(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/InsertProductBooking',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateInhouseTrainer(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateInhouseTrainer',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }


    updateInhouseItems(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateInhouseItems',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    approveBooking(req: any) {
        debugger;
        return this._requestService.POST_REQUEST(this._header_options, this.baseurl, 'api/Delegates/v1/ApproveBooking', req);


        //let headers = new HttpHeaders({
        //    'Content-Type': 'application/json ',
        //    'Authorization': this.token
        //});

        //return this.http.post<any>(this.baseurl + 'api/Delegates/v1/ApproveBooking',
        //    JSON.stringify(req), { headers })
        //    .pipe(map(resp => {
        //        debugger;
        //        return resp;
        //    }));
    }





    rejectBooking(req: any) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/RejectBooking',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateDelegateInvoiceAddress(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateDelegateInvoiceAddress',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateInhouseInvoiceAddress(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateInhouseInvoiceAddress',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateInhouse(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateInhouse',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateInhousePaid(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateInhousePaid',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    updateDelegatePostalAddress(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/UpdateDelegatePostalAddress',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    changeDelegateCourse(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegates/v1/ChangeDelegateCourse',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertDelegateProduct(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/InsertDelegateProduct',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    insertCreditNote(req: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        return this.http.post<any>(this.baseurl + 'api/Delegate/v1/InsertCreditNote',
            JSON.stringify(req), { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }
    getCreditNotesList() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });


        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/GetCreditNotesList', { headers })
            .pipe(map(resp => {
                return resp;
            }));
    }

    deleteCreditNote(id: any) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json ',
            'Authorization': this.token
        });

        //var _title = JSON.stringify(title);

        return this.http.get<any>(this.baseurl + 'api/Delegate/v1/DeleteCreditNote',
            {
                params: {
                    id: id
                }, headers
            })
            .pipe(map(resp => {
                return resp;
            }));
    }
}
